import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Dimensions, Modal, Platform, Text, View } from "react-native";
import tailwind from "twrnc";
import { currentUser, deleteConfirmModalVisible, theme, windowOrientation } from "../../signals/signals";
import { useFonts, Roboto_700Bold, Roboto_400Regular } from "@expo-google-fonts/roboto";
import Button from "../base/Button";
import axios from "axios";
import { API_URL, queryClient } from "../base/Constants";
import { BottomSheetContext } from "../base/ApplicationContext";
import * as ScreenOrientation from "expo-screen-orientation";

const DeleteVideoConfirmationModal = ({ video }) => {
  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        windowOrientation.value = getOrientation(orientationInfo.orientation);
      }, 300);
    });
  }, []);

  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };

  const isMobileWebFormat = Platform.OS === "web" &&
    (
      (windowOrientation.value === "portrait" && Dimensions.get("screen").width < 768) ||
      (windowOrientation.value === "landscape" && Dimensions.get("screen").height < 768)
    );

  const modalWidth = Platform.OS === "web" && !isMobileWebFormat ? "50%" : "100%";
  const { setBottomSheetVisible } = useContext(BottomSheetContext);

  let fonts = useFonts({ Roboto_700Bold, Roboto_400Regular });
  if (!fonts) return;

  return (
    <Modal
      visible={deleteConfirmModalVisible.value}
      animationType="slide"
      transparent={true}
      onRequestClose={() => {
        deleteConfirmModalVisible.value = !deleteConfirmModalVisible.value;
      }}
    >
      <View
        style={tailwind`rounded-lg h-auto p-6 self-center bg-[${theme.value.modalBackgroundColor}] border-[0.5px] border-[${theme.value.textColor}] flex-col w-[${modalWidth}] max-h-[400px] absolute bottom-40`}
      >
        <View style={tailwind`w-full pr-4`}>
          <Text
            style={[
              tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold self-center py-2 text-[20px]`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            Delete Video
          </Text>
          <View style={tailwind`self-center`}>
            <Text
              style={[tailwind`text-[${theme.value.textColor}]`, { fontFamily: "Roboto_400Regular" }]}
            >
              Are you sure you wish to delete this video?
            </Text>
          </View>
          <View style={tailwind`self-center`}>
            <Text
              style={[
                tailwind`text-[${theme.value.textColor}]`,
                { fontFamily: "Roboto_400Regular" }
              ]}
            >
              Warning, this operation cannot be undone.
            </Text>
          </View>
        </View>
        <View style={tailwind`flex-row self-center mt-4`}>
          <View>
            <Button
              title="Delete"
              onPress={() => {
                axios
                  .delete(
                    API_URL + "videos/" + video.id,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        authorization: currentUser.value.token,
                      },
                    }
                  )
                  .then((res) => {
                    queryClient.invalidateQueries([
                      "videos/provider/replay/" + currentUser.value.id,
                    ]);
                    queryClient.invalidateQueries([
                      "videos/provider/" + currentUser.value.id,
                    ]);
                    queryClient.invalidateQueries([
                      "videos/provider/ondemand/" + currentUser.value.id,
                    ]);
                    queryClient.invalidateQueries([
                      "videos/provider/youtube/" + currentUser.value.id,
                    ]);

                    deleteConfirmModalVisible.value = false;
                    setBottomSheetVisible(false);
                    return res.data;
                  });
              }}
            />
          </View>
          <View>
            <Button
              title="Cancel"
              onPress={() => {
                deleteConfirmModalVisible.value = !deleteConfirmModalVisible.value;
              }}
            />
          </View>
        </View>
      </View >
    </Modal>
  );
}

DeleteVideoConfirmationModal.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export { DeleteVideoConfirmationModal as default }