import React, { useState } from "react";
import PropTypes from "prop-types";
import { ActivityIndicator, Text, View } from "react-native";
import tailwind from "twrnc";
import Button from "../components/base/Button";
import axios from "axios";
import { API_URL, queryClient } from "../components/base/Constants";
import { CheckoutWebView } from "../components/checkout/CheckoutWebView";
import HorizontalRule from "../components/base/HorizontalRule";
import { currentUser, theme } from "../signals/signals";
import { QueryClientProvider, useQuery } from "@tanstack/react-query";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import { PrepareReplayOrder } from "../components/checkout/RevolutPopupHandler";
import ApiError from "../components/error/ApiError";

const ReplayOrderSummaryScreen = (props) => {
  const { videoId } = props.route.params;

  return (
    <QueryClientProvider client={queryClient}>
      <OrderSummaryPanel videoId={videoId} />
    </QueryClientProvider>
  );
};

const OrderSummaryPanel = ({ videoId }) => {
  const [order, setOrder] = useState();

  const { isLoading, error, data, isFetching } = useQuery(
    ["replay" + videoId],
    () =>
      axios
        .get(API_URL + "booking/replay/" + videoId, {
          headers: { authorization: currentUser.value.token },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err))
  );

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;
  if (isLoading || isFetching) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const video = data;
  const { title, price } = video;
  const displayPrice = Number(price).toFixed(2);

  return (
    <View
      style={tailwind`pt-10 w-full h-full bg-[${theme.value.backgroundColor}]`}
    >
      <View style={tailwind`max-w-[800px] min-w-[300px] self-center`}>
        {!order ? (
          <>
            <View style={tailwind`mb-4`}>
              <Text
                style={[
                  tailwind`self-center font-semibold text-[${theme.value.textColor}] text-[20px]`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                Order Summary
              </Text>
            </View>
            <View style={tailwind`px-4 py-8 self-center`}>
              <View>
                <Text
                  style={[
                    tailwind`text-[${theme.value.textColor}] font-semibold self-center`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  Order for &quot;{title}&quot;
                </Text>
              </View>

              <View style={tailwind`flex-row mt-8`}>
                <View style={tailwind`grow`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.value.textColor}] font-semibold`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    Price
                  </Text>
                </View>
                <View>
                  <Text
                    style={[
                      tailwind`text-[${theme.value.textColor}]`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    &pound; {displayPrice}
                  </Text>
                </View>
              </View>
              <HorizontalRule />
              <View style={tailwind`flex-row mt-4 py-4`}>
                <View style={tailwind`grow`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.value.textColor}] font-bold`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    Total
                  </Text>
                </View>
                <View>
                  <Text
                    style={[
                      tailwind`text-[${theme.value.textColor}] font-bold`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    &pound;
                    {displayPrice}
                  </Text>
                </View>
              </View>
              <HorizontalRule />
            </View>
            <View style={tailwind`self-center`}>
              <Button
                title="Pay"
                onPress={() =>
                  PrepareReplayOrder(currentUser, videoId, (order) =>
                    setOrder(order)
                  )
                }
              />
            </View>
          </>
        ) : (
          <>
            <View style={tailwind`mb-4`}>
              <Text
                style={[
                  tailwind`self-center font-semibold text-[${theme.value.textColor}]`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                Complete Order
              </Text>
            </View>
            <View style={tailwind`self-center`}>
              <CheckoutWebView order={order} setOrder={setOrder} />
            </View>
          </>
        )}
      </View>
    </View>
  );
};

ReplayOrderSummaryScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      videoId: PropTypes.number.isRequired,
    }),
  }),
};

OrderSummaryPanel.propTypes = {
  videoId: PropTypes.number.isRequired,
};

export { ReplayOrderSummaryScreen };
