import PropTypes from "prop-types";
import { Pressable } from "react-native";
import tailwind from "twrnc";
import { Image } from "@rneui/themed";
import { theme } from "../../../signals/signals";
import { handleMeLeavingCallOnly } from "../handlers/webRTCHandler";

const CallEndEnabled = ({ userId, roomId }): JSX.Element => {
  return (
    <Pressable
      style={tailwind`m-1`}
      onPress={() => handleMeLeavingCallOnly(userId, roomId)}
    >
      <Image
        resizeMode="contain"
        style={tailwind`h-[25px] w-[25px]`}
        source={require("../../../assets/hangup-icon.png")}
        tintColor={theme.value.redColor}
      />
    </Pressable>
  );
};

CallEndEnabled.propTypes = {
  userId: PropTypes.number.isRequired,
  roomId: PropTypes.string.isRequired,
};

export { CallEndEnabled as default };
