import { Pressable } from "react-native";
import tailwind from "twrnc";
import { Image } from "@rneui/themed";
import { theme } from "../../../signals/signals";

const CallEndDisbled = (): JSX.Element => {
  return (
    <Pressable style={tailwind`m-1`} disabled={true} onPress={() => null}>
      <Image
        resizeMode="contain"
        style={tailwind`h-[25px] w-[25px]`}
        source={require("../../../assets/hangup-icon.png")}
        tintColor={theme.value.disabledColor}
      />
    </Pressable>
  );
};

export { CallEndDisbled as default };
