import React, {
  SafeAreaView,
  View,
  Text,
  Platform,
  Dimensions,
  Linking,
  ActivityIndicator,
  Pressable,
} from "react-native";
import tailwind, { Style } from "twrnc";
import {
  currentUser,
  focusedScreen,
  isEmailAddressVerified,
  isProfileComplete,
  modalVisible,
  theme,
} from "../../signals/signals";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import Button from "../base/Button";
import { useContext, useEffect, useRef } from "react";
import { BottomSheetContext } from "../base/ApplicationContext";
import {
  API_URL,
  DIFFICULTY_ADVANCED,
  DIFFICULTY_ALL,
  DIFFICULTY_BEGINNER,
  DIFFICULTY_INTERMEDIATE,
} from "../base/Constants";
import Tag from "../base/Tag";
import axios from "axios";
import { useNavigation } from "@react-navigation/native";
import { checkoutStalledOrder } from "../checkout/RevolutPopupHandler";
import { useSignal } from "@preact/signals-react";
import PublishConfirmationModal from "../modals/PublishConfirmationModal";
import { Image } from "@rneui/themed";
import Clipboard from "@react-native-clipboard/clipboard";
import Toast from "react-native-toast-message";
import EditLiveEvent from "../event-screen/edit/EditLiveEvent";
import { FitCentrEvent } from "../broadcast-screen/types/FitCentrEvent";

const ViewLiveStreamEvent = ({ event }: { event: FitCentrEvent }) => {
  const isBooked = useSignal(false);
  const isStalled = useSignal(false);
  const streamCanBeJoined = useSignal(false);
  const streamCanBeStarted = useSignal(false);
  const eventCanBeEdited = useSignal(true);
  const isHistoric = useSignal(false);
  const bookingsCount = useSignal(0);
  const eventDeepLink = useSignal("");

  const startDate = event.startDate;
  const time = dayjs(event.startDate).format("HH:mm");
  const isPublic = event.isPublic;
  const duration = event.duration;
  const shortDescription = event.shortDescription;
  const longDescription = event.longDescription;
  const difficultyId = event.difficulty.id;
  const difficultyName = event.difficulty.name;
  const hostId = event.userId;
  const tags = event.scheduleTags;
  const bookings = event.bookings;
  const price = event.price;

  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  let startTimeButtonLabel;
  if (dayjs.utc().diff(startDate) > 0) {
    startTimeButtonLabel = "Expired";
  } else {
    startTimeButtonLabel = dayjs.utc().to(startDate);
  }

  const typeName = "Livestream";
  const headerColour = theme.value.calendarEventBroadcastColor;
  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);
  const navigation = useNavigation();
  const revolutPublicId = useSignal("");
  const revolutOrderid = useSignal("");
  const paymentId = useSignal(null);
  const isHost = event.userId === currentUser.value.id;
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;

  const handleInitialUrl = async () => {
    const initialURL = await Linking.getInitialURL();
    const protocol = initialURL.split("://")[0];
    eventDeepLink.value =
      protocol +
      "://" +
      initialURL.split("://")[1].split("/")[0] +
      "/live/" +
      event.id;
  };

  useEffect(() => {
    isHistoric.value = dayjs(startDate).diff(dayjs(), "minute") < -duration;
    if (bookings && bookings.length) {
      bookings.forEach((booking) => {
        if (
          (booking.user.id === currentUser.value.id || isHost) &&
          booking.eventDate === event.startDate
        ) {
          if (booking.payments && booking.payments.length) {
            booking.payments.forEach((payment) => {
              paymentId.value = payment.id;
              isStalled.value = true;
              if (payment.responses) {
                payment.responses.forEach((response) => {
                  if (
                    response.state === "COMPLETED" ||
                    response.state === "AUTHORISED"
                  ) {
                    isBooked.value = true;
                    isStalled.value = false;
                    bookingsCount.value++;
                  }
                  revolutPublicId.value = response.revolutPublicId;
                  revolutOrderid.value = response.revolutOrderid;
                });
              } else {
                isStalled.value = true;
              }
            });
          } else {
            isStalled.value = false;
          }
        } else {
          isStalled.value = false;
        }
      });
    }
    handleInitialUrl();
  }, []);

  let difficultyStyle: Style;
  switch (difficultyId) {
    case DIFFICULTY_ADVANCED:
      difficultyStyle = tailwind`bg-[${theme.value.difficultyAdvancedColor}] rounded-full p-[1px] px-4 ml-2`;
      break;
    case DIFFICULTY_INTERMEDIATE:
      difficultyStyle = tailwind`bg-[${theme.value.difficultyIntermediateColor}] rounded-full p-[1px] px-4 ml-2`;
      break;
    case DIFFICULTY_BEGINNER:
      difficultyStyle = tailwind`bg-[${theme.value.difficultyBeginnerColor}] rounded-full p-[1px] px-4 ml-2`;
      break;
    case DIFFICULTY_ALL:
    default:
      difficultyStyle = tailwind`bg-[${theme.value.difficultyBeginnerColor}] rounded-full p-[1px] px-2 ml-2`;
      break;
  }

  useEffect(() => {
    if (
      dayjs(startDate).diff(dayjs(), "minute") < 30 &&
      dayjs(startDate).diff(dayjs(), "minute") > -duration
    ) {
      streamCanBeJoined.value = true;
    }
    if (
      dayjs(startDate).diff(dayjs(), "minute") < 60 &&
      dayjs(startDate).diff(dayjs(), "minute") > -duration &&
      !event.broadcastEndDate
    ) {
      streamCanBeStarted.value = true;
    }

    if (event.broadcastEndDate) {
      eventCanBeEdited.value = false;
    }
  }, []);

  const handleLivestreamBooking = () => {
    axios
      .post(
        API_URL + "booking",
        {
          eventId: event.id,
          eventDate: event.startDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.value.token,
          },
        }
      )
      .then(async (res) => {
        setBottomSheetVisible(false);
        navigation.navigate({
          name: "OrderSummaryScreen",
          params: {
            bookingId: res.data.id,
            userScheduleId: res.data.userSchedule.id,
          },
        } as never);
      })
      .catch((err) => console.log(err));
  };

  return (
    <SafeAreaView style={tailwind`bg-[${theme.value.backgroundColor}]`}>
      <View style={tailwind`pb-10`}>
        <View
          style={tailwind`${
            headerColour &&
            "border-t-[" + headerColour + "] border-t-[4px] p-1 min-h-[15px]"
          }`}
        />
        <View style={tailwind`flex-row m-2 w-[600px] self-center`}>
          <View style={tailwind`w-full pr-4`}>
            <Text
              style={[
                tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold self-center pt-2 text-[20px]`,
                { fontFamily: "Roboto_700Bold" },
              ]}
            >
              {typeName}
            </Text>
            <View style={tailwind`flex-row self-center`}>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {dayjs(startDate).format("ddd MMM DD")}
              </Text>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] mx-2`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                at
              </Text>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {time}
              </Text>
            </View>
            {hostId === currentUser.value.id ? (
              <View
                style={tailwind`${
                  isMobileWebFormat
                    ? "max-w-[300px] self-center my-2"
                    : "absolute right-4 top-4"
                }`}
              >
                <Text
                  style={tailwind`font-semibold text-[${
                    isPublic ? theme.value.greenColor : theme.value.redColor
                  }]`}
                >
                  {isPublic ? "Published" : "NOT PUBLISHED"}
                </Text>
              </View>
            ) : null}
            <View
              style={tailwind`flex-row ${
                isMobileWebFormat
                  ? "max-w-[300px] self-center my-2"
                  : "self-center"
              }`}
            >
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] text-[12px] font-semibold ml-1 self-start grow`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {duration} MIN
              </Text>
              <View style={tailwind`flex-row`}>
                <View style={tailwind`p-[1px] px-4`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.value.difficultyTextColour}] font-semibold text-[12px]`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    Difficulty{" "}
                  </Text>
                </View>
                <View style={difficultyStyle}>
                  <Text
                    style={[
                      tailwind`text-[${theme.value.difficultyTextColour}] font-semibold text-[12px]`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    {difficultyName.toUpperCase()}
                  </Text>
                </View>
              </View>
            </View>
            {!isBooked.value ? (
              <>
                <View style={tailwind`flex-row mr-3`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.value.coinsIconColor}] font-semibold my-3 ml-2`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    &pound;{price.toFixed(2)}
                  </Text>
                </View>
              </>
            ) : null}
            {isBooked.value ? (
              <View style={tailwind`self-center mr-3`}>
                <Text
                  style={[
                    tailwind`text-[${theme.value.coinsIconColor}] font-semibold my-3 ml-2`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  {isHost ? bookingsCount.value + " Confirmed" : "Booked"}
                </Text>
              </View>
            ) : null}
            <View style={tailwind`mt-4 mb-1`}>
              <Text
                style={[
                  tailwind`self-center text-[${theme.value.textColor}] font-semibold`,
                  { fontFamily: "Roboto_700Bold" },
                ]}
              >
                {shortDescription}
              </Text>
            </View>
            <View style={tailwind`mb-4 self-center`}>
              <View style={tailwind` flex-row`}>
                <Text
                  style={[
                    tailwind`self-center text-[${theme.value.textColor}] text-xs mr-2`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  {eventDeepLink.value}
                </Text>
                <Pressable
                  onPress={() => {
                    Clipboard.setString(eventDeepLink.value);
                    Toast.show({
                      type: "success",
                      text1: "Event link copied",
                    });
                  }}
                >
                  <Image
                    style={tailwind`w-[16px] h-[16px]`}
                    source={require("../../assets/copy-icon.png")}
                    PlaceholderContent={<ActivityIndicator />}
                  />
                </Pressable>
              </View>
            </View>
            <View style={tailwind`mb-4`}>
              <Text
                style={[
                  tailwind`self-center text-[${theme.value.textColor}] font-semibold mr-2`,
                  { fontFamily: "Inter_400Regular" },
                ]}
              >
                {longDescription}
              </Text>
            </View>
            <View
              style={tailwind`flex-row flex-wrap ${
                isMobileWebFormat ? "max-w-[300px] self-center" : null
              }`}
            >
              {tags.map((tag) => {
                return (
                  <Tag
                    key={tag.id}
                    label={tag.tag.tagName}
                    colorKey={tag.tag.tagColorKey}
                  />
                );
              })}
            </View>
          </View>
        </View>
        <View style={tailwind`self-center flex-row`}>
          {hostId === currentUser.value.id ? (
            <>
              <Button
                title="Edit"
                disabled={bookingsCount.value !== 0 || !eventCanBeEdited.value}
                onPress={() => {
                  setBottomSheetContent(<EditLiveEvent event={event} />);
                }}
              />
              {Platform.OS === "web" &&
              streamCanBeStarted.value &&
              !isHistoric.value &&
              isPublic &&
              isEmailAddressVerified.value ? (
                <Button
                  title="Stream"
                  onPress={() => {
                    setBottomSheetVisible(false);
                    focusedScreen.value = "Broadcast3Screen";
                    navigation.navigate({
                      name: "Broadcast3Screen",
                      params: { event: event },
                    } as never);
                  }}
                />
              ) : null}
              {Platform.OS === "web" &&
              !isHistoric.value &&
              !isPublic &&
              isEmailAddressVerified.value ? (
                <Button
                  title="Publish"
                  onPress={() => {
                    modalVisible.value = true;
                  }}
                />
              ) : null}
            </>
          ) : !isStalled.value &&
            !isBooked.value &&
            !isHistoric.value &&
            isEmailAddressVerified.value ? (
            <Button
              title={"Book"}
              onPress={() => {
                if (isProfileComplete.value && isEmailAddressVerified.value) {
                  handleLivestreamBooking();
                } else {
                  focusedScreen.value = "EditProfileScreen";
                  navigation.navigate({
                    name: "EditProfileScreen",
                    params: {
                      userId: currentUser.value.id,
                      userType: currentUser.value.type,
                    },
                  } as never);
                  setBottomSheetVisible(false);
                }
              }}
            />
          ) : null}
          {isBooked.value &&
          !isStalled.value &&
          !isHost &&
          isEmailAddressVerified.value ? (
            streamCanBeJoined.value ? (
              <Button
                title="Join"
                onPress={() => {
                  navigation.navigate({
                    name: "ViewLiveScreen",
                    params: { event: event },
                  } as never);
                  setBottomSheetVisible(false);
                }}
              />
            ) : (
              <Button
                title={startTimeButtonLabel}
                onPress={() => null}
                disabled={true}
              />
            )
          ) : null}
          {isStalled.value &&
          !isBooked.value &&
          !isHost &&
          isEmailAddressVerified.value ? (
            <Button
              title={"Pay £" + price.toFixed(2)}
              onPress={() => {
                checkoutStalledOrder(
                  revolutPublicId.value,
                  revolutOrderid.value,
                  paymentId.value,
                  navigation,
                  currentUser
                );
              }}
            />
          ) : null}
          <Button
            title="Close"
            onPress={() => {
              setBottomSheetVisible(false);
            }}
          />
        </View>
      </View>
      <PublishConfirmationModal event={event} />
    </SafeAreaView>
  );
};

export { ViewLiveStreamEvent as default };
