import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ActivityIndicator, SectionList, Text, View } from "react-native";
import { API_URL, queryClient } from "../base/Constants";
import TagSection from "./TagSection";
import tailwind from "twrnc";
import { currentUser, theme } from "../../signals/signals";
import { analyzeTagSelections } from "../helpers/validation";
import { Signal } from "@preact/signals-react";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import ApiError from "../error/ApiError";

const TagSelector = ({ tagType, fieldStatus }) => {
  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <View style={tailwind`max-h-[300px]`}>
      <QueryClientProvider client={queryClient}>
        <View
          style={tailwind`max-w-[600px] rounded-lg bg-[${theme.value.tagSelectorBackgroundColor}]`}
        >
          <TagCategories tagType={tagType} fieldStatus={fieldStatus} />
        </View>
      </QueryClientProvider>
    </View>
  );
};

TagSelector.propTypes = {
  tagType: PropTypes.number.isRequired,
  fieldStatus: PropTypes.instanceOf(Signal).isRequired,
};

const TagCategories = ({ tagType, fieldStatus }) => {
  useEffect(() => {
    analyzeTagSelections(tagType, fieldStatus);
  }, []);

  const { isLoading, error, data }: any = useQuery(["roottags" + tagType], () =>
    axios
      .get(API_URL + "tags/" + tagType, {
        headers: { authorization: currentUser.value.token },
      })
      .then((res) => res.data)
      .catch((err) => console.log(err))
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const formattedData = [
    {
      data: [data],
    },
  ];

  return (
    <SectionList
      scrollEnabled={false}
      onPointerLeave={() => analyzeTagSelections(tagType, fieldStatus)}
      nestedScrollEnabled={false}
      sections={formattedData}
      keyExtractor={(item) => item.id.toString()}
      renderItem={({ item }) => {
        return <TagSection item={item} fieldStatus={fieldStatus} />;
      }}
      renderSectionHeader={({ section: { data } }) => {
        return (
          <>
            <View>
              <View style={tailwind`flex-row`}>
                <View style={tailwind`py-3`}>
                  <Text
                    style={[
                      tailwind`text-[20px] font-semibold text-[${theme.value.tagSelectorTextColor}] ml-2`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    {data[0].tagName}
                  </Text>
                </View>
              </View>
            </View>
          </>
        );
      }}
    />
  );
};

TagCategories.propTypes = {
  tagType: PropTypes.number.isRequired,
  fieldStatus: PropTypes.instanceOf(Signal).isRequired,
};

export default TagSelector;
