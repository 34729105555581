import { Signal } from "@preact/signals-react";
import { Socket } from "socket.io-client";
import { PeerConnectionStatus } from "../types/PeerConnectionStatus";
import {
  chatSocket,
  currentUser,
  localStream,
  peerConnections,
  peerStatus,
  remoteStreams,
} from "../../../signals/signals";
import { addConnectionEventListeners } from "./webRTCConnectionEventListeners";
import { CustomStream } from "../types/CustomStream";
import { addAnswerEventListeners } from "./webRTCAnswerEventListeners";
import { RTCPeerConnection } from "react-native-webrtc-web-shim";

const peerConstraints: RTCConfiguration = {
  iceServers: [
    {
      urls: "stun:demo.fitcentr.com:3478",
      username: "fitcentr",
      credential: "9S3MYU55MUdm",
    },
    {
      urls: "stun:demo.fitcentr.com:5349",
      username: "fitcentr",
      credential: "9S3MYU55MUdm",
    },
    {
      urls: "turn:demo.fitcentr.com:3478",
      username: "fitcentr",
      credential: "9S3MYU55MUdm",
    },
    {
      urls: "turn:demo.fitcentr.com:5349",
      username: "fitcentr",
      credential: "9S3MYU55MUdm",
    },
  ],
  iceTransportPolicy: "all",
  bundlePolicy: "max-bundle",
  iceCandidatePoolSize: 10,
  //   sdpSemantics: "unified-plan",
  rtcpMuxPolicy: "require",
};

export const connectToPeer = async (
  toUserId: number,
  remoteStreams: Signal,
  socket: Socket,
  myUserId: number,
  roomId: string
): Promise<RTCPeerConnection> => {
  const peerConnectionStatus: PeerConnectionStatus = {
    userId: toUserId,
    name: "",
    profileUrl: "",
    direction: "Caller",
    connectionStatus: "Calling",
    iceConnectionStatus: "unknown",
    signallingState: "unknown",
    mediaStreamTracks: 0,
  };

  updatePeerStatus(peerConnectionStatus);

  let rtcPeerConnection: RTCPeerConnection = new RTCPeerConnection(
    peerConstraints
  );
  rtcPeerConnection.userId = toUserId;

  if (localStream.value) rtcPeerConnection.addStream(localStream.value);

  addConnectionEventListeners(
    rtcPeerConnection,
    socket,
    remoteStreams,
    myUserId,
    toUserId,
    roomId
  );
  return rtcPeerConnection;
};

export const updatePeerStatus = (
  peerConnectionStatus: PeerConnectionStatus
) => {
  const existingPeerStatus = peerStatus.value.find(
    (peer) => peer.userId === peerConnectionStatus.userId
  );

  if (existingPeerStatus) {
    peerStatus.value = [
      ...peerStatus.value.filter(
        (peer) => peer.userId !== existingPeerStatus.userId
      ),
      peerConnectionStatus,
    ];
  } else {
    peerStatus.value = [...peerStatus.value, peerConnectionStatus];
  }
};

export const sendIceCandidate = async (
  myUserId: number,
  toUserId: number,
  roomId: string,
  iceCandidate: RTCIceCandidate,
  socket: Socket
) => {
  socket.emit("ice-candidate", {
    fromUserId: myUserId,
    toUserId: toUserId,
    roomId: roomId,
    candidate: iceCandidate,
  });
};

export const setRemoteDescription = async (
  rtcPeerConnection: RTCPeerConnection,
  sdp: RTCSessionDescription,
  callback: (localDescription: RTCSessionDescription) => void
): Promise<void> => {
  const desc = new RTCSessionDescription(sdp);

  if (rtcPeerConnection) {
    rtcPeerConnection
      .setRemoteDescription(desc)
      .then(() => {})
      .then(() => {
        return rtcPeerConnection.createAnswer();
      })
      .then((answer: RTCSessionDescription) => {
        return rtcPeerConnection.setLocalDescription(answer);
      })
      .then(() => {
        callback(rtcPeerConnection.localDescription);
      })
      .catch((e: Error) => console.log(e.message));
  } else {
    console.log("Error: peer has no connection against which to setRemoteDescription");
  }
};

export const handleMeLeavingCallOnly = async (
  targetUserId: number,
  roomId: string
) => {
  chatSocket.value.emit(
    "disconnect call only",
    roomId,
    currentUser.value.id,
    targetUserId
  );

  remoteStreams.value.forEach(async (remoteStream: CustomStream) => {
    if (remoteStream.userId === targetUserId) {
      await new Promise((resolve) => {
        remoteStream.media?.streams?.forEach((stream) => {
          stream.getTracks().forEach((track) => track.stop());
        });
        return resolve(null);
      });
      remoteStreams.value = [
        ...remoteStreams.value.filter(
          (remoteStream) => remoteStream.userId !== targetUserId
        ),
      ];
    }
  });

  peerStatus.value = peerStatus.value.filter(
    (peerStatus: PeerConnectionStatus) => {
      if (peerStatus.userId === targetUserId) {
        peerStatus.connectionStatus = "Disconnected";
        peerStatus.iceConnectionStatus = "closed";
        peerStatus.signallingState = "closed";
        peerStatus.mediaStreamTracks = 0;
      }
      return peerStatus;
    }
  );

  let leavingPeer = peerConnections.value.find(
    (peerConnection: RTCPeerConnection) =>
      peerConnection.userId === targetUserId
  );

  leavingPeer.close();
  leavingPeer = null;
  peerConnections.value = [
    ...peerConnections.value.filter(
      (peerConnection: RTCPeerConnection) =>
        peerConnection.userId !== targetUserId
    ),
  ];
};

export const answerPeer = async (
  toUserId: number,
  myUserId: number,
  roomId: string,
  remoteStreams: Signal,
  socket: Socket
): Promise<RTCPeerConnection> => {
  const peerConnectionStatus: PeerConnectionStatus = {
    userId: toUserId,
    name: "",
    profileUrl: "",
    direction: "Callee",
    connectionStatus: "Answering",
    iceConnectionStatus: "unkown",
    signallingState: "unkown",
    mediaStreamTracks: 0,
  };

  const existingPeerStatus: PeerConnectionStatus = peerStatus.value.find(
    (peer) => peer.userId === toUserId && peer.direction === "Callee"
  );

  if (existingPeerStatus) {
    peerStatus.value = [
      ...peerStatus.value.filter(
        (peer) => peer.userId !== existingPeerStatus.userId
      ),
      peerConnectionStatus,
    ];
  } else {
    peerStatus.value = [...peerStatus.value, peerConnectionStatus];
  }

  const rtcPeerConnection: RTCPeerConnection = new RTCPeerConnection(
    peerConstraints
  );
  rtcPeerConnection.userId = toUserId;
  rtcPeerConnection.addStream(localStream.value);

  addAnswerEventListeners(
    rtcPeerConnection,
    socket,
    remoteStreams,
    myUserId,
    toUserId,
    roomId
  );
  return rtcPeerConnection;
};
