import React from "react";
import PropTypes from "prop-types";
import { QueryClientProvider, useQuery } from "@tanstack/react-query";
import { ActivityIndicator, Dimensions, Platform, Text } from "react-native";
import tailwind from "twrnc";
import { API_URL, queryClient } from "../../base/Constants";
import { StyledPicker } from "../../base/StyledPicker";
import axios from "axios";
import { currentUser, theme } from "../../../signals/signals";
import { Signal } from "@preact/signals-react";
import ApiError from "../../error/ApiError";

const State = ({ state }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <StatePanel state={state} />
    </QueryClientProvider>
  );
};

const StatePanel = ({ state }) => {
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;

  const { isLoading, error, data }: any = useQuery(["states"], () =>
    axios
      .get(API_URL + "states", {
        headers: { authorization: currentUser.value.token },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err))
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  return (
    <>
      <Text
        style={tailwind`text-[${theme.value.textColor}] font-semibold mb-1`}
      >
        State
      </Text>
      <StyledPicker
        width={isMobileWebFormat ? "300px" : null}
        items={data.map((stateItem) => {
          return {
            label: stateItem.name,
            value: stateItem.id,
          };
        })}
        selectedValue={state.value}
        onValueChange={(itemValue) => {
          state.value = Number(itemValue);
        }}
      />
    </>
  );
};

State.propTypes = {
  state: PropTypes.instanceOf(Signal).isRequired,
};

StatePanel.propTypes = {
  state: PropTypes.instanceOf(Signal).isRequired,
};

export { State as default };
