import PropTypes from "prop-types";
import axios from "axios";
import { currentUser, theme } from "../../signals/signals";
import { API_URL, VIDEO_TYPE_YOUTUBE } from "../base/Constants";
import { ActivityIndicator, Text, View } from "react-native";
import tailwind from "twrnc";
import { BottomSheetContext } from "../base/ApplicationContext";
import { useContext } from "react";
import { OnDemandVideo } from "./types/OnDemandVideo";
import EditVideo from "../on-demand/EditVideo";
import AddVideo from "../on-demand/AddVideo";
import { WebScrollContainer } from "../home-screen/WebScrollContainer";
import AddVideoThumbnail from "./AddVideoThumbnail";
import { useQuery } from "@tanstack/react-query";
import YoutubeThumbnail from "../thumbnails/youtube/YoutubeThumbnail";
import ApiError from "../error/ApiError";

const YoutubeVideoPanel = () => {
  const {
    isLoading,
    error,
    data,
  }: { isLoading: boolean; error: Error; data: any } = useQuery(
    ["videos/provider/youtube/" + currentUser.value.id],
    async () => {
      return axios
        .get(`${API_URL}videos/provider/youtube/` + currentUser.value.id, {
          headers: { authorization: currentUser.value.token },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err));
    }
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const onDemandVideos = data;
  return (
    <>
      <View
        style={tailwind`flex-row bg-[${theme.value.backgroundColor}] pt-6 pb-4`}
      >
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] text-[20px]`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Your Youtube Videos
        </Text>
      </View>
      <YoutubeVideosInnerPanel videos={onDemandVideos} />
    </>
  );
};

const YoutubeVideosInnerPanel = ({ videos }) => {
  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);

  const handleVideoEdit = (video: OnDemandVideo) => {
    setBottomSheetContent(<EditVideo video={video} />);
    setBottomSheetVisible(true);
  };

  const handleVideoAdd = (type: number) => {
    setBottomSheetContent(<AddVideo type={type} />);
    setBottomSheetVisible(true);
  };

  return (
    <View style={tailwind`h-[200px]`}>
      <WebScrollContainer style={tailwind`pb-4`}>
        <AddVideoThumbnail onPress={() => handleVideoAdd(VIDEO_TYPE_YOUTUBE)} />
        {videos?.map((video: OnDemandVideo) => {
          return (
            <View key={video.id}>
              <YoutubeThumbnail
                video={{ video }}
                onPressOverride={() => handleVideoEdit(video)}
              />
            </View>
          );
        })}
      </WebScrollContainer>
    </View>
  );
};

YoutubeVideosInnerPanel.propTypes = {
  videos: PropTypes.array,
};

export { YoutubeVideoPanel as default };
