import { Signal } from "@preact/signals-react";
import {
  bodyTagSelection,
  currentUser,
  userTagSelection,
} from "../../signals/signals";
import { TREE_TYPE_BODY, TREE_TYPE_USER } from "../base/Constants";
import {
  satisfyDescriptionText,
  satisfyMaxCharacterCount,
  satisfyMaximumNumericalValue,
  satisfyMinCharacterCount,
  satisfyMinimumCount,
  satisfyMinimumNumericalValue,
  satisfyOnlyLetters,
  satisfyOnlyLettersAndNumbers,
  satisfyOnlyNumbersWithDecimal,
  satisfyOnlyWholeNumbers,
  satisfyPasswordComplexity,
  satisfyPostCodeCharacters,
  satisfyPreciseCharacterCount,
  satisfyTextRequired,
} from "./validationRules";

export const analyzeTagSelections = (
  tagType: number,
  tagSelectionsStatus: Signal
) => {
  tagSelectionsStatus.value = "";

  if (tagType === TREE_TYPE_BODY) {
    if (!satisfyMinimumCount(bodyTagSelection.value.length, 1)) {
      tagSelectionsStatus.value = "Please select a minimum of one category";
    }
  }

  if (tagType === TREE_TYPE_USER) {
    if (!satisfyMinimumCount(userTagSelection.value.length, 1)) {
      tagSelectionsStatus.value = "Please select a minimum of one category";
    }
  }
};

export const analyzeVideoTitle = (
  videoTitle: Signal,
  videoTitleStatus: Signal
) => {
  videoTitleStatus.value = "";
  if (!satisfyTextRequired(videoTitle.value)) {
    videoTitleStatus.value = "A title is required";
  }

  if (!satisfyOnlyLetters(videoTitle.value)) {
    videoTitleStatus.value = "Only letters are allowed";
  }
};

export const analyzeProfileText = (
  profileText: Signal,
  profileTextStatus: Signal
) => {
  profileTextStatus.value = "";
  if (profileText.value && !satisfyDescriptionText(profileText.value)) {
    profileTextStatus.value = "Only letters and ( ! - ; : & ' ` ) are allowed";
  }
};

export const analyzeVideoDescription = (
  videoDescription: Signal,
  videoDescriptionStatus: Signal
) => {
  videoDescriptionStatus.value = "";
  if (
    videoDescription.value &&
    !satisfyDescriptionText(videoDescription.value)
  ) {
    videoDescriptionStatus.value = "Only letters and ( ! - ; : & ' ` ) are allowed";
  }
};

export const analyzeSalePrice = (
  salePrice: Signal,
  salePriceStatus: Signal
) => {
  const { settings } = currentUser.value;
  const fitCentrLiveMinFee = settings.filter(
    (setting) => setting.key === "FITCENTR_LIVE_MIN_FEE"
  )[0].value;

  salePriceStatus.value = "";
  if (!satisfyMaximumNumericalValue(salePrice.value, 1000)) {
    salePriceStatus.value = "Max. £1000.00";
  }

  if (
    !satisfyMinimumNumericalValue(
      salePrice.value,
      parseFloat(fitCentrLiveMinFee)
    )
  ) {
    salePriceStatus.value = "Min. £" + Number(fitCentrLiveMinFee).toFixed(2);
  }

  if (!satisfyOnlyNumbersWithDecimal(salePrice.value)) {
    salePriceStatus.value = "Invalid Price";
  }
};

export const analyzeVideoYoutubeId = (
  videoYoutubeId: Signal,
  videoYoutubeIdStatus: Signal
) => {
  videoYoutubeIdStatus.value = "";

  if (!satisfyPreciseCharacterCount(videoYoutubeId.value, 11)) {
    videoYoutubeIdStatus.value = "Invalid youtube video ID";
  }

  if (!satisfyOnlyLettersAndNumbers(videoYoutubeId.value)) {
    videoYoutubeIdStatus.value = "Only letters are allowed";
  }

  if (!satisfyTextRequired(videoYoutubeId.value)) {
    videoYoutubeIdStatus.value = "A youtube video ID is required";
  }
};

export const analyzeClassDuration = (
  classDuration: Signal,
  classDurationStatus: Signal,
  isProviding: Signal
) => {
  classDurationStatus.value = "";
  if (isProviding.value) {
    if (!satisfyMaximumNumericalValue(parseInt(classDuration.value), 120)) {
      classDurationStatus.value = "Max. 120 minutes";
    }

    if (!satisfyMinimumNumericalValue(parseInt(classDuration.value), 30)) {
      classDurationStatus.value = "Min. 30 minutes";
    }

    if (!satisfyOnlyWholeNumbers(classDuration.value)) {
      classDurationStatus.value = "Only whole numbers are allowed";
    }
  }
};

export const analyzeDuration = (duration: Signal, durationStatus: Signal) => {
  durationStatus.value = "";

  if (!satisfyMaximumNumericalValue(parseInt(duration.value), 120)) {
    durationStatus.value = "Max. 120 minutes";
  }

  if (!satisfyMinimumNumericalValue(parseInt(duration.value), 30)) {
    durationStatus.value = "Min. 30 minutes";
  }

  if (!satisfyOnlyWholeNumbers(duration.value)) {
    durationStatus.value = "Only whole numbers are allowed";
  }
};

export const analyzeBaseCost = (
  baseCost: Signal,
  baseCostStatus: Signal,
  isProviding: Signal
) => {
  const { settings } = currentUser.value;
  const fitCentrPTMinFee = settings.filter(
    (setting) => setting.key === "FITCENTR_PT_MIN_FEE"
  )[0].value;

  baseCostStatus.value = "";
  if (isProviding.value) {
    if (!satisfyMaximumNumericalValue(parseFloat(baseCost.value), 1000)) {
      baseCostStatus.value = "Max. £1000.00";
    }

    if (
      !satisfyMinimumNumericalValue(
        parseFloat(baseCost.value),
        parseFloat(fitCentrPTMinFee)
      )
    ) {
      baseCostStatus.value = "Min. £" + Number(fitCentrPTMinFee).toFixed(2);
    }

    if (!satisfyOnlyNumbersWithDecimal(baseCost.value)) {
      baseCostStatus.value = "Invalid Price";
    }
  }
};

export const analyzeCity = (city: Signal, cityStatus: Signal) => {
  cityStatus.value = "";
  if (!satisfyMaxCharacterCount(city.value, 15)) {
    cityStatus.value = "Max. 15 characters";
  }

  if (!satisfyTextRequired(city.value)) {
    cityStatus.value = "City is required";
  }
};

export const analyzeFirstName = (
  firstName: Signal,
  firstNameStatus: Signal
) => {
  firstNameStatus.value = "";

  if (!satisfyMaxCharacterCount(firstName.value, 20)) {
    firstNameStatus.value = "Max. 20 characters";
  }

  if (!satisfyMinCharacterCount(firstName.value, 2)) {
    firstNameStatus.value = "Min. 2 characters";
  }

  if (!satisfyOnlyLetters(firstName.value)) {
    firstNameStatus.value = "Only letters are allowed";
  }

  if (!satisfyTextRequired(firstName.value)) {
    firstNameStatus.value = "First name is required";
  }
};

export const analyzeLastName = (lastName: Signal, lastNameStatus: Signal) => {
  lastNameStatus.value = "";

  if (!satisfyMaxCharacterCount(lastName.value, 20)) {
    lastNameStatus.value = "Max. 20 characters";
  }

  if (!satisfyMinCharacterCount(lastName.value, 2)) {
    lastNameStatus.value = "Min. 2 characters";
  }

  if (!satisfyOnlyLetters(lastName.value)) {
    lastNameStatus.value = "Only letters are allowed";
  }

  if (!satisfyTextRequired(lastName.value)) {
    lastNameStatus.value = "Last name is required";
  }
};

export const analyzePostalCode = (
  postalCode: Signal,
  postalCodeStatus: Signal
) => {
  postalCodeStatus.value = "";

  if (!satisfyMaxCharacterCount(postalCode.value, 10)) {
    postalCodeStatus.value = "Max. 10 characters";
  }

  if (!satisfyMinCharacterCount(postalCode.value, 4)) {
    postalCodeStatus.value = "Min. 4 characters";
  }

  if (!satisfyPostCodeCharacters(postalCode.value)) {
    postalCodeStatus.value = "The postal code contains invalid characters";
  }
};

export const analyzeLoginPassword = (
  typedPassword: Signal,
  passwordStatus: Signal
) => {
  if (!satisfyTextRequired(typedPassword.value)) {
    passwordStatus.value = "Password is required";
  }
};

export const analyzeNewPassword = (
  typedPassword: Signal,
  passwordStatus: Signal
) => {
  passwordStatus.value = "";
  if (!satisfyPasswordComplexity(typedPassword.value)) {
    passwordStatus.value =
      "Your password is too weak, mix upper and lower case letters, numbers and one of these symbols ! @ # $ % ^ & or *";
  }

  if (!satisfyMinCharacterCount(typedPassword.value, 8)) {
    passwordStatus.value = "Min. 8 characters";
  }

  if (!satisfyTextRequired(typedPassword.value)) {
    passwordStatus.value = "Password is required";
  }
};

export const analyzeConfirmPassword = (
  confirmPassword: Signal,
  password: Signal,
  confirmPasswordStatus: Signal
) => {
  if (password.value) {
    if (password.value !== confirmPassword.value) {
      confirmPasswordStatus.value = "Passwords don't match";
    } else {
      confirmPasswordStatus.value = "";
    }
  }
};

export const analyzeEmailAddress = (
  emailAddress: Signal,
  emailAddressStatus: Signal
) => {
  const validEmailRegex = new RegExp(
    `[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}`
  );
  if (!validEmailRegex.test(emailAddress.value)) {
    emailAddressStatus.value = "Invalid email address";
  } else {
    emailAddressStatus.value = "";
  }
};

export const analyzePhoneNumber = (
  phoneNumber: Signal,
  phoneNumberStatus: Signal
) => {
  const phoneNumberRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

  if (phoneNumber.value?.length) {
    if (!phoneNumberRegex.test(phoneNumber.value)) {
      phoneNumberStatus.value = "Invalid phone number";
    } else {
      phoneNumberStatus.value = "";
    }
  } else {
    phoneNumberStatus.value = "Phone number is required";
  }
};

export const analyzeShortDescription = (
  shortDescription: Signal,
  shortDescriptionStatus: Signal
) => {
  shortDescriptionStatus.value = "";
  if (!satisfyTextRequired(shortDescription.value)) {
    shortDescriptionStatus.value = "Title is required";
  }
};

export const analyzeTime = (time: Signal, timeStatus: Signal): boolean => {
  let returnValue = false;
  timeStatus.value = "";
  if (!time.value || !time.value.length) {
    timeStatus.value = "Time is required";
  }

  if (time.value.length !== 5) {
    timeStatus.value = "Invalid time";
  }

  if (!/^[0-9:]+$/.test(time.value)) {
    timeStatus.value = "Invalid time";
  }

  if (time.value.indexOf(":") !== 2) {
    timeStatus.value = "Invalid time";
  }

  if (
    parseInt(time.value.substr(0, 2), 10) < 0 ||
    parseInt(time.value.substr(0, 2), 10) > 23
  ) {
    timeStatus.value = "Invalid time";
  }

  if (
    parseInt(time.value.substr(3, 2), 10) < 0 ||
    parseInt(time.value.substr(3, 2), 10) > 59
  ) {
    timeStatus.value = "Invalid time";
  }

  if (timeStatus.value === "") returnValue = true;
  return returnValue;
};

export const analyzeAddressLine = (
  addressLine: Signal,
  addressLineStatus: Signal,
  mandatory?: boolean
) => {
  addressLineStatus.value = "";
  if (mandatory) {
    if (!satisfyTextRequired(addressLine.value)) {
      addressLineStatus.value = "Address is required";
    }
  }
};

export const analyzeSearchText = (
  searchText: Signal,
  searchTextStatus: Signal
) => {
  searchTextStatus.value = "";
  if (!satisfyTextRequired(searchText.value)) {
    searchTextStatus.value = "A search term is required";
  }

  if (!satisfyOnlyLetters(searchText.value)) {
    searchTextStatus.value = "Only letters are allowed";
  }

  if (!satisfyMinCharacterCount(searchText.value, 2)) {
    searchTextStatus.value = "Min. 2 characters";
  }
};
