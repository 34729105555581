import React, { useEffect, useState } from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import HomeScreen from "../../screens/HomeScreen";
import OnDemandScreen from "../../screens/OnDemandScreen";
import YoutubeScreen from "../../screens/YoutubeScreen";
import ViewProfileScreen from "../../screens/ViewProfileScreen";
import { Dimensions, Platform } from "react-native";
import CalendarScreen from "../../screens/CalendarScreen";
import { USER_TYPE_AMBASSADOR, USER_TYPE_PROVIDER } from "../base/Constants";
import LoginScreen from "../../screens/LoginScreen";
import RegisterUserScreen from "../../screens/RegisterUserScreen";
import { EditProfileScreen } from "../../screens/EditProfileScreen";
import LibraryScreen from "../../screens/LibraryScreen";
import { NotificationScreen } from "../../screens/NotificationsScreen";
import Broadcast3Screen from "../../screens/Broadcast3Screen";
import { OrderSummaryScreen } from "../../screens/OrderSummaryScreen";
import ViewLiveScreen from "../../screens/ViewLiveScreen";
import ProviderCalendarScreen from "../../screens/ProviderCalendarScreen";
import SearchResultsScreen from "../../screens/SearchResultsScreen";
import Header from "../base/Header";
import SettingsScreen from "../../screens/SettingsScreen";
import DeviceSettingsScreen from "../../screens/DeviceSettingsScreen";
import CustomDrawerContent from "./CustomDrawerContent";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { chatSocket, currentUser, focusedScreen, isNotificationServerConnected, loggedIn, notificationSocket, theme } from "../../signals/signals";
import PaymentHistoryScreen from "../../screens/PaymentHistoryScreen";
import BookingHistoryScreen from "../../screens/BookingHistoryScreen";
import { ReplayOrderSummaryScreen } from "../../screens/ReplayOrderSummaryScreen";
import ReplayHistoryScreen from "../../screens/ReplayHistoryScreen";
import ReplaySalesScreen from "../../screens/ReplaySalesScreen";
import { useSignal } from "@preact/signals-react";
import * as ScreenOrientation from "expo-screen-orientation";
import Chats from "../../screens/Chats";
import Chat from "../../screens/Chat";
import Conference from "../../screens/Conference";
import ConnectionsScreen from "../../screens/ConnectionsScreen";

const NavigationDrawers = () => {
  const RootStack = createDrawerNavigator();
  const userId = (currentUser.value?.id) || null;
  const userType = (currentUser.value?.type) || null;
  const title = (text) =>
    Platform.select({ web: `FitCentr - ${text}`, default: text });

  const [ytRefreshKey, setYtRefreshKey] = useState();
  useEffect(() => {
    setYtRefreshKey(0);
  }, []);

  const windowOrientation = useSignal("portrait");
  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        windowOrientation.value = getOrientation(orientationInfo.orientation);
      }, 300);
    });
  }, []);

  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };

  const isMobileWebFormat = Platform.OS === "web" && Dimensions.get("screen").width < 768;

  let screenOptions = {
    drawerStyle: {
      backgroundColor: theme.value.backgroundColor,
      borderRightWidth: 1,
      width: 112,
    },
    drawerLabelStyle: {
      color: theme.value.textColor,
    },
    drawerActiveTintColor: "#000",
  };

  if (Platform.OS === "web" && currentUser.value.authenticated) {
    screenOptions = {
      animationEnabled: true,
      drawerStyle: {
        backgroundColor: theme.value.navigationDrawerBackgroundColor,
        width: 112,
        borderRightWidth: 0,
      },
      drawerLabelStyle: {
        color: theme.value.textColor,
      },
      drawerActiveTintColor: "#000",
    };

    if (!isMobileWebFormat) {
      screenOptions.drawerType = "permanent";
    }
  }

  return (
    <RootStack.Navigator
      screenOptions={screenOptions}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      {!currentUser.value?.authenticated ? (
        <>
          <RootStack.Group>
            <RootStack.Screen
              name="LoginScreen"
              component={LoginScreen}
              options={{
                headerShown: false,
                orientation: "all",
                drawerLabel: "Login",
                unmountOnBlur: true,
                title: title("Login"),
              }}
            />
            <RootStack.Screen
              name="RegisterUserScreen"
              component={RegisterUserScreen}
              options={{
                headerShown: false,
                orientation: "all",
                drawerLabel: "Register User",
                unmountOnBlur: true,
                drawerItemStyle: { display: "none" },
                title: title("Register"),
              }}
            />
          </RootStack.Group>
        </>
      ) : (
        <>
          <RootStack.Group>
            {userType === USER_TYPE_PROVIDER ||
              userType === USER_TYPE_AMBASSADOR ? (
              <>
                <RootStack.Screen
                  name="HomeScreen"
                  component={HomeScreen}
                  options={{
                    headerShown: true,
                    orientation: "all",
                    drawerLabel: "Home",
                    unmountOnBlur: true,
                    title: title("Home"),
                    header: () => {
                      return <Header />;
                    },
                  }}
                />
                {Platform.OS === "web" ? (
                  <RootStack.Screen
                    name="CalendarScreen"
                    component={CalendarScreen}
                    options={{
                      headerShown: false,
                      orientation: "all",
                      drawerLabel: "Schedule",
                      unmountOnBlur: true,
                      title: title("Schedule"),
                      header: () => {
                        return <Header />;
                      },
                    }}
                  />
                ) : (
                  <RootStack.Screen
                    name="CalendarScreen"
                    component={CalendarScreen}
                    options={{
                      headerShown: true,
                      orientation: "all",
                      drawerLabel: "Schedule",
                      unmountOnBlur: true,
                      title: title("Schedule"),
                      header: () => {
                        return <Header />;
                      },
                    }}
                  />
                )}
              </>
            ) : (
              <>
                <RootStack.Screen
                  name="HomeScreen"
                  component={HomeScreen}
                  options={{
                    headerMode: "screen",
                    headerShown: true,
                    orientation: "all",
                    drawerLabel: "Home",
                    unmountOnBlur: true,
                    title: title("Home"),
                    header: () => {
                      return <Header />;
                    },
                  }}
                />
                {Platform.OS === "web" ? (
                  <RootStack.Screen
                    name="CalendarScreen"
                    component={CalendarScreen}
                    options={{
                      headerShown: false,
                      orientation: "all",
                      drawerLabel: "Schedule",
                      unmountOnBlur: true,
                      title: title("Schedule"),
                      header: () => {
                        return <Header />;
                      },
                    }}
                  />
                ) : (
                  <RootStack.Screen
                    name="CalendarScreen"
                    component={CalendarScreen}
                    options={{
                      headerShown: true,
                      orientation: "all",
                      drawerLabel: "Schedule",
                      unmountOnBlur: true,
                      title: title("Schedule"),
                      header: () => {
                        return <Header />;
                      },
                    }}
                  />
                )}
              </>
            )}
            <RootStack.Screen
              name="LibraryScreen"
              component={LibraryScreen}
              options={{
                headerShown: true,
                orientation: "all",
                drawerLabel: "Library",
                unmountOnBlur: true,
                title: title("Library"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="Chats"
              component={Chats}
              // initialParams={{ initialConnectionId: 0, initialParams: {} }}
              options={{
                headerShown: true,
                orientation: "all",
                unmountOnBlur: true,
                title: title("Messages"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="Chat"
              component={Chat}
              // initialParams={{ initialConnectionId: 0, initialParams: {} }}
              options={{
                headerShown: true,
                orientation: "all",
                unmountOnBlur: true,
                title: title("Messages"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="ConnectionsScreen"
              component={ConnectionsScreen}
              options={{
                headerShown: true,
                orientation: "all",
                unmountOnBlur: true,
                title: title("Connections"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="NotificationsScreen"
              component={NotificationScreen}
              options={{
                headerShown: true,
                orientation: "all",
                unmountOnBlur: true,
                title: title("Notifications"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="PaymentHistoryScreen"
              component={PaymentHistoryScreen}
              options={{
                headerShown: true,
                orientation: "all",
                unmountOnBlur: true,
                title: title("Payment History"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="LogoutScreen"
              component={LogoutButton}
              options={{
                headerShown: false,
                orientation: "all",
                drawerLabel: "Logout",
                title: title("Logout"),
              }}
            ></RootStack.Screen>
            {userType === USER_TYPE_PROVIDER ? (
              <RootStack.Screen
                name="BookingHistoryScreen"
                component={BookingHistoryScreen}
                options={{
                  headerShown: true,
                  orientation: "all",
                  unmountOnBlur: true,
                  title: title("Booking History"),
                  header: () => {
                    return <Header />;
                  },
                }}
              />
            ) : null}
            {userType === USER_TYPE_AMBASSADOR ? (
              <>
                <RootStack.Screen
                  name="BookingHistoryScreen"
                  component={BookingHistoryScreen}
                  options={{
                    headerShown: true,
                    orientation: "all",
                    unmountOnBlur: true,
                    title: title("Booking History"),
                    header: () => {
                      return <Header />;
                    },
                  }}
                />
                <RootStack.Screen
                  name="ReplaySalesScreen"
                  component={ReplaySalesScreen}
                  options={{
                    headerShown: true,
                    orientation: "all",
                    unmountOnBlur: true,
                    title: title("Replay Sales"),
                    header: () => {
                      return <Header />;
                    },
                  }}
                />
                <RootStack.Screen
                  name="Broadcast3Screen"
                  component={Broadcast3Screen}
                  options={{
                    headerShown: false,
                    orientation: "all",
                    drawerLabel: "Broadcast3",
                    unmountOnBlur: true,
                    drawerItemStyle: { display: "none" },
                    title: title("Live Broadcast"),
                  }}
                />
              </>
            ) : null}
          </RootStack.Group>
          <RootStack.Group>
            <RootStack.Screen
              name="ReplayHistoryScreen"
              component={ReplayHistoryScreen}
              options={{
                headerShown: true,
                orientation: "all",
                unmountOnBlur: true,
                title: title("Replay Purchase History"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="OrderSummaryScreen"
              component={OrderSummaryScreen}
              options={{
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Order Summary"),
              }}
            />
            <RootStack.Screen
              name="ReplayOrderSummaryScreen"
              component={ReplayOrderSummaryScreen}
              options={{
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Order Summary"),
              }}
            />
            <RootStack.Screen
              name="ViewProfileScreen"
              component={ViewProfileScreen}
              options={{
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Profile"),
              }}
            />
            {Platform.OS === "web" ? (
              <RootStack.Screen
                name="ProviderCalendarScreen"
                component={ProviderCalendarScreen}
                options={{
                  headerShown: false,
                  orientation: "all",
                  drawerItemStyle: { display: "none" },
                  unmountOnBlur: true,
                  title: title("Provider Schedule"),
                }}
              />
            ) : (
              <RootStack.Screen
                name="ProviderCalendarScreen"
                component={ProviderCalendarScreen}
                options={{
                  headerShown: true,
                  header: () => {
                    return <Header />;
                  },
                  orientation: "all",
                  drawerItemStyle: { display: "none" },
                  unmountOnBlur: true,
                  title: title("Provider Schedule"),
                }}
              />
            )}
            <RootStack.Screen
              name="SearchResultsScreen"
              component={SearchResultsScreen}
              options={{
                headerShown: true,
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Search Results"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="SettingsScreen"
              component={SettingsScreen}
              options={{
                headerShown: true,
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Options"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="EditProfileScreen"
              component={EditProfileScreen}
              initialParams={{ id: userId, type: userType }}
              options={{
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Edit Profile"),
              }}
            />
            <RootStack.Screen
              name="ViewLiveScreen"
              component={ViewLiveScreen}
              options={{
                headerShown: false,
                header: () => {
                  return <Header />;
                },
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Live Broadcast"),
              }}
            />
            <RootStack.Screen
              name="OnDemandScreen"
              component={OnDemandScreen}
              options={{
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("On Demand"),
              }}
            />
            <RootStack.Screen
              name="YoutubeScreen"
              key={ytRefreshKey}
              component={YoutubeScreen}
              options={{
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                drawerIcon: () => null,
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("On Demand"),
              }}
            />
            <RootStack.Screen
              name="Conference"
              component={Conference}
              options={{
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Private Training"),
                headerShown: true,
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="DeviceSettingsScreen"
              component={DeviceSettingsScreen}
              options={{
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Device Settings"),
                headerShown: true,
                header: () => {
                  return <Header />;
                },
              }}
            />
          </RootStack.Group>
        </>
      )}
    </RootStack.Navigator>
  );
};

const LogoutButton = () => {
  const handleLogout = () => {
    focusedScreen.value = "HomeScreen";
    if (chatSocket.value) {
      chatSocket.value.close();
    }
    if (notificationSocket.value) {
      notificationSocket.value.close();
      notificationSocket.value = null;
      isNotificationServerConnected.value = true;
    } else {
      console.log("no notification socket value to close", notificationSocket);
    }
    removeToken();
    currentUser.value = ({});
    loggedIn.value = false;
  };

  const removeToken = async () => {
    try {
      await AsyncStorage.removeItem("token", (err) => {
        if (err) console.log("An error occurred while removing token", err);
      });
    } catch (error) {
      console.error("Error removing token:", error);
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);
};

export default NavigationDrawers;
