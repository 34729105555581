import React from "react";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";
import { View, Pressable, ActivityIndicator, Text } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import MiniTag from "../../base/MiniTag";
import {
  API_URL,
  CONNECTION_STATUS_CONNECTED,
  DEFAULT_PROFILE_URL,
  USER_TYPE_AMBASSADOR,
  USER_TYPE_PROVIDER,
  queryClient,
} from "../../base/Constants";
import { QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { currentUser, focusedScreen, theme } from "../../../signals/signals";
import ApiError from "../../error/ApiError";

const TrainerThumbnail = ({ trainer }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <TrainerThumbnailPanel trainer={trainer} />
    </QueryClientProvider>
  );
};

const TrainerThumbnailPanel = ({ trainer }) => {
  const navigation = useNavigation();
  const userId = trainer.id;
  const trainerName = trainer.firstName + " " + trainer.lastName;
  const profileUrl = trainer.profileUrl;
  const type = trainer.type.id;
  const followers = (trainer.followers && trainer.followers.length) || 0;

  const incomingConnections = trainer.incomingConnections;
  const outgoingConnections = trainer.outgoingConnections;

  const filteredIncomingConnections =
    incomingConnections &&
    incomingConnections.filter(
      (connection) => connection.status === CONNECTION_STATUS_CONNECTED
    );

  const filteredOutgoingConnections =
    outgoingConnections &&
    outgoingConnections.filter(
      (connection) => connection.status === CONNECTION_STATUS_CONNECTED
    );

  const connectionsCount =
    Number(
      (filteredIncomingConnections && filteredIncomingConnections.length) +
        (filteredOutgoingConnections && filteredOutgoingConnections.length)
    ) || 0;

  const videoCount = Number(trainer.videos && trainer.videos.length) || 0;

  const { isLoading, error, data } = useQuery(["usertags" + userId], () =>
    axios
      .get(API_URL + "users/tags/" + userId, {
        headers: { authorization: currentUser.value.token },
      })
      .then((res) => res.data)
      .catch((err) => console.log(err))
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const tags = data;
  let tagCount = 0;

  return (
    <Pressable
      style={tailwind`mr-[40px]`}
      onPress={() => {
        focusedScreen.value = "ViewProfileScreen";
        navigation.navigate({
          name: "ViewProfileScreen",
          params: {
            id: userId,
            type: USER_TYPE_PROVIDER,
          },
        } as never);
      }}
    >
      <View
        style={tailwind`rounded-lg bg-[${theme.value.trainerThumbnailBackgroundColor}] w-[365px] h-[142px] p-4`}
      >
        <View style={tailwind`flex-row`}>
          <View style={tailwind`w-2/3`}>
            <View style={tailwind`flex-row mb-2`}>
              <View>
                <Text
                  style={tailwind`text-[${theme.value.textColor}] pl-1 font-semibold`}
                >
                  {trainerName}
                </Text>
              </View>
              <View style={tailwind`ml-[5px]`}>
                {type === USER_TYPE_AMBASSADOR ? (
                  <Image
                    resizeMode="contain"
                    style={tailwind`h-[16px] w-[13px]`}
                    source={require("../../../assets/ambassador-icon.png")}
                    tintColor={theme.value.iconColor}
                  />
                ) : null}
              </View>
            </View>
            <View style={tailwind`flex-row flex-wrap`}>
              {tags.length
                ? tags.map((tag) => {
                    tagCount++;
                    if (tagCount <= 7) {
                      return (
                        <View key={tag.id} style={tailwind`h-[20px]`}>
                          <MiniTag
                            label={tag.tagId.tagName.toUpperCase()}
                            colorKey={tag.tagId.tagColorKey}
                          />
                        </View>
                      );
                    } else {
                      return null;
                    }
                  })
                : null}
            </View>
            <View style={tailwind`grow`} />
            <View style={tailwind`flex-row`}>
              <View style={tailwind`flex-row w-1/3 ml-1`}>
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[18px] w-[18px]`}
                  source={require("../../../assets/connections-icon.png")}
                />
                <Text
                  style={tailwind`text-[${theme.value.textColor}] mt-1 text-[12px] ml-2`}
                >
                  {connectionsCount}
                </Text>
              </View>
              <View style={tailwind`flex-row w-1/3`}>
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[18px] w-[18px]`}
                  source={require("../../../assets/followers-icon.png")}
                />
                <Text
                  style={tailwind`text-[${theme.value.textColor}] mt-1 text-[12px] ml-2`}
                >
                  {followers}
                </Text>
              </View>
              <View style={tailwind`flex-row w-1/3`}>
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[18px] w-[18px]`}
                  source={require("../../../assets/videos-icon.png")}
                />
                <Text
                  style={tailwind`text-[${theme.value.textColor}] mt-1 text-[12px] ml-2`}
                >
                  {videoCount}
                </Text>
              </View>
            </View>
          </View>
          <View style={tailwind`w-1/3`}>
            <View style={tailwind`rounded-lg`}>
              <Image
                style={tailwind`w-[110px] h-[110px] rounded-lg`}
                source={{
                  uri: profileUrl || DEFAULT_PROFILE_URL,
                }}
                PlaceholderContent={<ActivityIndicator />}
              />
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

TrainerThumbnail.propTypes = {
  trainer: PropTypes.object.isRequired,
};

TrainerThumbnailPanel.propTypes = {
  trainer: PropTypes.object.isRequired,
};

export default TrainerThumbnail;
