import axios from "axios";
import { API_URL, DEFAULT_PROFILE_URL } from "../base/Constants";
import { configureTheme } from "../base/themeHandler";
import {
  currentUser,
  isEmailAddressVerified,
  isProfileComplete,
  notificationSocket,
} from "../../signals/signals";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { evaluateProfileWarningMessages } from "../base/ApplicationContainer";
import { connectNotificationSocket } from "../base/notificationSocketHandler";

export const handleLogin = async (
  email: string,
  password: string,
  loginStatus: any,
  callback: () => void
) => {
  try {
    await axios
      .post(
        API_URL + "auth/",
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        const {
          id,
          firstName,
          lastName,
          emailAddress,
          type,
          profileUrl,
          currencyId,
          currencyName,
          currencySymbol,
          token,
          credits,
          isProfileCompleted,
          settings,
          addressLine1,
          addressLine2,
          city,
          country,
          postalCode,
          isEmailVerified,
          theme,
          themes,
        } = response.data;

        configureTheme(theme.id, themes);

        currentUser.value = {
          id: id,
          firstName: firstName,
          lastName: lastName,
          emailAddress: emailAddress,
          type: type.id,
          profileUrl: profileUrl || DEFAULT_PROFILE_URL,
          authenticated: true,
          currencyId: currencyId,
          currencyName: currencyName,
          currencySymbol: currencySymbol,
          token: token,
          credits: credits,
          settings: settings,
          theme: theme,
        };

        await AsyncStorage.setItem("token", token, (err) => {
          if (err) console.log("An error occurred while saving token", err);
        });

        isEmailAddressVerified.value = isEmailVerified;
        isProfileComplete.value = isProfileCompleted;
        evaluateProfileWarningMessages({
          isProfileCompleted,
          profileUrl,
          currencyId,
          addressLine1,
          addressLine2,
          city,
          country,
          postalCode,
          type,
          isEmailVerified,
        });

        if (!notificationSocket.value) {
          await connectNotificationSocket(id, token);
        } else {
          console.log(
            "Error: socket is already connected and shouldn't be (login)"
          );
        }
        callback();
      });
  } catch (error) {
    console.log(error);
    if (error.response && error.response.data) {
      loginStatus.value = error.response.data.message;
    } else {
      loginStatus.value =
        "Unable to connect, please check your internet connection and try again.";
    }
    setTimeout(() => (loginStatus.value = ""), 5000);
  }
};
