import React, { useContext, useEffect, useRef } from "react";
import {
  Text,
  View,
  Dimensions,
  Platform,
  ActivityIndicator,
} from "react-native";
import VideoUpload from "./VideoUpload";
import tailwind from "twrnc";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import { Signal, signal, useSignal } from "@preact/signals-react";
import {
  bodyTagSelection,
  currentUser,
  theme,
  userTagSelection,
} from "../../signals/signals";
import { Switch } from "@rneui/themed";
import EditableVideoThumbnail from "./EditableVideoThumbnail";
import ThumbnailUpload from "./ThumbnailUpload";
import ThumbnailClear from "./ThumbnailClear";
import { StyledInput } from "../base/StyledInput";
import TagSelector from "../tags/TagSelector";
import {
  API_URL,
  TREE_TYPE_BODY,
  TREE_TYPE_USER,
  queryClient,
} from "../base/Constants";
import axios from "axios";
import Button from "../base/Button";
import { BottomSheetContext } from "../base/ApplicationContext";
import { ResizeMode, Video } from "expo-av";
import { signalVideoUploadAbandoned } from "../base/notificationSocketFileHandler";
import FileTooLargeErrorModal from "../modals/FileTooLargeErrorModal";
import {
  analyzeTagSelections,
  analyzeVideoDescription,
  analyzeVideoTitle,
} from "../helpers/validation";
import { setTagSelections } from "../helpers/tagProcessor";

const OnDemandAddPanel = (): JSX.Element => {
  const videoPath = useSignal("");
  const videoPathStatus = useSignal("A video file is required (mp4/mov)");
  const isUploading = useSignal(false);

  const video = { onDemandVideoTags: [] };
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;
  const isPublic: Signal<boolean> = useSignal(false);
  const videoTitleShown: Signal<boolean> = useSignal(false);
  const videoThumbnailPath = useSignal(currentUser.value.profileUrl);
  const thumbnailPath = currentUser.value.profileUrl;
  const videoTitle = useSignal("");
  const videoTitleStatus = useSignal("");
  const videoDescription = useSignal("");
  const videoDescriptionStatus = useSignal("");
  const { setBottomSheetVisible } = useContext(BottomSheetContext);

  const userTagStatus = useSignal("");
  const bodyTagStatus = useSignal("");

  const refVideo = useRef<Video>(null);

  const toggleSwitch = () => {
    isPublic.value = !isPublic.value;
  };

  const toggleTitleShown = () => {
    videoTitleShown.value = !videoTitleShown.value;
  };

  const saveVideoFile = async () => {
    axios
      .post(
        API_URL + "videos",
        {
          duration: 0,
          isEnabled: isPublic.value,
          title: videoTitle.value,
          description: videoDescription.value,
          path: videoPath.value,
          onDemandVideoTags: [
            ...userTagSelection.value.filter((tag) => !tag.deleted),
            ...bodyTagSelection.value.filter((tag) => !tag.deleted),
          ],
          isTitleShown: videoTitleShown.value,
          thumbnailPath: videoThumbnailPath.value,
          youtubeId: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.value.token,
          },
        }
      )
      .then((res) => {
        setBottomSheetVisible(false);
        queryClient.invalidateQueries([
          "videos/provider/replay/" + currentUser.value.id,
        ]);
        queryClient.invalidateQueries([
          "videos/provider/" + currentUser.value.id,
        ]);
        queryClient.invalidateQueries([
          "videos/provider/ondemand/" + currentUser.value.id,
        ]);
        return res.data;
      });
  };

  const selectedTags = [];

  useEffect(() => {
    setTagSelections(selectedTags);
    analyzeVideoTitle(videoTitle, videoTitleStatus);
    analyzeVideoDescription(videoDescription, videoDescriptionStatus);
    analyzeTagSelections(TREE_TYPE_USER, userTagStatus);
    analyzeTagSelections(TREE_TYPE_BODY, bodyTagStatus);
  }, []);

  const canSave = useSignal(false);
  useEffect(() => {
    canSave.value =
      videoTitleStatus.value === "" &&
      userTagStatus.value === "" &&
      bodyTagStatus.value === "";
  }, [videoTitleStatus.value, userTagStatus.value, bodyTagStatus.value]);

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <>
      <View style={tailwind`p-2`}>
        <View
          style={tailwind`w-full mb-4 ${
            !isPublic.value ? "bg-[" + theme.value.redColor + "]" : ""
          }`}
        >
          <View style={tailwind`flex-row self-center py-2`}>
            <Switch
              style={tailwind`mt-1`}
              trackColor={{
                false: theme.value.greenColor,
                true: theme.value.redColor,
              }}
              ios_backgroundColor="#3e3e3e"
              onValueChange={() => toggleSwitch()}
              value={isPublic.value}
            />
            <Text
              style={tailwind`ml-2 pt-1 font-semibold text-[${
                isPublic.value ? theme.value.textColor : theme.value.textColor
              }]`}
            >
              {isPublic.value
                ? "Enabled, this video will be shown publicly"
                : "Disabled, this video will not be shown publibly"}
            </Text>
          </View>
        </View>
        <View
          style={isMobileWebFormat ? tailwind`flex-col` : tailwind`flex-row`}
        >
          <View style={isMobileWebFormat ? tailwind`w-full` : tailwind`w-1/3`}>
            <View style={tailwind`self-center py-2`}>
              <View style={tailwind`py-2`}>
                <Text
                  style={[
                    tailwind`text-[${theme.value.textColor}] font-semibold pb-1`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  Video
                </Text>
                {videoPath.value && !isUploading.value ? (
                  <View style={tailwind`w-[212px] h-[118px] rounded-lg`}>
                    <Video
                      ref={refVideo}
                      volume={5}
                      style={{
                        height: 118,
                        width: 212,
                      }}
                      source={{
                        uri: videoPath.value,
                      }}
                      videoStyle={{
                        height: 118,
                        width: 212,
                      }}
                      useNativeControls
                      resizeMode={ResizeMode.CONTAIN}
                      onError={(error) =>
                        console.log("Unable to play video file", error)
                      }
                      shouldPlay={Platform.OS === "web" ? false : true}
                    />
                  </View>
                ) : isUploading.value ? (
                  <View
                    style={tailwind`w-[212px] h-[118px] rounded-lg bg-white py-2`}
                  >
                    <View style={tailwind`self-center pt-8`}>
                      <Text
                        style={[
                          tailwind`text-[${theme.value.textColor}] font-semibold pb-1`,
                          { fontFamily: "Roboto_400Regular" },
                        ]}
                      >
                        Uploading, please wait
                      </Text>
                      <ActivityIndicator />
                    </View>
                  </View>
                ) : (
                  <View
                    style={tailwind`w-[212px] h-[118px] rounded-lg bg-white py-2`}
                  >
                    <View style={tailwind`self-center pt-8`}>
                      <VideoUpload
                        videoPath={videoPath}
                        videoPathStatus={videoPathStatus}
                        isUploading={isUploading}
                      />
                    </View>
                  </View>
                )}
                {videoPathStatus.value ? (
                  <View style={tailwind`mb-2`}>
                    <Text
                      style={[
                        tailwind`text-xs ml-2 text-[${theme.value.redColor}]`,
                        { fontFamily: "Roboto_400Regular" },
                      ]}
                    >
                      {videoPathStatus.value}
                    </Text>
                  </View>
                ) : null}
              </View>
              <View style={tailwind`py-2`}>
                <Text
                  style={[
                    tailwind`text-[${theme.value.textColor}] font-semibold pb-1`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  Thumbnail
                </Text>
                <EditableVideoThumbnail
                  video={video}
                  videoThumbnailPath={videoThumbnailPath}
                  videoTitleShown={videoTitleShown}
                  videoTitle={videoTitle}
                  onPressOverride={() => {}}
                />
                <View style={tailwind`flex-row`}>
                  <ThumbnailUpload thumbnailPath={videoThumbnailPath} />
                  {thumbnailPath && (
                    <ThumbnailClear thumbnailPath={videoThumbnailPath} />
                  )}
                  <View style={tailwind`flex-row pt-2`}>
                    <Switch
                      style={tailwind`mt-1`}
                      trackColor={{
                        false: theme.value.redColor,
                        true: theme.value.greenColor,
                      }}
                      ios_backgroundColor="#3e3e3e"
                      onValueChange={toggleTitleShown}
                      value={videoTitleShown.value}
                    />
                    <Text
                      style={tailwind`ml-2 pt-1 font-semibold text-[${
                        videoTitleShown.value
                          ? theme.value.textColor
                          : theme.value.redColor
                      }]`}
                    >
                      {videoTitleShown.value ? "Title Shown" : "Title Hidden"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={isMobileWebFormat ? tailwind`w-full` : tailwind`w-2/3`}>
            <StyledInput
              width={"355px"}
              label={"Title"}
              placeholder={"Title"}
              value={videoTitle.value}
              onChangeText={(text) => {
                videoTitle.value = text;
                analyzeVideoTitle(videoTitle, videoTitleStatus);
              }}
              onBlur={() => {
                analyzeVideoTitle(videoTitle, videoTitleStatus);
              }}
              fieldStatus={videoTitleStatus}
              autoCorrect={true}
            />
            <View style={tailwind`mt-4`}>
              <StyledInput
                label={"Description"}
                multiline={true}
                numberOfLines={3}
                placeholder={"Description"}
                onChangeText={(text) => {
                  videoDescription.value = text;
                  analyzeVideoDescription(
                    videoDescription,
                    videoDescriptionStatus
                  );
                }}
                onBlur={() => {
                  analyzeVideoDescription(
                    videoDescription,
                    videoDescriptionStatus
                  );
                }}
                fieldStatus={videoDescriptionStatus}
                width={"355px"}
                height={"100px"}
                value={videoDescription.value}
                autoCorrect={true}
              />
            </View>
            <View style={tailwind`mt-4 mr-2`}>
              <Text
                style={[
                  tailwind`text-[${theme.value.textColor}] font-semibold pb-1`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                Categories
              </Text>
              <View style={tailwind`mb-2`}>
                <TagSelector
                  tagType={TREE_TYPE_USER}
                  fieldStatus={userTagStatus}
                />
                {userTagStatus.value ? (
                  <View style={tailwind`mb-2`}>
                    <Text
                      style={[
                        tailwind`text-xs ml-2 text-[${theme.value.redColor}]`,
                        { fontFamily: "Roboto_400Regular" },
                      ]}
                    >
                      {userTagStatus.value}
                    </Text>
                  </View>
                ) : null}
              </View>
              <View style={tailwind`mb-2`}>
                <TagSelector
                  tagType={TREE_TYPE_BODY}
                  fieldStatus={bodyTagStatus}
                />
                {bodyTagStatus.value ? (
                  <View style={tailwind`mb-2`}>
                    <Text
                      style={[
                        tailwind`text-xs ml-2 text-[${theme.value.redColor}]`,
                        { fontFamily: "Roboto_400Regular" },
                      ]}
                    >
                      {bodyTagStatus.value}
                    </Text>
                  </View>
                ) : null}
              </View>
            </View>
          </View>
        </View>
        <View style={tailwind`self-center w-full pb-6 mt-2`}></View>
      </View>
      <View style={tailwind`self-center flex-row`}>
        <Button
          title="Close"
          onPress={() => {
            signalVideoUploadAbandoned(videoPath.value, signal(false));
            setBottomSheetVisible(false);
          }}
        />
        <Button
          disabled={!canSave.value}
          title="Save"
          onPress={() => saveVideoFile()}
        />
      </View>
      <FileTooLargeErrorModal />
    </>
  );
};

export { OnDemandAddPanel as default };
