import { Text, View } from "react-native";
import { Signal } from "@preact/signals-react";
import tailwind from "twrnc";
import { theme } from "../../../signals/signals";

const ConfirmedBookingsCount = ({
  numberOfBookings,
}: {
  numberOfBookings: Signal<number>;
}) => {
  return (
    <View style={tailwind`flex-row self-end h-[24px]`}>
      <Text
        style={[
          tailwind`text-[${theme.value.coinsIconColor}] font-semibold self-end text-[14px]`,
          { fontFamily: "Roboto_400Regular" },
        ]}
      >
        {numberOfBookings.value} Confirmed
      </Text>
    </View>
  );
};

export { ConfirmedBookingsCount as default };
