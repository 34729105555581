import PropTypes from "prop-types";
import { Inter_600SemiBold } from "@expo-google-fonts/inter";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import { useNavigation } from "@react-navigation/native";
import { ActivityIndicator, Pressable, Text, View } from "react-native";
import tailwind from "twrnc";
import { focusedScreen, theme } from "../../../signals/signals";
import { Image } from "@rneui/themed";
import MiniTag from "../../base/MiniTag";
import VideoTitle from "../shared/VideoTitle";
import { useSignal } from "@preact/signals-react";
import DurationPill from "../replay/DurationPill";
import ThumbnailProfile from "./ThumbnailProfile";
import { DEFAULT_PROFILE_URL } from "../../base/Constants";

const OnDemandThumbnail = ({ video, onPressOverride }) => {
  const navigation = useNavigation();
  const {
    title,
    userVideos,
    onDemandVideoTags,
    thumbnailPath,
    isTitleShown,
    isEnabled,
  } = video.video;

  let profileUrl = "";
  if (userVideos && userVideos.length > 0) {
    profileUrl = userVideos[0].userId.profileUrl;
  }

  const thumbnailDimensions = useSignal({
    width: 212,
    height: 178,
  });
  const thumbnailImageDimensions = useSignal({
    width: 212,
    height: 118,
  });

  let fonts = useFonts({ Inter_600SemiBold, Roboto_400Regular });
  if (!fonts) return;

  return (
    <Pressable
      style={tailwind`rounded-lg bg-[${theme.value.backgroundColor}] mr-[40px] mb-2`}
      onPress={
        onPressOverride
          ? onPressOverride
          : () => {
              focusedScreen.value = "OnDemandScreen";
              navigation.navigate({
                name: "OnDemandScreen",
                params: { video: video },
              } as never);
            }
      }
    >
      <View
        style={tailwind`rounded-lg w-[${thumbnailDimensions.value.width}px] bg-white`}
      >
        <View
          style={tailwind`w-[${thumbnailDimensions.value.width}px] h-[${thumbnailDimensions.value.height}px]`}
        >
          <View style={tailwind`absolute`}>
            <Image
              style={tailwind`w-[${thumbnailImageDimensions.value.width}px] h-[${thumbnailImageDimensions.value.height}px] rounded-tr-lg rounded-tl-lg`}
              source={{ uri: thumbnailPath }}
              PlaceholderContent={<ActivityIndicator />}
            >
              <View
                style={
                  isEnabled
                    ? null
                    : tailwind`absolute w-full h-full opacity-50 bg-black`
                }
              />
            </Image>
          </View>
          <View style={tailwind`p-1 grow`}>
            <View style={tailwind`py-10`}>
              {isTitleShown ? (
                <VideoTitle title={title} />
              ) : (
                <View style={tailwind`py-[2px] px-[6px] mx-4 h-[18px]`}></View>
              )}
            </View>
            <View style={tailwind`w-full flex-row pt-6 px-1`}>
              <View style={tailwind`w-1/2 flex-row`}>
                <View>
                  <DurationPill duration={30} />
                  <View style={tailwind`grow`} />
                </View>
                <View style={tailwind`grow`} />
              </View>
              <View style={tailwind`w-1/2 self-end`}>
                <ThumbnailProfile
                  profileUrl={profileUrl || DEFAULT_PROFILE_URL}
                />
              </View>
            </View>
            <View style={tailwind`flex-row flex-wrap px-1`}>
              {onDemandVideoTags.length ? (
                <MiniTag
                  key={onDemandVideoTags[0].id}
                  label={
                    (
                      onDemandVideoTags[0].tagId &&
                      onDemandVideoTags[0].tagId.tagName
                    ).toUpperCase() || ""
                  }
                  colorKey={onDemandVideoTags[0].tagId.tagColorKey}
                />
              ) : null}
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

OnDemandThumbnail.propTypes = {
  video: PropTypes.object.isRequired,
  onPressOverride: PropTypes.func,
};

export { OnDemandThumbnail as default };
