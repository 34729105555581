import React, {
  ActivityIndicator,
  Dimensions,
  Platform,
  Pressable,
  ScrollView,
  SectionList,
  Text,
  View,
} from "react-native";
import PropTypes from "prop-types";
import tailwind from "twrnc";
import { currentUser, theme } from "../signals/signals";
import { QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  API_URL,
  EVENT_TYPE_BROADCAST,
  queryClient,
} from "../components/base/Constants";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import dayjs from "dayjs";
import { useSignal } from "@preact/signals-react";
import SettingScreenHeader from "../components/settings-screen/SettingsScreenHeader";
import ApiError from "../components/error/ApiError";

const BookingHistoryScreen = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BookingHistoryYear />
    </QueryClientProvider>
  );
};

const BookingHistoryYear = () => {
  const expandedYear = useSignal(dayjs().format("YYYY"));
  const userId = currentUser.value.id;
  const { token } = currentUser.value;

  const { isLoading, error, data, isFetching } = useQuery(
    ["booking/provider/history" + userId],
    () =>
      axios
        .get(API_URL + "booking/provider/history", {
          headers: { authorization: token },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err))
  );

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;
  if (isLoading || isFetching) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const handleSectionHeaderPress = (title) => {
    expandedYear.value === title ? (expandedYear.value = "") : (expandedYear.value = title);
  }

  const parsedData = [];
  let rowIndex = 1;
  data.forEach((userSchedule) => {
    const date = dayjs(userSchedule.startDate).format("YYYY");
    const existingSection = parsedData.filter(
      (sectionItem) => sectionItem.title === date
    )[0];

    if (!existingSection) {
      const newSection = {
        title: date,
        data: [userSchedule],
        showHeadings: expandedYear.value === date,
      };
      parsedData.push(newSection);
    } else {
      existingSection.data.push(userSchedule);
    }
  });

  return (
    <View
      style={tailwind`pt-10 p-2 w-full h-full bg-[${theme.value.backgroundColor}]`}
    >
      <SettingScreenHeader />
      <View style={tailwind`w-full self-center`}>
        <>
          <View style={tailwind`mb-4`}>
            <Text
              style={[
                tailwind`self-center font-semibold text-[${theme.value.textColor}] text-[14px]`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              Your Hosted Events
            </Text>
          </View>
          <ScrollView style={tailwind`h-[150v] bg-white`}>
            <SectionList
              collapsable={true}
              nestedScrollEnabled={true}
              sections={parsedData}
              keyExtractor={(item) => item.id}
              renderSectionHeader={({ section: { title, showHeadings } }) => {
                return (
                  <Pressable
                    onPress={() => handleSectionHeaderPress(title)
                    }
                  >
                    <View
                      style={tailwind`w-full pl-2 py-1 border-b-[1px] border-[${theme.value.eventBookingsYearTitleBottomBorderColor}] bg-[${theme.value.eventBookingsYearTitleBackgroundColor}]`}
                    >
                      <Text
                        style={[
                          tailwind`font-semibold text-[${theme.value.textColor}]`,
                          { fontFamily: "Roboto_700Bold" },
                        ]}
                      >
                        {title}
                      </Text>
                    </View>
                    {showHeadings && <YearTitles />}
                  </Pressable>
                );
              }}
              renderItem={({ item }) => {
                return (
                  <View style={{ height: expandedYear.value === dayjs(item.startDate).format("YYYY") ? 'auto' : 0, overflow: 'hidden' }}>
                    <BookingHistorySchedule
                      userSchedule={item}
                      rowIndex={rowIndex++}
                    />
                  </View>
                );
              }}
            />
          </ScrollView>
        </>
      </View>
    </View>
  );
};

const BookingHistorySchedule = ({ userSchedule, rowIndex }) => {
  const expandedEvent = useSignal();
  const startDate = dayjs(userSchedule.startDate).format(
    "ddd DD MMM YYYY HH:mm"
  );
  const { settings } = currentUser.value;
  const fitCentrPTFee = settings.filter(
    (setting) => setting.key === "FITCENTR_PT_FIXED_FEE"
  )[0].value;
  const fitCentrLiveFee = settings.filter(
    (setting) => setting.key === "FITCENTR_LIVE_FIXED_FEE"
  )[0].value;

  const parsedData = [];
  const existingSection = parsedData.filter(
    (sectionItem) => sectionItem.title === userSchedule.id.toString()
  )[0];

  let bookingTotal = 0,
    bookingCount = 0;
  userSchedule.bookings.forEach((booking) => {
    const { pricePaid, bookingStatus } = extractBookingSummaryValues(booking);
    bookingTotal += Number(pricePaid);
    if (bookingStatus === "Paid") bookingCount++;
  });

  if (!existingSection) {
    const feeTotal =
      userSchedule.type.id === EVENT_TYPE_BROADCAST
        ? fitCentrLiveFee * bookingCount
        : fitCentrPTFee * bookingCount;
    const newSection = {
      title: userSchedule.id.toString(),
      data: [userSchedule.bookings],
      chargedTotal: bookingTotal,
      feeTotal: feeTotal,
      revenueTotal: bookingTotal - feeTotal,
      bookingCount: bookingCount,
      showHeadings: expandedEvent.value === startDate,
      rowIndex: rowIndex,
    };
    parsedData.push(newSection);
  } else {
    existingSection.data.push(userSchedule.bookings);
  }
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;

  return (
    <View style={tailwind`w-[90%] self-center mb-1`}>
      <SectionList
        style={tailwind`w-full`}
        nestedScrollEnabled={true}
        collapsable={true}
        sections={parsedData}
        keyExtractor={(item) => item.id}
        renderSectionHeader={({
          section: {
            chargedTotal,
            showHeadings,
            bookingCount,
            feeTotal,
            revenueTotal,
          },
        }) => {
          return (
            <Pressable
              onPress={() =>
                expandedEvent.value === startDate
                  ? (expandedEvent.value = "")
                  : (expandedEvent.value = startDate)
              }
            >
              <View
                style={tailwind`w-full pt-8 py-1 ${rowIndex % 2 === 0 ? "bg-[#f9f9f9]" : null
                  } ${expandedEvent.value === startDate
                    ? "bg-[" + theme.value.bundleItemBackgroundColor + "]"
                    : null
                  }`}
              >
                <View style={tailwind`w-full self-center flex-row`}>
                  <View style={tailwind`w-2/8`}>
                    <Text
                      style={[
                        tailwind`font-semibold text-[${theme.value.textColor
                          }] ${isMobileWebFormat ? "text-xs" : null}`,
                        { fontFamily: "Roboto_700Bold" },
                      ]}
                    >
                      {startDate}
                    </Text>
                  </View>
                  <View style={tailwind`w-2/8`}>
                    <Text
                      style={[
                        tailwind`font-semibold text-[${theme.value.textColor
                          }] ${isMobileWebFormat ? "text-xs" : null}`,
                        { fontFamily: "Roboto_700Bold" },
                      ]}
                    >
                      {userSchedule.shortDescription}
                    </Text>
                  </View>
                  <View style={tailwind`w-1/8`}>
                    <Text
                      style={[
                        tailwind`font-semibold text-[${theme.value.textColor
                          }] self-end ${isMobileWebFormat ? "text-xs" : null}`,
                        { fontFamily: "Roboto_700Bold" },
                      ]}
                    >
                      {bookingCount}
                    </Text>
                  </View>
                  <View style={tailwind`w-1/8`}>
                    <Text
                      style={[
                        tailwind`font-semibold text-[${theme.value.textColor
                          }] self-end ${isMobileWebFormat ? "text-xs" : null}`,
                        { fontFamily: "Roboto_700Bold" },
                      ]}
                    >
                      &pound;{chargedTotal.toFixed(2)}
                    </Text>
                  </View>
                  <View style={tailwind`w-1/8`}>
                    <Text
                      style={[
                        tailwind`font-semibold text-[${theme.value.textColor
                          }] self-end ${isMobileWebFormat ? "text-xs" : null}`,
                        { fontFamily: "Roboto_700Bold" },
                      ]}
                    >
                      &pound;{feeTotal.toFixed(2)}
                    </Text>
                  </View>
                  <View style={tailwind`w-1/8`}>
                    <Text
                      style={[
                        tailwind`font-semibold text-[${theme.value.textColor
                          }] self-end ${isMobileWebFormat ? "text-xs" : null}`,
                        { fontFamily: "Roboto_700Bold" },
                      ]}
                    >
                      &pound;{revenueTotal.toFixed(2)}
                    </Text>
                  </View>
                </View>
              </View>
              {showHeadings && (
                <View
                  style={tailwind`bg-[${theme.value.bundleItemBackgroundColor}]`}
                >
                  <BookingTitles />
                </View>
              )}
            </Pressable>
          );
        }}
        renderItem={({ item }) => {
          return expandedEvent.value === startDate ? (
            <View
              style={tailwind`w-full self-center bg-[${theme.value.bundleItemBackgroundColor}] mb-2`}
            >
              <View style={tailwind`w-[90%] self-center`}>
                {item.map((booking) => {
                  const { pricePaid, bookingStatus } =
                    extractBookingSummaryValues(booking);
                  return (
                    <View
                      key={booking.id}
                      style={tailwind`flex-row w-full self-center bg-[${theme.value.trainerThumbnailBackgroundColor}]`}
                    >
                      <View style={tailwind`w-1/4`}>
                        <Text
                          style={[
                            tailwind`font-semibold text-[${theme.value.textColor
                              }] ${isMobileWebFormat ? "text-xs" : null}`,
                            { fontFamily: "Roboto_700Bold" },
                          ]}
                        >
                          {dayjs(booking.createdAt).format(
                            "ddd DD MMM YYYY HH:mm"
                          )}
                        </Text>
                      </View>
                      <View style={tailwind`w-1/4`}>
                        <Text
                          style={[
                            tailwind`font-semibold text-[${theme.value.textColor
                              }] ${isMobileWebFormat ? "text-xs" : null}`,
                            { fontFamily: "Roboto_700Bold" },
                          ]}
                        >
                          {booking.user.firstName} {booking.user.lastName}
                        </Text>
                      </View>
                      <View style={tailwind`w-1/4`}>
                        <Text
                          style={[
                            tailwind`font-semibold text-[${theme.value.textColor
                              }] ${isMobileWebFormat ? "text-xs" : null}`,
                            { fontFamily: "Roboto_700Bold" },
                          ]}
                        >
                          {bookingStatus}
                        </Text>
                      </View>
                      <View style={tailwind`w-1/4`}>
                        <Text
                          style={[
                            tailwind`font-semibold text-[${theme.value.textColor
                              }] self-end ${isMobileWebFormat ? "text-xs" : null}`,
                            { fontFamily: "Roboto_700Bold" },
                          ]}
                        >
                          &pound;{pricePaid.toFixed(2)}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          ) : null;
        }}
      />
    </View>
  );
};

function extractBookingSummaryValues(booking) {
  let bookingStatus = "Pending",
    pricePaid = 0;
  if (!booking.payments || !booking.payments.length) {
    bookingStatus = "No payment received";
  } else {
    booking.payments.forEach((payment) => {
      if (!payment.responses || !payment.responses.length) {
        bookingStatus = "Payment stalled";
      } else {
        payment.responses.forEach((paymentResponse) => {
          if (
            paymentResponse.state === "COMPLETED" ||
            paymentResponse.state === "AUTHORISED"
          ) {
            bookingStatus = "Paid";
            pricePaid = paymentResponse.orderValue / 100;
          }
        });
      }
    });
  }

  return {
    pricePaid: pricePaid,
    bookingStatus: bookingStatus,
  };
}

const BookingTitles = () => {
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;
  return (
    <View style={tailwind`flex-row pt-2 w-[90%] self-center`}>
      <View style={tailwind`w-1/4`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] ${isMobileWebFormat ? "text-xs" : null
              }`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Date Booked
        </Text>
      </View>
      <View style={tailwind`w-1/4`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] ${isMobileWebFormat ? "text-xs" : null
              }`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Booked By
        </Text>
      </View>
      <View style={tailwind`w-1/4`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] ${isMobileWebFormat ? "text-xs" : null
              }`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Payment Status
        </Text>
      </View>
      <View style={tailwind`w-1/4`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] self-end ${isMobileWebFormat ? "text-xs" : null
              }`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Amount
        </Text>
      </View>
    </View>
  );
};

const YearTitles = () => {
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;
  return (
    <View style={tailwind`flex-row pt-2 w-[90%] self-center`}>
      <View style={tailwind`w-2/8`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] ${isMobileWebFormat ? "text-xs" : null
              }`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Date
        </Text>
      </View>
      <View style={tailwind`grow`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] ${isMobileWebFormat ? "text-xs" : null
              }`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Event
        </Text>
      </View>
      <View style={tailwind`w-1/8`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] self-end ${isMobileWebFormat ? "text-xs" : null
              }`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          {isMobileWebFormat ? "Bkngs." : "Bookings"}
        </Text>
      </View>
      <View style={tailwind`w-1/8`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] self-end ${isMobileWebFormat ? "text-xs" : null
              }`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          {isMobileWebFormat ? "Chrg." : "Charged"}
        </Text>
      </View>
      <View style={tailwind`w-1/8`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] self-end ${isMobileWebFormat ? "text-xs" : null
              }`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Fee
        </Text>
      </View>
      <View style={tailwind`w-1/8`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] self-end ${isMobileWebFormat ? "text-xs" : null
              }`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          {isMobileWebFormat ? "Rev." : "Revenue"}
        </Text>
      </View>
    </View>
  );
};

BookingHistorySchedule.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  userSchedule: PropTypes.shape({
    id: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    bookings: PropTypes.array.isRequired,
    type: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }),
};

export { BookingHistoryScreen as default };
