import React, { useRef } from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { ChatInput } from "./ChatInput";
import tailwind from "twrnc";
import dayjs from "dayjs";
import { sendMessage } from "../private-room/shared/socketHandler";
import { Signal } from "@preact/signals-react";
import {
  chatSocket,
  currentUser,
  privateMessages,
} from "../../signals/signals";

const MessageEntry = ({ roomId, onMessageSent }) => {
  const chatInputRef = useRef(null);

  const createRandomKey = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  const handleOnPress = () => {
    const newMessage = {
      text: chatInputRef.current.value,
      createdAt: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
      _id: createRandomKey(),
      user: {
        _id: currentUser.value.id,
        name: currentUser.value.firstName,
        avatar: currentUser.value.profileUrl,
      },
    };

    if (privateMessages.value && privateMessages.value.length) {
      privateMessages.value = [
        ...privateMessages.value,
        newMessage,
      ];
    } else {
      privateMessages.value = [newMessage];
    }

    sendMessage(
      chatSocket.value,
      currentUser.value.id,
      currentUser.value.firstName,
      currentUser.value.lastName,
      currentUser.value.profileUrl,
      currentUser.value.token,
      roomId,
      newMessage
    );

    chatInputRef.current.value = "";
    setTimeout(() => onMessageSent(), 200);
  };

  return (
    <View>
      <View style={tailwind`mr-1 mt-2`}>
        <ChatInput onSubmit={handleOnPress} ref={chatInputRef} />
      </View>
    </View>
  );
};

MessageEntry.propTypes = {
  roomId: PropTypes.string.isRequired,
  onMessageSent: PropTypes.func.isRequired,
};

export { MessageEntry as default };
