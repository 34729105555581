import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Dimensions,
  Platform,
  SafeAreaView,
  ScrollView,
  Text,
  View,
} from "react-native";
import tailwind from "twrnc";
import {
  bodyTagSelection,
  currentUser,
  deleteConfirmModalVisible,
  theme,
  userTagSelection,
} from "../../signals/signals";
import Button from "../base/Button";
import { BottomSheetContext } from "../base/ApplicationContext";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import {
  API_URL,
  TREE_TYPE_BODY,
  TREE_TYPE_USER,
  queryClient,
} from "../base/Constants";
import { Signal, useSignal } from "@preact/signals-react";
import { Switch } from "@rneui/themed";
import axios from "axios";
import { StyledInput } from "../base/StyledInput";
import ThumbnailUpload from "./ThumbnailUpload";
import EditableVideoThumbnail from "./EditableVideoThumbnail";
import ThumbnailClear from "./ThumbnailClear";
import TagSelector from "../tags/TagSelector";
import { ResizeMode, Video } from "expo-av";
import DeleteVideoConfirmationModal from "../modals/DeleteVideoConfirmationModal";
import YTPlayer from "react-native-youtube-iframe";
import {
  analyzeSalePrice,
  analyzeTagSelections,
  analyzeVideoDescription,
  analyzeVideoTitle,
  analyzeVideoYoutubeId,
} from "../helpers/validation";
import FileTooLargeErrorModal from "../modals/FileTooLargeErrorModal";
import YoutubeThumbnail from "../thumbnails/youtube/YoutubeThumbnail";
import { setTagSelections } from "../helpers/tagProcessor";

const EditVideo = ({ video }): JSX.Element => {
  const {
    id,
    price,
    isEnabled,
    difficulty,
    title,
    description,
    youtubeId,
    userSchedule,
    isTitleShown,
    thumbnailPath,
    onDemandVideoTags,
    path,
  } = video;
  const { setBottomSheetVisible } = useContext(BottomSheetContext);
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;
  const isYoutubeVideo = youtubeId?.length > 0;
  const isReplay = userSchedule !== null;
  const videoPath = useSignal(path);
  const isPublic: Signal<boolean> = useSignal(isEnabled);
  const videoTitleShown: Signal<boolean> = useSignal(isTitleShown);
  const videoThumbnailPath = useSignal(thumbnailPath);
  const salePrice = useSignal(price?.toString() || 0);
  const salePriceStatus = useSignal("");
  const videoTitle = useSignal(title);
  const videoTitleStatus = useSignal("");
  const videoDescription = useSignal(description);
  const videoDescriptionStatus = useSignal("");
  const videoYoutubeId = useSignal(youtubeId);
  const videoYoutubeIdStatus = useSignal("");
  const canShowYouTubeVideo = useSignal(false);
  const refVideo = useRef<Video>(null);

  const userTagStatus = useSignal("");
  const bodyTagStatus = useSignal("");

  const toggleSwitch = () => {
    isPublic.value = !isPublic.value;
  };

  const toggleTitleShown = () => {
    videoTitleShown.value = !videoTitleShown.value;
  };

  const saveChanges = () => {
    axios
      .put(
        API_URL + "videos/" + id,
        {
          price: salePrice.value,
          isEnabled: isPublic.value,
          title: videoTitle.value,
          description: videoDescription.value,
          onDemandVideoTags: [
            ...userTagSelection.value.filter((tag) => !tag.deleted),
            ...bodyTagSelection.value.filter((tag) => !tag.deleted),
          ],
          difficulty: difficulty,
          isTitleShown: videoTitleShown.value,
          thumbnailPath: videoThumbnailPath.value,
          youtubeId: videoYoutubeId.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.value.token,
          },
        }
      )
      .then((res) => {
        setBottomSheetVisible(false);
        queryClient.invalidateQueries([
          "videos/provider/replay/" + currentUser.value.id,
        ]);
        queryClient.invalidateQueries([
          "videos/provider/" + currentUser.value.id,
        ]);
        queryClient.invalidateQueries([
          "videos/provider/ondemand/" + currentUser.value.id,
        ]);
        queryClient.invalidateQueries([
          "videos/provider/youtube/" + currentUser.value.id,
        ]);
        return res.data;
      });
  };

  useEffect(() => {
    const selectedTags = [];
    onDemandVideoTags?.forEach((videoTag) => {
      selectedTags.push({
        id: videoTag.tagId.id,
        type: videoTag.tagId.descendantClosure[0].ancestor.id,
      });
    });

    canShowYouTubeVideo.value = true;
    setTagSelections(selectedTags);
    analyzeVideoTitle(videoTitle, videoTitleStatus);
    analyzeVideoDescription(videoDescription, videoDescriptionStatus);
    analyzeTagSelections(TREE_TYPE_USER, userTagStatus);
    analyzeTagSelections(TREE_TYPE_BODY, bodyTagStatus);
  }, []);

  const canSave = useSignal(false);
  useEffect(() => {
    canSave.value = isYoutubeVideo
      ? videoYoutubeIdStatus.value === ""
      : videoTitleStatus.value === "" &&
        userTagStatus.value === "" &&
        bodyTagStatus.value === "";
  }, [videoTitleStatus.value, userTagStatus.value, bodyTagStatus.value]);

  const screenHeight = Dimensions.get("screen").height;

  let playerHeight = 300;
  let playerWidth = 200;

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <SafeAreaView
      style={tailwind`flex w-full bg-[${theme.value.backgroundColor}]`}
    >
      <ScrollView
        scrollEnabled={true}
        style={isMobileWebFormat ? tailwind`h-[${screenHeight}px]` : null}
      >
        <View style={tailwind`p-2`}>
          <View
            style={tailwind`w-full mb-4 ${
              !isPublic.value ? "bg-[" + theme.value.redColor + "]" : ""
            }`}
          >
            <View style={tailwind`flex-row self-center py-2`}>
              <Switch
                style={tailwind`mt-1`}
                trackColor={{
                  false: theme.value.greenColor,
                  true: theme.value.redColor,
                }}
                ios_backgroundColor="#3e3e3e"
                onValueChange={() => toggleSwitch()}
                value={isPublic.value}
              />
              <Text
                style={tailwind`ml-2 pt-1 font-semibold text-[${
                  isPublic.value ? theme.value.textColor : theme.value.textColor
                }]`}
              >
                {isPublic.value
                  ? "Enabled, this video will be shown publicly"
                  : "Disabled, this video will not be shown publibly"}
              </Text>
            </View>
          </View>
          <View
            style={isMobileWebFormat ? tailwind`flex-col` : tailwind`flex-row`}
          >
            <View
              style={isMobileWebFormat ? tailwind`w-full` : tailwind`w-1/3`}
            >
              <View style={tailwind`self-center py-2`}>
                <View style={tailwind`py-2`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.value.textColor}] font-semibold pb-1`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    Video
                  </Text>
                  <View style={tailwind`w-[212px] h-[118px] rounded-lg`}>
                    {isYoutubeVideo ? (
                      canShowYouTubeVideo.value && (
                        <YTPlayer
                          height={playerHeight}
                          width={playerWidth}
                          play={false}
                          videoId={youtubeId}
                          onChangeState={() => {}}
                        />
                      )
                    ) : (
                      <Video
                        ref={refVideo}
                        volume={5}
                        style={{
                          height: 118,
                          width: 212,
                        }}
                        source={{
                          uri: videoPath.value,
                        }}
                        videoStyle={{
                          height: 118,
                          width: 212,
                        }}
                        useNativeControls
                        resizeMode={ResizeMode.CONTAIN}
                        onError={(error) => console.log("error", error)}
                        shouldPlay={Platform.OS === "web" ? false : true}
                      />
                    )}
                  </View>
                </View>
                <View style={tailwind`py-2`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.value.textColor}] font-semibold pb-1`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    Thumbnail
                  </Text>
                  {isYoutubeVideo ? (
                    <YoutubeThumbnail
                      key={0}
                      video={{ video }}
                      onPressOverride={() => {}}
                    />
                  ) : (
                    <>
                      <EditableVideoThumbnail
                        video={video}
                        videoThumbnailPath={videoThumbnailPath}
                        videoTitleShown={videoTitleShown}
                        videoTitle={videoTitle}
                        onPressOverride={() => {}}
                      />

                      <View style={tailwind`flex-row`}>
                        <ThumbnailUpload
                          thumbnailPath={videoThumbnailPath}
                        />
                        <View>
                          <ThumbnailClear
                            thumbnailPath={videoThumbnailPath}
                          />
                        </View>
                        <View style={tailwind`flex-row pt-2`}>
                          <Switch
                            style={tailwind`mt-1`}
                            trackColor={{
                              false: theme.value.redColor,
                              true: theme.value.greenColor,
                            }}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={toggleTitleShown}
                            value={videoTitleShown.value}
                          />
                          <Text
                            style={tailwind`ml-2 pt-1 font-semibold text-[${
                              videoTitleShown.value
                                ? theme.value.textColor
                                : theme.value.redColor
                            }]`}
                          >
                            {videoTitleShown.value
                              ? "Title Shown"
                              : "Title Hidden"}
                          </Text>
                        </View>
                      </View>
                    </>
                  )}
                </View>
              </View>
            </View>
            <View
              style={isMobileWebFormat ? tailwind`w-full` : tailwind`w-2/3`}
            >
              {!isYoutubeVideo ? (
                <>
                  <StyledInput
                    width={"355px"}
                    label={"Title"}
                    placeholder={"Title"}
                    value={videoTitle.value}
                    onChangeText={(text) => {
                      videoTitle.value = text;
                      analyzeVideoTitle(videoTitle, videoTitleStatus);
                    }}
                    onBlur={() => {
                      analyzeVideoTitle(videoTitle, videoTitleStatus);
                    }}
                    fieldStatus={videoTitleStatus}
                    autoCorrect={true}
                  />
                  <View style={tailwind`mt-4`}>
                    <StyledInput
                      label={"Description"}
                      multiline={true}
                      numberOfLines={3}
                      placeholder={"Description"}
                      onChangeText={(text) => {
                        videoDescription.value = text;
                        analyzeVideoDescription(
                          videoDescription,
                          videoDescriptionStatus
                        );
                      }}
                      onBlur={() => {
                        analyzeVideoDescription(
                          videoDescription,
                          videoDescriptionStatus
                        );
                      }}
                      fieldStatus={videoDescriptionStatus}
                      width={"355px"}
                      height={"100px"}
                      value={videoDescription.value}
                      autoCorrect={true}
                    />
                  </View>
                  {isReplay && (
                    <View style={tailwind`mt-4`}>
                      <StyledInput
                        label="Price (&pound;)"
                        onChangeText={(itemValue) => {
                          salePrice.value = itemValue;
                          analyzeSalePrice(salePrice, salePriceStatus);
                        }}
                        onBlur={() => {
                          analyzeSalePrice(salePrice, salePriceStatus);
                        }}
                        fieldStatus={salePriceStatus}
                        width={"355px"}
                        keyboardType="numeric"
                        value={salePrice.value}
                        autoCorrect={false}
                      />
                    </View>
                  )}
                </>
              ) : (
                <StyledInput
                  width={"355px"}
                  label={"Youtube Id"}
                  placeholder={"Youtube Id"}
                  disabled={true}
                  value={videoYoutubeId.value}
                  onChangeText={(text) => {
                    videoYoutubeId.value = text;
                    analyzeVideoYoutubeId(videoYoutubeId, videoYoutubeIdStatus);
                  }}
                  onBlur={() => {
                    analyzeVideoYoutubeId(videoYoutubeId, videoYoutubeIdStatus);
                  }}
                  fieldStatus={videoYoutubeIdStatus}
                  autoCorrect={true}
                />
              )}
              <View style={tailwind`mt-4 mr-2`}>
                <Text
                  style={[
                    tailwind`text-[${theme.value.textColor}] font-semibold pb-1`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  Categories
                </Text>
                <View style={tailwind`mb-2`}>
                  <TagSelector
                    tagType={TREE_TYPE_USER}
                    fieldStatus={userTagStatus}
                  />
                  {userTagStatus.value ? (
                    <View style={tailwind`mb-2`}>
                      <Text
                        style={[
                          tailwind`text-xs ml-2 text-[${theme.value.redColor}]`,
                          { fontFamily: "Roboto_400Regular" },
                        ]}
                      >
                        {userTagStatus.value}
                      </Text>
                    </View>
                  ) : null}
                </View>
                <View style={tailwind`mb-2`}>
                  <TagSelector
                    tagType={TREE_TYPE_BODY}
                    fieldStatus={bodyTagStatus}
                  />
                  {bodyTagStatus.value ? (
                    <View style={tailwind`mb-2`}>
                      <Text
                        style={[
                          tailwind`text-xs ml-2 text-[${theme.value.redColor}]`,
                          { fontFamily: "Roboto_400Regular" },
                        ]}
                      >
                        {bodyTagStatus.value}
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={tailwind`self-center w-full pb-6 mt-2`}>
          <View style={tailwind`self-center flex-row`}>
            {isYoutubeVideo || !isReplay ? (
              <>
                <Button
                  title="Delete"
                  onPress={() => {
                    deleteConfirmModalVisible.value = true;
                  }}
                />
                <View style={tailwind`w-4`} />
              </>
            ) : null}
            <Button
              title="Close"
              onPress={() => {
                setBottomSheetVisible(false);
              }}
            />
            <Button
              disabled={!canSave.value}
              title="Save"
              onPress={() => saveChanges()}
            />
          </View>
        </View>
      </ScrollView>
      <DeleteVideoConfirmationModal video={video} />
      <FileTooLargeErrorModal />
    </SafeAreaView>
  );
};

EditVideo.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  thumbnailPath: PropTypes.string,
  videoUrl: PropTypes.string,
  price: PropTypes.number,
  isEnabled: PropTypes.bool,
  difficulty: PropTypes.number,
  youtubeId: PropTypes.string,
  userSchedule: PropTypes.any,
  isTitleShown: PropTypes.bool,
  onDemandVideoTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export { EditVideo as default };
