import React from "react";
import PropTypes from "prop-types";
import { Text, Pressable, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../signals/signals";

const Button = ({ title, onPress, selected, disabled }) => {
  let buttonStyle = {};

  buttonStyle = selected
    ? tailwind`w-auto h-[40px] bg-[${theme.value.primaryButtonSelectedColor}] rounded-lg p-2 ml-1 w-[100px] max-w-[200px]`
    : disabled
    ? tailwind`w-auto h-[40px] bg-[#cccccc] rounded-lg p-2 ml-1 w-[100px] max-w-[200px]`
    : tailwind`w-auto h-[40px] bg-[${theme.value.primaryButtonColor}] rounded-lg p-2 ml-1 w-[100px] max-w-[200px]`;

  return (
    <Pressable onPress={!disabled ? onPress : null} style={buttonStyle}>
      <View style={tailwind`py-1`}>
        <Text
          style={tailwind`text-[${theme.value.primaryButtonTextColor}] font-semibold text-xs self-center`}
        >
          {title}
        </Text>
      </View>
    </Pressable>
  );
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
