import React from "react";
import PropTypes from "prop-types";

import { Text, View } from "react-native";
import tailwind from "twrnc";
import {
  NOTIFICATION_TYPE_CONNECTION,
  NOTIFICATION_TYPE_MESSAGE,
} from "../base/Constants";
import {
  outstandingConnectionCount,
  outstandingMessageCount,
  outstandingNotificationCount,
  theme,
} from "../../signals/signals";

const NotificationBadge = ({ type }) => {
  let badgeValue = 0;
  switch (type) {
    case NOTIFICATION_TYPE_CONNECTION:
      badgeValue = outstandingConnectionCount.value;
      break;
    case NOTIFICATION_TYPE_MESSAGE:
      badgeValue = outstandingMessageCount.value;
      break;
    default:
      badgeValue = outstandingNotificationCount.value;
      break;
  }

  return badgeValue > 0 ? (
    <View
      style={tailwind`absolute left-11 top--3 rounded-full bg-[${theme.value.notificationItemBackgroundColor}] px-[2px] py-[1px] min-w-[16px]`}
    >
      <Text
        style={tailwind`text-[8px] self-center py-[3px] text-[${theme.value.textColor}]`}
      >
        {badgeValue}
      </Text>
    </View>
  ) : null;
};

NotificationBadge.propTypes = {
  type: PropTypes.number.isRequired,
};

export { NotificationBadge as default };
