import { Signal } from "@preact/signals-react";
import dayjs from "dayjs";
import { theme } from "../../signals/signals";
import { RefObject } from "react";
import { scaleCanvasElementFromTopLeft } from "./broadcastFunctions";

const sourceWidth = 800;
const sourceHeight = 600;

export const drawLiveTimer = (
  ctx: CanvasRenderingContext2D,
  startTime: Signal<string>,
  endTime: Signal<string>,
  timeLive: Signal<number>,
  canvasRef: RefObject<HTMLCanvasElement>
) => {
  if (startTime.value && !endTime.value) {
    const timeLiveLabel = toTimeString(
      dayjs(Date.now()).diff(startTime.value) / 1000
    );
    timeLive.value = dayjs(Date.now()).diff(startTime.value) / 1000;

    ctx.fillStyle = "#fff";

    const timerWidthAtScale = 80;
    const timerHeightAtScale = 20;
    const timerRadiusAtScale = timerHeightAtScale / 2;
    const timerLeftOffset = 15;
    const timerTopOffset = 15;
    const textTopOffset = 29;
    const textLeftOffset = 39;
    const liveDotTopOffset = 25;
    const liveDotLeftOffset = 25;
    const liveDotRadius = 6;

    let { leftPosition, topPosition, width, height } =
      scaleCanvasElementFromTopLeft(
        sourceWidth,
        sourceHeight,
        canvasRef.current.width,
        canvasRef.current.height,
        timerLeftOffset,
        timerTopOffset,
        timerWidthAtScale,
        timerHeightAtScale
      );

    let radiusResults = scaleCanvasElementFromTopLeft(
      sourceWidth,
      sourceHeight,
      canvasRef.current.width,
      canvasRef.current.height,
      0,
      0,
      timerRadiusAtScale,
      0
    );

    let textResults = scaleCanvasElementFromTopLeft(
      sourceWidth,
      sourceHeight,
      canvasRef.current.width,
      canvasRef.current.height,
      textLeftOffset,
      textTopOffset,
      12,
      0
    );

    let liveDotResults = scaleCanvasElementFromTopLeft(
      sourceWidth,
      sourceHeight,
      canvasRef.current.width,
      canvasRef.current.height,
      liveDotLeftOffset,
      liveDotTopOffset,
      liveDotRadius,
      0
    );

    drawTimeContainer(
      ctx,
      radiusResults.width,
      leftPosition,
      topPosition,
      width,
      height
    );
    drawLiveDot(
      ctx,
      liveDotResults.leftPosition,
      liveDotResults.topPosition,
      liveDotResults.width
    );
    drawTimeText(
      ctx,
      timeLiveLabel,
      textResults.leftPosition,
      textResults.topPosition,
      textResults.width
    );
  }
};

const drawTimeContainer = (
  ctx: CanvasRenderingContext2D,
  radius: number,
  leftOffset: number,
  topOffset: number,
  width: number,
  height: number
) => {
  ctx.beginPath();
  ctx.moveTo(radius + leftOffset, topOffset);
  ctx.lineTo(width - radius + leftOffset, topOffset);
  ctx.arc(
    width - radius + leftOffset,
    radius + leftOffset,
    radius,
    -Math.PI / 2,
    Math.PI / 2
  );
  ctx.lineTo(radius + leftOffset, height + topOffset);
  ctx.arc(
    radius + leftOffset,
    radius + topOffset,
    radius,
    Math.PI / 2,
    (3 * Math.PI) / 2
  );
  ctx.closePath();
  ctx.fill();
};

const drawTimeText = (
  ctx: CanvasRenderingContext2D,
  timeLiveLabel: string,
  textLeftOffset: number,
  textTopOffset: number,
  fontSize: number
) => {
  ctx.font = fontSize + "px roboto";
  ctx.fillStyle = "#000";
  ctx.fillText(timeLiveLabel, textLeftOffset, textTopOffset);
};

const drawLiveDot = (
  ctx: CanvasRenderingContext2D,
  leftOffset: number,
  topOffset: number,
  radius: number
) => {
  ctx.beginPath();
  ctx.arc(leftOffset, topOffset, radius, 0, 2 * Math.PI, false);
  ctx.fillStyle = theme.value.redColor;
  ctx.fill();
};
export const toTimeString = (totalSeconds: number): string => {
  const totalMs = totalSeconds * 1000;
  const result = new Date(totalMs).toISOString().slice(11, 19);
  return result;
};
