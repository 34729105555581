import axios from "axios";
import { createContext } from "react";
import { API_URL } from "./Constants";

export const BottomSheetContext = createContext(null);
export const FullScreenContext = createContext(null);
export const OrientationContext = createContext(null);

export function updateReadMessages(
  userId: number,
  privateRoomId: number,
  token: string
) {
  axios.put(
    API_URL + "private-room/markread",
    {
      privateRoomId: privateRoomId,
      userId: userId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
      },
    }
  );
}
