import { Signal } from "@preact/signals-react";
import StreamControls from "./StreamControls";
import { ScrollView, View } from "react-native";
import tailwind from "twrnc";
import SignalChatLive from "../chat/SignalChatLive";
import { FitCentrEvent } from "./types/FitCentrEvent";
import BroadcastDetailsPanel from "./BroadcastDetailsPanel";
import ViewerSummary from "./ViewerSummary";

const BroadcastPanel = ({
  event,
  roomId,
  cameraEnabled,
  streamStoppingCounter,
  stopStreaming,
  startStreaming,
  scrollViewHeight,
}: {
  event: FitCentrEvent;
  roomId: string;
  cameraEnabled: Signal<boolean>;
  streamStoppingCounter: Signal<number>;
  stopStreaming: () => void;
  startStreaming: () => void;
  scrollViewHeight: Signal<number>;
}) => {
  if (!cameraEnabled.value) return null;
  return (
    <ScrollView style={tailwind`h-[${scrollViewHeight.value}px] w-full`}>
      <View style={tailwind`flex-row flex-wrap self-center mt-2`}>
        <View style={tailwind`w-9/30 min-w-[300px] max-w-[600px] mb-2 px-1`}>
          <SignalChatLive roomId={roomId} />
        </View>
        <View style={tailwind`grow`} />
        <View style={tailwind`w-9/30 min-w-[300px] max-w-[600px] mb-2 px-1`}>
          <View style={tailwind`rounded-lg bg-white p-2 mb-2 px-1`}>
            <StreamControls
              cameraEnabled={cameraEnabled}
              streamStoppingCounter={streamStoppingCounter}
              stopStreaming={stopStreaming}
              startStreaming={startStreaming}
            />
          </View>
          <BroadcastDetailsPanel fitCentrEvent={event} />
        </View>
        <View style={tailwind`grow`} />
        <View style={tailwind`w-9/30 min-w-[300px] max-w-[600px] mb-2 px-1`}>
          <View style={tailwind`rounded-lg bg-white p-2`}>
            <ViewerSummary event={event} />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export { BroadcastPanel as default };
