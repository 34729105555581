import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { DrawerContentScrollView, DrawerItem } from "@react-navigation/drawer";
import tailwind from "twrnc";
import { ActivityIndicator, Dimensions, Platform, View } from "react-native";
import { Image } from "@rneui/themed";
import NotificationBadge from "./NotificationBadge";
import {
  DEFAULT_PROFILE_URL,
  NOTIFICATION_TYPE_CONNECTION,
  NOTIFICATION_TYPE_EVENT,
  NOTIFICATION_TYPE_MESSAGE,
} from "../base/Constants";
import { currentUser, focusedScreen, isEmailAddressVerified, isProfileComplete, isStreaming, theme } from "../../signals/signals";
import { useSignal } from "@preact/signals-react";
import * as ScreenOrientation from "expo-screen-orientation";
const CustomDrawerContent = (props) => {
  const { navigation } = props;
  const windowOrientation = useSignal("portrait");
  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        windowOrientation.value = getOrientation(orientationInfo.orientation);
      }, 300);
    });
  }, []);
  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };
  const isMobileWebFormat = Platform.OS === "web" &&
    (
      (windowOrientation.value === "portrait" && Dimensions.get("screen").width < 768) ||
      (windowOrientation.value === "landscape" && Dimensions.get("screen").height < 768)
    );

  return (
    <>
      <DrawerContentScrollView
        {...props}
        style={tailwind`bg-[${theme.value.navigationDrawerBackgroundColor}]`}
      >
        <DrawerItem
          label={() => {
            return (
              <View style={tailwind`w-[112px] mb-10`}>
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[56px] w-[75px] self-center`}
                  source={require("../../assets/fitcentr-logo-white-orange.png")}
                />
              </View>
            );
          }}
          onPress={() => {
            if (!isStreaming.value) {
              focusedScreen.value = "HomeScreen";
              navigation.navigate("HomeScreen");
            }
          }}
        />
        {Platform.OS === "web" && !isMobileWebFormat && !isStreaming.value ? (
          <>
            <DrawerItem
              focused={focusedScreen.value === "HomeScreen"}
              activeTintColor={theme.value.navigationDrawerActiveTintColor}
              label={({ focused }) => {
                return (
                  <View style={tailwind`w-[112px] mb-4`}>
                    <Image
                      resizeMode="contain"
                      style={tailwind`h-[18px] w-[18px] ml-[26px]`}
                      source={require("../../assets/home-icon.png")}
                      tintColor={focused ? theme.value.footerNavigationIconActiveColor : theme.value.footerNavigationIconColor}
                    />
                  </View>
                );
              }}
              onPress={() => {
                focusedScreen.value = "HomeScreen";
                navigation.navigate("HomeScreen");
              }}
            />
            <DrawerItem
              focused={focusedScreen.value === "CalendarScreen"}
              activeTintColor={theme.value.navigationDrawerActiveTintColor}
              label={({ focused }) => (
                <View style={tailwind`w-[112px] mb-4`}>
                  <Image
                    resizeMode="contain"
                    style={tailwind`h-[18px] w-[18px] ml-[26px]`}
                    source={require("../../assets/calendar-icon.png")}
                    tintColor={focused ? theme.value.footerNavigationIconActiveColor : theme.value.footerNavigationIconColor}
                  />
                </View>
              )}
              onPress={() => {
                focusedScreen.value = "CalendarScreen";
                navigation.navigate("CalendarScreen");
              }}
            />
            <DrawerItem
              focused={focusedScreen.value === "Chats"}
              activeTintColor={theme.value.navigationDrawerActiveTintColor}
              label={({ focused }) => (
                <View style={tailwind`w-[112px] mb-4`}>
                  <Image
                    resizeMode="contain"
                    style={tailwind`h-[18px] w-[18px] ml-[26px]`}
                    source={require("../../assets/messages-icon.png")}
                    tintColor={focused ? theme.value.footerNavigationIconActiveColor : theme.value.footerNavigationIconColor}
                  />
                  <NotificationBadge type={NOTIFICATION_TYPE_MESSAGE} />
                </View>
              )}
              onPress={() => {
                focusedScreen.value = "Chats";
                navigation.navigate("Chats");
              }}
            />
            <DrawerItem
              focused={focusedScreen.value === "ConnectionsScreen"}
              activeTintColor={theme.value.navigationDrawerActiveTintColor}
              label={({ focused }) => (
                <View style={tailwind`w-[112px] mb-4`}>
                  <Image
                    resizeMode="contain"
                    style={tailwind`h-[18px] w-[18px] ml-[26px]`}
                    source={require("../../assets/connections-icon.png")}
                    tintColor={focused ? theme.value.footerNavigationIconActiveColor : theme.value.footerNavigationIconColor}
                  />
                  <NotificationBadge type={NOTIFICATION_TYPE_CONNECTION} />
                </View>
              )}
              onPress={() => {
                focusedScreen.value = "ConnectionsScreen";
                navigation.navigate("ConnectionsScreen");
              }}
            />
            <DrawerItem
              focused={focusedScreen.value === "NotificationsScreen"}
              activeTintColor={theme.value.navigationDrawerActiveTintColor}
              label={({ focused }) => (
                <View style={tailwind`w-[112px] mb-4`}>
                  <Image
                    resizeMode="contain"
                    style={tailwind`h-[18px] w-[18px] ml-[26px]`}
                    source={require("../../assets/notifications-icon.png")}
                    tintColor={focused ? theme.value.footerNavigationIconActiveColor : theme.value.footerNavigationIconColor}
                  />
                  <NotificationBadge type={NOTIFICATION_TYPE_EVENT} />
                </View>
              )}
              onPress={() => {
                focusedScreen.value = "NotificationsScreen";
                navigation.navigate("NotificationsScreen");
              }}
            />
          </>
        ) : null}
        {!isStreaming.value && (
          <DrawerItem
            focused={focusedScreen.value === "SettingsScreen"}
            activeTintColor={theme.value.navigationDrawerActiveTintColor}
            label={({ focused }) => (
              <View style={tailwind`w-[60px] mb-4`}>
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[18px] w-[18px] ml-[26px]`}
                  source={require("../../assets/settings-icon.png")}
                  tintColor={focused ? theme.value.footerNavigationIconActiveColor : theme.value.footerNavigationIconColor}
                />
              </View>
            )}
            onPress={() => {
              focusedScreen.value = "SettingsScreen";
              navigation.navigate("SettingsScreen");
            }}
          />
        )}
        {!isStreaming.value && (
          <DrawerItem
            focused={focusedScreen.value === "CalendarScreen"}
            activeTintColor={theme.value.navigationDrawerActiveTintColor}
            label={() => {
              return (
                <View style={tailwind`w-[70px] h-[120px]`}>
                  <View style={tailwind`self-center h-full`}>
                    <View style={tailwind`grow`} />
                    <View
                      style={tailwind`rounded-full w-[55px] h-[55px] border-[1px]`}
                    >
                      <Image
                        style={tailwind`h-[55px] rounded-full`}
                        source={{ uri: currentUser.value.profileUrl || DEFAULT_PROFILE_URL }}
                        PlaceholderContent={<ActivityIndicator />}
                      />
                    </View>
                    {!isProfileComplete.value || !isEmailAddressVerified.value ? (
                      <View style={tailwind`absolute bottom-12 right--2`}>
                        <Image
                          resizeMode="contain"
                          style={tailwind`h-[18px] w-[18px]`}
                          source={require("../../assets/warning-icon.png")}
                          tintColor={theme.value.redColor}
                        />
                      </View>
                    ) : null}
                  </View>
                </View>
              );
            }}
            onPress={() => {
              focusedScreen.value = "ViewProfileScreen";
              navigation.navigate("ViewProfileScreen", {
                id: currentUser.value.id,
                type: currentUser.value.type,
              });
            }}
          />
        )}
      </DrawerContentScrollView>
    </>
  );
};
CustomDrawerContent.propTypes = {
  navigation: PropTypes.any,
};
export { CustomDrawerContent as default };