import * as React from "react";
import { View } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";

const HeaderLogin = () => {
  const image = require("../../assets/fitcentr-logo-orange.png");

  if (!image) return;

  return (
    <View style={tailwind`flex-row justify-center w-full mb-2`}>
      {image && (
        <Image
          resizeMode="contain"
          style={tailwind`h-15 w-20 mt-6`}
          source={image}
        />
      )}
    </View>
  );
};

export default HeaderLogin;
