import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  EVENT_TYPE_BOOKABLE,
  EVENT_TYPE_BROADCAST,
  EVENT_TYPE_OTHER,
  EVENT_TYPE_PRIVATE,
} from "../base/Constants";
import dayjs from "dayjs";
import ViewLiveStreamEvent from "./ViewLiveStreamEvent";
import ViewOpenPTEvent from "./ViewOpenPTEvent";
import { useSignal } from "@preact/signals-react";
import ViewPrivatePTEvent from "./ViewPrivatePTEvent";
import ViewGeneralEvent from "./ViewGeneralEvent";
import { currentUser } from "../../signals/signals";

const ViewEvent = ({ event }) => {
  const startDate = event.startDate;
  const typeId = event.type.id;
  const participants = event.participants;
  const bookings = event.bookings;

  const isBooked = useSignal(false);
  const streamCanBeJoined = useSignal(false);
  const streamCanBeStarted = useSignal(false);
  const canJoinPrivateRoom = useSignal(false);
  const isHistoric = useSignal(false);

  useEffect(() => {
    if (
      dayjs(startDate).diff(dayjs(), "minute") < 30 &&
      dayjs(startDate).diff(dayjs(), "minute") > 0
    ) {
      canJoinPrivateRoom.value = true;
    }
    if (
      dayjs(startDate).diff(dayjs(), "minute") < 30 &&
      dayjs(startDate).diff(dayjs(), "minute") > 0
    ) {
      streamCanBeJoined.value = true;
    }
    if (
      dayjs(startDate).diff(dayjs(), "minute") < 60 &&
      dayjs(startDate).diff(dayjs(), "minute") > 0
    ) {
      streamCanBeStarted.value = true;
    }
    isBooked.value =
      bookings && bookings.length
        ? bookings.filter((booking) => booking.eventDate === startDate).length
        : false;
    isHistoric.value = dayjs(startDate).diff(dayjs(), "minute") < 0;
  }, []);

  let eventParticipants = [];
  participants.forEach((participant) => {
    const newParticipant = {
      id: participant.user.id,
      profileUrl: participant.user.profileUrl,
      name: participant.user.firstName,
    };
    if (currentUser.value.id !== participant.user.id) {
      eventParticipants.push(newParticipant);
    }
  });

  switch (typeId) {
    case EVENT_TYPE_BROADCAST:
      return <ViewLiveStreamEvent event={event} />;
    case EVENT_TYPE_BOOKABLE:
      return <ViewOpenPTEvent event={event} />;
    case EVENT_TYPE_PRIVATE:
      return <ViewPrivatePTEvent event={event} />;
    case EVENT_TYPE_OTHER:
    default:
      return <ViewGeneralEvent event={event} />;
  }
};

ViewEvent.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    type: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    isPublic: PropTypes.bool.isRequired,
    duration: PropTypes.number.isRequired,
    shortDescription: PropTypes.string.isRequired,
    longDescription: PropTypes.string,
    difficulty: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    participants: PropTypes.array,
    location: PropTypes.string,
    tags: PropTypes.arrayOf({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    rule: PropTypes.shape({
      id: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      dayOfMonth: PropTypes.number,
      daysOfWeek: PropTypes.string,
      repeatType: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
    scheduleTags: PropTypes.array,
    bookings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired,
        eventDate: PropTypes.string.isRequired,
        updatedAt: PropTypes.string.isRequired,
        user: PropTypes.shape({
          id: PropTypes.number.isRequired,
          createdAt: PropTypes.string.isRequired,
        }).isRequired,
      })
    ),
  }),
};

export { ViewEvent as default };
