import React, {
  ActivityIndicator,
  Dimensions,
  Platform,
  Pressable,
  ScrollView,
  SectionList,
  Text,
  View,
} from "react-native";
import PropTypes from "prop-types";
import tailwind from "twrnc";
import { currentUser, theme } from "../signals/signals";
import { QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  API_URL,
  queryClient,
} from "../components/base/Constants";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import dayjs from "dayjs";
import { useSignal } from "@preact/signals-react";
import SettingScreenHeader from "../components/settings-screen/SettingsScreenHeader";
import ApiError from "../components/error/ApiError";

const ReplaySalesScreen = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReplayHistory />
    </QueryClientProvider>
  );
};

const ReplayHistory = () => {
  const expandedSection = useSignal();
  const userId = currentUser.value.id;
  const { token } = currentUser.value;

  const { isLoading, error, data, isFetching } = useQuery(
    ["booking/provider/replay/history" + userId],
    () =>
      axios
        .get(API_URL + "booking/provider/replay/history/" + userId, {
          headers: { authorization: token },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err))
  );

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;
  if (isLoading || isFetching) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const handleSectionHeaderPress = (title) => {
    expandedSection.value === title ? (expandedSection.value = "") : (expandedSection.value = title);
  }

  const parsedData = [];
  let rowIndex = 1;
  data.forEach((onDemandVideo) => {
    const existingSection = parsedData.filter(
      (sectionItem) => sectionItem.title === onDemandVideo.title
    )[0];

    if (!existingSection) {
      const newSection = {
        title: onDemandVideo.title,
        data: [onDemandVideo],
        showHeadings: expandedSection.value === onDemandVideo.title,
      };
      parsedData.push(newSection);
    } else {
      existingSection.data.push(onDemandVideo);
    }
  });

  return (
    <View
      style={tailwind`pt-10 p-2 w-full h-full bg-[${theme.value.backgroundColor}]`}
    >
      <SettingScreenHeader />
      <View style={tailwind`w-full self-center`}>
        <>
          <View style={tailwind`mb-4`}>
            <Text
              style={[
                tailwind`self-center font-semibold text-[${theme.value.textColor}] text-[14px]`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              Your Replay Sales
            </Text>
          </View>
          <ScrollView style={tailwind`h-[150v] bg-white`}>
            <SectionList
              collapsable={true}
              nestedScrollEnabled={true}
              sections={parsedData}
              keyExtractor={(item) => item.id}
              renderSectionHeader={({ section: { title, showHeadings } }) => {
                return (
                  <Pressable
                    onPress={() => handleSectionHeaderPress(title)
                    }
                  >
                    <View
                      style={tailwind`w-full pl-2 py-1 border-b-[1px] border-[${theme.value.eventBookingsYearTitleBottomBorderColor}] bg-[${theme.value.eventBookingsYearTitleBackgroundColor}]`}
                    >
                      <Text
                        style={[
                          tailwind`font-semibold text-[${theme.value.textColor}]`,
                          { fontFamily: "Roboto_700Bold" },
                        ]}
                      >
                        {title}
                      </Text>
                    </View>
                    {showHeadings && <PaymentTitles />}
                  </Pressable>
                );
              }}
              renderItem={({ item }) => {
                return (
                  <View style={{ height: expandedSection.value === item.title ? 'auto' : 0, overflow: 'hidden' }}>
                    <ReplayHistoryPurchases
                      onDemandVideo={item}
                      rowIndex={rowIndex++}
                    />
                  </View>
                );
              }}
            />
          </ScrollView>
        </>
      </View>
    </View>
  );
};

const ReplayHistoryPurchases = ({ onDemandVideo }) => {
  const { settings } = currentUser.value;
  const fitCentrLiveFee = settings.filter(
    (setting) => setting.key === "FITCENTR_LIVE_FIXED_FEE"
  )[0].value;

  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;

  return (
    <View style={tailwind`w-[90%] self-center mb-1`}>

      <View style={tailwind`w-full pt-8 py-1`}>
        {onDemandVideo.payments.map((payment) => {
          return (
            <View key={payment.id} style={tailwind`w-full self-center flex-row`}>
              <View style={tailwind`w-2/8`}>
                <Text
                  style={[
                    tailwind`font-semibold text-[${theme.value.textColor}] ${isMobileWebFormat ? "text-xs" : null}`,
                    { fontFamily: "Roboto_700Bold" },
                  ]}
                >
                  {dayjs(payment.createdAt).format("ddd DD MMM YYYY HH:mm")}
                </Text>
              </View>
              <View style={tailwind`grow`}>
                <Text
                  style={[
                    tailwind`font-semibold text-[${theme.value.textColor}] ${isMobileWebFormat ? "text-xs" : null}`,
                    { fontFamily: "Roboto_700Bold" },
                  ]}
                >
                  {payment.user.firstName + " " + payment.user.lastName}
                </Text>
              </View>
              <View style={tailwind`w-1/8`}>
                <Text
                  style={[
                    tailwind`font-semibold text-[${theme.value.textColor}] self-end ${isMobileWebFormat ? "text-xs" : null}`,
                    { fontFamily: "Roboto_700Bold" },
                  ]}
                >
                  &pound;{payment.amount.toFixed(2)}
                </Text>
              </View>
              <View style={tailwind`w-1/8`}>
                <Text
                  style={[
                    tailwind`font-semibold text-[${theme.value.textColor}] self-end ${isMobileWebFormat ? "text-xs" : null}`,
                    { fontFamily: "Roboto_700Bold" },
                  ]}
                >
                  &pound;{Number(fitCentrLiveFee).toFixed(2)}
                </Text>
              </View>
              <View style={tailwind`w-1/8`}>
                <Text
                  style={[
                    tailwind`font-semibold text-[${theme.value.textColor}] self-end ${isMobileWebFormat ? "text-xs" : null}`,
                    { fontFamily: "Roboto_700Bold" },
                  ]}
                >
                  &pound;{(payment.amount - Number(fitCentrLiveFee)).toFixed(2)}

                </Text>
              </View>
            </View>
          )
        })}
      </View>
    </View>
  );
}

const PaymentTitles = () => {
  const isMobileWebFormat = Platform.OS === "web" && Dimensions.get("screen").width < 768;
  return (
    <View style={tailwind`flex-row pt-2 w-[90%] self-center`}>
      <View style={tailwind`w-2/8`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] ${isMobileWebFormat ? "text-xs" : null}`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Date
        </Text>
      </View>
      <View style={tailwind`grow`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] ${isMobileWebFormat ? "text-xs" : null}`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Purchased By
        </Text>
      </View>
      <View style={tailwind`w-1/8`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] self-end ${isMobileWebFormat ? "text-xs" : null}`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Charged
        </Text>
      </View>
      <View style={tailwind`w-1/8`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] self-end ${isMobileWebFormat ? "text-xs" : null}`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Fee
        </Text>
      </View>
      <View style={tailwind`w-1/8`}>
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] self-end ${isMobileWebFormat ? "text-xs" : null}`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          {isMobileWebFormat ? "Rev." : "Revenue"}
        </Text>
      </View>
    </View>
  );
};

ReplayHistoryPurchases.propTypes = {
  onDemandVideo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    payments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired
    }))
  }),
};

export { ReplaySalesScreen as default };
