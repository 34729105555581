import React from "react";
import PropTypes from "prop-types";
import { Text, View } from "react-native";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import tailwind from "twrnc";
import RTCStreamWrapper from "./RTCStreamWrapper";

const ClientStream = ({ remoteStream, name, profileUrl, isClient }) => {
  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return null;

  return (
    <View>
      {remoteStream && (
        <RTCStreamWrapper
          remoteStream={remoteStream}
          isHost={false}
          isClient={isClient}
        />
      )}
      <Text
        style={[
          tailwind`self-center text-xs`,
          { fontFamily: "Rotobo_400Regular" },
        ]}
      >
        {name}
      </Text>
    </View>
  );
};

ClientStream.propTypes = {
  remoteStream: PropTypes.shape({
    userId: PropTypes.number,
    media: PropTypes.shape({
      streams: PropTypes.array,
    }),
  }).isRequired,
  name: PropTypes.string.isRequired,
  profileUrl: PropTypes.string.isRequired,
  isClient: PropTypes.bool.isRequired,
};

export { ClientStream as default };
