import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { SafeAreaView, ScrollView, Text, View } from "react-native";
import tailwind from "twrnc";
import {
  API_URL,
  TREE_TYPE_USER,
  USER_TYPE_PROVIDER,
  USER_TYPE_USER,
} from "../components/base/Constants";
import Button from "../components/base/Button";
import TagSelector from "../components/tags/TagSelector";
import { useNavigation } from "@react-navigation/native";
import PersonalDetailsForm from "../components/register-screen/PersonalDetailsForm";
import axios from "axios";
import SwitchSelector from "react-native-switch-selector";
import {
  Roboto_400Regular,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";
import { theme, userTagSelection } from "../signals/signals";
import { Signal, useSignal } from "@preact/signals-react";
import { handleLogin } from "../components/helpers/auth";

const RegisterUserScreen = () => {
  const navigation = useNavigation();
  const userTagStatus = useSignal("");
  const emailAddress = useSignal("");
  const emailAddressStatus = useSignal("");
  const password = useSignal("");
  const passwordStatus = useSignal("");
  const confirmPassword = useSignal("");
  const confirmPasswordStatus = useSignal("");
  const firstName = useSignal("");
  const firstNameStatus = useSignal("");
  const lastName = useSignal("");
  const lastNameStatus = useSignal("");
  const isProvider = useSignal(false);
  const registerResponse = useSignal("");
  const canSave = useSignal(false);
  const loginStatus = useSignal("");

  useEffect(() => {
    if (
      emailAddressStatus.value === "" &&
      passwordStatus.value === "" &&
      confirmPasswordStatus.value === "" &&
      firstNameStatus.value === "" &&
      lastNameStatus.value === "" &&
      userTagStatus.value === "" &&
      userTagSelection.value.length > 0
    ) {
      canSave.value = true;
    } else {
      canSave.value = false;
    }
  }, [
    emailAddressStatus.value,
    passwordStatus.value,
    confirmPasswordStatus.value,
    firstNameStatus.value,
    lastNameStatus.value,
    userTagStatus.value,
    userTagSelection.value,
  ]);

  const options = [
    {
      label: "Fitness User",
      value: "0",
      accessibilityLabel: "fitness-user",
    },
    {
      label: "Fitness Provider",
      value: "1",
      accessibilityLabel: "fitness-provider",
    },
  ];

  let fonts = useFonts({ Roboto_700Bold, Roboto_400Regular });
  if (!fonts) return;

  return (
    <SafeAreaView
      style={tailwind`flex h-full bg-[${theme.value.backgroundColor}]`}
    >
      <ScrollView>
        <View>
          <View style={tailwind`self-center flex-row mt-[40px] mb-[60px]`}>
            <SwitchSelector
              options={options}
              initial={0}
              textColor={theme.value.registrationUserTypeSliderTextColor}
              selectedColor={theme.value.registrationUserTypeSliderActiveColor}
              buttonColor={theme.value.registrationUserTypeButtonColor}
              borderColor={theme.value.registrationUserTypeBackgroundColor}
              hasPadding
              valuePadding={1}
              fontSize={20}
              onPress={(value) => (isProvider.value = value === "1")}
              style={[
                tailwind`w-[350px] h-[50px] font-bold`,
                { FontFace: "Robot_700Bold" },
              ]}
            />
          </View>
          <View style={tailwind`max-w-[800px] self-center`}>
            <PersonalDetailsForm
              emailAddress={emailAddress}
              emailAddressStatus={emailAddressStatus}
              password={password}
              passwordStatus={passwordStatus}
              confirmPassword={confirmPassword}
              confirmPasswordStatus={confirmPasswordStatus}
              firstName={firstName}
              firstNameStatus={firstNameStatus}
              lastName={lastName}
              lastNameStatus={lastNameStatus}
            />
          </View>
          <View style={tailwind`mx-2 self-center max-h-[400px]`}>
            <Text
              style={[
                tailwind`text-[${theme.value.textColor}] font-semibold pb-1`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              Categories
            </Text>
            <TagSelector tagType={TREE_TYPE_USER} fieldStatus={userTagStatus} />
            {userTagStatus.value ? (
              <View style={tailwind`mb-2`}>
                <Text
                  style={[
                    tailwind`text-xs ml-2 text-[${theme.value.redColor}]`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  {userTagStatus.value}
                </Text>
              </View>
            ) : null}
          </View>
          <View style={tailwind`self-center flex-row my-4`}>
            <Button
              disabled={!canSave.value}
              title="Submit"
              onPress={() =>
                submitNewUser(
                  emailAddress,
                  password,
                  firstName,
                  lastName,
                  isProvider,
                  registerResponse,
                  () => {
                    handleLogin(
                      emailAddress.value,
                      password.value,
                      loginStatus,
                      () => {}
                    );
                  }
                )
              }
            />
            <Button
              title="Cancel"
              onPress={() => navigation.navigate("LoginScreen" as never)}
            />
          </View>
          {registerResponse ? (
            <View style={tailwind`w-full`}>
              <Text
                style={tailwind`text-[${theme.value.textColor}] self-center font-semibold`}
              >
                {registerResponse}
              </Text>
            </View>
          ) : null}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const submitNewUser = async (
  emailAddress: Signal<string>,
  password: Signal<string>,
  firstName: Signal<string>,
  lastName: Signal<string>,
  isProvider: Signal<boolean>,
  registerResponse: Signal<string>,
  callback: (response: any) => void
) => {
  console.log("tags at save", userTagSelection.value);
  const data = {
    emailAddress: emailAddress.value,
    password: password.value,
    type: isProvider.value ? USER_TYPE_PROVIDER : USER_TYPE_USER,
    firstName: firstName.value,
    lastName: lastName.value,
    tags: [...userTagSelection.value.filter((tag) => !tag.deleted)],
  };
  try {
    const response = await axios.post(API_URL + "register", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    callback(response.data);
  } catch (error) {
    const { response } = error;
    registerResponse.value = response.data.message;
  }
};

submitNewUser.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};

export { RegisterUserScreen as default };
