import React from "react";
import PropTypes from "prop-types";
import { useFonts, Inter_600SemiBold } from "@expo-google-fonts/inter";
import { Text, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../signals/signals";

const Tag = ({ label, colorKey }) => {
  let tagTextColor, tagBackgroundColor;

  switch (colorKey) {
    case "PURPLE":
      tagTextColor = theme.value.tagTextColorPurple;
      tagBackgroundColor = theme.value.tagBackgroundColorPurple;
      break;
    case "ORANGE":
      tagTextColor = theme.value.tagTextColorOrange;
      tagBackgroundColor = theme.value.tagBackgroundColorOrange;
      break;
    case "WHITE":
      tagTextColor = theme.value.tagTextColorWhite;
      tagBackgroundColor = theme.value.tagBackgroundColorWhite;
      break;
    case "RED":
      tagTextColor = theme.value.tagTextColorRed;
      tagBackgroundColor = theme.value.tagBackgroundColorRed;
      break;
    case "BLACK":
      tagTextColor = theme.value.tagTextColorBlack;
      tagBackgroundColor = theme.value.tagBackgroundColorBlack;
      break;
    case "YELLOW":
      tagTextColor = theme.value.tagTextColorYellow;
      tagBackgroundColor = theme.value.tagBackgroundColorYellow;
      break;
    case "PINK":
      tagTextColor = theme.value.tagTextColorPink;
      tagBackgroundColor = theme.value.tagBackgroundColorPink;
      break;
    case "GREEN":
      tagTextColor = theme.value.tagTextColorGreen;
      tagBackgroundColor = theme.value.tagBackgroundColorGreen;
      break;
    case "BLUE":
      tagTextColor = theme.value.tagTextColorBlue;
      tagBackgroundColor = theme.value.tagBackgroundColorBlue;
      break;
    case "VIOLET":
      tagTextColor = theme.value.tagTextColorViolet;
      tagBackgroundColor = theme.value.tagBackgroundColorViolet;
      break;
    default:
      tagTextColor = theme.value.tagTextColor;
      tagBackgroundColor = theme.value.tagBackgroundColor;
      break;
  }

  let [fontsLoaded] = useFonts({ Inter_600SemiBold });
  if (!fontsLoaded) return null;

  return (
    <View
      style={tailwind`bg-[${tagBackgroundColor}] rounded-lg px-[5px] py-[3px] mr-1 opacity-85`}
    >
      <Text
        style={[
          tailwind`text-[${tagTextColor}]`,
          { fontFamily: "Inter_600SemiBold" },
        ]}
      >
        {label.toUpperCase()}
      </Text>
    </View>
  );
};

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  colorKey: PropTypes.string,
};

export default Tag;
