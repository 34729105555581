import { Text, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../../signals/signals";
import InLibraryLabel from "./InLibraryLabel";

const ReplayPrice = ({ hasPaid, didAttend, isOwner, price }) => {
  const pounds = price.toFixed(2).toString().split(".")[0];
  const pence = price.toFixed(2).toString().split(".")[1];

  return (
    <>
      {(hasPaid.value || didAttend.value) && !isOwner ? (
        <View style={tailwind`self-end h-[24px]`}>
          <InLibraryLabel />
        </View>
      ) : null}
      {price > 0 && !hasPaid.value && !didAttend.value ? (
        <View style={tailwind`flex-row self-end h-[24px]`}>
          <Text
            style={[
              tailwind`text-[${theme.value.coinsIconColor}] font-semibold rounded-lg self-start text-[20px]`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            &pound;{pounds}
          </Text>
          <Text
            style={[
              tailwind`text-[${theme.value.coinsIconColor}] font-semibold rounded-lg self-start text-[10px] pt-[2px]`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            .{pence}
          </Text>
        </View>
      ) : null}
    </>
  );
};

export { ReplayPrice as default };
