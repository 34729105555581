import React from "react";
import PropTypes from "prop-types";
import { QueryClientProvider } from "@tanstack/react-query";
import tailwind from "twrnc";
import { View, Platform, Dimensions } from "react-native";
import { DEFAULT_PROFILE_URL, queryClient } from "../../base/Constants";
import {
  Roboto_400Regular,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";
import { currentUser } from "../../../signals/signals";
import { Signal } from "@preact/signals-react";
import AttendeeList from "./AttendeeList";
import InviteList from "../invitees/InviteList";

const Attendees = ({ attendees }) => {
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;

  let fonts = useFonts({ Roboto_700Bold, Roboto_400Regular });
  if (!fonts) return;

  return (
    <QueryClientProvider client={queryClient}>
      <View
        style={[
          tailwind`w-full self-center`,
          !isMobileWebFormat && tailwind`flex-row`,
        ]}
      >
        <View style={tailwind`self-center pt-2`}>
          <AttendeeList
            userId={currentUser.value.id}
            selectedAttendees={attendees.value}
            onPress={(newAttendee) => {
              let newAttendees = attendees.value;
              if (
                newAttendees.filter(
                  (attendee) => attendee.id === newAttendee.id
                ).length
              ) {
                newAttendees = attendees.value.filter(
                  (attendee) => attendee.id !== newAttendee.id
                );
              } else {
                newAttendees.push({
                  id: newAttendee.id,
                  profileUrl: newAttendee.profileUrl || DEFAULT_PROFILE_URL,
                  name: newAttendee.firstName + " " + newAttendee.lastName,
                });
              }
              newAttendees = [...new Set(newAttendees)];
              attendees.value = newAttendees;
            }}
          />
        </View>
        <View style={tailwind`self-center pt-2`}>
          <InviteList attendees={attendees} />
        </View>
      </View>
    </QueryClientProvider>
  );
};

Attendees.propTypes = {
  attendees: PropTypes.instanceOf(Signal).isRequired,
};

export default Attendees;
