import { useFonts, Roboto_400Regular } from "@expo-google-fonts/roboto";
import { View, Text } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../../signals/signals";
import { useSignal } from "@preact/signals-react";

const NoLiveContentThumbnail = () => {
  const thumbnailDimensions = useSignal({
    width: 365,
    height: 300,
  });

  let [fontsLoaded] = useFonts({ Roboto_400Regular });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <View
      style={tailwind`rounded-lg w-[${thumbnailDimensions.value.width}px] bg-white w-[${thumbnailDimensions.value.width}px] h-[${thumbnailDimensions.value.height}px]`}
    >
      <View style={tailwind`mt-32 self-center`}>
        <Text
          numberOfLines={1}
          style={[
            tailwind`text-[${theme.value.redColor}] text-[18px] self-center`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          Nothing scheduled this week...
        </Text>
        <View style={tailwind`mb-4 mt-2 min-h-[12px] self-center`}>
          <Text
            style={[
              tailwind`text-[16px] self-start text-[${theme.value.redColor}]`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            but come back soon!
          </Text>
        </View>
      </View>
    </View>
  );
};

export default NoLiveContentThumbnail;
