import { MutableRefObject } from "react";

export const drawPlanTimer = (
  ctx: CanvasRenderingContext2D,
  currentPlanStep: MutableRefObject<any>,
  overlayPlanRef: MutableRefObject<any>,
  planStep: MutableRefObject<any>,
  planStepTime: MutableRefObject<any>
) => {
  const top = 46;
  const left = 744;
  const radius = 28;
  const backgroundRadius = 30;

  currentPlanStep.current = overlayPlanRef.current.filter((planStepItem) => {
    return planStepItem.key === planStep.current;
  })[0];

  ctx.fillStyle = "#eeeeee";
  ctx.beginPath();
  ctx.arc(left, top, backgroundRadius, 0, 2 * Math.PI, false);
  ctx.fill();

  ctx.strokeStyle =
    currentPlanStep.current.setType === 1 ? "#00dd00" : "#dd0000";
  ctx.lineWidth = 4;
  ctx.beginPath();
  ctx.arc(
    left,
    top,
    radius,
    0,
    (2 / parseInt(currentPlanStep.current.duration)) *
      (parseInt(currentPlanStep.current.duration) -
        parseInt(planStepTime.current)) *
      Math.PI,
    false
  );
  ctx.stroke();

  ctx.fillStyle = currentPlanStep.current.setType === 1 ? "#00dd00" : "#dd0000";
  let planText = (
    "0" +
    (
      parseInt(currentPlanStep.current.duration) -
      parseInt(planStepTime.current)
    ).toString()
  ).slice(-2);
  ctx.font = "36px roboto";
  ctx.fillText(planText, left - 19, top + 12);
};
