import axios from "axios";
import { API_URL, queryClient } from "../base/Constants";
import { currentUser, focusedScreen } from "../../signals/signals";
import RevolutCheckout from "@revolut/checkout";

export async function PrepareReplayOrder(currentUser, videoId, callback) {
  axios
    .post(
      API_URL + "order/replay/prepare",
      {
        videoId: videoId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: currentUser.value.token,
        },
      }
    )
    .then((revolutResponse) => {
      callback(revolutResponse.data);
    })
    .catch((error) => {
      callback(error);
      console.log(error);
    });
}

export async function PrepareOrder(currentUser, bookingId, callback) {
  axios
    .post(
      API_URL + "order/prepare",
      {
        bookingId: bookingId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: currentUser.value.token,
        },
      }
    )
    .then((revolutResponse) => {
      callback(revolutResponse.data);
    })
    .catch((error) => {
      callback(error);
      console.log(error);
    });
}

export async function checkout(order, setOrder, navigation) {
  const emailAddress = currentUser.value.emailAddress;
  const token = currentUser.value.token;

  const publicOrderId = order.public_id;
  const uniqueOrderId = order.id;
  const paymentId = order.paymentId;
  const userId = currentUser.value.id;
  const name = currentUser.value.firstName + " " + currentUser.value.lastName;
  const countryCode = "GB";

  let city;
  let postcode;
  let streetLine1;
  let streetLine2;

  await axios
    .get(API_URL + "users/" + userId, {
      headers: {
        "Content-Type": "application/json",
        Authorization: currentUser.value.token,
      },
    })
    .then((response) => {
      const fetchedUser = response.data;
      city = fetchedUser.city;
      postcode = fetchedUser.postalCode;
      streetLine1 = fetchedUser.addressLine1;
      streetLine2 = fetchedUser.addressLine2;
    })
    .catch((err) => {
      console.log("An error occurered fetching user for checkout process", err);
      setOrder(null);
    });

  await RevolutCheckout(publicOrderId, "sandbox").then((RC) => {
    RC.payWithPopup({
      name: name,
      email: emailAddress,
      billingAddress: {
        countryCode: countryCode,
        city: city,
        postcode: postcode,
        streetLine1: streetLine1,
        streetLine2: streetLine2,
      },
      onSuccess() {
        setTimeout(
          () => finishOrder(order, setOrder, token, paymentId, navigation),
          500
        );
      },
      onError(err) {
        console.log("Revolut produced an error", uniqueOrderId, paymentId, err);
        logRevolutPopupError(uniqueOrderId, token, paymentId, err, setOrder);
        setOrder(null);
      },
      onCancel() {
        setOrder(null);
      },
    });
  });
}

export async function finishOrder(
  order,
  setOrder,
  token,
  paymentId,
  navigation
) {
  axios
    .post(
      API_URL + "order/complete",
      {
        id: order.id,
        paymentId: paymentId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      }
    )
    .then((response) => {
      const { raw } = response.data;
      switch (raw.state) {
        case "COMPLETED":
        case "AUTHORISED":
        case "PROCESSED":
        case "PROCESSING":
        default:
          queryClient.invalidateQueries("homepagesections");
          focusedScreen.value = "HomeScreen";
          navigation.navigate("HomeScreen");
          break;
      }
    })
    .catch((error) => {
      alert(
        "There was a problem with completing your order.  Please try again."
      );
      console.log(error);
      setOrder(null);
    });
}

export async function checkoutStalledOrder(
  publicOrderId,
  uniqueOrderId,
  paymentId,
  navigation,
  currentUser
) {
  const emailAddress = currentUser.value.emailAddress;
  const token = currentUser.value.token;

  const userId = currentUser.value.id;
  const name = currentUser.value.firstName + " " + currentUser.value.lastName;
  const countryCode = "GB";

  let city;
  let postcode;
  let streetLine1;
  let streetLine2;

  await axios
    .get(API_URL + "users/" + userId, {
      headers: {
        "Content-Type": "application/json",
        Authorization: currentUser.value.token,
      },
    })
    .then(async (response) => {
      const fetchedUser = response.data;
      city = fetchedUser.city;
      postcode = fetchedUser.postalCode;
      streetLine1 = fetchedUser.addressLine1;
      streetLine2 = fetchedUser.addressLine2;

      await RevolutCheckout(publicOrderId, "sandbox").then((RC) => {
        RC.payWithPopup({
          name: name,
          email: emailAddress,
          billingAddress: {
            countryCode: countryCode,
            city: city,
            postcode: postcode,
            streetLine1: streetLine1,
            streetLine2: streetLine2,
          },
          onSuccess() {
            setTimeout(
              () =>
                finishStalledOrder(uniqueOrderId, token, paymentId, navigation),
              500
            );
          },
          onError(err) {
            console.log(
              "Revolut produced an error",
              uniqueOrderId,
              paymentId,
              err
            );
            // logRevolutPopupError(uniqueOrderId, token, paymentId, err, setOrder);
          },
          onCancel() { },
        });
      });
    })
    .catch((err) => {
      console.log("An error occurered fetching user for checkout process", err);
    });
}

async function finishStalledOrder(
  revolutOrderId,
  token,
  paymentId,
  navigation
) {
  axios
    .post(
      API_URL + "order/complete",
      {
        id: revolutOrderId,
        paymentId: paymentId,
      },
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((response) => {
      const { raw } = response.data;
      switch (raw.state) {
        case "COMPLETED":
        case "AUTHORISED":
        case "PROCESSED":
        case "PROCESSING":
        default:
          // focusedScreen.value = "HomeScreen";
          queryClient.invalidateQueries("homepagesections");
          navigation.navigate("HomeScreen");
          break;
      }
    })
    .catch((error) => {
      alert(
        "There was a problem with completing your order.  Please try again."
      );
      console.log(error);
    });
}

export async function logRevolutPopupError(
  uniqueOrderId,
  token,
  paymentId,
  err,
  setOrder
) {
  if (!err) return;
  axios
    .post(
      API_URL + "order/popuperror",
      {
        id: uniqueOrderId,
        paymentId: paymentId,
        error: JSON.stringify(err),
        errorMessage: err.message,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      }
    )
    .then(() => setOrder(null));
}
