import axios from "axios";
import { API_URL } from "../../base/Constants";
import { useQuery } from "@tanstack/react-query";
import { currentUser } from "../../../signals/signals";

const useHomePageTrainersSection = (sectionId: number, userId: number) => {
  const { isLoading, error, data } = useQuery(
    ["trainers" + sectionId],
    async () => {
      const response = await axios
        .get(API_URL + "homepage/section/" + sectionId + "/" + userId, {
          headers: { authorization: currentUser.value.token },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err));
      return response;
    },
    {
      cacheTime: 1000 * 60 * 60,
    }
  );

  return { isLoading, error, data };
};

export { useHomePageTrainersSection as default };
