import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Platform, Pressable, View } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { Signal } from "@preact/signals-react";
import { currentUser, theme } from "../../../signals/signals";

const RTCStreamVideoControls = ({
  videoRef,
  userId,
  isSpeakerMuted,
  isMicMuted,
  isVideoShown,
}): JSX.Element => {
  const iconTintColor =
    Platform.OS === "web"
      ? "#FFFFFF"
      : theme.value.mobileChatVideoControlIconColor;

  useEffect(() => {}, [isMicMuted.value]);

  return (
    <View
      style={tailwind`flex-row w-full p-1 bg-black bg-opacity-50 rounded-tr-lg`}
    >
      {currentUser.value.id === userId ? (
        <Pressable
          style={tailwind`p-2 w-[36px] h-[36px] ml-1`}
          onPress={() => (isMicMuted.value = !isMicMuted.value)}
        >
          {isMicMuted.value ? (
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../../assets/microphone-icon-off.png")}
              tintColor={theme.value.redColor}
            />
          ) : (
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../../assets/microphone-icon.png")}
              tintColor={iconTintColor}
            />
          )}
        </Pressable>
      ) : (
        <Pressable
          style={tailwind`p-2 w-[36px] h-[36px] ml-1`}
          onPress={() => (isSpeakerMuted.value = !isSpeakerMuted.value)}
        >
          {isSpeakerMuted.value ? (
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../../assets/volume-icon-off.png")}
              tintColor={theme.value.redColor}
            />
          ) : (
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../../assets/volume-icon.png")}
              tintColor={iconTintColor}
            />
          )}
        </Pressable>
      )}
      {currentUser.value.id === userId ? (
        <Pressable
          style={tailwind`p-2 w-[36px] h-[36px] ml-1`}
          onPress={() => (isVideoShown.value = !isVideoShown.value)}
        >
          {!isVideoShown.value ? (
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../../assets/video-icon-off.png")}
              tintColor={theme.value.redColor}
            />
          ) : (
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../../assets/video-icon.png")}
              tintColor={iconTintColor}
            />
          )}
        </Pressable>
      ) : null}
      <Pressable
        style={tailwind`p-2 w-[36px] h-[36px] ml-1`}
        onPress={() => videoRef.current.requestFullscreen()}
      >
        <Image
          resizeMode="contain"
          style={tailwind`h-[18px] w-[18px]`}
          source={require("../../../assets/fullscreen-icon.png")}
          tintColor={iconTintColor}
        />
      </Pressable>
    </View>
  );
};

RTCStreamVideoControls.propTypes = {
  videoRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  userId: PropTypes.number.isRequired,
  isSpeakerMuted: PropTypes.instanceOf(Signal).isRequired,
  isMicMuted: PropTypes.instanceOf(Signal).isRequired,
  isVideoShown: PropTypes.instanceOf(Signal).isRequired,
};

export { RTCStreamVideoControls as default };
