import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { StyledInput } from "../../base/StyledInput";
import { Dimensions, Platform } from "react-native";
import { Signal } from "@preact/signals-react";
import { analyzeAddressLine } from "../../helpers/validation";

const AddressLine = ({ label, addressLine, addressLineStatus, mandatory }) => {
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;

  useEffect(() => {
    if (addressLine.value?.length) {
      analyzeAddressLine(addressLine, addressLineStatus, mandatory);
    }
  }, []);

  return (
    <>
      <StyledInput
        label={label}
        onChangeText={(text) => {
          addressLine.value = text;
          analyzeAddressLine(addressLine, addressLineStatus, mandatory);
        }}
        onBlur={() =>
          analyzeAddressLine(addressLine, addressLineStatus, mandatory)
        }
        value={addressLine.value}
        width={isMobileWebFormat ? "300px" : "350px"}
        autoCorrect={false}
        fieldStatus={addressLineStatus}
      />
    </>
  );
};

AddressLine.propTypes = {
  label: PropTypes.string.isRequired,
  addressLine: PropTypes.instanceOf(Signal).isRequired,
  addressLineStatus: PropTypes.instanceOf(Signal).isRequired,
  mandatory: PropTypes.bool,
};

export { AddressLine as default };
