import { Text, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../signals/signals";

const NoCameraPermission = () => {
  return (
    <View style={tailwind`rounded-lg w-[350px] self-center bg-white p-2 m-2`}>
      <Text
        style={[
          tailwind`self-center ml-2 pt-1 font-semibold text-[${theme.value.textColor}]`,
          { fontFamily: "Roboto_400Regular" },
        ]}
      >
        We don&apos;t have permission from your device
      </Text>
      <Text
        style={[
          tailwind`self-center ml-2 pt-1 font-semibold text-[${theme.value.textColor}]`,
          { fontFamily: "Roboto_400Regular" },
        ]}
      >
        to access your camera.
      </Text>
      <Text
        style={[
          tailwind`self-center ml-2 pt-4 font-semibold text-[${theme.value.textColor}]`,
          { fontFamily: "Roboto_400Regular" },
        ]}
      >
        Check your settings and try again.
      </Text>
    </View>
  );
};

export { NoCameraPermission as default };
