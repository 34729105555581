import { Image } from "@rneui/themed";
import { ActivityIndicator, Platform, Text, View } from "react-native";
import tailwind from "twrnc";
import { DEFAULT_PROFILE_URL } from "../../base/Constants";
import { theme } from "../../../signals/signals";

const InviteItem = ({ attendee }) => {
  return (
    <View style={tailwind`flex-row mx-1 rounded-lg p-1`}>
      <View style={tailwind`flex-row`}>
        <Image
          style={tailwind`w-[${Platform.OS === "web" ? "40px" : "35px"}] h-[${
            Platform.OS === "web" ? "40px" : "35px"
          }] rounded-full self-center`}
          source={{ uri: attendee.profileUrl || DEFAULT_PROFILE_URL }}
          PlaceholderContent={<ActivityIndicator />}
        />
        <Text
          style={[
            tailwind`flex-wrap text-[${theme.value.textColor}] text-[${
              Platform.OS === "web" ? "14px" : "10px"
            }] font-semibold self-center pl-2`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          {attendee.name}
        </Text>
      </View>
    </View>
  );
};

export { InviteItem as default };
