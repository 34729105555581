import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Text,
  View,
  ActivityIndicator,
  Pressable,
  ScrollView,
} from "react-native";
import { useQuery, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import tailwind from "twrnc";
import {
  API_URL,
  DEFAULT_PROFILE_URL,
  NOTIFICATION_TYPE_CONNECTION,
  NOTIFICATION_TYPE_EVENT,
  queryClient,
} from "../base/Constants";
import { Image } from "@rneui/themed";
import ViewEvent from "../calendar-screen/ViewEvent";
import { useNavigation } from "@react-navigation/native";
import { BottomSheetContext } from "../base/ApplicationContext";
import {
  Roboto_400Regular,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";
import dayjs from "dayjs";
import Button from "../base/Button";
import { currentUser, focusedScreen, theme } from "../../signals/signals";
import ApiError from "../error/ApiError";

const NotificationsPanel = () => {
  let fonts = useFonts({ Roboto_400Regular, Roboto_700Bold });
  if (!fonts) return;

  return (
    <QueryClientProvider client={queryClient}>
      <View>
        <Notifications />
      </View>
    </QueryClientProvider>
  );
};

const Notifications = () => {
  const userId = currentUser.value.id;

  const { isLoading, error, data } = useQuery(["notifications" + userId], () =>
    axios
      .get(API_URL + "notification/" + userId, {
        headers: { authorization: currentUser.value.token },
      })
      .then((res) => res.data)
      .catch((err) => console.log(err))
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const clearAllNotifications = async (callback) => {
    await axios
      .patch(
        API_URL + "notifications/clear",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.value.token,
          },
        }
      )
      .then(() => callback());
  };

  const filteredData = data.filter((notification) => !notification.isRead);

  return (
    <View style={tailwind`p-2`}>
      {filteredData.length ? (
        <View style={tailwind`self-end pr-2 mb-4`}>
          <Button
            title="Clear All"
            onPress={() =>
              clearAllNotifications(() => {
                queryClient.invalidateQueries(["notifications" + userId]);
              })
            }
          />
        </View>
      ) : null}
      {!filteredData.length ? (
        <View
          style={tailwind`my-1 mx-2 rounded-sm bg-[${theme.value.notificationItemBackgroundColor}] p-2`}
        >
          <Text
            style={[
              tailwind`font-semibold text-[${theme.value.textColor}] self-center mb-2 pt-2`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            No Notifications
          </Text>
        </View>
      ) : null}
      <ScrollView
        style={tailwind`h-[100v]`}
      >
        <View>
          {filteredData?.map((notification) => {
            console.log(notification);
            return notification.isRead !== true ? (
              <View
                key={notification.id}
                style={tailwind`my-1 mx-2 rounded-lg bg-[${theme.value.notificationItemBackgroundColor}] p-2`}
              >
                <View>
                  {notification.type.id === NOTIFICATION_TYPE_EVENT &&
                    notification.userSchedule ? (
                    <EventNotification notification={notification} />
                  ) : notification.type.id === NOTIFICATION_TYPE_CONNECTION &&
                    notification.sourceUser ? (
                    <ConnectionNotification notification={notification} />
                  ) : <GenericNotification notification={notification} />}
                </View>
              </View>
            ) : null;
          })}
        </View>
      </ScrollView>
    </View>
  );
};

const SourceUserPanel = ({ sourceUser }) => {
  return (
    <View style={tailwind`max-w-[120px]`}>
      <View>
        <View style={tailwind`self-center`}>
          <Image
            style={tailwind`w-[40px] h-[40px] rounded-full m-1`}
            source={{
              uri: sourceUser?.profileUrl || DEFAULT_PROFILE_URL,
            }}
            PlaceholderContent={<ActivityIndicator />}
          />
        </View>
      </View>
      <View>
        <Text style={[tailwind`self-center`, { fontFamily: "Roboto_700Bold" }]}>
          {sourceUser?.firstName + " " + sourceUser?.lastName}
        </Text>
      </View>
    </View>
  );
};

SourceUserPanel.propTypes = {
  sourceUser: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profileUrl: PropTypes.string,
  }),
};

const NotificationMessagePanel = ({ message }) => {
  return (
    <View>
      <Text
        style={[
          tailwind`font-semibold text-[${theme.value.textColor}] mb-2`,
          { fontFamily: "Roboto_400Regular" },
        ]}
      >
        {message}
      </Text>
    </View>
  )
};

NotificationMessagePanel.propTypes = {
  message: PropTypes.string.isRequired,
};

const GenericNotification = ({ notification }) => {
  return (
    <>
      <NotificationMessagePanel message={notification.message} />
      <SourceUserPanel sourceUser={notification.sourceUser} />
    </>
  );
}

GenericNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
    message: PropTypes.string,
    userSchedule: PropTypes.shape({
      id: PropTypes.number,
      shortDescription: PropTypes.string,
      startDate: PropTypes.string,
      duration: PropTypes.number,
      user: PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profileUrl: PropTypes.string,
      }),
    }),
    sourceUser: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      profileUrl: PropTypes.string,
    }),
  }),
};

const EventNotification = ({ notification }) => {
  const time =
    notification.userSchedule && notification.userSchedule.startDate
      ? dayjs(notification.userSchedule.startDate).format("HH:mm")
      : "";
  return (
    <View style={tailwind`w-full`}>
      <Pressable
        onPress={() => {
          handleUpdateNotification(notification.id, () =>
            handleRedirect(notification)
          );
        }}
      >
        <NotificationMessagePanel message={notification.message} />
        <View style={tailwind`flex-row`}>
          <View style={tailwind`grow`}>
            <SourceUserPanel sourceUser={notification.sourceUser} />
          </View>
          <View style={tailwind`w-1/2 self-end pr-3`}>
            <Text
              style={[
                tailwind`font-semibold`,
                { fontFamily: "Roboto_700Bold" },
              ]}
            >
              {notification.userSchedule.shortDescription}
            </Text>
            <View style={tailwind`flex-row`}>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {dayjs(
                  notification.userSchedule.startDate
                ).format("ddd MMM DD")}
              </Text>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] mx-2`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                at
              </Text>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {time}
              </Text>
            </View>
            <View style={tailwind`flex-row`}>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] text-[12px]`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {notification.userSchedule.duration} MIN
              </Text>
            </View>
          </View>
        </View>
      </Pressable>
    </View>
  );
};

EventNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
    message: PropTypes.string,
    userSchedule: PropTypes.shape({
      id: PropTypes.number,
      shortDescription: PropTypes.string,
      startDate: PropTypes.string,
      duration: PropTypes.number,
      user: PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profileUrl: PropTypes.string,
      }),
    }),
    sourceUser: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      profileUrl: PropTypes.string,
    }),
  }),
};

const ConnectionNotification = ({ notification }) => {
  return (
    <Pressable
      onPress={() => {
        handleUpdateNotification(notification.id, () =>
          handleRedirect(notification)
        );
      }}
    >
      <NotificationMessagePanel message={notification.message} />
      <SourceUserPanel sourceUser={notification.sourceUser} />
      <View>
        <Text
          style={[
            tailwind`font-semibold`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          {notification.sourceUser.firstName +
            " " +
            notification.sourceUser.lastName}
        </Text>
        <View style={tailwind`m-1`}>
          <Image
            style={tailwind`w-[40px] h-[40px] rounded-full m-1`}
            source={{
              uri:
                notification.sourceUser.profileUrl ||
                DEFAULT_PROFILE_URL,
            }}
            PlaceholderContent={<ActivityIndicator />}
          />
        </View>
      </View>
    </Pressable>
  );
}

ConnectionNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
    message: PropTypes.string,
    userSchedule: PropTypes.shape({
      id: PropTypes.number,
      shortDescription: PropTypes.string,
      startDate: PropTypes.string,
      duration: PropTypes.number,
      user: PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profileUrl: PropTypes.string,
      }),
    }),
    sourceUser: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      profileUrl: PropTypes.string,
    }),
  }),
};

const handleUpdateNotification = (notificationId, callback) => {
  axios
    .patch(
      API_URL + "notification/" + notificationId,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          authorization: currentUser.value.token,
        },
      }
    )
    .then(() => callback());
};

const handleRedirect = (notification) => {
  const navigation = useNavigation();
  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);

  const { type, userSchedule } = notification;
  const notificationType = type.id;
  switch (notificationType) {
    case NOTIFICATION_TYPE_EVENT:
      setBottomSheetContent(<ViewEvent event={userSchedule} />);
      setBottomSheetVisible(true);
      break;
    case NOTIFICATION_TYPE_CONNECTION:
      focusedScreen.value = "ConnectionsScreen";
      navigation.navigate("ConnectionsScreen");
      break;
  }
};

Notifications.propTypes = {};

export default NotificationsPanel;
