import { Text, View } from "react-native";
import { Signal } from "@preact/signals-react";
import tailwind from "twrnc";
import ConfirmedBookingsCount from "./ConfirmedBookingsCount";
import { theme } from "../../../signals/signals";
import BookedLabel from "./BookedLabel";

const LivePrice = ({
  hasBookings,
  isHost,
  numberOfBookings,
  price,
  isBooked,
  isStalled,
}: {
  hasBookings: Signal<boolean>;
  isHost: boolean;
  numberOfBookings: Signal<number>;
  price: number;
  isBooked: Signal<boolean>;
  isStalled: Signal<boolean>;
}) => {
  const pounds = price.toFixed(2).toString().split(".")[0];
  const pence = price.toFixed(2).toString().split(".")[1];

  if (hasBookings.value && isHost && numberOfBookings.value > 0) {
    return <ConfirmedBookingsCount numberOfBookings={numberOfBookings} />;
  }

  if (
    (!hasBookings.value && isHost) ||
    (!isHost && (!isBooked.value || isStalled.value))
  ) {
    return (
      <View style={tailwind`flex-row self-end h-[24px]`}>
        <Text
          style={[
            tailwind`text-[${theme.value.coinsIconColor}] font-semibold rounded-lg self-start text-[20px]`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          &pound;{pounds}
        </Text>
        <Text
          style={[
            tailwind`text-[${theme.value.coinsIconColor}] font-semibold rounded-lg self-start text-[10px] pt-[2px]`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          .{pence}
        </Text>
      </View>
    );
  }

  if (!isHost && isBooked.value && !isStalled.value) {
    return <BookedLabel />;
  }
};

export { LivePrice as default };
