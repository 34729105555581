import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { checkout } from "./RevolutPopupHandler";

const CheckoutWebView = ({ order, setOrder }) => {
  const navigation = useNavigation();
  useEffect(() => {
    checkout(order, setOrder, navigation);
  }, []);

  return (
    <>
      <Image
        resizeMode="contain"
        style={tailwind`h-15 w-20`}
        source={require("../../assets/revolut-logo.png")}
      />
    </>
  );
};

CheckoutWebView.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    public_id: PropTypes.string.isRequired,
    paymentId: PropTypes.number.isRequired,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
};

export { CheckoutWebView };
