import { Socket, io } from "socket.io-client";
import { PeerPayload } from "../types/PeerPayload";
import { Signal } from "@preact/signals-react";
import { SOCKET_URL } from "../../base/Constants";
import { IceCandidate } from "../types/IceCandidate";
import { answerPeer, setRemoteDescription } from "./webRTCHandler";
import { RTCPeerConnection } from "react-native-webrtc-web-shim";

export const attachRTCListeners = async (
  socket: Socket,
  userId: number,
  roomId: string,
  peerConnections: Signal,
  localStream: MediaStream,
  remoteStreams: Signal
): Promise<void> => {
  //Received when we connect to a room with an existing member
  socket.on("offer", async (incoming: PeerPayload) => {
    if (incoming.fromUserId === userId) return;
    if (incoming.fromUserId === userId) return;

    let existingPeer: RTCPeerConnection = findExistingPeer(
      peerConnections.value,
      incoming.fromUserId
    );

    if (!existingPeer) {
      existingPeer = await answerPeer(
        incoming.fromUserId,
        incoming.toUserId,
        roomId,
        remoteStreams,
        socket
      );
      addPeerToPeerConnections(
        peerConnections,
        incoming.fromUserId,
        existingPeer
      );
    } else {
      //This shouldn't happen
      console.log("Error: Offer from existing peer", incoming.fromUserId);
    }

    await setRemoteDescription(
      existingPeer,
      incoming.sdp,
      (localDescription) => {
        const payload: PeerPayload = {
          fromUserId: incoming.toUserId,
          toUserId: incoming.fromUserId,
          roomId: roomId,
          sdp: localDescription,
        };
        socket.emit("answer", payload);
      }
    );
  });

  //Received when a member to which we have sent an offer responds
  socket.on("answer", async (message: PeerPayload) => {
    if (message.fromUserId === userId) {
      return;
    }

    const description = new RTCSessionDescription(message.sdp);
    const existingPeer: RTCPeerConnection = findExistingPeer(
      peerConnections.value,
      message.fromUserId
    );
    if (existingPeer) {
      existingPeer
        .setRemoteDescription(description)
        .catch((e) => console.log("Error handle answer", e));
    } else {
      console.log("Error:no existing peer to add remote description");
    }
  });

  socket.on("ice-candidate", async (incoming: IceCandidate) => {
    const candidate = new RTCIceCandidate(incoming.candidate);

    if (incoming.fromUserId === userId) return;
    if (!incoming.candidate)
      console.log("Error: SocketHandler: Warning candidate empty");

    let existingPeer = findExistingPeer(
      peerConnections.value,
      incoming.fromUserId
    );

    if (existingPeer) {
      if (candidate) {
        existingPeer
          .addIceCandidate(candidate)
          .catch((e) =>
            console.log("ice-candidate error " + e.code + " " + e.message)
          );
      } else {
        console.log("Error: no connection or candidate to add");
      }
    } else {
      console.log("Error: Received ice-candidate for non-existing peer");
    }
  });
  return Promise.resolve();
};

const findExistingPeer = (
  peerConnections: RTCPeerConnection[],
  userId: number
): RTCPeerConnection | null => {
  return peerConnections.find(
    (peerConnection) => peerConnection.userId === userId
  );
};

export const addPeerToPeerConnections = (
  peerConnections: Signal,
  userId: number,
  rtcPeerConnection: RTCPeerConnection
) => {
  const existingPeer = findExistingPeer(peerConnections.value, userId);
  if (existingPeer) return existingPeer;

  peerConnections.value = [...peerConnections.value, rtcPeerConnection];
  return rtcPeerConnection;
};

export const newConnectChatSocket = async (): Promise<Socket> => {
  return new Promise((resolve, reject) => {
    try {
      const socket = io(SOCKET_URL);
      socket.on("connect", () => {
        resolve(socket);
      });
    } catch (err) {
      reject(err);
    }
  });
};
