import React from "react";
import { ActivityIndicator, Pressable, View } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { Signal } from "@preact/signals-react";
import { currentUser } from "../../signals/signals";

const ThumbnailClear = ({ thumbnailPath }: { thumbnailPath: Signal }) => {
  const clearThumbnail = async () => {
    thumbnailPath.value = currentUser.value.profileUrl;
  };

  return (
    <Pressable
      style={tailwind`w-10 h-10 py-[6px] mx-1 mr-4 mb-2`}
      onPress={clearThumbnail}
    >
      <View style={tailwind`self-center`}>
        <Image
          style={tailwind`w-[32px] h-[32px]`}
          source={require("../../assets/delete-icon.png")}
          PlaceholderContent={<ActivityIndicator />}
        />
      </View>
    </Pressable>
  );
};

export { ThumbnailClear as default };
