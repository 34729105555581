import React, { useEffect } from "react";
import { Dimensions, Platform, View } from "react-native";
import * as ScreenOrientation from "expo-screen-orientation";
import { Signal, useSignal } from "@preact/signals-react";
import tailwind from "twrnc";
import RTCStreamWrapper from "./RTCStreamWrapper";
import { providerStream } from "../../../signals/signals";

const ProviderStream = () => {
  const screenWidth: number = Dimensions.get("screen").width;
  const windowOrientation: Signal<string> = useSignal("portrait");
  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        windowOrientation.value = getOrientation(orientationInfo.orientation);
      }, 300);
    });
  }, []);

  const getOrientation = (orientation: number): string => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };

  const isMobileWebFormat: boolean =
    Platform.OS === "web" &&
    ((windowOrientation.value === "portrait" &&
      Dimensions.get("screen").width < 768) ||
      (windowOrientation.value === "landscape" &&
        Dimensions.get("screen").height < 768));

  if (!providerStream.value) return null;

  return (
    <View
      style={tailwind`max-w-[100%] max-w-[${
        isMobileWebFormat && windowOrientation.value === "landscape"
          ? screenWidth / 2
          : screenWidth
      }px]`}
    >
      {providerStream.value && (
        <RTCStreamWrapper
          remoteStream={providerStream.value}
          isHost={true}
          isClient={false}
        />
      )}
    </View>
  );
};

export { ProviderStream as default };
