import React from "react";
import PropTypes from "prop-types";
import ReplayThumbnail from "./replay/ReplayThumbnail";
import OnDemandThumbnail from "./on-demand/OnDemandThumbnail";

const VideoThumbnail = ({ video, onPressOverride }) => {
  const userScheduleId = video.video.userSchedule?.id;

  return (
    userScheduleId
      ? <ReplayThumbnail video={video} onPressOverride={onPressOverride} />
      : <OnDemandThumbnail video={video} onPressOverride={onPressOverride} />
  );
};

VideoThumbnail.propTypes = {
  video: PropTypes.object.isRequired,
  onPressOverride: PropTypes.func,
};

export default VideoThumbnail;
