import React from "react";
import { Platform, Text, View, Modal } from "react-native";
import tailwind from "twrnc";
import Button from "../base/Button";
import { theme, streamCompleted, streamClosing } from "../../signals/signals";
import { useNavigation } from "@react-navigation/native";

const StreamCompletedModal = () => {
  const modalWidth = Platform.OS === "web" ? "50%" : "100%";
  const navigation = useNavigation();

  return (
    <View style={tailwind`justify-center`}>
      <Modal
        visible={streamCompleted.value}
        animationType="slide"
        transparent={true}
      >
        <View
          style={tailwind`rounded-lg h-auto p-6 self-center bg-[${theme.value.modalBackgroundColor}] border-[0.5px] border-[${theme.value.textColor}] flex-col w-[${modalWidth}] max-h-[400px] absolute top-40`}
        >
          <View style={tailwind`w-full`}>
            <Text
              style={tailwind`text-[${theme.value.textColor}] font-bold text-sm mt-3 self-center`}
            >
              Congratulations, your live broadcast is complete!
            </Text>
            <Text
              style={tailwind`text-[${theme.value.textColor}] font-bold text-sm mt-3 self-center`}
            >
              If you wish to sell the replay of your broadcast
            </Text>
            <Text
              style={tailwind`text-[${theme.value.textColor}] font-bold text-sm mt-3 self-center`}
            >
              then enable it and set the price in the Library
            </Text>
          </View>
          <View style={tailwind`w-full self-center mt-4`}>
            <View style={tailwind`flex-row self-center`}>
              <View>
                <Button
                  title="Home"
                  onPress={() => {
                    streamCompleted.value = false;
                    streamClosing.value = false;
                    navigation.navigate({ name: "HomeScreen" } as never);
                  }}
                />
              </View>
              <View>
                <Button
                  title="Library"
                  onPress={() => {
                    streamCompleted.value = false;
                    streamClosing.value = false;
                    navigation.navigate({ name: "LibraryScreen" } as never);
                  }}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export { StreamCompletedModal as default };
