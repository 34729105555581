import React from "react";
import PropTypes from "prop-types";
import { Text, View, ActivityIndicator } from "react-native";
import { useQuery, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";

import tailwind from "twrnc";
import ConnectionThumbnail from "./ConnectionThumbnail";
import { API_URL, queryClient } from "../base/Constants";
import { Roboto_700Bold, useFonts } from "@expo-google-fonts/roboto";
import { currentUser } from "../../signals/signals";
import { ConnectionItem } from "./types/ConnectionItem";
import ApiError from "../error/ApiError";

const ConnectionsPanel = () => {
  const handleStatusChange = () => {
    queryClient.setQueryData(["connections", currentUser.value.id], () => {
      queryClient.invalidateQueries(["connections" + currentUser.value.id]);
    });
  };

  let fonts = useFonts({ Roboto_700Bold });

  if (!fonts) return;

  return (
    <QueryClientProvider client={queryClient}>
      <View style={tailwind``}>
        <Connections handleStatusChange={handleStatusChange} />
      </View>
    </QueryClientProvider>
  );
};

// function CurrentFollowing() {}

// function CurrentFollowers() {}

// function SuggestedConnections() {}

const Connections = ({ handleStatusChange }) => {
  const userId = currentUser.value.id;
  const {
    isLoading,
    error,
    data,
  }: { isLoading: boolean; error: any; data: ConnectionItem[] } = useQuery(
    ["connections" + userId],
    () =>
      axios
        .get(API_URL + "userconnection/connections/" + userId, {
          headers: { authorization: currentUser.value.token },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err))
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  return (
    <View style={tailwind`flex-row flex-wrap`}>
      {data.map((item) => (
        <ConnectionThumbnail
          key={item.id}
          connectionItem={item}
          handleStatusChange={handleStatusChange}
        />
      ))}
    </View>
  );
};

Connections.propTypes = {
  handleStatusChange: PropTypes.func.isRequired,
};

export default ConnectionsPanel;
