import React, { useState } from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import tailwind from "twrnc";
import YoutubePlayer from "../video-players/vod/YouTubePlayer";
import MiniTrainerProfilePanel from "../profile-screen/MiniTrainerProfilePanel";
import { TrainerVideos } from "../profile-screen/TrainerVideos";
import { getYoutubeMeta } from "react-native-youtube-iframe";
import YoutubeDetails from "./YoutubeDetails";
import { WebScrollContainer } from "../home-screen/WebScrollContainer";
import { theme } from "../../signals/signals";

const YoutubeLayout = ({ video }) => {
  const trainerId = video.video.userVideos[0].userId.id;
  const firstName = video.video.userVideos[0].userId.firstName;

  const [title, setTitle] = useState();

  getYoutubeMeta(video.video.youtubeId).then((meta) => {
    setTitle(meta.title);
  });

  return (
    <View style={tailwind`flex-col bg-[${theme.value.backgroundColor}]`}>
      <View style={tailwind`w-full grow`}>
        <View style={tailwind`flex-row self-center h-full w-full mr-4`}>
          <View style={tailwind`w-2/3`}>
            <YoutubePlayer video={video} />
          </View>
          <View style={tailwind`ml-2 w-1/3`}>
            <MiniTrainerProfilePanel id={trainerId} />
            <YoutubeDetails video={video} title={title} />
          </View>
        </View>
      </View>
      <View style={tailwind`h-[200px]`}>
        <WebScrollContainer
          style={tailwind`bg-[${theme.value.backgroundColor}]`}
        >
          <TrainerVideos id={trainerId} firstName={firstName} />
        </WebScrollContainer>
      </View>
    </View>
  );
};

YoutubeLayout.propTypes = {
  video: PropTypes.object.isRequired,
};
export default YoutubeLayout;
