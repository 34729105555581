import React, { ForwardedRef, forwardRef } from "react";
import PropTypes from "prop-types";
import {
  Pressable,
  TextInput,
  TextInputKeyPressEventData,
  View,
} from "react-native";
import tailwind, { Style } from "twrnc";

import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import { Image } from "@rneui/themed";
import { theme } from "../../signals/signals";

interface ChatInputProps {
  height?: string;
  width?: string;
  disabled?: boolean;
  onSubmit: () => void;
}

const ChatInput = forwardRef(
  (
    { height, width, disabled, onSubmit }: ChatInputProps,
    ref: ForwardedRef<TextInput>
  ) => {
    let inputStyle: Style[] = [
      tailwind`text-base rounded-md pl-1 w-full bg-[#ffffff]`,
    ];
    let containerStyle: Style[] = [tailwind`rounded-lg flex-row`];

    if (width) {
      containerStyle.push(tailwind`w-[${width}] mx-1`);
    } else {
      containerStyle.push(tailwind`w-full mx-1`);
    }
    if (height) inputStyle.push(tailwind`h-[${height}]`);

    let fonts = useFonts({ Roboto_400Regular });
    if (!fonts) return;

    return (
      <>
        <View style={containerStyle}>
          <TextInput
            ref={ref}
            multiline={true}
            inputMode={"text"}
            editable={!disabled || false}
            style={[inputStyle, { fontFamily: "Roboto_400Regular" }]}
            placeholder={"Type a message..."}
            placeholderTextColor={"#ccc"}
            autoFocus={true}
            autoCorrect={true}
            onKeyPress={(event) => {
              if (event.nativeEvent.key === "Enter") {
                event.preventDefault();
                onSubmit();
              }
            }}
          />
          <View style={tailwind`mt-2 ml-4`}>
            <Pressable onPress={() => onSubmit()}>
              <View style={tailwind`self-center w-[60px]`}>
                <Image
                  source={require("../../assets/send-icon.png")}
                  style={tailwind`w-[32px] h-[32px]`}
                  tintColor={theme.value.chatSendIconColor}
                />
              </View>
            </Pressable>
          </View>
        </View>
      </>
    );
  }
);

ChatInput.displayName = "ChatInput";

ChatInput.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export { ChatInput };
