import { useFonts, Roboto_400Regular } from "@expo-google-fonts/roboto";
import { View, Text } from "react-native";
import tailwind from "twrnc";
import { currentUser, focusedScreen, theme } from "../../../signals/signals";
import { useSignal } from "@preact/signals-react";
import MiniButton from "../../base/MiniButton";
import { useNavigation } from "@react-navigation/native";

const NoOnDemandContentThumbnail = () => {
  const navigation = useNavigation();
  const thumbnailDimensions = useSignal({
    width: 212,
    height: 178,
  });

  let [fontsLoaded] = useFonts({ Roboto_400Regular });
  if (!fontsLoaded) {
    return null;
  }

  return (
    <View
      style={tailwind`rounded-lg w-[${thumbnailDimensions.value.width}px] bg-white w-[${thumbnailDimensions.value.width}px] h-[${thumbnailDimensions.value.height}px]`}
    >
      <View style={tailwind`mt-14 w-full`}>
        <View style={tailwind`self-center`}>
          <Text
            style={[
              tailwind`text-[${theme.value.redColor}] text-[14px] self-center`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            No videos available for
          </Text>
          <Text
            style={[
              tailwind`text-[${theme.value.redColor}] text-[14px] self-center`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            your selected filters
          </Text>
          <View style={tailwind`self-center mt-2`}>
            <MiniButton
              title="Edit Filters"
              onPress={() => {
                focusedScreen.value = "EditProfileScreen";
                navigation.navigate({
                  name: "EditProfileScreen",
                  params: {
                    userId: currentUser.value.id,
                    userType: currentUser.value.type,
                  },
                } as never);
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default NoOnDemandContentThumbnail;
