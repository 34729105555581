import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Dimensions, Platform, View } from "react-native";
import tailwind from "twrnc";
import { StyledInput } from "../base/StyledInput";
import {
  analyzeFirstName,
  analyzeLastName,
  analyzePhoneNumber,
} from "../helpers/validation";
import {
  isEmailAddressVerified,
  modalVisible,
  emailValidationErrorModalVisible,
  currentUser,
  emailValidationErrorMessage,
} from "../../signals/signals";
import Button from "../base/Button";
import axios from "axios";
import { API_URL } from "../base/Constants";
import VerifyEmailModal from "../modals/VerifyEmailModal";
import VerifyEmailErrorModal from "../modals/VerifyEmailErrorModal";
import { Signal } from "@preact/signals-react";

const EditPersonalDetailsForm = ({
  emailAddress,
  firstName,
  firstNameStatus,
  lastName,
  lastNameStatus,
  phoneNumber,
  phoneNumberStatus,
}) => {
  const token = currentUser.value.token;
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;

  useEffect(() => {
    if (
      firstName.value?.length > 0 &&
      lastName.value?.length > 0 &&
      phoneNumber.value?.length > 0
    ) {
      analyzeFirstName(firstName, firstNameStatus);
      analyzeLastName(lastName, lastNameStatus);
      analyzePhoneNumber(phoneNumber, phoneNumberStatus);
    }
  }, []);

  return (
    <>
      <View style={tailwind`mb-4 px-2 self-center w-full mt-2`}>
        <View style={tailwind`${isMobileWebFormat ? "w-full" : "flex-row"}`}>
          <View
            style={tailwind`${
              isMobileWebFormat ? "w-full self-center" : "w-1/2"
            }`}
          >
            <StyledInput
              label={"First Name"}
              width={isMobileWebFormat ? "300px" : null}
              placeholder={"Enter your first name"}
              value={firstName.value}
              onChangeText={(text) => {
                firstName.value = text;
                analyzeFirstName(firstName, firstNameStatus);
              }}
              onBlur={() => analyzeFirstName(firstName, firstNameStatus)}
              autoCorrect={false}
              fieldStatus={firstNameStatus}
            />
          </View>
          <View style={tailwind`${isMobileWebFormat ? "w-full" : "w-1/2"}`}>
            <StyledInput
              width={isMobileWebFormat ? "300px" : null}
              label={"Last Name"}
              placeholder={"Enter your last name"}
              value={lastName.value}
              onChangeText={(text) => {
                lastName.value = text;
                analyzeLastName(lastName, lastNameStatus);
              }}
              onBlur={() => analyzeLastName(lastName, lastNameStatus)}
              autoCorrect={false}
              fieldStatus={lastNameStatus}
            />
          </View>
        </View>
        <View
          style={tailwind`${isMobileWebFormat ? "w-full" : "flex-row"} mt-4`}
        >
          <View style={tailwind`${isMobileWebFormat ? "w-full" : "w-1/2"}`}>
            <StyledInput
              label={"Email Address"}
              width={isMobileWebFormat ? "300px" : null}
              disabled={true}
              placeholder={"someone@somewhere.net"}
              value={emailAddress}
              onChangeText={() => null}
              onBlur={() => null}
              autoCorrect={false}
              fieldStatus={new Signal(null)}
            />
            {!isEmailAddressVerified.value ? (
              <View style={tailwind`self-end mt-1`}>
                <Button
                  title="Verify Email"
                  onPress={() => {
                    axios
                      .post(
                        API_URL + "users/email/verify",
                        {},
                        {
                          headers: {
                            "Content-Type": "application/json",
                            authorization: token,
                          },
                        }
                      )
                      .then(() => {
                        modalVisible.value = true;
                      })
                      .catch((error) => {
                        emailValidationErrorMessage.value = error.response.data;
                        emailValidationErrorModalVisible.value = true;
                      });
                  }}
                />
              </View>
            ) : null}
          </View>
          <View
            style={tailwind`${isMobileWebFormat ? "w-full mt-2" : "w-1/2"}`}
          >
            <StyledInput
              label={"Phone Number"}
              width={isMobileWebFormat ? "300px" : null}
              multiline={false}
              placeholder={"+441604123456"}
              autoComplete={"off"}
              autoFocus={false}
              value={phoneNumber.value}
              autoCorrect={false}
              onChangeText={(text) => {
                phoneNumber.value = text;
                analyzePhoneNumber(phoneNumber, phoneNumberStatus);
              }}
              onBlur={() => analyzePhoneNumber(phoneNumber, phoneNumberStatus)}
              fieldStatus={phoneNumberStatus}
            />
          </View>
        </View>
        <VerifyEmailModal />
        <VerifyEmailErrorModal />
      </View>
    </>
  );
};

EditPersonalDetailsForm.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  firstName: PropTypes.instanceOf(Signal).isRequired,
  firstNameStatus: PropTypes.instanceOf(Signal).isRequired,
  lastName: PropTypes.instanceOf(Signal).isRequired,
  lastNameStatus: PropTypes.instanceOf(Signal).isRequired,
  phoneNumber: PropTypes.instanceOf(Signal).isRequired,
  phoneNumberStatus: PropTypes.instanceOf(Signal).isRequired,
};

export { EditPersonalDetailsForm };
