import axios from "axios";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { currentUser, theme } from "../../signals/signals";
import { ReplayVideo } from "./types/ReplayVideo";
import { API_URL } from "../base/Constants";
import { ActivityIndicator, Text, View } from "react-native";
import tailwind from "twrnc";
import { useContext } from "react";
import { BottomSheetContext } from "../base/ApplicationContext";
import EditVideo from "../on-demand/EditVideo";
import { WebScrollContainer } from "../home-screen/WebScrollContainer";
import VideoThumbnail from "../thumbnails/VideoThumbnail";
import ApiError from "../error/ApiError";

const LivestreamReplayPanel = () => {
  const {
    isLoading,
    error,
    data,
  }: { isLoading: boolean; error: Error; data: ReplayVideo[] } = useQuery(
    ["videos/provider/replay/" + currentUser.value.id],
    async () => {
      return axios
        .get(`${API_URL}videos/provider/replay/` + currentUser.value.id, {
          headers: { authorization: currentUser.value.token },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err));
    }
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const replayVideos: ReplayVideo[] = data;
  return (
    <>
      <View
        style={tailwind`flex-row bg-[${theme.value.backgroundColor}] pt-6 pb-4`}
      >
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] text-[20px]`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Your Livestream Replays
        </Text>
      </View>
      <LivestreamReplaysInnerPanel replayVideos={replayVideos} />
    </>
  );
};

const LivestreamReplaysInnerPanel = ({ replayVideos }) => {
  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);

  const handleVideoEdit = (video: ReplayVideo) => {
    setBottomSheetContent(<EditVideo video={video} />);
    setBottomSheetVisible(true);
  };

  return (
    <View style={tailwind`h-[200px]`}>
      <WebScrollContainer style={tailwind`pb-4`}>
        {replayVideos.map((video: ReplayVideo) => (
          <View key={video.id}>
            <VideoThumbnail
              video={{ video }}
              onPressOverride={() => handleVideoEdit(video)}
            />
          </View>
        ))}
      </WebScrollContainer>
    </View>
  );
};

LivestreamReplaysInnerPanel.propTypes = {
  videos: PropTypes.array,
};

export { LivestreamReplayPanel as default };
