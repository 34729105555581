import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { ActivityIndicator, Platform, Text, View, Modal } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import Button from "../base/Button";
import { API_URL } from "../base/Constants";
import { theme, modalVisible, currentUser } from "../../signals/signals";

const ConnectModal = ({ id, firstName, profileUrl }) => {
  const userId = currentUser.value.id;

  const modalWidth = Platform.OS === "web" ? "50%" : "100%";

  return (
    <View style={tailwind`justify-center`}>
      <Modal
        visible={modalVisible.value}
        animationType="slide"
        transparent={true}
        onRequestClose={() => {
          modalVisible.value = !modalVisible.value;
        }}
      >
        <View
          style={tailwind`rounded-lg h-auto p-6 self-center bg-[${theme.value.modalBackgroundColor}] border-[0.5px] border-[${theme.value.textColor}] flex-col w-[${modalWidth}] max-h-[400px] absolute bottom-40`}
        >
          <View style={tailwind`flex-row self-center`}>
            <View style={tailwind`px-2`}>
              <Image
                style={tailwind`w-[30px] h-[41px] rounded-lg`}
                source={{ uri: profileUrl }}
                PlaceholderContent={<ActivityIndicator />}
              />
            </View>
            <View>
              <Text
                style={tailwind`text-[${theme.value.textColor}] font-bold text-sm mt-3`}
              >
                Request a connection with {firstName} ?
              </Text>
            </View>
          </View>
          <View style={tailwind`w-full self-center mt-4`}>
            <View style={tailwind`flex-row self-center`}>
              <View>
                <Button
                  title="Request"
                  onPress={() => {
                    axios
                      .post(
                        API_URL + "userconnection/request",
                        {
                          sourceUserId: userId,
                          destinationUserId: id,
                        },
                        {
                          headers: {
                            "Content-Type": "application/json",
                            authorization: currentUser.value.token,
                          },
                        }
                      )
                      .then((res) => {
                        modalVisible.value = !modalVisible.value;
                        return res.data;
                      });
                  }}
                />
              </View>
              <View>
                <Button
                  title="Cancel"
                  onPress={() => {
                    modalVisible.value = !modalVisible.value;
                  }}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

ConnectModal.propTypes = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  profileUrl: PropTypes.string.isRequired,
};

export default ConnectModal;
