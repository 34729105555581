import React, { useEffect, useState } from "react";
import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import NavigationDrawers from "../navigation/NavigationDrawers";
import NavigationFooterBar from "../navigation/NavigationFooterBar";
import { NavigationContainer } from "@react-navigation/native";
import * as ScreenOrientation from "expo-screen-orientation";
import { Dimensions, Linking, Platform, View } from "react-native";
// import { SlideInRight } from "react-native-reanimated";
import tailwind from "twrnc";
import { BottomSheet } from "@rneui/themed";
import {
  BottomSheetContext,
  FullScreenContext,
  OrientationContext,
} from "./ApplicationContext";
import HeaderLogin from "./HeaderLogin";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { API_URL, DEFAULT_PROFILE_URL, USER_TYPE_USER } from "./Constants";
import axios from "axios";
import {
  chatSocket,
  currentUser,
  emailVerificationCode,
  isEmailAddressVerified,
  isProfileComplete,
  loggedIn,
  notificationSocket,
  profileMessages,
  redirectParams,
  redirectUrl,
  theme,
} from "../../signals/signals";

import Toast from "react-native-toast-message";
import { connectNotificationSocket, disconnectSocket } from "./notificationSocketHandler";
import { configureTheme } from "./themeHandler";
import NotificationServerDisonnectedModal from "../modals/NotificationServerDisconnectedModal";

const linking = {
  prefixes: ["http://localhost:19006", "fitcentr://"],
  config: {
    screens: {
      verifyEmailScreen: "verify-email",
    },
  },
};

const ApplicationContainer = () => {
  const [mobileFullScreen, setMobileFullScreen] = useState(null);
  const [bottomSheetContent, setBottomSheetContent] = useState();
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);

  const defaultDimensions = {
    height: Dimensions.get("window").height,
    width: Dimensions.get("window").width,
  };
  const defaultOrientation =
    defaultDimensions.width < defaultDimensions.height
      ? "portrait"
      : "landscape";

  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };

  const [orientation, setOrientation] = useState(defaultOrientation);
  const shouldShowHeader =
    (orientation === "portrait" || Platform.OS === "web") && !mobileFullScreen;

  useEffect(() => {

    const handleInitialURL = async () => {
      const initialURL = await Linking.getInitialURL();
      const validRedirectPaths = [
        'verify-email',
        'profile',
        'live',
      ];

      if (initialURL) {
        let path = initialURL.split('://')[1].split('/')[1];
        if (validRedirectPaths.includes(path)) {
          redirectUrl.value = path;
          if (path === "verify-email") {
            emailVerificationCode.value = initialURL.split('://')[1].split('/')[2];
          }
          if (path === "profile") {
            redirectUrl.value = "ViewProfileScreen";
            redirectParams.value = {
              id: parseInt(initialURL.split('://')[1].split('/')[2]),
              type: parseInt(initialURL.split('://')[1].split('/')[3]),
            };
          }
          if (path === "live") {
            redirectParams.value = parseInt(initialURL.split('://')[1].split('/')[2]);
          }
        }
      }
    };

    const tokenLogin = async (retreivedToken) => {
      try {
        await axios
          .post(
            API_URL + "auth-token/",
            {},
            {
              headers: {
                "Content-Type": "application/json",
                authorization: retreivedToken,
              },
            }
          )
          .then(async (response) => {
            const {
              id,
              firstName,
              lastName,
              emailAddress,
              type,
              profileUrl,
              currencyId,
              currencyName,
              currencySymbol,
              token,
              isProfileCompleted,
              settings,
              addressLine1,
              addressLine2,
              city,
              country,
              postalCode,
              isEmailVerified,
              theme,
              themes
            } = response.data;

            configureTheme(theme.id, themes);

            currentUser.value = ({
              id: id,
              firstName: firstName,
              lastName: lastName,
              emailAddress: emailAddress,
              type: type.id,
              profileUrl: profileUrl || DEFAULT_PROFILE_URL,
              authenticated: true,
              currencyId: currencyId,
              currencyName: currencyName,
              currencySymbol: currencySymbol,
              token: token,
              settings: settings,
              theme: theme,
            });

            isEmailAddressVerified.value = isEmailVerified;
            isProfileComplete.value = isProfileCompleted;
            evaluateProfileWarningMessages({ isProfileCompleted, profileUrl, currencyId, addressLine1, addressLine2, city, country, postalCode, type, isEmailVerified });
            setMobileFullScreen(false);

            if (!notificationSocket.value) {
              await connectNotificationSocket(id, token);
            }
          });

      } catch (err) {
        console.log(err);
      }
    };

    handleInitialURL();
    retrieveToken().then((retreivedToken) => {
      if (retreivedToken) tokenLogin(retreivedToken);
    });

    return async () => {
      if (chatSocket.value) {
        await disconnectSocket(chatSocket.value);
      }
    };
  }, []);

  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        setOrientation(getOrientation(orientationInfo.orientation));
      }, 300);
    });
  }, [orientation]);

  useEffect(() => {
    if (currentUser.value?.authenticated) {
      loggedIn.value = true;
    } else {
      loggedIn.value = false;
    }
  }, [currentUser.value]);

  const shouldShowNavigationFooter =
    Platform.isTV ||
      (Platform.OS === "web" && Dimensions.get("window").width > 768) ||
      (Platform.OS !== "web" && !Platform.isTV && mobileFullScreen)
      ? null
      : loggedIn.value;

  return (
    <OrientationContext.Provider value={{ orientation, setOrientation }}>
      <FullScreenContext.Provider
        value={{ mobileFullScreen, setMobileFullScreen }}
      >
        <SafeAreaProvider
          style={tailwind`bg-[${theme.value.backgroundColor}] overflow-hidden`}
        >
          <BottomSheetContext.Provider
            value={{
              bottomSheetContent,
              setBottomSheetContent,
              bottomSheetVisible,
              setBottomSheetVisible,
            }}
          >
            <NavigationContainer linking={linking}>
              <SafeAreaView />
              {shouldShowHeader ? (
                !loggedIn.value ? (
                  <HeaderLogin />
                ) : null
              ) : null}
              <StatusBar barStyle="dark-content" />
              <View style={tailwind`w-full grow`}>
                <View style={tailwind`grow`}>
                  <NavigationDrawers />
                </View>
                {shouldShowNavigationFooter ? (
                  <View
                    style={tailwind`border-[${theme.value.footerNavigationIconColor}] border-t-[1px] self-center w-full`}
                  >
                    {orientation === "portrait" ? (
                      <View style={tailwind`bottom-0 h-18`}>
                        {loggedIn.value ? <NavigationFooterBar /> : null}
                      </View>
                    ) : null}
                  </View>
                ) : null}
              </View>
              <BottomSheet
                isVisible={bottomSheetVisible}
                onBackdropPress={() => setBottomSheetVisible(false)}
              >
                <View style={tailwind`bg-black w-full self-center`}>
                  {bottomSheetContent}
                </View>
              </BottomSheet>
              <Toast />
              <NotificationServerDisonnectedModal />
            </NavigationContainer>
          </BottomSheetContext.Provider>
        </SafeAreaProvider>
      </FullScreenContext.Provider>
    </OrientationContext.Provider>
  );
};

const retrieveToken = async () => {
  try {
    const token = await AsyncStorage.getItem("token");
    return token;
  } catch (error) {
    console.error("Error retrieving token:", error);
  }
};

const evaluateProfileWarningMessages = ({
  isProfileCompleted,
  profileUrl,
  currencyId,
  addressLine1,
  addressLine2,
  city,
  country,
  postalCode,
  type,
  isEmailVerified,
}) => {
  profileMessages.value = [];
  if (isEmailVerified) {
    isEmailAddressVerified.value = isEmailVerified;
    profileMessages.value = profileMessages.value.filter((message) => !message.isEmail);
  } else {
    profileMessages.value = [];
    isProfileComplete.value = isProfileCompleted;

    if (!isProfileCompleted) {
      if (!profileUrl && type.id !== USER_TYPE_USER)
        profileMessages.value = [
          ...profileMessages.value,
          {
            type: "warning",
            message: "Your profile picture is not set",
          },
        ];

      if (!currencyId || !addressLine1 || !addressLine2 || !city || !country || !postalCode)
        profileMessages.value = [
          ...profileMessages.value,
          {
            type: "error",
            message: "You need to complete your billing settings.",
          },
        ];
    }
    profileMessages.value = [
      ...profileMessages.value,
      {
        isEmail: true,
        type: "warning",
        message: "Your email address is not verified",
      },
    ];
  }
}

export { ApplicationContainer as default, evaluateProfileWarningMessages };
