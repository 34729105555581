import { Socket, io } from "socket.io-client";
import {
  LOGTYPE_CONNECTION,
  LOGTYPE_EXPOTOKEN,
  NOTIFICATION_SOCKET_URL,
} from "./Constants";
import Constants from "expo-constants";
import { isNotificationServerConnected, notificationSocket } from "../../signals/signals";
import { attachNotificationSocketHandlers } from "./notificationSocketEventHandlers";

export const connectNotificationSocket = async (id: number, token: string) => {
  const socket = io(NOTIFICATION_SOCKET_URL);
  socket.on("connect", () => {
    notificationSocket.value = socket;
    isNotificationServerConnected.value = true;
    registerConnection(id, token);
    attachNotificationSocketHandlers(socket);
  });
};

export const disconnectSocket = async (socket: Socket) => {
  socket.close();
  isNotificationServerConnected.value = false;
};

export const storeExpoToken = (
  expoToken: string,
  token: string,
  userId: number
) => {
  const message = {
    type: LOGTYPE_EXPOTOKEN,
    expoPushToken: expoToken,
    deviceInfo: {
      deviceName: Constants.deviceName,
      platform: Constants.platform,
      sessionId: Constants.sessionId,
      token: token,
      userId: userId,
    },
  };
  if (notificationSocket.value) {
    notificationSocket.value.emit("addExpoToken", message);
  }
};

export const registerConnection = (userId: number, token: string) => {
  const loggingInfo = {
    type: LOGTYPE_CONNECTION,
    deviceInfo: {
      deviceName: Constants.deviceName,
      platform: Constants.platform,
      sessionId: Constants.sessionId,
      systemVersion: Constants.systemVersion,
      debugMode: Constants.debugMode,
      experienceUrl: Constants.experienceUrl,
      linkingUri: Constants.linkingUri,
      token: token,
      userId: userId,
    },
  };

  if (notificationSocket.value) {
    notificationSocket.value.emit("registerConnection", loggingInfo);
  } else {
    console.log("Unable to register connection with notification server");
    isNotificationServerConnected.value = false;
  }
};
