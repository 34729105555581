import PropTypes from "prop-types";
import { Pressable } from "react-native";
import tailwind from "twrnc";
import { Image } from "@rneui/themed";
import {
  chatSocket,
  currentUser,
  peerConnections,
  remoteStreams,
  theme,
} from "../../../signals/signals";
import { clearDownLeavingMember } from "../handlers/chatSocketHandler";
import { addPeerToPeerConnections } from "../handlers/callSocketHandler";
import { connectToPeer } from "../handlers/webRTCHandler";
import { RTCPeerConnection } from "react-native-webrtc-web-shim";

const CallEnabled = ({ userId, roomId }): JSX.Element => {
  return (
    <Pressable
      style={tailwind`m-1`}
      onPress={async () => {
        clearDownLeavingMember(userId);
        const rtcPeerConnection = await connectToPeer(
          userId,
          remoteStreams,
          chatSocket.value,
          currentUser.value.id,
          roomId
        );
        const peerConnection: RTCPeerConnection = addPeerToPeerConnections(
          peerConnections,
          userId,
          rtcPeerConnection
        );
      }}
    >
      <Image
        resizeMode="contain"
        style={tailwind`h-[25px] w-[25px]`}
        source={require("../../../assets/call-icon.png")}
        tintColor={theme.value.greenColor}
      />
    </Pressable>
  );
};

CallEnabled.propTypes = {
  userId: PropTypes.number.isRequired,
  roomId: PropTypes.string.isRequired,
};

export { CallEnabled as default };
