import React, { ScrollView } from "react-native";
import tailwind from "twrnc";
import ConnectionsPanel from "../components/network/ConnectionsPanel";
import { theme } from "../signals/signals";

const ConnectionsScreen = () => {
  return (
    <ScrollView
      style={tailwind`flex h-full bg-[${theme.value.backgroundColor}] self-center`}
    >
      <ConnectionsPanel />
    </ScrollView>
  );
};

export {ConnectionsScreen as default };
