import React from "react";
import { ActivityIndicator, Pressable, View } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { API_URL } from "../base/Constants";
import * as DocumentPicker from "expo-document-picker";
import axios from "axios";
import { Signal } from "@preact/signals-react";
import {
  currentUser,
  fileTooLargeErrorModalVisible,
} from "../../signals/signals";

const ThumbnailUpload = ({ thumbnailPath }: { thumbnailPath: Signal }) => {
  const uploadImage = async (
    singleFile: DocumentPicker.DocumentPickerSuccessResult
  ) => {
    let newImageUrl = "";
    const data = new FormData();
    if (singleFile != null) {
      data.append("file_attachment", {
        uri: singleFile.assets[0].uri,
        name: singleFile.output[0].name,
        type: singleFile.output[0].type,
        size: singleFile.output[0].size,
      });
      data.append("file", singleFile.assets[0].uri);
      data.append("originalFilename", singleFile.output[0].name);

      axios
        .post(API_URL + "users/image", data, {
          headers: {
            Authorization: currentUser.value.token,
          },
        })
        .then((result) => {
          newImageUrl = result.data;
          thumbnailPath.value = newImageUrl;
        })
        .catch((error) => {
          fileTooLargeErrorModalVisible.value = true;
          console.error("Error:", error);
        });
    }
  };

  async function selectFile() {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        copyToCacheDirectory: false,
        type: "image/*",
      });

      if (result.canceled === false) {
        await uploadImage(result);
      }
    } catch (err) {
      console.warn(err);
      return false;
    }
  }

  return (
    <Pressable
      style={tailwind`w-10 h-10 py-[6px] mx-1 mr-4 mb-2`}
      onPress={selectFile}
    >
      <View style={tailwind`self-center`}>
        <Image
          style={tailwind`w-[32px] h-[32px]`}
          source={require("../../assets/upload-icon.png")}
          PlaceholderContent={<ActivityIndicator />}
        />
      </View>
    </Pressable>
  );
};

export { ThumbnailUpload as default };
