import PropTypes from "prop-types";
import { Text, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../../signals/signals";
import { StyledPicker } from "../../base/StyledPicker";
import {
  DIFFICULTY_ADVANCED,
  DIFFICULTY_ALL,
  DIFFICULTY_BEGINNER,
  DIFFICULTY_INTERMEDIATE,
} from "../../base/Constants";
import { Signal } from "@preact/signals-react";

const DifficultyInput = ({ difficulty }) => {
  return (
    <View style={tailwind`mb-2`}>
      <Text
        style={[
          tailwind`text-[${theme.value.textColor}] font-semibold mb-1`,
          { fontFamily: "Roboto_400Regular" },
        ]}
      >
        Difficulty
      </Text>
      <StyledPicker
        selectedValue={difficulty.value}
        onValueChange={(itemValue) => (difficulty.value = itemValue)}
        items={[
          {
            label: "All",
            value: DIFFICULTY_ALL,
          },
          {
            label: "Beginner",
            value: DIFFICULTY_BEGINNER,
          },
          {
            label: "Intermediate",
            value: DIFFICULTY_INTERMEDIATE,
          },
          {
            label: "Advanced",
            value: DIFFICULTY_ADVANCED,
          },
        ]}
      />
    </View>
  );
};

DifficultyInput.propTypes = {
  difficulty: PropTypes.instanceOf(Signal).isRequired,
};

export { DifficultyInput as default };
