import React, { useEffect } from "react";
import { Dimensions, Modal, Platform, Text, View } from "react-native";
import tailwind from "twrnc";
import {
  currentUser,
  isEmailAddressVerified,
  modalVisible,
  redirectUrl,
  theme,
} from "../../signals/signals";
import {
  useFonts,
  Roboto_700Bold,
  Roboto_400Regular,
} from "@expo-google-fonts/roboto";
import Button from "../base/Button";
import { useSignal } from "@preact/signals-react";
import * as ScreenOrientation from "expo-screen-orientation";
import { evaluateProfileWarningMessages } from "../base/ApplicationContainer";

const EmailVerifiedModal = () => {
  const windowOrientation = useSignal("portrait");
  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        windowOrientation.value = getOrientation(orientationInfo.orientation);
      }, 300);
    });
  }, []);

  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };

  const isMobileWebFormat =
    Platform.OS === "web" &&
    ((windowOrientation.value === "portrait" &&
      Dimensions.get("screen").width < 768) ||
      (windowOrientation.value === "landscape" &&
        Dimensions.get("screen").height < 768));

  const modalWidth =
    Platform.OS === "web" && !isMobileWebFormat ? "50%" : "100%";

  let fonts = useFonts({ Roboto_700Bold, Roboto_400Regular });
  if (!fonts) return;

  return (
    <Modal
      visible={modalVisible.value}
      animationType="slide"
      transparent={true}
      onRequestClose={() => {
        modalVisible.value = !modalVisible.value;
      }}
    >
      <View
        style={tailwind`rounded-lg h-auto p-6 self-center bg-[${theme.value.modalBackgroundColor}] border-[0.5px] border-[${theme.value.textColor}] flex-col w-[${modalWidth}] max-h-[400px] absolute bottom-40`}
      >
        <View style={tailwind`w-full pr-4`}>
          <Text
            style={[
              tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold self-center py-2 text-[20px]`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            Email Address Verified
          </Text>
          <View style={tailwind`self-center`}>
            <Text
              style={[
                tailwind`text-[${theme.value.textColor}]`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              Thanks for verifying your email address.
            </Text>
          </View>
        </View>
        <View style={tailwind`flex-row self-center mt-4`}>
          <View>
            <Button
              title="Close"
              onPress={() => {
                modalVisible.value = !modalVisible.value;
                isEmailAddressVerified.value = true;

                const {
                  isProfileCompleted,
                  profileUrl,
                  currencyId,
                  addressLine1,
                  addressLine2,
                  city,
                  country,
                  postalCode,
                  type,
                } = currentUser.value;
                const isEmailVerified = true;

                evaluateProfileWarningMessages({
                  isProfileCompleted,
                  profileUrl,
                  currencyId,
                  addressLine1,
                  addressLine2,
                  city,
                  country,
                  postalCode,
                  type,
                  isEmailVerified,
                });
                redirectUrl.value = "";
              }}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export { EmailVerifiedModal as default };
