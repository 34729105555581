import axios from "axios";
import { API_URL } from "../../base/Constants";
import { currentUser } from "../../../signals/signals";
import { useQuery } from "@tanstack/react-query";

const useProviderSchedule = (id: number, providerId: number, day: string) => {
  const { isLoading, error, data } = useQuery(
    ["providerschedule" + providerId + day],
    () =>
      axios
        .get(API_URL + "schedule/provider/" + providerId + "/" + day, {
          headers: { authorization: currentUser.value.token },
        })
        .then((res) => {
          return res.data;
        }),
    { cacheTime: 0, staleTime: 0 }
  );
  return { isLoading, error, data };
};

export { useProviderSchedule as default };
