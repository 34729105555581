import PropTypes from "prop-types";
import { View } from "react-native";
import tailwind from "twrnc";
import { StyledInput } from "../../base/StyledInput";
import { analyzeSearchText } from "../../helpers/validation";
import { Signal } from "@preact/signals-react";

const TextSearchInput = ({ searchText, searchTextStatus, isTyping }) => {
  return (
    <View style={tailwind`mb-2 pl-1 w-full`}>
      <StyledInput
        value={searchText.value}
        onChangeText={(text) => {
          isTyping.value = true;
          searchText.value = text;
          analyzeSearchText(searchText, searchTextStatus);
        }}
        placeholder="Search"
        autoCorrect={true}
        width={"100%"}
        height={"40px"}
        fieldStatus={searchTextStatus}
        onBlur={() => analyzeSearchText(searchText, searchTextStatus)}
      />
    </View>
  );
};

TextSearchInput.propTypes = {
  searchText: PropTypes.instanceOf(Signal).isRequired,
  searchTextStatus: PropTypes.instanceOf(Signal).isRequired,
  isTyping: PropTypes.instanceOf(Signal).isRequired,
};

export { TextSearchInput as default };
