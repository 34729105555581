import PropTypes from "prop-types";
import React, { ActivityIndicator, ScrollView, Text, View } from "react-native";
import tailwind from "twrnc";
import { currentUser, focusedScreen, isEmailAddressVerified, isProfileComplete, theme } from "../signals/signals";
import Button from "../components/base/Button";
import { QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL, DEFAULT_PROFILE_URL, queryClient } from "../components/base/Constants";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import {
  PrepareOrder,
  checkoutStalledOrder,
} from "../components/checkout/RevolutPopupHandler";
import SettingScreenHeader from "../components/settings-screen/SettingsScreenHeader";
import { Image } from "@rneui/themed";
import ApiError from "../components/error/ApiError";

const PaymentHistoryScreen = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <PaymentHistoryPanel />
    </QueryClientProvider>
  );
};

const PaymentHistoryPanel = () => {
  const navigation = useNavigation();
  const userId = currentUser.value.id;
  const { token } = currentUser.value;

  const { isLoading, error, data, isFetching } = useQuery(
    ["booking/history" + userId],
    () =>
      axios
        .get(API_URL + "booking/history/" + userId, {
          headers: { authorization: token },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err))
  );

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;
  if (isLoading || isFetching) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const parsedData = [];
  data.forEach((booking) => {
    const date = dayjs(booking.startDate).format("YYYY");
    const existingSection = parsedData.filter(
      (sectionItem) => sectionItem.title === date
    )[0];

    if (!existingSection) {
      const newSection = {
        title: date,
        data: [booking],
      };
      parsedData.push(newSection);
    } else {
      existingSection.data.push(booking);
    }
  });

  return (
    <View
      style={tailwind`pt-10 p-2 w-full h-full bg-[${theme.value.backgroundColor}]`}
    >
      <SettingScreenHeader />
      <View style={tailwind`w-full self-center`}>
        <>
          <View style={tailwind`mb-4`}>
            <Text
              style={[
                tailwind`self-center font-semibold text-[${theme.value.textColor}] text-[14px]`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              Your Booked Events
            </Text>
          </View>
          <ScrollView style={tailwind`h-[150v]`}>
            <View style={tailwind`h-full`}>
              {data.map((booking) => {
                const { id, eventDate, userSchedule, payments, createdAt } =
                  booking;
                const { shortDescription, price } = userSchedule;
                const isExpired = dayjs().diff(dayjs(eventDate)) > 0;

                const hasPayments = payments.length;

                let paymentStatus;
                let paymentId;
                let revolutOrderId;
                payments.forEach((payment) => {
                  const { responses } = payment;
                  paymentId = payment.id;
                  responses.forEach((response) => {
                    if (response.state === "COMPLETED" || response.state === "AUTHORISED") {
                      paymentStatus = response.state;
                      revolutOrderId = response.revolutPublicId;
                    }
                    if (paymentStatus !== "COMPLETED")
                      paymentStatus = response.state;
                  });
                });

                let statusText;
                switch (paymentStatus) {
                  case "COMPLETED":
                    statusText = "BOOKED";
                    break;
                  case "PENDING":
                    statusText = "PENDING";
                    if (isExpired) statusText = "EXPIRED";
                    break;
                  default:
                    statusText = "AWAITING PAYMENT";
                    if (isExpired) statusText = "EXPIRED";
                    break;
                }

                return (
                  <View key={id}>
                    <BookingCard
                      profileUrl={userSchedule.user?.profileUrl || DEFAULT_PROFILE_URL}
                      eventDate={eventDate}
                      shortDescription={shortDescription}
                      createdAt={createdAt}
                      revolutOrderId={revolutOrderId}
                      statusText={statusText}
                      paymentStatus={paymentStatus}
                      price={price}
                    />
                    <View style={tailwind`flex-row`}>
                      <View style={tailwind`w-full`}>
                        {paymentStatus !== "COMPLETED" && !isExpired ? (
                          <View style={tailwind`self-end`}>
                            <Button
                              title={"Pay £" + price.toFixed(2)}
                              onPress={() => {
                                if (isProfileComplete.value && isEmailAddressVerified.value) {
                                  if (hasPayments) {
                                    checkoutStalledOrder(
                                      payments[0].responses[0].revolutPublicId,
                                      payments[0].responses[0].revolutOrderid,
                                      paymentId,
                                      navigation,
                                      currentUser
                                    );
                                  } else {
                                    PrepareOrder(currentUser, id, (order) => {
                                      checkoutStalledOrder(
                                        order.public_id,
                                        order.id,
                                        order.paymentId,
                                        navigation,
                                        currentUser
                                      );
                                    });
                                  }
                                } else {
                                  focusedScreen.value = "EditProfileScreen";
                                  navigation.navigate("EditProfileScreen", {
                                    userId: currentUser.value.id,
                                    userType: currentUser.value.type,
                                  });
                                }
                              }}
                            />
                          </View>
                        ) : null}
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
          </ScrollView>
        </>
      </View>
    </View >
  );
};

const BookingCard = ({
  profileUrl,
  eventDate,
  shortDescription,
  createdAt,
  revolutOrderId,
  statusText,
  paymentStatus,
  price
}) => {
  return (
    <View style={tailwind`w-full mb-1 flex-row`}>
      <View style={tailwind`w-1/2 bg-white p-1 rounded-tl-md rounded-bl-md`}>
        <View style={tailwind`flex-row`}>
          <Image
            style={tailwind`w-[59px] h-[59px] rounded-full`}
            source={{ uri: profileUrl || DEFAULT_PROFILE_URL }}
            PlaceholderContent={<ActivityIndicator />}
          />
          <View style={tailwind`mx-2 px-1 w-2/3`}>
            <Text
              style={[
                tailwind`text-[12px] font-semibold`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              {dayjs(eventDate).format("ddd DD MMM YYYY")}
            </Text>
            <Text
              style={[
                tailwind`text-[12px] font-semibold`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              {dayjs(eventDate).format("HH:mm")}
            </Text>

            <Text
              lineBreakMode="tail"
              textBreakStrategy="simple"
              numberOfLines={2}
              style={[
                tailwind`text-[14px] font-semibold`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              {shortDescription}
            </Text>

          </View>
        </View>
      </View>
      <View style={tailwind`w-1/2 bg-[#4a4a49] rounded-tr-md rounded-br-md p-1`}>
        <Text
          style={[
            tailwind`text-[12px] font-semibold text-[#f8f8f8]`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          {dayjs(createdAt).format("ddd DD MMM YYYY HH:mm:ss")}
        </Text>
        <Text
          style={[
            tailwind`mr-2 text-[8px] font-semibold text-[#f8f8f8]`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          {revolutOrderId}
        </Text>
        <View style={tailwind`flex-row mt-4`}>
          <View style={tailwind`grow`}>
            <Text
              style={[
                tailwind`text-[14px] font-semibold text-[#f8f8f8]`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              {statusText}
            </Text>
          </View>
          {paymentStatus === "COMPLETED" || paymentStatus === "AUTHORISED" ? (
            <View>
              <Text
                style={[
                  tailwind`text-[14px] font-semibold text-[#f8f8f8] self-end`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                £{price.toFixed(2)}
              </Text>
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );
};

BookingCard.propTypes = {
  profileUrl: PropTypes.string.isRequired,
  eventDate: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  revolutOrderId: PropTypes.string,
  statusText: PropTypes.string,
  paymentStatus: PropTypes.string,
  price: PropTypes.number,
}

export { PaymentHistoryScreen as default };
