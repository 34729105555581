import PropTypes from "prop-types";
import SwitchSelector from "react-native-switch-selector";
import { theme } from "../../signals/signals";
import tailwind from "twrnc";
import { Roboto_700Bold, useFonts } from "@expo-google-fonts/roboto";
import { Signal } from "@preact/signals-react";

const SearchTypeSelector = ({ searchType }) => {
  const options = [
    {
      label: "Find a trainer",
      value: "0",
      accessibilityLabel: "search-trainer",
    },
    {
      label: "Search for videos",
      value: "1",
      accessibilityLabel: "search-videos",
    },
  ];

  let fonts = useFonts({ Roboto_700Bold });
  if (!fonts) return;

  return (
    <SwitchSelector
      options={options}
      initial={Number(searchType.value)}
      textColor={theme.value.registrationUserTypeSliderTextColor}
      selectedColor={theme.value.registrationUserTypeSliderActiveColor}
      buttonColor={theme.value.registrationUserTypeButtonColor}
      borderColor={theme.value.registrationUserTypeBackgroundColor}
      hasPadding
      valuePadding={1}
      fontSize={20}
      onPress={(value) => (searchType.value = value)}
      style={[
        tailwind`w-[350px] h-[50px] font-bold`,
        { FontFace: "Robot_700Bold" },
      ]}
    />
  );
};

SearchTypeSelector.propTypes = {
  searchType: PropTypes.instanceOf(Signal).isRequired,
};

export { SearchTypeSelector as default };
