import React, { Pressable, View } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { currentUser, focusedScreen, theme } from "../../signals/signals";
import { useNavigation } from "@react-navigation/native";
import { USER_TYPE_AMBASSADOR, USER_TYPE_PROVIDER } from "../base/Constants";

const SettingScreenHeader = () => {
  const hostedEventsIcon = require("../../assets/live-events-icon.png");
  const replaySalesIcon = require("../../assets/sales-icon.png");
  const replayPurchasesIcon = require("../../assets/replays-icon.png");
  const bookedEventsIcon = require("../../assets/events-icon.png");
  const navigation = useNavigation();

  return (
    <View style={tailwind`flex-row w-full mb-2`}>
      <View style={tailwind`w-1/4`}>
        <Pressable
          style={tailwind`self-center`}
          onPress={() => {
            focusedScreen.value = "PaymentHistoryScreen";
            navigation.navigate("PaymentHistoryScreen");
          }}
        >
          <Image
            resizeMode="contain"
            style={tailwind`h-[32px] w-[32px]`}
            source={bookedEventsIcon}
            tintColor={
              focusedScreen.value === "PaymentHistoryScreen"
                ? theme.value.footerNavigationIconActiveColor
                : theme.value.footerNavigationIconColor
            }
          />
        </Pressable>
      </View>
      <View style={tailwind`w-1/4`}>
        <Pressable
          style={tailwind`self-center`}
          onPress={() => {
            focusedScreen.value = "ReplayHistoryScreen";
            navigation.navigate("ReplayHistoryScreen");
          }}
        >
          <Image
            resizeMode="contain"
            style={tailwind`h-[32px] w-[32px]`}
            source={replayPurchasesIcon}
            tintColor={
              focusedScreen.value === "ReplayHistoryScreen"
                ? theme.value.footerNavigationIconActiveColor
                : theme.value.footerNavigationIconColor
            }
          />
        </Pressable>
      </View>
      <View style={tailwind`w-1/4`}>
        {currentUser.value.type === USER_TYPE_AMBASSADOR || currentUser.value.type === USER_TYPE_PROVIDER ? (
          <Pressable
            style={tailwind`self-center`}
            onPress={() => {
              focusedScreen.value = "BookingHistoryScreen";
              navigation.navigate("BookingHistoryScreen");
            }}
          >
            <Image
              resizeMode="contain"
              style={tailwind`h-[32px] w-[32px]`}
              source={hostedEventsIcon}
              tintColor={focusedScreen.value === "BookingHistoryScreen"
                ? theme.value.footerNavigationIconActiveColor
                : theme.value.footerNavigationIconColor
              }
            />
          </Pressable>
        ) : null}
      </View>
      <View style={tailwind`w-1/4`}>
        {currentUser.value.type === USER_TYPE_AMBASSADOR ? (
          <Pressable
            style={tailwind`self-center`}
            onPress={() => {
              focusedScreen.value = "ReplaySalesScreen";
              navigation.navigate("ReplaySalesScreen");
            }}
          >
            <Image
              resizeMode="contain"
              style={tailwind`h-[32px] w-[32px]`}
              source={replaySalesIcon}
              tintColor={
                focusedScreen.value === "ReplaySalesScreen"
                  ? theme.value.footerNavigationIconActiveColor
                  : theme.value.footerNavigationIconColor
              }
            />
          </Pressable>
        ) : null}
      </View>
    </View>
  );
};

export { SettingScreenHeader as default };
