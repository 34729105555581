import React from "react";
import { ScrollView, Text, View } from "react-native";
import tailwind from "twrnc";
import { currentUser, theme } from "../signals/signals";
import {
  USER_TYPE_AMBASSADOR,
  USER_TYPE_PROVIDER,
  queryClient,
} from "../components/base/Constants";
import { QueryClientProvider } from "@tanstack/react-query";
import { Roboto_700Bold, useFonts } from "@expo-google-fonts/roboto";
import PurchasedReplaysPanel from "../components/library/PurchasedReplaysPanel";
import LivestreamReplayPanel from "../components/library/LiveStreamReplayPanel";
import OnDemandVideoPanel from "../components/library/OnDemandVideoPanel";
import YoutubeVideoPanel from "../components/library/YoutubeVideoPanel";

const LibraryScreen = () => {
  const type = currentUser.value.type;

  let fonts = useFonts({ Roboto_700Bold });
  if (!fonts) return;

  return (
    <View
      style={tailwind`flex h-full p-2 bg-[${theme.value.backgroundColor}] pb-6`}
    >
      <View style={tailwind`mb-4`}>
        <Text
          style={[
            tailwind`self-center font-semibold text-[${theme.value.textColor}] text-[20px]`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          Your Library
        </Text>
      </View>
      <QueryClientProvider client={queryClient}>
        <ScrollView>
          {type === USER_TYPE_AMBASSADOR || type === USER_TYPE_PROVIDER ? (
            <View>
              {currentUser.value.type === USER_TYPE_AMBASSADOR ? (
                <LivestreamReplayPanel />
              ) : null}
              <OnDemandVideoPanel />
              <YoutubeVideoPanel />
            </View>
          ) : null}
          <PurchasedReplaysPanel />
        </ScrollView>
      </QueryClientProvider>
    </View>
  );
};

export default LibraryScreen;
