import { Text, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../../signals/signals";

const BookedLabel = () => {
  return (
    <View style={tailwind`flex-row self-end h-[24px]`}>
      <Text
        style={[
          tailwind`text-[${theme.value.coinsIconColor}] font-semibold self-end text-[14px]`,
          { fontFamily: "Roboto_400Regular" },
        ]}
      >
        Booked
      </Text>
    </View>
  );
};

export { BookedLabel as default };
