import React from "react";
import PropTypes from "prop-types";
import { useQuery, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { ActivityIndicator, Text } from "react-native";
import { API_URL, queryClient } from "../base/Constants";
import { currentUser, searchQuery } from "../../signals/signals";
import TrainerThumbnail from "../thumbnails/trainer/TrainerThumbnail";
import ApiError from "../error/ApiError";

const SearchTrainerThumbnailStrip = ({ section }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThumbnailStrip sectionId={section.id} userId={currentUser.value.id} />
    </QueryClientProvider>
  );
};

SearchTrainerThumbnailStrip.propTypes = {
  section: PropTypes.object.isRequired,
};

function ThumbnailStrip({ sectionId, userId }) {
  const { isLoading, error, data } = useQuery(
    ["trainers" + sectionId + searchQuery.value],
    () =>
      axios
        .get(
          API_URL +
          "search/section/" +
          sectionId +
          "/" +
          userId +
          "/" +
          searchQuery.value,
          {
            headers: { authorization: currentUser.value.token },
          }
        )
        .then((res) => res.data)
  );
  if (isLoading) return <ActivityIndicator />;
  if (error) return<ApiError />;

  return (
    <>
      {data.map((trainer) => (
        <TrainerThumbnail key={trainer.id} id={trainer.id} trainer={trainer} />
      ))}
    </>
  );
}

ThumbnailStrip.propTypes = {
  sectionId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};

export default SearchTrainerThumbnailStrip;
