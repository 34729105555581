import PropTypes from "prop-types";
import { ActivityIndicator, Text, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../signals/signals";
import SearchTrainerProfilePanel from "./SearchTrainerProfilePanel";
import { Signal } from "@preact/signals-react";

const TextSearchResults = ({ foundTrainers, isLoading }) => {
  if (isLoading.value) {
    return (
      <View>
        <ActivityIndicator />
      </View>
    );
  }

  if (foundTrainers.value.length === 0) {
    return (
      <View style={tailwind`pl-3`}>
        <Text
          style={[
            tailwind`text-[${theme.value.textColor}] font-semibold mb-1`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          No results found
        </Text>
      </View>
    );
  }

  return (
    <View>
      {foundTrainers.value.map((trainer) => (
        <View
          key={trainer.id}
          style={tailwind`bg-white my-1 mx-2 rounded-md p-2`}
        >
          <SearchTrainerProfilePanel id={trainer.id} />
        </View>
      ))}
    </View>
  );
};

TextSearchResults.propTypes = {
  foundTrainers: PropTypes.instanceOf(Signal).isRequired,
  isLoading: PropTypes.instanceOf(Signal).isRequired,
};

export { TextSearchResults as default };
