import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Dimensions, Platform, Text, View } from "react-native";
import tailwind from "twrnc";
import { StyledInput } from "../base/StyledInput";
import { Switch } from "@rneui/themed";
import {
  Roboto_400Regular,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";
import { currentUser, theme, windowOrientation } from "../../signals/signals";
import { Signal } from "@preact/signals-react";
import { analyzeBaseCost, analyzeClassDuration } from "../helpers/validation";

const PersonalTrainingSettings = ({
  baseCost,
  baseCostStatus,
  classDuration,
  classDurationStatus,
  isProviding,
}) => {
  const isMobileWebFormat =
    Platform.OS === "web" &&
    ((windowOrientation.value === "portrait" &&
      Dimensions.get("screen").width < 768) ||
      (windowOrientation.value === "landscape" &&
        Dimensions.get("screen").height < 768));

  let fonts = useFonts({ Roboto_700Bold, Roboto_400Regular });
  if (!fonts) return;

  return (
    <PersonalTrainingSettingsPanel
      baseCost={baseCost}
      baseCostStatus={baseCostStatus}
      classDuration={classDuration}
      classDurationStatus={classDurationStatus}
      isProviding={isProviding}
      isMobileWebFormat={isMobileWebFormat}
    />
  );
};

const PersonalTrainingSettingsPanel = ({
  baseCost,
  baseCostStatus,
  classDuration,
  classDurationStatus,
  isProviding,
  isMobileWebFormat,
}) => {
  const toggleSwitch = () => {
    isProviding.value = !isProviding.value;
    analyzeBaseCost(baseCost, baseCostStatus, isProviding);
    analyzeClassDuration(classDuration, classDurationStatus, isProviding);
  };
  const { settings } = currentUser.value;
  const fitCentrPTFee = settings.filter(
    (setting) => setting.key === "FITCENTR_PT_FIXED_FEE"
  )[0].value;

  useEffect(() => {
    analyzeBaseCost(baseCost, baseCostStatus, isProviding);
    analyzeClassDuration(classDuration, classDurationStatus, isProviding);
  }, [isProviding.value]);

  return (
    <View
      style={tailwind`${
        !isMobileWebFormat ? "min-w-[624px]" : "w-full"
      } pb-4 mb-4`}
    >
      <View style={tailwind`w-full`}>
        <View
          style={tailwind`py-3 ${
            isMobileWebFormat ? "w-full self-center" : "flex-row"
          }`}
        >
          <Text
            style={[
              tailwind`text-[20px] text-[${theme.value.textColor}] ml-2 grow`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            Personal Training Charges
          </Text>
          <View style={tailwind`ml-2 mb-4`}>
            <View style={tailwind`flex-row`}>
              <Switch
                style={tailwind`mt-1`}
                thumbColor={theme.value.textColor}
                color={theme.value.pTEnabledSwitchTrackColorFalse}
                trackColor={{
                  false: theme.value.pTEnabledSwitchTrackColorTrue,
                  true: theme.value.pTEnabledSwitchTrackColorFalse,
                }}
                ios_backgroundColor={"#3e3e3e"}
                onValueChange={toggleSwitch}
                value={isProviding.value}
              />
              <Text
                style={[
                  tailwind`ml-2 pt-1 font-semibold text-[${
                    isProviding ? theme.value.redColor : theme.value.textColor
                  }]`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {isProviding
                  ? "Connections for PT Enabled "
                  : "Connections for PT Disabled"}
              </Text>
            </View>
          </View>
        </View>
        <View style={tailwind`w-full`}>
          <View
            style={tailwind`${isMobileWebFormat ? "self-center" : "w-full"} ${
              isMobileWebFormat ? null : "flex-row flex-wrap"
            }`}
          >
            <View style={tailwind`${isMobileWebFormat ? "w-full" : "w-1/2"}`}>
              <StyledInput
                disabled={!isProviding.value}
                width={isMobileWebFormat ? "300px" : null}
                label="Cost for single session (£)"
                onChangeText={(text) => {
                  baseCost.value = text;
                  analyzeBaseCost(baseCost, baseCostStatus, isProviding);
                }}
                placeholder={Number(fitCentrPTFee).toFixed(2)}
                value={baseCost.value}
                autoCorrect={false}
                fieldStatus={baseCostStatus}
                onBlur={() =>
                  analyzeBaseCost(baseCost, baseCostStatus, isProviding)
                }
              />
            </View>
            <View>
              <StyledInput
                disabled={!isProviding.value}
                width={isMobileWebFormat ? "300px" : null}
                label={"Duration of session (minutes)"}
                onChangeText={(text) => {
                  classDuration.value = text;
                  analyzeClassDuration(
                    classDuration,
                    classDurationStatus,
                    isProviding
                  );
                }}
                value={classDuration.value}
                placeholder={"60"}
                autoCorrect={false}
                fieldStatus={classDurationStatus}
                onBlur={() =>
                  analyzeClassDuration(
                    classDuration,
                    classDurationStatus,
                    isProviding
                  )
                }
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

PersonalTrainingSettings.propTypes = {
  baseCost: PropTypes.instanceOf(Signal<string>).isRequired,
  baseCostStatus: PropTypes.instanceOf(Signal<string>).isRequired,
  classDuration: PropTypes.instanceOf(Signal<string>).isRequired,
  classDurationStatus: PropTypes.instanceOf(Signal<string>).isRequired,
  isProviding: PropTypes.instanceOf(Signal<boolean>).isRequired,
};

PersonalTrainingSettingsPanel.propTypes = {
  baseCost: PropTypes.instanceOf(Signal<string>).isRequired,
  baseCostStatus: PropTypes.instanceOf(Signal<string>).isRequired,
  classDuration: PropTypes.instanceOf(Signal<string>).isRequired,
  classDurationStatus: PropTypes.instanceOf(Signal<string>).isRequired,
  isProviding: PropTypes.instanceOf(Signal<boolean>).isRequired,
  isMobileWebFormat: PropTypes.bool.isRequired,
};

export { PersonalTrainingSettings as default };
