import { Text, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../signals/signals";

const NoCameraOrAudioPermission = () => {
  return (
    <View style={tailwind`w-full`}>
      <View style={tailwind`rounded-lg self-center bg-white p-4 m-4`}>
        <Text
          style={[
            tailwind`self-center ml-2 pt-1 font-semibold text-[${theme.value.textColor}]`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          We don&apos;t have permission from your device
        </Text>
        <Text
          style={[
            tailwind`self-center ml-2 pt-1 font-semibold text-[${theme.value.textColor}]`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          to access your camera and/or microphone.
        </Text>
        <Text
          style={[
            tailwind`self-center ml-2 pt-4 font-semibold text-[${theme.value.textColor}]`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          Check your settings and try again.
        </Text>
      </View>
    </View>
  );
};

export { NoCameraOrAudioPermission as default };
