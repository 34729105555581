import React from "react";
import PropTypes from "prop-types";
import { ActivityIndicator, Pressable, View } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import * as DocumentPicker from "expo-document-picker";
import {
  attachSocketFileHandlers,
  prepareVideoUpload,
  sendVideoChunk,
  signalVideoUploadAbandoned,
  signalVideoUploadComplete,
} from "../base/notificationSocketFileHandler";
import { Signal, useSignal } from "@preact/signals-react";

const VideoUpload = ({ videoPath, videoPathStatus, isUploading }) => {
  const hasErrored = useSignal(false);
  const hasCompleted = useSignal(false);
  const fileName = useSignal("");
  const publicFilePath = useSignal("");
  attachSocketFileHandlers(hasErrored, videoPath, isUploading, publicFilePath);

  const uploadVideo = async (singleFile: { assets: any[]; output: any[] }) => {
    isUploading.value = true;
    if (singleFile != null) {
      const file = singleFile.assets[0].uri;
      fileName.value = singleFile.output[0].name;

      const reader = new FileReader();
      reader.onload = (event) => {
        if (!event.target?.result) {
          return;
        }

        const chunkSize = 1024 * 1024; // 1MB
        let offset = 0;

        prepareVideoUpload(fileName.value, hasErrored, () => {
          if (!file.length) {
            console.error("Tried to upload empty file");
            hasErrored.value = true;
            return;
          }

          console.log("Sending video chunks");
          hasCompleted.value = false;
          while (offset < file.length) {
            if (!hasErrored.value) {
              const chunk = file.slice(
                offset,
                Math.min(offset + chunkSize, file.length)
              );
              sendVideoChunk(
                chunk,
                offset,
                Math.min(offset + chunkSize, file.length)
              );
              offset += chunkSize;
            } else {
              break;
            }
          }

          if (!hasErrored.value) {
            hasCompleted.value = true;
          } else {
            console.log("hasErrored is true!");
          }

          if (!hasErrored.value && hasCompleted.value) {
            setTimeout(() => {
              signalVideoUploadComplete(fileName.value);
            }, 500);
          } else {
            signalVideoUploadAbandoned(fileName.value, hasErrored);
          }
        });
      };
      reader.readAsArrayBuffer(new Blob([file]));
    }
  };

  async function selectFile() {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        copyToCacheDirectory: false,
        type: "video/*",
      });

      if (result.canceled === false) {
        await uploadVideo(
          result as unknown as { assets: any[]; output: any[] }
        );
      }
    } catch (err) {
      console.warn(err);
      return false;
    }
  }

  return (
    <Pressable style={tailwind`w-10 h-10`} onPress={selectFile}>
      <View style={tailwind`self-center`}>
        <Image
          style={tailwind`w-[32px] h-[32px]`}
          source={require("../../assets/upload-icon.png")}
          PlaceholderContent={<ActivityIndicator />}
        />
      </View>
    </Pressable>
  );
};

VideoUpload.propTypes = {
  videoPath: PropTypes.instanceOf(Signal).isRequired,
  videoPathStatus: PropTypes.instanceOf(Signal).isRequired,
  isUploading: PropTypes.instanceOf(Signal).isRequired,
};

export { VideoUpload as default };
