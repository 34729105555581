import React, { SafeAreaView, View, Text } from "react-native";
import PropTypes from "prop-types";
import tailwind from "twrnc";
import { currentUser, focusedScreen, isEmailAddressVerified, isProfileComplete, theme } from "../../signals/signals";
import { useContext, useEffect } from "react";
import { BottomSheetContext } from "../base/ApplicationContext";
import MiniTrainerProfilePanel from "../profile-screen/MiniTrainerProfilePanel";
import Button from "../base/Button";
import dayjs from "dayjs";
import { useSignal } from "@preact/signals-react";
import axios from "axios";
import { API_URL } from "../base/Constants";
import { useNavigation } from "@react-navigation/native";
import EditOpenEvent from "../event-screen/edit/EditOpenEvent";

const ViewOpenPTEvent = ({ event }) => {
  const typeName = "Open Personal Training";
  const headerColour = theme.value.calendarEventBookableColor;
  const navigation = useNavigation();
  const isBooked = useSignal(false);
  const isHistoric = useSignal(false);
  const isStalled = useSignal(false);

  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);
  const { startDate, shortDescription, bookings, duration, longDescription } =
    event;
  const time = dayjs(event.startDate).format("HH:mm");
  const hostId = event.userId;

  const handlePTPayment = () => {
    navigation.navigate("PaymentHistoryScreen");
    setBottomSheetVisible(false);
  };

  const handlePTBooking = () => {
    axios
      .post(
        API_URL + "booking",
        {
          eventId: event.id,
          eventDate: event.startDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.value.token,
          },
        }
      )
      .then(async (res) => {
        if (!res.error) {
          setBottomSheetVisible(false);
          navigation.navigate("OrderSummaryScreen", {
            bookingId: res.data.id,
            userScheduleId: event.id,
          });
        } else {
          console.log(res.error);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    isHistoric.value = dayjs(startDate).diff(dayjs(), "minute") < -duration;
    if (bookings && bookings.length) {
      if (bookings[0].user.id === currentUser.value.id) {
        const payments = bookings[0].payments;
        if (payments.length) {
          payments.forEach((payment) => {
            if (payment.responses) {
              isStalled.value = true;
              payment.responses.forEach((response) => {
                if (response.state === "COMPLETED" || response.state === "AUTHORISED") {
                  isStalled.value = false;
                }
              });
            } else {
              isStalled.value = true;
            }
          });
        } else {
          isStalled.value = true;
        }
      } else {
        isBooked.value = true;
      }
    }
  }, []);

  return (
    <SafeAreaView style={tailwind`bg-[${theme.value.backgroundColor}]`}>
      <View style={tailwind`pb-10`}>
        <View
          style={tailwind`${headerColour &&
            "border-t-[" + headerColour + "] border-t-[4px] p-1 min-h-[15px]"
            }`}
        />
        <View style={tailwind`flex-row m-2 w-[600px] self-center`}>
          <View
            style={
              hostId !== currentUser.value.id
                ? tailwind`w-1/2 pr-4`
                : tailwind`w-full pr-4`
            }
          >
            <Text
              style={[
                tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold self-center pt-2 text-[20px]`,
                { fontFamily: "Roboto_700Bold" },
              ]}
            >
              {typeName}
            </Text>
            <View style={tailwind`flex-row self-center`}>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {dayjs(startDate).format("ddd MMM DD")}
              </Text>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] mx-2`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                at
              </Text>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {time}
              </Text>
            </View>
            <View style={tailwind`self-center`}>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] text-[12px] font-semibold ml-1 self-start grow`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {duration} MIN
              </Text>
            </View>
            <View style={tailwind`my-4`}>
              <Text
                style={[
                  tailwind`self-center text-[${theme.value.textColor}] font-semibold`,
                  { fontFamily: "Roboto_700Bold" },
                ]}
              >
                {shortDescription}
              </Text>
            </View>
            <View style={tailwind`mb-4`}>
              <Text
                style={[
                  tailwind`self-center text-[${theme.value.textColor}] font-semibold mr-2`,
                  { fontFamily: "Inter_400Regular" },
                ]}
              >
                {longDescription}
              </Text>
            </View>
          </View>
          {hostId !== currentUser.value.id ? (
            <View style={tailwind`mb-2 w-1/2 pl-4`}>
              <MiniTrainerProfilePanel id={hostId} />
            </View>
          ) : null}
        </View>
        <View style={tailwind`self-center flex-row`}>
          {hostId === currentUser.value.id ? (
            <>
              <Button
                title="Edit"
                onPress={() => {
                  setBottomSheetContent(
                    <EditOpenEvent event={event} />
                  );
                }}
              />
            </>
          ) : null}
          {hostId !== currentUser.value.id &&
            !isBooked.value &&
            !isHistoric.value &&
            !isStalled.value ? (
            <Button title="Book" onPress={() => {
              if (isProfileComplete.value && isEmailAddressVerified.value) {
                handlePTBooking();
              } else {
                focusedScreen.value = "EditProfileScreen";
                navigation.navigate("EditProfileScreen", {
                  userId: currentUser.value.id,
                  userType: currentUser.value.type,
                });
                setBottomSheetVisible(false);
              }
            }} />
          ) : null}
          {hostId !== currentUser.value.id &&
            !isBooked.value &&
            !isHistoric.value &&
            isStalled.value &&
            isProfileComplete.value &&
            isEmailAddressVerified.value ? (
            <Button title={"Pay £" + event.price.toFixed(2)} onPress={() => handlePTPayment()} />
          ) : null}
          <Button
            title="Close"
            onPress={() => {
              setBottomSheetVisible(false);
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

ViewOpenPTEvent.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    type: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    isPublic: PropTypes.bool.isRequired,
    duration: PropTypes.number.isRequired,
    shortDescription: PropTypes.string.isRequired,
    longDescription: PropTypes.string,
    difficulty: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    participants: PropTypes.array,
    location: PropTypes.string,
    tags: PropTypes.arrayOf({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    rule: PropTypes.shape({
      id: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      dayOfMonth: PropTypes.number,
      daysOfWeek: PropTypes.string,
      repeatType: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
    scheduleTags: PropTypes.array,
    bookings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired,
        eventDate: PropTypes.string.isRequired,
        updatedAt: PropTypes.string.isRequired,
        payments: PropTypes.arrayOf(
          PropTypes.shape({
            state: PropTypes.string,
          })
        ),
        user: PropTypes.shape({
          id: PropTypes.number.isRequired,
          createdAt: PropTypes.string.isRequired,
        }).isRequired,
      })
    ),
  }),
};
export { ViewOpenPTEvent as default };
