import { ScrollView, Text, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../signals/signals";
import { FitCentrEvent } from "./types/FitCentrEvent";

const BroadcastDetailsPanel = ({
  fitCentrEvent,
}: {
  fitCentrEvent: FitCentrEvent;
}) => {
  return (
    <View style={tailwind`bg-[#fff] rounded-lg p-2`}>
      <View>
        <Text
          numberOfLines={1}
          style={[
            tailwind`text-[${theme.value.textColor}] font-semibold mb-1`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          {fitCentrEvent.shortDescription}
        </Text>
      </View>
      <View style={tailwind``}>
        <Text
          style={[
            tailwind`text-[${theme.value.textColor}] font-semibold text-xs mb-1`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          {fitCentrEvent.duration} Minute Livestream
        </Text>
      </View>
      <View>
        <ScrollView style={tailwind`max-h-[120px]`}>
          <Text
            style={[
              tailwind`text-[${theme.value.textColor}] mb-2`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            {fitCentrEvent.longDescription}
          </Text>
        </ScrollView>
      </View>
    </View>
  );
};

export { BroadcastDetailsPanel as default };
