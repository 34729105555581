import React, { Pressable, SafeAreaView, Text, View } from "react-native";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import { currentUser, focusedScreen, theme } from "../signals/signals";
import {
  USER_TYPE_AMBASSADOR,
  USER_TYPE_PROVIDER,
} from "../components/base/Constants";

const SettingsScreen = () => {
  const navigation = useNavigation();
  const libraryIcon = require("../assets/video-library-icon.png");
  const logoutIcon = require("../assets/logout-icon.png");
  const hostedEventsIcon = require("../assets/live-events-icon.png");
  const replaySalesIcon = require("../assets/sales-icon.png");
  const replayPurchasesIcon = require("../assets/replays-icon.png");
  const bookedEventsIcon = require("../assets/events-icon.png");

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <SafeAreaView
      style={tailwind`h-full pt-3 bg-[${theme.value.backgroundColor}]`}
    >
      <View style={tailwind`flex-row flex-wrap self-center mb-1`}>
        <Pressable
          style={tailwind`self-center w-[170px] h-[90px]`}
          onPress={() => {
            focusedScreen.value = "PaymentHistoryScreen";
            navigation.navigate("PaymentHistoryScreen");
          }}
        >
          <SettingButton icon={bookedEventsIcon} title="Your Booked Events" />
        </Pressable>
        <Pressable
          style={tailwind`self-center w-[170px] h-[90px] ml-1`}
          onPress={() => {
            focusedScreen.value = "ReplayHistoryScreen";
            navigation.navigate("ReplayHistoryScreen");
          }}
        >
          <SettingButton icon={replayPurchasesIcon} title="Your Replay Purchases" />
        </Pressable>
      </View>
      <View style={tailwind`flex-row flex-wrap self-center mb-1`}>
        {currentUser.value.type === USER_TYPE_PROVIDER || currentUser.value.type === USER_TYPE_AMBASSADOR ? (
          <>
            <Pressable
              style={tailwind`self-center w-[170px] h-[90px]`}
              onPress={() => {
                focusedScreen.value = "BookingHistoryScreen";
                navigation.navigate("BookingHistoryScreen");
              }}
            >
              <SettingButton icon={hostedEventsIcon} title={"Your Hosted Events"} />
            </Pressable>
          </>
        ) : null}
        {currentUser.value.type === USER_TYPE_AMBASSADOR ? (
          <>
            <Pressable
              style={tailwind`self-center w-[170px] h-[90px] ml-1`}
              onPress={() => {
                focusedScreen.value = "ReplaySalesScreen";
                navigation.navigate("ReplaySalesScreen");
              }}
            >
              <SettingButton icon={replaySalesIcon} title="Replay Sales" />
            </Pressable>
          </>
        ) : null}
      </View>
      <View style={tailwind`flex-row flex-wrap self-center`}>
        {/* <Pressable
          style={tailwind`self-center w-[170px] h-[90px]`}
          onPress={() => {
            focusedScreen.value = "DeviceSettingsScreen";
            navigation.navigate("DeviceSettingsScreen");
          }}
        >
          <SettingButton icon={null} title="Manage your devices" />
        </Pressable> */}
        <Pressable
          style={tailwind`self-center w-[170px] h-[90px]`}
          onPress={() => {
            focusedScreen.value = "LibraryScreen";
            navigation.navigate("LibraryScreen");
          }}
        >
          <SettingButton icon={libraryIcon} title="Your Library" />
        </Pressable>
      </View>
      <View style={tailwind`grow`} />
      <View style={tailwind`w-full pb-6`}>
        <Pressable
          style={tailwind`self-center w-[170px] h-[90px]`}
          onPress={() => {
            navigation.navigate("LogoutScreen");
          }}
        >
          <SettingButton icon={logoutIcon} title="Logout" />
        </Pressable>
      </View>
    </SafeAreaView>
  );
};

const SettingButton = ({ icon, title }) => {
  return (
    <View
      style={tailwind`rounded-lg mx-1 p-3 bg-[${theme.value.bundleItemBackgroundColor}] mb-4 w-[170px] h-[90px]`}
    >
      <Text
        style={[
          tailwind`font-semibold self-center text-xs`,
          { fontFamily: "Roboto_400Regular" },
        ]}
      >
        {title}
      </Text>
      <View style={tailwind`self-center pt-1`}>
        <Image
          resizeMode="contain"
          style={tailwind`h-[32px] w-[32px]`}
          source={icon}
          tintColor={theme.value.iconColor}
        />
      </View>
    </View>
  );
}

SettingButton.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string.isRequired,
}

export { SettingsScreen as default };
