import { StyledInput } from "../../base/StyledInput";
import { analyzeDuration } from "../../helpers/validation";

const DurationInput = ({ duration, durationStatus }) => {
  return (
    <StyledInput
      label="Duration (minutes)"
      onChangeText={(itemValue) => {
        duration.value = itemValue;
        analyzeDuration(duration, durationStatus);
      }}
      onBlur={() => analyzeDuration(duration, durationStatus)}
      fieldStatus={durationStatus}
      autoCorrect={false}
      value={duration.value}
      width={"100px"}
    />
  );
};

export { DurationInput as default };
