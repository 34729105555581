import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  View,
  Text,
  ScrollView,
  SafeAreaView,
  Platform,
} from "react-native";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { QueryClientProvider } from "@tanstack/react-query";
import HorizontalRule from "../components/base/HorizontalRule";
import tailwind from "twrnc";
import CalendarDayHeading from "../components/calendar-screen/CalendarDayHeading";
import { queryClient } from "../components/base/Constants";
import { WebScrollContainer } from "../components/home-screen/WebScrollContainer";
import CalendarDay from "../components/calendar-screen/CalendarDay";
import { theme } from "../signals/signals";

const ProviderCalendarScreen = (props) => {
  dayjs.extend(utc);
  const viewStartDate = dayjs.utc().format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState(viewStartDate);
  const { providerId, providerShortName } = props.route.params;

  const quickDaySelect = (event, date) => {
    setSelectedDate(dayjs(date).format("YYYY-MM-DD"));
    setHeadings(setupHeadings(dayjs(date).format("YYYY-MM-DD"), viewStartDate));
  };

  const setupHeadings = (selectedDate, initialDate) => {
    const headings = [];
    for (let index = 0; index < 31; index++) {
      let upcomingDate = dayjs(initialDate).add(index, "day");
      headings.push({
        label: dayjs(upcomingDate).format("ddd"),
        onPress: (event) => quickDaySelect(event, upcomingDate),
        dayNumber: dayjs(upcomingDate).format("DD"),
        monthNumber: dayjs(upcomingDate).format("MM"),
        selected:
          dayjs(upcomingDate).toString() === dayjs(selectedDate).toString(),
      });
    }
    return headings;
  };

  const [headings, setHeadings] = useState(
    setupHeadings(selectedDate, viewStartDate)
  );

  const calendarTitle =
    providerShortName +
    (providerShortName.substr(providerShortName.length - 1) !== "s"
      ? "'s" + " Calendar"
      : "'" + " Calendar");

  return (
    <SafeAreaView style={tailwind`h-full bg-[${theme.value.backgroundColor}]`}>
      <View
        style={
          Platform.OS === "web"
            ? tailwind`w-full border-b-[2px] border-b-[${theme.value.providerCalendarTopColor}] py-5`
            : tailwind`w-full border-b-[2px] border-b-[${theme.value.providerCalendarTopColor}] py-3`
        }
      >
        <Text
          style={tailwind`self-center text-[${theme.value.textColor}] font-semibold`}
        >
          {calendarTitle}
        </Text>
      </View>
      <View style={tailwind`flex w-full`}>
        <WebScrollContainer
          horizontal
          showsHorizontalScrollIndicator={Platform.OS === "web" ? true : false}
          style={tailwind`ml-2 mr-2`}
        >
          {headings &&
            headings.map((heading) => {
              return (
                <CalendarDayHeading
                  key={heading.dayNumber + heading.monthNumber}
                  label={heading.label}
                  onPress={heading.onPress}
                  dayNumber={heading.dayNumber}
                  selected={heading.selected}
                />
              );
            })}
        </WebScrollContainer>
      </View>
      <View style={tailwind`self-center`}>
        <Text style={tailwind`text-[${theme.value.textColor}]`}>
          {dayjs(selectedDate).format("ddd, DD MMM YYYY")}
        </Text>
      </View>
      <HorizontalRule />
      <ScrollView style={tailwind`mb-4`}>
        <QueryClientProvider client={queryClient}>
          <CalendarDay day={selectedDate} providerId={providerId} />
        </QueryClientProvider>
      </ScrollView>
    </SafeAreaView>
  );
};

ProviderCalendarScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      providerId: PropTypes.number.isRequired,
      providerShortName: PropTypes.string.isRequired,
      initialDate: PropTypes.string,
    }),
  }),
};

export default ProviderCalendarScreen;
