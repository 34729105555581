import React, { useEffect } from "react";
import { ActivityIndicator, Pressable, View } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { API_URL } from "../base/Constants";
import * as DocumentPicker from "expo-document-picker";
import axios from "axios";
import { currentUser } from "../../signals/signals";
import { Signal } from "@preact/signals-react";

const SelfieImageUpload = ({
  existingImageUrl,
  takingSelfie,
  selfieImage,
}: {
  existingImageUrl: Signal;
  takingSelfie: Signal;
  selfieImage: Signal;
}) => {
  useEffect(() => {
    if (selfieImage.value) {
      uploadSelfieImage({
        uri: selfieImage.value.uri,
        name: "selfie.jpg",
        mimeType: "image/jpeg",
        size: selfieImage.value.uri.length,
      });
    }
  }, [selfieImage.value]);

  const convertToPublicUrl = (url: string): string => {
    return url.replace(/\/public\/images\//, API_URL + "images/");
  };

  const uploadSelfieImage = async (singleFile: {
    uri: string;
    name: string;
    mimeType: string;
    size: number;
  }) => {
    const data = new FormData();
    if (singleFile != null) {
      data.append("file_attachment", {
        uri: singleFile.uri,
        name: singleFile.name,
        type: singleFile.mimeType,
        size: singleFile.size,
      });
      data.append("file", singleFile.uri);
      data.append("originalFilename", singleFile.name);

      axios
        .post(API_URL + "users/image", data, {
          headers: {
            Authorization: currentUser.value.token,
          },
        })
        .then((result) => {
          existingImageUrl.value = convertToPublicUrl(result.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const uploadLocalImage = async (
    singleFile: DocumentPicker.DocumentPickerSuccessResult
  ) => {
    const data = new FormData();
    if (singleFile != null) {
      data.append("file_attachment", {
        uri: singleFile.assets[0].uri,
        name: singleFile.output[0].name,
        type: singleFile.output[0].type,
        size: singleFile.output[0].size,
      });
      data.append("file", singleFile.assets[0].uri);
      data.append("originalFilename", singleFile.output[0].name);

      axios
        .post(API_URL + "users/image", data, {
          headers: {
            Authorization: currentUser.value.token,
          },
        })
        .then((result) => {
          existingImageUrl.value = convertToPublicUrl(result.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  async function selectFile() {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        copyToCacheDirectory: false,
        type: "image/*",
      });

      if (result.canceled === false) {
        await uploadLocalImage(result);
      }
    } catch (err) {
      console.warn(err);
      return false;
    }
  }

  //   const checkPermissionsAndroid = async () => {
  //     try {
  //       const result = await PermissionsAndroid.check(
  //         PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE
  //       );

  //       if (!result) {
  //         const granted = await PermissionsAndroid.request(
  //           PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
  //           {
  //             title:
  //               "You need to give storage permission to download and save the file",
  //             message: "App needs access to your camera ",
  //             buttonNeutral: "Ask Me Later",
  //             buttonNegative: "Cancel",
  //             buttonPositive: "OK",
  //           }
  //         );
  //         if (granted === PermissionsAndroid.RESULTS.GRANTED) {
  //           console.log("You can use the camera");
  //           return true;
  //         } else {
  //           Alert.alert("Error", "PERMISSION_ACCESS_FILE");

  //           console.log("Camera permission denied");
  //           return false;
  //         }
  //       } else {
  //         return true;
  //       }
  //     } catch (err) {
  //       console.warn(err);
  //       return false;
  //     }
  //   };

  return (
    <View style={tailwind`flex-row px-2`}>
      <Image
        style={tailwind`w-[118px] h-[164px] rounded-lg`}
        source={{ uri: existingImageUrl.value }}
        PlaceholderContent={<ActivityIndicator />}
      />
      <View style={tailwind`ml-2 pt-4`}>
        <Pressable
          style={tailwind`w-10 h-10 py-[6px] mx-1 mr-4 mb-2`}
          onPress={selectFile}
        >
          <View style={tailwind`self-center`}>
            <Image
              style={tailwind`w-[32px] h-[32px]`}
              source={require("../../assets/upload-icon.png")}
              PlaceholderContent={<ActivityIndicator />}
            />
          </View>
        </Pressable>
        <Pressable
          style={tailwind`w-10 h-10 py-[6px] mx-1 mr-4`}
          onPress={() => (takingSelfie.value = true)}
        >
          <View style={tailwind`self-center`}>
            <Image
              style={tailwind`w-[32px] h-[32px]`}
              source={require("../../assets/camera-icon.png")}
              PlaceholderContent={<ActivityIndicator />}
            />
          </View>
        </Pressable>
      </View>
    </View>
  );
};

export { SelfieImageUpload as default };
