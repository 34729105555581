import React from "react";
import PropTypes from "prop-types";
import {
  useFonts,
  Roboto_700Bold,
  Roboto_400Regular,
} from "@expo-google-fonts/roboto";
import { Inter_400Regular } from "@expo-google-fonts/inter";
import {
  ActivityIndicator,
  Dimensions,
  Platform,
  ScrollView,
  Text,
  View,
} from "react-native";
import { useQuery, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import Tag from "../base/Tag";
import ConnectModal from "../modals/ConnectModal";
import { API_URL, DEFAULT_PROFILE_URL, queryClient } from "../base/Constants";
import Button from "../base/Button";
import { useNavigation } from "@react-navigation/native";
import { currentUser, focusedScreen, profileMessages, theme } from "../../signals/signals";
import ApiError from "../error/ApiError";

const UserProfilePanel = ({ id }) => {
  let [fontsLoaded] = useFonts({
    Roboto_700Bold,
    Roboto_400Regular,
    Inter_400Regular,
  });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Panel id={id} />
    </QueryClientProvider>
  );
};

UserProfilePanel.propTypes = {
  id: PropTypes.number.isRequired,
};

function Panel({ id }) {
  const navigation = useNavigation();

  const { isLoading, error, data } = useQuery(["user" + id], () =>
    axios
      .get(API_URL + "users/" + id, {
        staleTime: 0,
        headers: { authorization: currentUser.value.token },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err))
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const userId = data.id;
  const userType = data.type.id;
  const firstName = data.firstName;
  const shortName = firstName + " " + data.lastName;
  const userTags = data.tags;
  const isProfileOwner = data.id === currentUser.value.id;
  const isMobileWebFormat = Platform.OS === "web" && Dimensions.get("screen").width < 768;

  let key = 0;
  return (
    <View style={tailwind`flex-col w-full h-full`}>
      {profileMessages.value.length && isProfileOwner
        ? profileMessages.value.map((message) => {
          return (
            <View
              key={++key}
              style={tailwind`flex-row self-center bg-[${theme.value.warningMessageBackgroundColor}] rounded-full py-2 px-3 mb-1`}
            >
              <Image
                resizeMode="contain"
                style={tailwind`h-[18px] w-[18px]`}
                source={require("../../assets/warning-icon.png")}
                tintColor={theme.value.redColor}
              />
              <Text
                style={tailwind`text-[${theme.value.redColor}] font-semibold ml-2`}
              >
                {message.message}
              </Text>
            </View>
          );
        })
        : null}
      <View>
        <View style={tailwind`flex-row px-4 pt-4`}>
          <View style={tailwind`w-1/3`}>
            <View style={tailwind`w-32`}>
              <Image
                style={
                  Platform.OS === "web"
                    ? tailwind`w-[118px] h-[164px] rounded-lg`
                    : tailwind`w-[59px] h-[82px] rounded-lg`
                }
                source={{ uri: data.profileUrl || DEFAULT_PROFILE_URL }}
                PlaceholderContent={<ActivityIndicator />}
              />
            </View>
            <View style={tailwind`flex-col pr-4`}>
              <View>
                <View style={tailwind`self-start`}>
                  <Text
                    style={[
                      tailwind`capitalize text-[${theme.value.textColor}] font-bold text-[18px] mt-3`,
                      { fontFamily: "Roboto_700Bold" },
                    ]}
                  >
                    {shortName}
                  </Text>
                </View>
                <View style={tailwind`flex-row max-w-[118px]`}>
                  <View style={tailwind`w-1/2`}>
                    <View style={tailwind`self-end`}>
                      <Image
                        resizeMode="contain"
                        style={tailwind`h-[18px] w-[18px]`}
                        source={require("../../assets/connections-icon.png")}
                      />
                      <Text
                        style={[
                          tailwind`font-bold text-[${theme.value.textColor}] self-end`,
                          { fontFamily: "Roboto_400Regular" },
                        ]}
                      >
                        0
                      </Text>
                    </View>
                  </View>
                  <View style={tailwind`w-1/2`}>
                    <View style={tailwind`self-end`}>
                      <Image
                        resizeMode="contain"
                        style={tailwind`h-[18px] w-[18px]`}
                        source={require("../../assets/followers-icon.png")}
                      />
                      <Text
                        style={[
                          tailwind`font-bold text-[${theme.value.textColor}] self-end`,
                          { fontFamily: "Roboto_400Regular" },
                        ]}
                      >
                        0
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>

      <ScrollView>
        <View style={tailwind`px-4`}>
          <Text
            style={[
              tailwind`text-[${theme.value.textColor}] font-semibold py-2`,
              { fontFamily: "Inter_400Regular" },
            ]}
          >
            {data.profileText}
          </Text>
        </View>
        <View style={tailwind`px-4 my-2`}>
          <View style={tailwind`flex flex-row mt-2 flex-wrap`}>
            {userTags.map((userTag) => (
              <Tag
                key={userTag.id}
                label={userTag.tagId.tagName}
                colorKey={userTag.tagId.tagColorKey}
              />
            ))}
          </View>
        </View>
      </ScrollView>
      {isProfileOwner ? (
        <View style={tailwind`self-center my-2`}>
          <Button
            title="Edit"
            onPress={() => {
              focusedScreen.value = "EditProfileScreen";
              navigation.navigate("EditProfileScreen", {
                userId: userId,
                userType: userType,
              });
            }}
          />
        </View>
      ) : null}
      <ConnectModal
        id={id}
        firstName={firstName}
        profileUrl={data.profileUrl || DEFAULT_PROFILE_URL}
      />
    </View>
  );
}

Panel.propTypes = {
  id: PropTypes.number.isRequired,
};

export { UserProfilePanel as default };
