import React, { useContext } from "react";
import PropTypes from "prop-types";
import { EVENT_TYPE_BOOKABLE } from "../base/Constants";
import { ActivityIndicator, Dimensions, Text, View } from "react-native";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import ViewEvent from "./ViewEvent";
import tailwind from "twrnc";
import { Calendar } from "react-native-big-calendar";
import CalendarEvent from "./CalendarEvent";
import { BottomSheetContext } from "../base/ApplicationContext";
import { currentUser, theme } from "../../signals/signals";
import { localizeDateTime } from "../helpers/timeZones";
import ApiError from "../error/ApiError";
import useProviderSchedule from "../custom-hooks/database/useProviderSchedule";
import useUserSchedule from "../custom-hooks/database/useUserSchedule";

const CalendarDay = ({ day, providerId }) => {
  dayjs.extend(tz);
  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);

  const id = currentUser.value.id;

  let apiResponse: { isLoading: boolean; error: any; data: any };
  if (providerId) {
    apiResponse = useProviderSchedule(id, providerId, day);
  } else {
    apiResponse = useUserSchedule(id, day);
  }
  const { isLoading, error, data } = apiResponse;
  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  let parsedData = data.map((event) => {
    let isBooked = false;
    if (
      event.bookings &&
      event.bookings.length &&
      event.bookings[0] &&
      event.bookings[0].payments &&
      event.bookings[0].payments.length
    ) {
      if (dayjs.tz(event.bookings[0].eventDate).format("YYYY-MM-DD") === day)
        event.bookings[0].payments.forEach((payment) => {
          if (payment.responses) {
            payment.responses.forEach((response) => {
              if (
                response.state === "COMPLETED" ||
                response.state === "AUTHORISED"
              ) {
                isBooked = true;
              }
            });
          }
        });
    }

    return {
      title: event.shortDescription,
      start: localizeDateTime(event.startDate).toDate(),
      end: localizeDateTime(event.startDate)
        .add(event.duration, "minute")
        .toDate(),
      event: event,
      type: event.type.id,
      isPublic: event.isPublic,
      isBooked: isBooked,
    };
  });

  parsedData = parsedData.filter(
    (calendarEvent) =>
      calendarEvent.type !== EVENT_TYPE_BOOKABLE ||
      (calendarEvent.type === EVENT_TYPE_BOOKABLE &&
        calendarEvent.isPublic === true)
  );

  const openModal = (event) => {
    setBottomSheetContent(<ViewEvent event={event} />);
    setBottomSheetVisible(true);
  };

  return (
    <View style={tailwind`mx-2`}>
      <Calendar
        hourStyle={{ backgroundColor: theme.value.primaryColor }}
        date={day}
        showTime={true}
        scrollOffsetMinutes={360}
        renderEvent={CalendarEvent}
        swipeEnabled={false}
        renderHeader={() => null}
        events={parsedData}
        height={Dimensions.get("window").height - 80}
        sortedMonthView={false}
        mode={"day"}
        onPressEvent={(data) => {
          openModal(data.event);
        }}
      />
    </View>
  );
};

CalendarDay.propTypes = {
  day: PropTypes.string.isRequired,
  providerId: PropTypes.number,
};

export { CalendarDay as default };
