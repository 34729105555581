import { useSignal } from "@preact/signals-react";
import PropTypes from "prop-types";
import { View } from "react-native";
import tailwind from "twrnc";
import $ from "jquery";
import { useEffect, useRef } from "react";
import { currentUser, localStream } from "../../../signals/signals";
import RTCStreamVideoControls from "./RTCStreamVideoControls";

const LocalStreamWrapper = ({ userId }) => {
  const videoRef = useRef(null);
  const isMicMuted = useSignal(false);
  const isSpeakerMuted = useSignal(false);
  const isVideoShown = useSignal(true);
  const isVideoHovered = useSignal(false);
  const videoStyle = useSignal(tailwind`w-0 h-0`);

  useEffect(() => {
    if (localStream.value) {
      videoRef.current.srcObject = localStream.value;

      if (userId === currentUser.value.id) {
        isSpeakerMuted.value = true;
      } else {
        isSpeakerMuted.value = false;
      }
      videoRef.current.play();
    }
  }, [localStream.value]);

  useEffect(() => {
    let videoStream = $("#" + userId)[0];
    if (videoStream) {
      videoStream.muted = isSpeakerMuted.value;
    }
  }, [isSpeakerMuted.value]);

  useEffect(() => {
    if (localStream.value) {
      if (isMicMuted.value) {
        localStream.value.getAudioTracks()[0].enabled = false;
      } else {
        localStream.value.getAudioTracks()[0].enabled = true;
      }
    }
  }, [isMicMuted.value]);

  useEffect(() => {
    if (localStream.value) {
      if (isVideoShown.value) {
        localStream.value.getVideoTracks()[0].enabled = true;
        videoStyle.value = tailwind`w-[150px] h-[113px]`;
      } else {
        localStream.value.getVideoTracks()[0].enabled = false;
        videoStyle.value = tailwind`w-0 h-0`;
      }
    }
  }, [isVideoShown.value]);

  const handleMouseOver = () => {
    isVideoHovered.value = true;
    setTimeout(() => (isVideoHovered.value = false), 4000);
  };

  return (
    <View style={tailwind`w-full`}>
      {!isVideoShown.value ? (
        <View style={tailwind`w-[150px] h-[113px]`}></View>
      ) : (
        <video
          ref={videoRef}
          id={userId}
          autoPlay
          playsInline
          style={videoStyle.value}
          onMouseOver={handleMouseOver}
          onFocus={handleMouseOver}
        />
      )}

      <View style={[tailwind`absolute bottom-0 left-0`]}>
        {isVideoHovered.value || isMicMuted.value || !isVideoShown.value ? (
          <View style={tailwind`w-full`}>
            <RTCStreamVideoControls
              videoRef={videoRef}
              userId={userId}
              isMicMuted={isMicMuted}
              isSpeakerMuted={isSpeakerMuted}
              isVideoShown={isVideoShown}
            />
          </View>
        ) : null}
      </View>
    </View>
  );
};

LocalStreamWrapper.propTypes = {
  userId: PropTypes.number.isRequired,
};

export { LocalStreamWrapper as default };
