import React from "react";
import PropTypes from "prop-types";
import LiveThumbnail from "./LiveThumbnail";
import { QueryClientProvider } from "@tanstack/react-query";
import { ActivityIndicator, Text, View } from "react-native";
import { queryClient } from "../../base/Constants";
import { currentUser } from "../../../signals/signals";
import ApiError from "../../error/ApiError";
import useHomePageLiveSection from "../../custom-hooks/database/useHomePageLiveSection";
import NoLiveContentThumbnail from "./NoLiveContentThumbnail";

const LiveThumbnailStrip = ({ section }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <LiveStrip sectionId={section.id} userId={currentUser.value.id} />
    </QueryClientProvider>
  );
};

LiveThumbnailStrip.propTypes = {
  section: PropTypes.object.isRequired,
};

const LiveStrip = ({ sectionId, userId }) => {
  const { isLoading, error, data } = useHomePageLiveSection(sectionId, userId);
  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  if (data.length) {
    return (
      <>
        {data.map((liveEvent) => (
          <LiveThumbnail
            key={liveEvent.id + liveEvent.startDate}
            event={liveEvent}
          />
        ))}
      </>
    );
  } else {
    return <NoLiveContentThumbnail />;
  }
};

LiveStrip.propTypes = {
  sectionId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};

export default LiveThumbnailStrip;
