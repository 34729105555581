import { View } from "react-native";
import tailwind from "twrnc";
import {
  EVENT_TYPE_BOOKABLE,
  EVENT_TYPE_BROADCAST,
  EVENT_TYPE_PRIVATE,
} from "../base/Constants";
import { theme } from "../../signals/signals";

const EventHeaderBar = ({ type }) => {
  let headerColour: string;
  switch (type) {
    case EVENT_TYPE_BOOKABLE:
      headerColour = theme.value.calendarEventBookableColor;
      break;
    case EVENT_TYPE_PRIVATE:
      headerColour = theme.value.calendarEventPrivateColor;
      break;
    case EVENT_TYPE_BROADCAST:
      headerColour = theme.value.calendarEventBroadcastColor;
      break;
    default:
      headerColour = theme.value.calendarEventOtherColor;
      break;
  }

  return (
    <View
      style={tailwind`border-t-[${headerColour}] border-t-[4px] p-1 min-h-[15px]`}
    />
  );
};

export { EventHeaderBar as default };
