import PropTypes from "prop-types";
import { Signal, useSignal } from "@preact/signals-react";
import { useNavigation } from "@react-navigation/native";
import {
  bodyTagSelection,
  currentUser,
  focusedScreen,
  isProfileComplete,
  profileMessages,
  theme,
  themes,
  userTagSelection,
} from "../../signals/signals";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  API_URL,
  COUNTRY_ID_UK,
  DEFAULT_PROFILE_URL,
  TREE_TYPE_BODY,
  TREE_TYPE_USER,
  USER_TYPE_AMBASSADOR,
  USER_TYPE_PROVIDER,
  USER_TYPE_USER,
  queryClient,
} from "../base/Constants";
import {
  ActivityIndicator,
  Dimensions,
  Platform,
  SafeAreaView,
  ScrollView,
  Text,
  View,
} from "react-native";
import tailwind from "twrnc";
import { Image } from "@rneui/themed";
import { StyledInput } from "../base/StyledInput";
import TagSelector from "../tags/TagSelector";
import HorizontalRule from "../base/HorizontalRule";
import Button from "../base/Button";
import { evaluateProfileWarningMessages } from "../base/ApplicationContainer";
import { configureTheme } from "../base/themeHandler";
import TakeSelfie from "./TakeSelfie";
import SelfieImageUpload from "./SelfieImageUpload";
import { EditPersonalDetailsForm } from "./EditPersonalDetailsForm";
import { ChangePasswordForm } from "./ChangePasswordForm";
import PersonalTrainingSettings from "./PersonalTrainingSettings";
import PersonalTrainingSettingsNative from "./PersonalTrainingSettingsNative";
import { BillingSettings } from "./BillingSettings";
import { BillingSettings as BillingSettingsNative } from "./BillingSettings.native";
import { useEffect } from "react";
import {
  analyzeProfileText,
  analyzeTagSelections,
} from "../helpers/validation";
import ApiError from "../error/ApiError";
import { setTagSelections } from "../helpers/tagProcessor";

const EditProfileContainer = ({ userId, userType }) => {
  interface NavigationParams {
    id: number;
    type: number;
  }
  const editingPassword = useSignal("");
  const editingPasswordStatus = useSignal("");

  const editingConfirmPassword = useSignal("");
  const editingConfirmPasswordStatus = useSignal("");

  const existingPassword = useSignal("");

  const editingFirstName = useSignal("");
  const editingFirstNameStatus = useSignal("");

  const editingLastName = useSignal("");
  const editingLastNameStatus = useSignal("");

  const editingProfileText = useSignal("");
  const editingProfileTextStatus = useSignal("");

  const editingProfileUrl = useSignal(DEFAULT_PROFILE_URL);

  const editingPhoneNumber = useSignal("");
  const editingPhoneNumberStatus = useSignal("");

  const editingBaseCost = useSignal("");
  const editingBaseCostStatus = useSignal("");

  const editingAdditionalCost = useSignal("0");
  const editingAdditionalCostStatus = useSignal("");

  const editingClassDuration = useSignal("");
  const editingClassDurationStatus = useSignal("");

  const takingSelfie = useSignal(false);
  const selfieImage = useSignal(null);

  const editingIsProviding = useSignal(false);
  const editingAddressLine1 = useSignal("");
  const editingAddressLine1Status = useSignal("");

  const editingAddressLine2 = useSignal("");
  const editingAddressLine2Status = useSignal("");

  const editingCity = useSignal("");
  const editingCityStatus = useSignal("");
  const editingCountry = useSignal("");
  const editingUnitedState = useSignal("");

  const editingPostalCode = useSignal("");
  const editingPostalCodeStatus = useSignal("");

  const editingSelectedTheme = useSignal(currentUser.value.theme.id);

  const userTagStatus = useSignal("");
  const bodyTagStatus = useSignal("");

  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;

  const SaveChanges = async () => {
    UpdatePersonalDetails(
      userId,
      editingFirstName.value,
      editingLastName.value,
      editingProfileText.value,
      editingProfileUrl.value,
      editingPhoneNumber.value,
      editingPassword.value,
      existingPassword.value,
      editingBaseCost.value,
      editingAdditionalCost.value,
      editingClassDuration.value,
      editingIsProviding.value,
      editingAddressLine1.value,
      editingAddressLine2.value,
      editingCity.value,
      editingCountry.value,
      editingUnitedState.value,
      editingPostalCode.value,
      editingSelectedTheme.value
    );
    await SaveTagSelection(userId);
    configureTheme(editingSelectedTheme.value, themes.value);
    queryClient.invalidateQueries(["trainer/" + userId]);
    queryClient.invalidateQueries(["users/" + userId]);
    focusedScreen.value = "ViewProfileScreen";
    navigation.navigate({
      name: "ViewProfileScreen",
      params: {
        id: userId,
        type: userType,
      },
    } as never);
  };

  const UpdatePersonalDetails = async (
    userId: number,
    firstName: string,
    lastName: string,
    profileText: string,
    profileUrl: string,
    phoneNumber: string,
    newPassword: string,
    existingPassword: string,
    baseCost: string,
    additionalCost: string,
    classDuration: string,
    isProviding: boolean,
    addressLine1: string,
    addressLine2: string,
    city: string,
    country: string,
    unitedState: string,
    postalCode: string,
    theme: number
  ) => {
    await axios
      .patch(
        API_URL + "users/" + userId,
        {
          firstName: firstName,
          lastName: lastName,
          profileText: profileText,
          profileUrl: profileUrl,
          phoneNumber: phoneNumber,
          newPassword: newPassword,
          existingPassword: existingPassword,
          baseCost: parseFloat(baseCost),
          classDuration: parseInt(classDuration),
          isProvidingPersonalTraining: isProviding,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          city: city,
          country: country,
          unitedState: unitedState,
          postalCode: postalCode,
          theme: theme,
        },
        {
          headers: { authorization: currentUser.value.token },
        }
      )
      .then((response) => {
        const updatedUser = response.data.raw;

        const newCurrentUser = {
          ...currentUser.value,
          currency: updatedUser.currencyId,
          currencySymbol: updatedUser.currencySymbol,
          baseCost: updatedUser.baseCost,
          classDuration: updatedUser.classDuration,
          profileUrl: updatedUser.profileUrl,
          isProfileCompleted: updatedUser.isProfileCompleted,
          theme: updatedUser.theme,
        };
        isProfileComplete.value = updatedUser.isProfileCompleted;
        evaluateProfileWarningMessages({
          isProfileCompleted: updatedUser.isProfileCompleted,
          profileUrl: updatedUser.profileUrl,
          currencyId: updatedUser.currencyId,
          addressLine1,
          addressLine2,
          city,
          country,
          postalCode,
          type: currentUser.value.type,
          isEmailVerified: updatedUser.isEmailVerified,
        });
        currentUser.value = newCurrentUser;
      });
  };

  const SaveTagSelection = async (userId) => {
    const toBeSaved = [
      ...userTagSelection.value.filter((tag) => !tag.deleted),
      ...bodyTagSelection.value.filter((tag) => !tag.deleted),
    ];
    await axios.patch(
      API_URL + "users/tags/" + userId,
      {
        tags: toBeSaved,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: currentUser.value.token,
        },
      }
    );
  };

  useEffect(() => {
    analyzeTagSelections(TREE_TYPE_USER, userTagStatus);
    analyzeTagSelections(TREE_TYPE_BODY, bodyTagStatus);
  }, []);

  const canSave = useSignal(false);
  useEffect(() => {
    canSave.value =
      editingFirstNameStatus.value === "" &&
      editingLastNameStatus.value === "" &&
      editingPhoneNumberStatus.value === "" &&
      editingPasswordStatus.value === "" &&
      editingBaseCostStatus.value === "" &&
      editingClassDurationStatus.value === "" &&
      editingAddressLine1Status.value === "" &&
      editingAddressLine2Status.value === "" &&
      editingCityStatus.value === "" &&
      editingPostalCodeStatus.value === "" &&
      userTagStatus.value === "";
  }, [
    editingFirstNameStatus.value,
    editingLastNameStatus.value,
    editingPhoneNumberStatus.value,
    editingPasswordStatus.value,
    editingBaseCostStatus.value,
    editingClassDurationStatus.value,
    editingAddressLine1Status.value,
    editingAddressLine2Status.value,
    editingCityStatus.value,
    editingPostalCodeStatus.value,
    userTagStatus.value,
  ]);

  const { isLoading, error, data } = useQuery(["users" + userId], () =>
    axios
      .get(API_URL + "users/" + userId, {
        headers: { authorization: currentUser.value.token },
      })
      .then((res) => {
        const {
          firstName,
          lastName,
          tags,
          profileUrl,
          profileText,
          classDuration,
          baseCost,
          additionalCost,
          phoneNumber,
          isProvidingPersonalTraining,
          addressLine1,
          addressLine2,
          city,
          country,
          unitedState,
          postalCode,
        } = res.data;

        const selectedTags = tags.map((tag) => {
          return {
            id: tag.tagId.id,
            type: tag.tagId.descendantClosure[0].ancestor.id,
          };
        });

        setTagSelections(selectedTags);
        editingFirstName.value = firstName;
        editingLastName.value = lastName;
        editingProfileText.value = profileText;
        editingProfileUrl.value = profileUrl;
        editingClassDuration.value = classDuration.toString();
        editingBaseCost.value = baseCost.toString();
        editingAdditionalCost.value = additionalCost.toString();
        editingPhoneNumber.value = phoneNumber;
        editingIsProviding.value = isProvidingPersonalTraining;
        editingAddressLine1.value = addressLine1;
        editingAddressLine2.value = addressLine2;
        editingCity.value = city;
        editingCountry.value = (country && country.id) || COUNTRY_ID_UK;
        editingUnitedState.value = (unitedState && unitedState.id) || 0;
        editingPostalCode.value = postalCode;
        return res.data;
      })
  );

  const navigation = useNavigation();

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  let key = 0;
  return (
    <SafeAreaView
      style={tailwind`flex h-full bg-[${theme.value.backgroundColor}]`}
    >
      {profileMessages.value.length
        ? profileMessages.value.map((message) => {
            return (
              <View
                key={++key}
                style={tailwind`flex-row self-center bg-[${theme.value.warningMessageBackgroundColor}] rounded-full py-2 px-3 mb-1`}
              >
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[18px] w-[18px]`}
                  source={require("../../assets/warning-icon.png")}
                  tintColor={theme.value.redColor}
                />
                <Text
                  style={tailwind`text-[${theme.value.redColor}] font-semibold ml-2`}
                >
                  {message.message}
                </Text>
              </View>
            );
          })
        : null}
      <ScrollView style={tailwind`w-full self-center`}>
        {takingSelfie.value ? (
          <TakeSelfie selfieImage={selfieImage} takingSelfie={takingSelfie} />
        ) : null}
        <View style={tailwind`flex-row flex-wrap self-center`}>
          <View style={tailwind`self-center self-start`}>
            <SelfieImageUpload
              existingImageUrl={editingProfileUrl}
              takingSelfie={takingSelfie}
              selfieImage={selfieImage}
            />
          </View>
          <View style={tailwind`self-center mb-4`}>
            <EditPersonalDetailsForm
              emailAddress={currentUser.value.emailAddress}
              firstName={editingFirstName}
              firstNameStatus={editingFirstNameStatus}
              lastName={editingLastName}
              lastNameStatus={editingLastNameStatus}
              phoneNumber={editingPhoneNumber}
              phoneNumberStatus={editingPhoneNumberStatus}
            />
          </View>
        </View>
        <View style={tailwind`self-center mb-4`}>
          <View
            style={tailwind`${
              isMobileWebFormat ? "self-start ml-2" : "self-center"
            } mb-4`}
          >
            <StyledInput
              label={"About You"}
              multiline={true}
              placeholder={"Write a few words to describe yourself..."}
              value={editingProfileText.value}
              height={"120px"}
              width={isMobileWebFormat ? "350px" : "620px"}
              onChangeText={(text) => {
                editingProfileText.value = text;
                analyzeProfileText(
                  editingProfileText,
                  editingProfileTextStatus
                );
              }}
              autoCorrect={true}
              onBlur={() =>
                analyzeProfileText(editingProfileText, editingProfileTextStatus)
              }
              fieldStatus={editingProfileTextStatus}
            />
          </View>
          <View style={tailwind`mb-2 mt-4 ml-2`}>
            <Text
              style={[
                tailwind`text-[20px] text-[${theme.value.textColor}] w-1/2`,
                { fontFamily: "Roboto_700Bold" },
              ]}
            >
              Content Filters
            </Text>
          </View>
          <View style={tailwind`self-center mx-2 mb-2`}>
            <TagSelector tagType={TREE_TYPE_USER} fieldStatus={userTagStatus} />
            {userTagStatus.value ? (
              <View style={tailwind`mb-2`}>
                <Text
                  style={[
                    tailwind`text-xs ml-2 text-[${theme.value.redColor}]`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  {userTagStatus.value}
                </Text>
              </View>
            ) : null}
          </View>
          {userType === USER_TYPE_USER ? (
            <View style={tailwind`self-center mx-2 mb-2`}>
              <TagSelector
                tagType={TREE_TYPE_BODY}
                fieldStatus={bodyTagStatus}
              />
            </View>
          ) : null}
        </View>
        {userType === USER_TYPE_PROVIDER ||
        userType === USER_TYPE_AMBASSADOR ? (
          <>
            <HorizontalRule />
            <View style={tailwind`mt-2`}>
              {Platform.OS === "web" ? (
                <View style={tailwind`self-center mt-2`}>
                  <PersonalTrainingSettings
                    baseCost={editingBaseCost}
                    baseCostStatus={editingBaseCostStatus}
                    classDuration={editingClassDuration}
                    classDurationStatus={editingClassDurationStatus}
                    isProviding={editingIsProviding}
                  />
                </View>
              ) : (
                <View style={tailwind`mt-4`}>
                  <PersonalTrainingSettingsNative
                    baseCost={editingBaseCost}
                    baseCostStatus={editingBaseCostStatus}
                    classDuration={editingClassDuration}
                    classDurationStatus={editingClassDurationStatus}
                    isProviding={editingIsProviding}
                  />
                </View>
              )}
            </View>
          </>
        ) : null}
        <HorizontalRule />
        {Platform.OS === "web" ? (
          <View style={tailwind`self-center mt-2`}>
            <BillingSettings
              addressLine1={editingAddressLine1}
              addressLine1Status={editingAddressLine1Status}
              addressLine2={editingAddressLine2}
              addressLine2Status={editingAddressLine2Status}
              city={editingCity}
              cityStatus={editingCityStatus}
              country={editingCountry}
              unitedState={editingUnitedState}
              postalCode={editingPostalCode}
              postalCodeStatus={editingPostalCodeStatus}
            />
          </View>
        ) : (
          <View style={tailwind`mt-4`}>
            <BillingSettingsNative
              addressLine1={editingAddressLine1}
              addressLine1Status={editingAddressLine1Status}
              addressLine2={editingAddressLine2}
              addressLine2Status={editingAddressLine2Status}
              city={editingCity}
              cityStatus={editingCityStatus}
              country={editingCountry}
              unitedState={editingUnitedState}
              postalCode={editingPostalCode}
              postalCodeStatus={editingPostalCodeStatus}
            />
          </View>
        )}
        <HorizontalRule />
        <View style={tailwind`mt-6 mb-4`}>
          <View
            style={tailwind`${
              Platform.OS === "web" ? "self-center" : "self-start"
            }`}
          >
            <ChangePasswordForm
              existingPassword={existingPassword}
              newPassword={editingPassword}
              confirmPassword={editingConfirmPassword}
              newPasswordStatus={editingPasswordStatus}
              confirmPasswordStatus={editingConfirmPasswordStatus}
            />
          </View>
        </View>
        <HorizontalRule />
        <View style={tailwind`self-center mb-6`}>
          <Button
            title="Save"
            onPress={() => SaveChanges()}
            disabled={!canSave.value}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

EditProfileContainer.propTypes = {
  userId: PropTypes.number.isRequired,
  userType: PropTypes.number.isRequired,
};

export { EditProfileContainer as default };
