import React, { Pressable, Text, View } from "react-native";
import PropTypes from "prop-types";
import {
  useFonts,
  Roboto_700Bold,
  Roboto_400Regular,
} from "@expo-google-fonts/roboto";
import { Inter_400Regular, Inter_500Medium } from "@expo-google-fonts/inter";
import tailwind from "twrnc";

import { Image } from "@rneui/themed";
import { focusedScreen, isEmailAddressVerified, theme } from "../../signals/signals";

const PersonalTrainingCostsPanel = ({
  id,
  firstName,
  shortName,
  data,
  isProfileOwner,
  navigation,
}) => {
  let [fontsLoaded] = useFonts({
    Roboto_700Bold,
    Roboto_400Regular,
    Inter_400Regular,
    Inter_500Medium,
  });

  if (!fontsLoaded) {
    return null;
  }

  if (!data.currency) return null;

  const handleProviderCalendarPress = () => {
    if (isProfileOwner) {
      focusedScreen.value = "CalendarScreen";
      navigation.navigate("CalendarScreen");
    } else {
      focusedScreen.value = "ProviderCalendarScreen";
      navigation.navigate("ProviderCalendarScreen", {
        providerId: id,
        providerShortName: shortName,
      });
    }
  };

  return (
    <View style={tailwind`self-center w-full max-w-[800px] mt-4 px-4`}>
      <Text
        style={[
          tailwind`capitalize text-[${theme.value.textColor}] font-bold text-[18px] mt-3 mb-4`,
          { fontFamily: "Roboto_700Bold" },
        ]}
      >
        Personal Training costs for {firstName}
      </Text>
      <View style={tailwind`flex-row`}>
        <Text
          style={[
            tailwind`text-[${theme.value.textColor}] w-1/2 font-bold`,
            { fontFamily: "Inter_500Medium" },
          ]}
        >
          Price per session
        </Text>
        <Text
          style={[
            tailwind`text-[${theme.value.textColor}] w-1/2 font-semibold`,
            { fontFamily: "Inter_400Regular" },
          ]}
        >
          {data.currency.symbol}
          {data.baseCost.toFixed(2)}
        </Text>
      </View>
      <View style={tailwind`flex-row`}>
        <Text
          style={[
            tailwind`text-[${theme.value.textColor}] w-1/2 font-bold`,
            { fontFamily: "Inter_500Medium" },
          ]}
        >
          Session duration
        </Text>
        <Text
          style={[
            tailwind`text-[${theme.value.textColor}] w-1/2 font-semibold`,
            { fontFamily: "Inter_400Regular" },
          ]}
        >
          {data.classDuration} minutes
        </Text>
      </View>
      {!isProfileOwner && isEmailAddressVerified.value ? (
        <View style={tailwind`mt-4 p-2`}>
          <Text
            style={[
              tailwind`text-[${theme.value.textColor}] font-semibold mb-4 self-center`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            Book a personal training session with {firstName}
          </Text>
          <Pressable
            onPress={handleProviderCalendarPress}
            style={tailwind`self-center`}
          >
            <View
              style={tailwind`border-[1px] border-[${theme.value.calendarAddIconBorderColor}] bg-[${theme.value.backgroundColor}] rounded-full p-3`}
            >
              <Image
                resizeMode="contain"
                style={tailwind`h-[18px] w-[18px]`}
                source={require("../../assets/calendar-plus-icon.png")}
              />
            </View>
          </Pressable>
        </View>
      ) : null}
    </View>
  );
};

PersonalTrainingCostsPanel.propTypes = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  isProfileOwner: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    baseCost: PropTypes.number.isRequired,
    classDuration: PropTypes.number.isRequired,
    currency: PropTypes.shape({
      symbol: PropTypes.string.isRequired,
    }),
    sessionBundles: PropTypes.array,
  }).isRequired,
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }),
};

export { PersonalTrainingCostsPanel as default };
