import { Modal, Platform, Text, View } from "react-native";
import tailwind from "twrnc";
import { isNotificationServerConnected, theme } from "../../signals/signals";

const NotificationServerDisonnectedModal = () => {
  const modalWidth = Platform.OS === "web" ? "50%" : "100%";

  return (
    <View style={tailwind`justify-center`}>
      <Modal
        visible={!isNotificationServerConnected.value}
        animationType="slide"
        transparent={true}
      >
        <View
          style={tailwind`rounded-lg h-auto p-6 self-center bg-[${theme.value.modalBackgroundColor}] border-[0.5px] border-[${theme.value.textColor}] flex-col w-[${modalWidth}] max-h-[400px] absolute top-40`}
        >
          <View style={tailwind`self-center`}>
            <Text
              style={tailwind`text-[${theme.value.textColor}] font-bold text-sm mt-3 self-center`}
            >
              Unable to communicate with the notification service
            </Text>
            <Text
              style={tailwind`text-[${theme.value.textColor}] font-bold text-sm mt-3 self-center`}
            >
              Please check your internet connection
            </Text>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export { NotificationServerDisonnectedModal as default };
