import { Dimensions, SafeAreaView, ScrollView, Text, View } from "react-native";
import DateTimeInput from "../../form/events/DateTimeInput";
import tailwind from "twrnc";
import DurationInput from "../../form/events/DurationInput";
import { currentUser, theme } from "../../../signals/signals";
import EventHeaderBar from "../EventHeaderBar";
import {
  API_URL,
  EVENT_TYPE_BOOKABLE,
  queryClient,
} from "../../base/Constants";
import { useSignal } from "@preact/signals-react";
import dayjs from "dayjs";
import { useContext, useEffect } from "react";
import { BottomSheetContext } from "../../base/ApplicationContext";
import {
  analyzeDuration,
  analyzeSalePrice,
  analyzeTime,
} from "../../helpers/validation";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import PriceInput from "../../form/events/PriceInput";
import Button from "../../base/Button";
import axios from "axios";
import ViewEvent from "../../calendar-screen/ViewEvent";
import DatePicker from "react-native-neat-date-picker";
import { localTimeToUTC, localizeDateTime } from "../../helpers/timeZones";

const NewOpenEvent = () => {
  const mode = useSignal("date");
  const startDate = useSignal(
    localizeDateTime(dayjs().format("YYYY-MM-DDTHH:mm:[00]Z"))
  );
  const time = useSignal("");
  const timeStatus = useSignal("");
  const duration = useSignal("0");
  const durationStatus = useSignal("");
  const price = useSignal("0"); //todo get value from profile
  const priceStatus = useSignal("");
  const showDatePicker = useSignal(false);
  const canSave = useSignal(false);

  const { setBottomSheetVisible, setBottomSheetContent } =
    useContext(BottomSheetContext);

  const onCancel = () => {
    showDatePicker.value = false;
  };

  const onConfirm = (date) => {
    showDatePicker.value = false;

    if (typeof date.dateString !== "undefined") {
      if (mode.value === "date") {
        let currentTime = dayjs(startDate.value).format("THH:mm:ssZ");
        startDate.value = dayjs(
          dayjs(date.dateString).format("YYYY-MM-DD") + currentTime
        );
      } else {
        //time
        let newTime = dayjs(date.dateString).format("THH:mm:ssZ");
        let newStartDateTime =
          dayjs(startDate.value).format("YYYY-MM-DD") + newTime;
        startDate.value = dayjs(newStartDateTime);
      }
    } else {
      if (mode.value === "date") {
        let currentTime = dayjs(startDate.value).format("THH:mm:ssZ");
        startDate.value = dayjs(dayjs(date).format("YYYY-MM-DD") + currentTime);
      } else {
        let newStartDateTime =
          dayjs(startDate.value).format("YYYY-MM-DD") +
          dayjs(date).format("THH:mm:ssZ");
        startDate.value = dayjs(newStartDateTime);
      }
    }
  };

  useEffect(() => {
    analyzeSalePrice(price, priceStatus);
    analyzeDuration(duration, durationStatus);
    analyzeTime(time, timeStatus);
  }, []);

  useEffect(() => {
    canSave.value =
      durationStatus.value === "" &&
      timeStatus.value === "" &&
      priceStatus.value === "";
  }, [durationStatus.value, timeStatus.value, priceStatus.value]);

  const windowHeight = Dimensions.get("window").height;

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <SafeAreaView
      style={tailwind`flex w-full bg-[${theme.value.backgroundColor}]`}
    >
      <ScrollView
        scrollEnabled={true}
        style={tailwind`max-h-[${windowHeight}px] min-h-[400px] w-full`}
      >
        <EventHeaderBar type={EVENT_TYPE_BOOKABLE} />
        <View style={tailwind`p-2 w-full max-w-[800px] self-center`}>
          <View style={tailwind`pt-2 pb-6`}>
            <Text
              style={[
                tailwind`text-[${theme.value.textColor}] font-semibold pb-1 self-center`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              New Open Personal Training Event
            </Text>
          </View>
          <View style={tailwind`self-center w-full`}>
            <DateTimeInput
              startDate={startDate}
              time={time}
              timeStatus={timeStatus}
              showDatePicker={showDatePicker}
            />
            <View style={tailwind`flex-row pt-2`}>
              <View style={tailwind`w-1/2`}>
                <DurationInput
                  duration={duration}
                  durationStatus={durationStatus}
                />
              </View>
              <View style={tailwind`w-1/2`}>
                <PriceInput price={price} priceStatus={priceStatus} />
              </View>
            </View>
          </View>
        </View>

        <View style={tailwind`self-center flex-row pt-2 pb-4`}>
          <Button
            title="Close"
            onPress={() => {
              setBottomSheetVisible(false);
            }}
          />
          <Button
            disabled={!canSave.value}
            title="Save"
            onPress={() => {
              axios
                .post(
                  API_URL + "schedule",
                  {
                    userId: currentUser.value.id,
                    startDate: localTimeToUTC(startDate.value),
                    duration: duration.value,
                    shortDescription: "Open PT Session",
                    price: price.value,
                    type: EVENT_TYPE_BOOKABLE,
                    participants: [],
                    tags: [],
                    rule: null,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      authorization: currentUser.value.token,
                    },
                  }
                )
                .then((res) => {
                  queryClient.invalidateQueries([
                    "schedule" +
                      currentUser.value.id +
                      dayjs().format("YYYY-MM-DD"),
                  ]);

                  queryClient.invalidateQueries([
                    "providerSchedule" +
                      currentUser.value.id +
                      dayjs().format("YYYY-MM-DD"),
                  ]);
                  queryClient.invalidateQueries(["homepagesections"]);

                  const event = res.data.raw;
                  setBottomSheetContent(<ViewEvent event={event} />);
                  return res.data;
                });
            }}
          />
        </View>
      </ScrollView>
      {mode.value === "date" && startDate.value.isValid() ? (
        <DatePicker
          isVisible={showDatePicker.value}
          mode={"single"}
          initialDate={new Date(startDate.value.toISOString())}
          onCancel={onCancel}
          onConfirm={onConfirm}
          colorOptions={{
            headerColor: theme.value.backgroundColor,
            selectedDateBackgroundColor: theme.value.textColor,
            confirmButtonColor: theme.value.textColor,
            weekDaysColor: theme.value.backgroundColor,
            changeYearModalColor: theme.value.textColor,
            dateTextColor: theme.value.textColor,
            headerTextColor: theme.value.textColor,
          }}
        />
      ) : null}
    </SafeAreaView>
  );
};

export { NewOpenEvent as default };
