import { bodyTagSelection, userTagSelection } from "../../signals/signals";
import { TREE_TYPE_BODY, TREE_TYPE_USER } from "../base/Constants";

export const setTagSelections = (selectedTags: any) => {
  userTagSelection.value = [];
  bodyTagSelection.value = [];
  selectedTags.forEach((tag) => {
    switch (tag.type) {
      case TREE_TYPE_BODY:
        bodyTagSelection.value = [
          ...bodyTagSelection.value,
          {
            id: tag.id,
            title: tag.tagName,
            deleted: 0,
          },
        ];
        break;
      default:
      case TREE_TYPE_USER:
        userTagSelection.value = [
          ...userTagSelection.value,
          {
            id: tag.id,
            title: tag.tagName,
            deleted: 0,
          },
        ];
        break;
    }
  });
};
