import PropTypes from "prop-types";
import { Text, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../../signals/signals";

const DurationPill = ({ duration }) => {
  return (
    <View
      style={tailwind`rounded-lg`}
    >
      <Text
        style={[
          tailwind`text-[${theme.value.timePillTextColor}] text-[10px]`,
          { fontFamily: "Inter_600SemiBold" },
        ]}
      >
        {duration} MIN
      </Text>
    </View>
  );
};

DurationPill.propTypes = {
  duration: PropTypes.number.isRequired,
};

export { DurationPill as default };
