import React, { Dimensions, Platform, ScrollView, View } from "react-native";
import PropTypes from "prop-types";
import OnDemandDetails from "./OnDemandDetails";
import tailwind from "twrnc";
import ExpoPlayer from "../video-players/vod/ExpoPlayer";
import MiniTrainerProfilePanel from "../profile-screen/MiniTrainerProfilePanel";
import { TrainerVideos } from "../profile-screen/TrainerVideos";
import { WebScrollContainer } from "../home-screen/WebScrollContainer";
import { theme, windowOrientation } from "../../signals/signals";
import { useEffect } from "react";
import * as ScreenOrientation from "expo-screen-orientation";

const WebLayout = ({ video }) => {
  const trainerId = video.video.userVideos[0].userId.id;
  const firstName = video.video.userVideos[0].userId.firstName;

  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        windowOrientation.value = getOrientation(orientationInfo.orientation);
      }, 300);
    });
  }, []);

  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };

  const isMobileWebFormat = Platform.OS === "web" &&
    (
      (windowOrientation.value === "portrait" && Dimensions.get("screen").width < 768) ||
      (windowOrientation.value === "landscape" && Dimensions.get("screen").height < 768)
    );

  return (
    <View style={tailwind`h-full w-full`}>
      {isMobileWebFormat && windowOrientation.value === "portrait" ? (
        <View style={tailwind`self-center w-full mr-4`}>
          <View style={tailwind`w-full h-1/3`}>
            <ExpoPlayer video={video} />
          </View>
          <View style={tailwind`grow`}>
            <ScrollView style={tailwind` bg-[${theme.value.backgroundColor}]`}>
              <View style={tailwind`mx-2 w-full`}>
                <OnDemandDetails video={video} />
                <MiniTrainerProfilePanel id={trainerId} />
              </View>
            </ScrollView>
          </View>
          <View style={tailwind`min-h-[220px]`}>
            <WebScrollContainer
              style={tailwind`bg-[${theme.value.backgroundColor}]`}
            >
              <TrainerVideos id={trainerId} firstName={firstName} />
            </WebScrollContainer>
          </View>
        </View>
      ) : null}
      {isMobileWebFormat && windowOrientation.value === "landscape" ? (
        <View style={tailwind`self-center w-full mr-4 flex-row`}>
          <View style={tailwind`h-full grow`}>
            <ExpoPlayer video={video} />
          </View>
          <View style={tailwind`h-full`}>
            <ScrollView style={tailwind` bg-[${theme.value.backgroundColor}]`}>
              <View style={tailwind`mx-2 w-full`}>
                <OnDemandDetails video={video} />
                <MiniTrainerProfilePanel id={trainerId} />
              </View>
            </ScrollView>
          </View>
        </View>
      ) : null}
      {!isMobileWebFormat ? (
        <>
          <View style={tailwind`flex-row self-center grow w-full mr-4`}>
            <View style={tailwind`w-2/3`}>
              <ExpoPlayer video={video} />
            </View>
            <View style={tailwind`ml-2 w-1/3`}>
              <View>
                <OnDemandDetails video={video} />
              </View>
              <MiniTrainerProfilePanel id={trainerId} />
            </View>
          </View>
          <View style={tailwind`h-[220px]`}>
            <WebScrollContainer
              style={tailwind`bg-[${theme.value.backgroundColor}]`}
            >
              <TrainerVideos id={trainerId} firstName={firstName} />
            </WebScrollContainer>
          </View>
        </>
      ) : null}
    </View>
  );
};

WebLayout.propTypes = {
  video: PropTypes.object.isRequired,
};

export default WebLayout;
