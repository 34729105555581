import { Socket } from "socket.io-client";
import { chatSocket, currentUser } from "../../../signals/signals";
import { Message } from "../../conference/types/Message";

export const connectToLiveChatRoom = async (
  socket: Socket,
  roomId: string,
  userId: number,
  token: string,
  newMessageCallback: (newMessage: any) => void,
  messageHistoryCallback: (messageHistory: Message[], userId?: number) => void,
  userJoinedCallback: (userId: number, messageHistory: any) => void
) => {
  attachLiveChatSocketListeners(
    socket,
    roomId,
    newMessageCallback,
    userJoinedCallback,
    messageHistoryCallback
  );
  socket.emit("join room live", roomId, userId, token);
};

export const connectToPrivateChatRoom = async (
  socket: Socket,
  roomId: string,
  userId: number,
  token: string,
  newMessageCallback: (newMessage: any) => void,
  messageHistoryCallback: (messageHistory: Message[], userId?: number) => void,
  userJoinedCallback: (userId: number, messageHistory: any) => void
) => {
  attachPrivateChatSocketListeners(
    socket,
    roomId,
    newMessageCallback,
    userJoinedCallback,
    (messageHistory) => {
      messageHistoryCallback(messageHistory);
    }
  );
};

export const disconnectFromPrivateChatRoom = () => {
  chatSocket.value.emit("leave room private chat");
  closeChatSocket();
};

export const disconnectFromLiveChatRoom = () => {
  chatSocket.value.emit("leave room live");
  closeChatSocket();
};

export const disconnectFromPTChatRoom = () => {
  chatSocket.value.emit("leave room PT");
  closeChatSocket();
};

const closeChatSocket = () => {
  chatSocket.value.close();
  chatSocket.value = null;
};

const attachPrivateChatSocketListeners = async (
  socket: Socket,
  roomId: string,
  newMessageCallback: (newMessage: any) => void,
  userJoinedCallback: (userId: number, messageHistory: any) => void,
  messageHistoryCallback: (messageHistory: any, userId?: number) => void
): Promise<void> => {
  socket.on("connected", () => {
    try {
      socket.emit(
        "join room private chat",
        roomId,
        currentUser.value.id,
        currentUser.value.token
      );
    } catch (err) {
      console.log("caught an error", err);
    }
  });

  socket.on("authentication error", () => {
    console.log("Authentication error from socket server");
  });

  socket.on("user left", (userId: number) => {});

  socket.on("new-message", (newMessage: any) => {
    if (newMessage.user._id !== currentUser.value.id) {
      newMessageCallback(newMessage);
    }
  });

  socket.on(
    "user joined",
    async (joiningUserId: number, socketId: string, messageHistory: any) => {
      // console.log(
      //   "user joined (socketHandler Private)",
      //   joiningUserId,
      //   socketId,
      //   messageHistory
      // );
      if (joiningUserId !== currentUser.value.id) {
        messageHistoryCallback(joiningUserId, messageHistory);
      }
      userJoinedCallback(joiningUserId, messageHistory);
    }
  );

  socket.on("message-history", (messageHistory: any) => {
    if (messageHistoryCallback) messageHistoryCallback(messageHistory);
  });

  return Promise.resolve();
};

const attachLiveChatSocketListeners = async (
  socket: Socket,
  roomId: string,
  newMessageCallback: (newMessage: any) => void,
  userJoinedCallback: (userId: number, messageHistory: any) => void,
  messageHistoryCallback: (messageHistory: any, userId?: number) => void
): Promise<void> => {
  socket.on("connected", () => {
    try {
      socket.emit(
        "join room live",
        roomId,
        currentUser.value.id,
        currentUser.value.token
      );
    } catch (err) {
      console.log("caught an error", err);
    }
  });

  socket.on("authentication error", () => {
    console.log("Authentication error from socket server");
  });

  socket.on("user left", (userId: number) => {});

  socket.on("new-message", (newMessage: any) => {
    if (newMessage.user._id !== currentUser.value.id) {
      newMessageCallback(newMessage);
    }
  });

  socket.on(
    "user joined",
    async (joiningUserId: number, socketId: string, messageHistory: any) => {
      if (joiningUserId !== currentUser.value.id) {
        messageHistoryCallback(joiningUserId, messageHistory);
      }
      userJoinedCallback(joiningUserId, messageHistory);
    }
  );

  socket.on("message-history", (messageHistory: any) => {
    if (messageHistoryCallback) messageHistoryCallback(messageHistory);
  });

  return Promise.resolve();
};

export const sendMessage = (
  socket: Socket,
  userId: number,
  firstName: string,
  lastName: string,
  profileUrl: string,
  token: string,
  roomId: string,
  message: any
) => {
  socket.emit(
    "add-message",
    userId,
    firstName + " " + lastName,
    profileUrl,
    roomId,
    message,
    token
  );
};
