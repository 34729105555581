import { View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../../signals/signals";
import { Image } from "@rneui/themed";

const ThumbnailProfile = ({ profileUrl }) => {
  const thumbnailSize = 40;
  if (!profileUrl) return null;

  return (
    <>
      <View style={tailwind`flex-row self-end h-[24px]`}>
        <View
          style={tailwind`rounded-full w-[${thumbnailSize}px] h-[${thumbnailSize}px] border-[1px] border-[${theme.value.onDemandProfileBorderColor}]`}
        >
          <Image
            style={tailwind`h-[${thumbnailSize}px] rounded-full`}
            source={{ uri: profileUrl }}
          />
        </View>
      </View>
    </>
  );
};

export { ThumbnailProfile as default };
