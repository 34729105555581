import React, { useRef } from "react";
import PropTypes from "prop-types";
import OnDemandThumbnailStrip from "../thumbnails/on-demand/OnDemandThumbnailStrip";
import TrainerThumbnailStrip from "../thumbnails/trainer/TrainerThumbnailStrip";
import tailwind from "twrnc";
import { WebScrollContainer } from "./WebScrollContainer";
import LiveThumbnailStrip from "../thumbnails/live/LiveThumbnailStrip";

const SectionContainer = ({ item }) => {
  const scrollViewRef = useRef(null);
  const sectionType = item.type.id;
  const isDynamic = item.isDynamic;

  switch (sectionType) {
    case 1: //live
      return (
        <WebScrollContainer
          scrollViewRef={scrollViewRef}
          style={tailwind`pt-1 pb-4`}
        >
          <LiveThumbnailStrip section={item} />
        </WebScrollContainer>
      );
    case 2: //trainers
      return (
        <WebScrollContainer style={tailwind`pb-4`}>
          <TrainerThumbnailStrip section={item} />
        </WebScrollContainer>
      );
    case 6:
    default: //vod
      return (
        <WebScrollContainer
          scrollViewRef={scrollViewRef}
          style={tailwind`pb-4`}
        >
          <OnDemandThumbnailStrip section={item} isDynamic={isDynamic} />
        </WebScrollContainer>
      );
  }
};

SectionContainer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default SectionContainer;
