import React, { SafeAreaView } from "react-native";
import PropTypes from "prop-types";
import tailwind from "twrnc";
import TrainerProfilePanel from "../components/profile-screen/TrainerProfilePanel";
import UserProfilePanel from "../components/profile-screen/UserProfilePanel";
import { theme } from "../signals/signals";

const ViewProfileScreen = (props) => {
  const { id, type } = props.route.params;

  return (
    <SafeAreaView
      style={tailwind`flex h-full bg-[${theme.value.backgroundColor}]`}
    >
      {type === 1 ? (
        <UserProfilePanel id={id} />
      ) : (
        <TrainerProfilePanel id={id} />
      )}
    </SafeAreaView>
  );
};

ViewProfileScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.number.isRequired,
    }),
  }),
};

export default ViewProfileScreen;
