import dayjs from "dayjs";
import { Signal } from "@preact/signals-react";
import {
  currentUser,
  isStreaming,
  streamClosing,
  streamCompleted,
} from "../../signals/signals";
import { drawPlanTimer } from "./planTimerFunctions";
import {
  BROADCAST_VIDEO_HEIGHT,
  BROADCAST_VIDEO_WIDTH,
  CAST_SOCKET_URL,
} from "../base/Constants";
import { MutableRefObject, RefObject } from "react";
import * as io from "socket.io-client";
import { drawLiveTimer } from "./timerFunctions";

const logoWidth = 75;
const logoHeight = 53;
const logoRightPosition = 100;
const logoBottomPosition = 75;

const stoppingImageWidth = BROADCAST_VIDEO_WIDTH;
const stoppingImageHeight = BROADCAST_VIDEO_HEIGHT;
const stoppingImage: HTMLImageElement = document.createElement("img");
stoppingImage.src = require("../../assets/stream_thank_you.png");

const sourceWidth = BROADCAST_VIDEO_WIDTH;
const sourceHeight = BROADCAST_VIDEO_HEIGHT;

export const scaleCanvasElementFromBottomRight = (
  sourceWidth: number,
  sourceHeight: number,
  canvasWidth: number,
  canvasHeight: number,
  rightPositionAtFullScale: number,
  bottomPositionAtFullScale: number,
  elementWidthAtFullScale: number,
  elementHeightAtFullScale: number
): {
  leftPosition: number;
  topPosition: number;
  width: number;
  height: number;
} => {
  const widthReductionFactor = canvasWidth / sourceWidth;
  const heightReductionFactor = canvasHeight / sourceHeight;
  let leftPosition =
    canvasWidth - rightPositionAtFullScale * widthReductionFactor;
  let topPosition =
    canvasHeight - bottomPositionAtFullScale * heightReductionFactor;
  let width = elementWidthAtFullScale;
  let height = elementHeightAtFullScale;

  if (canvasWidth < sourceWidth) {
    width = elementWidthAtFullScale * widthReductionFactor;
    height = elementHeightAtFullScale * heightReductionFactor;
  }
  return { leftPosition, topPosition, width, height };
};

export const scaleCanvasElementFromTopLeft = (
  sourceWidth: number,
  sourceHeight: number,
  canvasWidth: number,
  canvasHeight: number,
  leftPositionAtFullScale: number,
  topPositionAtFullScale: number,
  elementWidthAtFullScale: number,
  elementHeightAtFullScale: number
): {
  leftPosition: number;
  topPosition: number;
  width: number;
  height: number;
} => {
  const widthReductionFactor = canvasWidth / sourceWidth;
  const heightReductionFactor = canvasHeight / sourceHeight;
  let leftPosition = leftPositionAtFullScale * widthReductionFactor;
  let topPosition = topPositionAtFullScale * heightReductionFactor;
  let width = elementWidthAtFullScale;
  let height = elementHeightAtFullScale;

  if (canvasWidth < sourceWidth) {
    width = elementWidthAtFullScale * widthReductionFactor;
    height = elementHeightAtFullScale * heightReductionFactor;
  }
  return { leftPosition, topPosition, width, height };
};

export const drawFitCentrLogo = (
  ctx: CanvasRenderingContext2D,
  logoImageRef: RefObject<HTMLImageElement>,
  canvasRef: RefObject<HTMLCanvasElement>
) => {
  const { leftPosition, topPosition, width, height } =
    scaleCanvasElementFromBottomRight(
      sourceWidth,
      sourceHeight,
      canvasRef.current.width,
      canvasRef.current.height,
      logoRightPosition,
      logoBottomPosition,
      logoWidth,
      logoHeight
    );
  if (logoImageRef.current) {
    ctx.drawImage(
      logoImageRef.current,
      leftPosition,
      topPosition,
      width,
      height
    );
  }
};

//executes @ 30fps
export const updateCanvas = (
  videoRef: RefObject<HTMLVideoElement>,
  canvasRef: RefObject<HTMLCanvasElement>,
  streamStopped: Signal<boolean>,
  startTime: Signal<string>,
  endTime: Signal<string>,
  timeLive: Signal<number>,
  logoImageRef: RefObject<HTMLImageElement>,
  planShown: Signal<boolean>,
  requestAnimationRef: MutableRefObject<any>,
  currentPlanStep: MutableRefObject<any>,
  overlayPlanRef: MutableRefObject<any>,
  planStep: MutableRefObject<any>,
  planStepTime: MutableRefObject<any>
) => {
  if (!videoRef.current) {
    return;
  }

  const ctx: CanvasRenderingContext2D = canvasRef.current.getContext("2d");
  const { videoWidth, videoHeight } = videoRef.current;
  const canvasWidth = canvasRef.current.width;
  const canvasHeight = canvasRef.current.height;

  if (streamStopped.value) {
    ctx.drawImage(
      stoppingImage,
      0,
      0,
      stoppingImageWidth,
      stoppingImageHeight,
      0,
      0,
      canvasWidth,
      canvasHeight
    );
  } else {
    ctx.drawImage(
      videoRef.current,
      0,
      0,
      videoWidth,
      videoHeight,
      0,
      0,
      canvasWidth,
      canvasHeight
    );

    drawLiveTimer(ctx, startTime, endTime, timeLive, canvasRef);
    drawFitCentrLogo(ctx, logoImageRef, canvasRef);
    if (planShown.value) {
      drawPlanTimer(
        ctx,
        currentPlanStep,
        overlayPlanRef,
        planStep,
        planStepTime
      );
    }
  }
  requestAnimationRef.current = requestAnimationFrame((timeIndex) => {
    updateCanvas(
      videoRef,
      canvasRef,
      streamStopped,
      startTime,
      endTime,
      timeLive,
      logoImageRef,
      planShown,
      requestAnimationRef,
      currentPlanStep,
      overlayPlanRef,
      planStep,
      planStepTime
    );
  });
};

export const stopStreaming = (
  outputStreamRef: MutableRefObject<MediaStream>,
  streamStoppingCounter: Signal<number>,
  streamStopped: Signal<boolean>,
  stoppingCountdownInterval,
  mediaRecorderRef: MutableRefObject<MediaRecorder>,
  endTime: Signal<string>,
  wsRef: MutableRefObject<WebSocket>,
  event: any
) => {
  const millisecsTillEnd = 5000;
  let remainingSecs = millisecsTillEnd / 1000;
  streamClosing.value = true;

  if (outputStreamRef.current) {
    outputStreamRef.current.getAudioTracks().forEach((track) => {
      track.enabled = false;
    });
  }

  streamStoppingCounter.value = remainingSecs;
  streamStopped.value = true;
  if (!stoppingCountdownInterval) {
    stoppingCountdownInterval = setInterval(() => {
      remainingSecs--;
      streamStoppingCounter.value = remainingSecs;
      if (remainingSecs <= 0) {
        clearInterval(stoppingCountdownInterval);
        streamStoppingCounter.value = 0;
        if (outputStreamRef.current)
          outputStreamRef.current.getAudioTracks().forEach((track) => {
            track.enabled = true;
          });
      }
    }, 1000);
  }

  const stoppingTimeout = setTimeout(() => {
    clearTimeout(stoppingTimeout);
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
    }
    isStreaming.value = false;
    streamStopped.value = false;
    endTime.value = dayjs().toISOString();
    streamClosing.value = false;

    if (wsRef.current) {
      wsRef.current.send({
        type: "stop_stream",
        key: event.eventKey,
        token: currentUser.value.token,
        broadcastEndDate: endTime.value,
      } as unknown as Buffer);
    }

    streamCompleted.value = true;
  }, millisecsTillEnd);
};

export const startStreaming = async (
  wsRef: MutableRefObject<WebSocket>,
  event: any,
  outputStreamRef: MutableRefObject<MediaStream>,
  streamStoppingCounter: Signal<number>,
  streamStopped: Signal<boolean>,
  stoppingCountdownInterval: any,
  mediaRecorderRef: MutableRefObject<MediaRecorder>,
  startTime: Signal<string>,
  endTime: Signal<string>,
  canvasRef: MutableRefObject<HTMLCanvasElement>,
  inputStreamRef: MutableRefObject<MediaStream>,
  audioStream: MediaStream
) => {
  const userAgent = navigator.userAgent;
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  isStreaming.value = true;

  if (!wsRef.current) {
    wsRef.current = io.connect(CAST_SOCKET_URL) as unknown as WebSocket; //, { event: { event } }

    wsRef.current.addEventListener("open", function open() {
      //   console.log("socket connected");
    });

    wsRef.current.addEventListener("error", (errorMessage) => {
      console.log("received error over socket", errorMessage);
    });

    wsRef.current.addEventListener("close", () => {
      stopStreaming(
        outputStreamRef,
        streamStoppingCounter,
        streamStopped,
        stoppingCountdownInterval,
        mediaRecorderRef,
        endTime,
        wsRef,
        event
      );
    });

    wsRef.current.addEventListener("message", (message) => {
      //   console.log("received message over socket", message);
    });
  }

  const videoOutputStream = canvasRef.current.captureStream(30); // 30 FPS

  const audioTracks: MediaStreamTrack[] =
    inputStreamRef.current.getAudioTracks();
  audioTracks.forEach(function (track) {
    audioStream.addTrack(track);
  });

  outputStreamRef.current = new MediaStream();
  [audioStream, videoOutputStream].forEach(function (s) {
    s.getTracks().forEach(function (t) {
      outputStreamRef.current.addTrack(t);
    });
  });

  if (isSafari) {
    mediaRecorderRef.current = new MediaRecorder(outputStreamRef.current, {
      mimeType: "video/mp4",
      videoBitsPerSecond: 3000000,
    });
  } else {
    mediaRecorderRef.current = new MediaRecorder(outputStreamRef.current, {
      mimeType: "video/webm",
      videoBitsPerSecond: 3000000,
    });
  }

  mediaRecorderRef.current.addEventListener("dataavailable", (e) => {
    if (wsRef.current)
      wsRef.current.send({
        type: "stream",
        key: event.eventKey,
        data: e.data,
      } as unknown as Buffer);
  });

  wsRef.current.send({
    type: "init",
    key: event.eventKey,
    token: currentUser.value.token,
    broadcastStartDate: Date.now(),
  } as unknown as Buffer);
  mediaRecorderRef.current.start(100);
  startTime.value = dayjs().toISOString();
  endTime.value = null;
};

export const loadImage = (imageLoaded, logoImageRef) => {
  const img = new Image();
  img.onload = () => {
    imageLoaded.value = true;
  };
  img.onerror = (e) => {
    console.error("Error loading logo image", e);
  };
  try {
    img.src = require("../../assets/fitcentr-logo-white-orange.png");
    logoImageRef.current = img;
  } catch (e) {
    console.error("Error setting image src");
    console.log(e);
  }
};
