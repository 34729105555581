import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Dimensions, Platform, View, ScrollView } from "react-native";
import tailwind from "twrnc";
import { currentUser, localStream } from "../../../signals/signals";
import { Signal, useSignal } from "@preact/signals-react";
import * as ScreenOrientation from "expo-screen-orientation";
import SignalChatPT from "../../chat/SignalChatPT";
import ClientStreamsContainer from "./ClientStreamsContainer";
import LocalStreamWrapper from "./LocalStreamWrapper";

const NewProviderStreamLayout = ({
  event,
  participants,
}): JSX.Element => {
  const windowOrientation: Signal = useSignal("portrait");
  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        windowOrientation.value = getOrientation(orientationInfo.orientation);
      }, 300);
    });
  }, []);

  const getOrientation = (orientation: number): string => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };

  const isMobileWebFormat =
    Platform.OS === "web" &&
    ((windowOrientation.value === "portrait" &&
      Dimensions.get("screen").width < 768) ||
      (windowOrientation.value === "landscape" &&
        Dimensions.get("screen").height < 768));

  const roomId: string = event.eventKey;
  return (
    <ScrollView style={tailwind`h-full`}>
      <View
        style={tailwind`${
          !isMobileWebFormat || windowOrientation.value === "landscape"
            ? "flex-row"
            : ""
        } h-full pb-10`}
      >
        <View style={tailwind`grow`}>
          <View>
            <ClientStreamsContainer
              hostId={currentUser.value.id}
              participants={participants}
            />
          </View>
          <View>
            {localStream.value && (
              <LocalStreamWrapper userId={currentUser.value.id} />
            )}
          </View>
        </View>
        <View
          style={tailwind`self-end w-[300px] bg-[#00000f] mr-2 ${
            isMobileWebFormat && windowOrientation.value === "landscape"
              ? "max-h-[" + (Dimensions.get("screen").height - 50) + "px]"
              : "max-h-[" + (Dimensions.get("screen").height - 250) + "px]"
          }`}
        >
          <SignalChatPT roomId={roomId} />
        </View>
      </View>
    </ScrollView>
  );
};

NewProviderStreamLayout.propTypes = {
  event: PropTypes.object.isRequired,
  participants: PropTypes.instanceOf(Signal).isRequired,
};

export { NewProviderStreamLayout as default };
