import React from "react";
import { Signal } from "@preact/signals-react";
import { View } from "react-native";
import tailwind from "twrnc";
import Button from "../base/Button";
import { isStreaming, timeLive } from "../../signals/signals";

const StreamControls = ({
  cameraEnabled,
  streamStoppingCounter,
  stopStreaming,
  startStreaming,
}: {
  cameraEnabled: Signal<boolean>;
  streamStoppingCounter: Signal<number>;
  stopStreaming: () => void;
  startStreaming: () => void;
}) => {
  return (
    <View style={tailwind`p-2 self-center`}>
      {cameraEnabled.value &&
        (isStreaming.value ? (
          <View style={tailwind`flex-row`}>
            {streamStoppingCounter.value ? (
              <Button
                onPress={stopStreaming}
                title={"End in " + streamStoppingCounter.value}
              />
            ) : (
              <Button
                onPress={stopStreaming}
                title="End Stream"
                disabled={timeLive.value < 10}
              />
            )}
          </View>
        ) : (
          <>
            <Button
              title="Go Live"
              disabled={isStreaming.value}
              onPress={startStreaming}
            />
          </>
        ))}
    </View>
  );
};

export default StreamControls;
