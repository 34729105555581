import { useContext } from "react";
import { Pressable, SafeAreaView, Text, View } from "react-native";
import { BottomSheetContext } from "../base/ApplicationContext";
import NewPTEvent from "./new/NewPTEvent";
import NewGeneralEvent from "./new/NewGeneralEvent";
import NewLiveEvent from "./new/NewLiveEvent";
import NewOpenEvent from "./new/NewOpenEvent";
import tailwind from "twrnc";
import { currentUser, theme } from "../../signals/signals";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import EventHeaderBar from "./EventHeaderBar";
import {
  EVENT_TYPE_BOOKABLE,
  EVENT_TYPE_BROADCAST,
  EVENT_TYPE_OTHER,
  EVENT_TYPE_PRIVATE,
  USER_TYPE_AMBASSADOR,
} from "../base/Constants";

const EventTypeChoice = () => {
  const { setBottomSheetContent } = useContext(BottomSheetContext);
  let fonts = useFonts({ Roboto_400Regular });

  const transitionToScreen = (screen: JSX.Element) => {
    setBottomSheetContent(screen);
  };

  let fraction = "w-1/2";
  if (currentUser.value.type === USER_TYPE_AMBASSADOR) {
    fraction = "w-1/3";
  }

  return (
    <SafeAreaView
      style={tailwind`flex w-full bg-[${theme.value.backgroundColor}]`}
    >
      <View style={tailwind`w-full`}>
        <View style={tailwind`flex-row`}>
          {currentUser.value.type === USER_TYPE_AMBASSADOR && (
            <View style={tailwind`${fraction} pb-4 pt-1 px-2`}>
              <Pressable onPress={() => transitionToScreen(<NewLiveEvent />)}>
                <EventHeaderBar type={EVENT_TYPE_BROADCAST} />
                <View style={tailwind`self-center`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.value.textColor}] font-semibold pb-1 h-[40px]`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    Live Broadcast
                  </Text>
                  <Text
                    style={[
                      tailwind`text-[${theme.value.textColor}] pb-1`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    Create a live broadcast event
                  </Text>
                </View>
              </Pressable>
            </View>
          )}

          <View style={tailwind`${fraction} pb-4 pt-1 px-2`}>
            <Pressable onPress={() => transitionToScreen(<NewPTEvent />)}>
              <EventHeaderBar type={EVENT_TYPE_PRIVATE} />
              <View style={tailwind`self-center`}>
                <Text
                  style={[
                    tailwind`text-[${theme.value.textColor}] font-semibold pb-1 h-[40px]`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  Private PT
                </Text>
                <Text
                  style={[
                    tailwind`text-[${theme.value.textColor}] pb-1`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  Create a personal training event
                </Text>
              </View>
            </Pressable>
          </View>

          <View style={tailwind`${fraction} pb-4 pt-1 px-2`}>
            <Pressable onPress={() => transitionToScreen(<NewOpenEvent />)}>
              <EventHeaderBar type={EVENT_TYPE_BOOKABLE} />
              <View style={tailwind`self-center`}>
                <Text
                  style={[
                    tailwind`text-[${theme.value.textColor}] font-semibold pb-1 h-[40px]`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  Open PT
                </Text>
                <Text
                  style={[
                    tailwind`text-[${theme.value.textColor}] pb-1`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  Create a bookable personal training slot
                </Text>
              </View>
            </Pressable>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

export { EventTypeChoice as default };
