import React from "react";
import tailwind from "twrnc";
import { Platform, ScrollView, Text, View } from "react-native";
import Button from "../components/base/Button";
import { useNavigation } from "@react-navigation/native";
import { StyledInput } from "../components/base/StyledInput";
import {
  analyzeEmailAddress,
  analyzeLoginPassword,
} from "../components/helpers/validation";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import { theme } from "../signals/signals";
import { useSignal } from "@preact/signals-react";
import { handleLogin } from "../components/helpers/auth";

const LoginScreen = () => {
  const navigation = useNavigation();

  const loginStatus = useSignal("");
  const email = useSignal("");
  const emailStatus = useSignal("");
  const password = useSignal("");
  const passwordStatus = useSignal("");

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <ScrollView style={tailwind`bg-[${theme.value.backgroundColor}]`}>
      <View style={tailwind`w-full pb-4`}>
        <View
          style={tailwind`flex h-full bg-[${theme.value.backgroundColor}] pt-5`}
        >
          <View style={tailwind`mb-5`}></View>
          {Platform.OS === "web" ? (
            <View style={tailwind`flex w-3/4 self-center`}>
              <form>
                <View style={tailwind`mb-5`}>
                  <StyledInput
                    label="Email Address"
                    placeholder="Email address"
                    autoFocus={true}
                    autoComplete="email"
                    keyboardType="email"
                    onChangeText={(text) => {
                      email.value = text;
                      analyzeEmailAddress(email, emailStatus);
                    }}
                    onBlur={() => analyzeEmailAddress(email, emailStatus)}
                    fieldStatus={emailStatus}
                    autoCorrect={false}
                    width={"300px"}
                  />
                </View>
                <View style={tailwind`mb-5`}>
                  <StyledInput
                    label={"Password"}
                    placeholder="Password"
                    keyboardType="text"
                    autoComplete="password"
                    secure={true}
                    onChangeText={(text) => {
                      password.value = text;
                      analyzeLoginPassword(password, passwordStatus);
                    }}
                    onBlur={() =>
                      analyzeLoginPassword(password, passwordStatus)
                    }
                    onKeyPress={(e) => {
                      if (e.nativeEvent.key === "Enter") {
                        e.preventDefault();
                        handleLogin(
                          email,
                          password,
                          loginStatus,
                          () => (loginStatus.value = "")
                        );
                      }
                    }}
                    fieldStatus={passwordStatus}
                    autoCorrect={false}
                    width={"300px"}
                  />
                </View>
                <Button
                  title="Login"
                  disabled={
                    !(
                      password.value &&
                      email.value &&
                      passwordStatus.value === "" &&
                      emailStatus.value === ""
                    )
                  }
                  onPress={() => {
                    loginStatus.value = "Authenticating...";
                    handleLogin(
                      email,
                      password,
                      loginStatus,
                      () => (loginStatus.value = "")
                    );
                  }}
                />
                <View style={tailwind`min-h-[30px] self-center`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.value.redColor}] mt-2`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    {loginStatus}
                  </Text>
                </View>
                <View style={tailwind`flex-row mt-20 self-center`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.value.textColor}] mt-3 mr-3`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    Don&#39;t have an account?
                  </Text>
                  <Button
                    title="Register"
                    onPress={() =>
                      navigation.navigate("RegisterUserScreen" as never)
                    }
                  />
                </View>
              </form>
            </View>
          ) : (
            <View style={tailwind`flex w-3/4 self-center`}>
              <View style={tailwind`mb-5`}>
                <StyledInput
                  label="Email Address"
                  placeholder="Email address"
                  autoFocus={true}
                  autoComplete="email"
                  keyboardType="email"
                  onChangeText={(text) => {
                    email.value = text;
                    analyzeEmailAddress(email, emailStatus);
                  }}
                  onBlur={() => analyzeEmailAddress(email, emailStatus)}
                  fieldStatus={emailStatus}
                  autoCorrect={false}
                  width={"300px"}
                />
              </View>
              <View style={tailwind`mb-5`}>
                <StyledInput
                  label={"Password"}
                  placeholder="Password"
                  keyboardType="text"
                  autoComplete="password"
                  secure={true}
                  onChangeText={(text) => {
                    password.value = text;
                    analyzeLoginPassword(password, passwordStatus);
                  }}
                  fieldStatus={passwordStatus}
                  autoCorrect={false}
                  width={"300px"}
                />
              </View>
              <Button
                title="Login"
                disabled={
                  !(
                    password &&
                    email &&
                    passwordStatus.value === "" &&
                    emailStatus.value === ""
                  )
                }
                onPress={() => {
                  loginStatus.value = "Authenticating...";
                  handleLogin(
                    email,
                    password,
                    loginStatus,
                    () => (loginStatus.value = "")
                  );
                }}
              />
              <View style={tailwind`min-h-[30px] self-center`}>
                <Text
                  style={[
                    tailwind`text-[${theme.value.redColor}] mt-2`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  {loginStatus}
                </Text>
              </View>
              <View style={tailwind`flex-row mt-20 self-center`}>
                <Text
                  style={[
                    tailwind`text-[${theme.value.textColor}] mt-3 mr-3`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  Don&#39;t have an account?
                </Text>
                <Button
                  title="Register"
                  onPress={() =>
                    navigation.navigate("RegisterUserScreen" as never)
                  }
                />
              </View>
            </View>
          )}
        </View>
      </View>
    </ScrollView>
  );
};

export { LoginScreen as default };
