import axios from "axios";
import PropTypes from "prop-types";
import { currentUser, theme } from "../../signals/signals";
import { API_URL } from "../base/Constants";
import { ActivityIndicator, Text, View } from "react-native";
import tailwind from "twrnc";
import { OnDemandVideo } from "./types/OnDemandVideo";
import VideoThumbnail from "../thumbnails/VideoThumbnail";
import { WebScrollContainer } from "../home-screen/WebScrollContainer";
import { useQuery } from "@tanstack/react-query";
import ApiError from "../error/ApiError";

const PurchasedReplaysPanel = () => {
  const {
    isLoading,
    error,
    data,
  }: { isLoading: boolean; error: Error; data: any } = useQuery(
    ["booking/replay/history/" + currentUser.value.id],
    async () => {
      return axios
        .get(`${API_URL}booking/replay/history/` + currentUser.value.id, {
          headers: { authorization: currentUser.value.token },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err));
    }
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const onDemandVideos = data;
  return (
    <>
      <View
        style={tailwind`flex-row bg-[${theme.value.backgroundColor}] pt-6 pb-4`}
      >
        <Text
          style={[
            tailwind`font-semibold text-[${theme.value.textColor}] text-[20px]`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Your Purchased Replays
        </Text>
      </View>
      <PurchasedReplaysInnerPanel videos={onDemandVideos} />
    </>
  );
};

const PurchasedReplaysInnerPanel = ({ videos }) => {
  return (
    <View style={tailwind`h-[200px]`}>
      <WebScrollContainer style={tailwind`pb-4`}>
        {videos.map((video: OnDemandVideo) => (
          <View key={video.id}>
            <VideoThumbnail video={{ video }} />
          </View>
        ))}
      </WebScrollContainer>
    </View>
  );
};

PurchasedReplaysInnerPanel.propTypes = {
  videos: PropTypes.array,
};

export { PurchasedReplaysPanel as default };
