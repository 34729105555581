import PropTypes from "prop-types";
import {
  ActivityIndicator,
  Platform,
  Pressable,
  Text,
  View,
} from "react-native";
import tailwind from "twrnc";
import { theme } from "../../../signals/signals";
import { Image } from "@rneui/themed";
import { DEFAULT_PROFILE_URL } from "../../base/Constants";

const AttendeeItem = ({ attendeeProfileUrl, name, selected, onPress }) => {
  return (
    <Pressable onPress={onPress} style={tailwind`m-1`}>
      <View
        style={tailwind`flex-row mx-1 rounded-lg p-1 bg-[${
          selected
            ? theme.value.attendeeListSelectedBackgroundColor
            : theme.value.attendeeListBackgroundColor
        }]`}
      >
        <View style={tailwind`flex-row`}>
          <Image
            style={tailwind`w-[${Platform.OS === "web" ? "40px" : "35px"}] h-[${
              Platform.OS === "web" ? "40px" : "35px"
            }] rounded-full self-center`}
            source={{ uri: attendeeProfileUrl || DEFAULT_PROFILE_URL }}
            PlaceholderContent={<ActivityIndicator />}
          />
          <Text
            style={[
              tailwind`flex-wrap text-[${
                selected
                  ? theme.value.attendeeListSelectedTextColor
                  : theme.value.textColor
              }] text-[${
                Platform.OS === "web" ? "14px" : "10px"
              }] font-semibold self-center pl-2`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            {name}
          </Text>
        </View>
      </View>
    </Pressable>
  );
};

AttendeeItem.propTypes = {
  attendeeProfileUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
};

export { AttendeeItem as default };
