import React from "react";
import PropTypes from "prop-types";
import { Text, View, Platform, Dimensions } from "react-native";
import tailwind from "twrnc";
import { StyledInput } from "../base/StyledInput";
import {
  analyzeConfirmPassword,
  analyzeNewPassword,
} from "../helpers/validation";
import { Roboto_700Bold, useFonts } from "@expo-google-fonts/roboto";
import { theme } from "../../signals/signals";
import { Signal } from "@preact/signals-react";

const ChangePasswordForm = ({
  existingPassword,
  newPassword,
  confirmPassword,
  newPasswordStatus,
  confirmPasswordStatus,
}) => {
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;

  const checkPasswordValidation = () => {
    if (!existingPassword.value) {
      confirmPassword.value = "";
      confirmPasswordStatus.value = "";
      newPassword.value = "";
      newPasswordStatus.value = "";
    } else {
      analyzeNewPassword(newPassword, newPasswordStatus);
    }
    analyzeConfirmPassword(confirmPassword, newPassword, confirmPasswordStatus);
  };

  let fonts = useFonts({ Roboto_700Bold });
  if (!fonts) return;

  return (
    <>
      <form>
        <View style={tailwind`${isMobileWebFormat ? "w-full" : "w-[618px]"}`}>
          <Text
            style={[
              tailwind`text-[20px] mx-2 my-4 font-semibold text-[${theme.value.textColor}]`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            Change Password
          </Text>

          <View
            style={tailwind`${
              isMobileWebFormat ? "w-full" : "w-1/2"
            } pl-2 mb-4`}
          >
            <StyledInput
              label="Existing Password"
              onChangeText={(text) => {
                existingPassword.value = text;
                checkPasswordValidation();
              }}
              secure={true}
              placeholder={"existing password"}
              width={isMobileWebFormat ? "300px" : null}
              fieldStatus={new Signal(null)}
              autoCorrect={false}
              onBlur={() => checkPasswordValidation()}
            />
          </View>
          <View style={tailwind`${isMobileWebFormat ? "w-full" : "flex-row"}`}>
            <View
              style={tailwind`${
                isMobileWebFormat ? "w-full" : "w-1/2"
              } pl-2 mb-4`}
            >
              <StyledInput
                label="New Password"
                onChangeText={(text) => {
                  newPassword.value = text;
                  checkPasswordValidation();
                }}
                placeholder={"new password"}
                secure={true}
                fieldStatus={newPasswordStatus}
                width={isMobileWebFormat ? "300px" : null}
                autoCorrect={false}
                onBlur={() => checkPasswordValidation()}
                disabled={existingPassword.value ? false : true}
              />
            </View>
            <View
              style={tailwind`${
                isMobileWebFormat ? "w-full" : "w-1/2"
              } pl-2 mb-4`}
            >
              <StyledInput
                label="Confirm New Password"
                onChangeText={(text) => {
                  confirmPassword.value = text;
                  checkPasswordValidation();
                }}
                placeholder={"confirm password"}
                fieldStatus={confirmPasswordStatus}
                secure={true}
                width={isMobileWebFormat ? "300px" : null}
                autoCorrect={false}
                onBlur={() => checkPasswordValidation()}
                disabled={existingPassword.value ? false : true}
              />
            </View>
          </View>
        </View>
      </form>
    </>
  );
};

ChangePasswordForm.propTypes = {
  existingPassword: PropTypes.instanceOf(Signal).isRequired,
  newPassword: PropTypes.instanceOf(Signal).isRequired,
  confirmPassword: PropTypes.instanceOf(Signal).isRequired,
  newPasswordStatus: PropTypes.instanceOf(Signal).isRequired,
  confirmPasswordStatus: PropTypes.instanceOf(Signal).isRequired,
};

export { ChangePasswordForm };
