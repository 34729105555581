import React from "react";
import PropTypes from "prop-types";
import {
  useFonts,
  Roboto_700Bold,
  Roboto_400Regular,
} from "@expo-google-fonts/roboto";
import { Inter_400Regular } from "@expo-google-fonts/inter";
import {
  ActivityIndicator,
  Text,
  View,
} from "react-native";
import { useQuery, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { API_URL, DEFAULT_PROFILE_URL, queryClient } from "../base/Constants";
import { currentUser, theme } from "../../signals/signals";
import ApiError from "../error/ApiError";

const ProfileChatHeader = ({ id }) => {
  let [fontsLoaded] = useFonts({
    Roboto_700Bold,
    Roboto_400Regular,
    Inter_400Regular,
  });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Panel id={id} />
    </QueryClientProvider>
  );
};

function Panel({ id }) {
  const { isLoading, error, data } = useQuery(["user" + id], () =>
    axios
      .get(API_URL + "users/" + id, {
        staleTime: 0,
        headers: { authorization: currentUser.value.token },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err))
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const firstName = data.firstName;
  const shortName = firstName + " " + data.lastName;

  return (
    <View style={tailwind`flex-row px-4 py-4 w-full`}>
      <View style={tailwind`w-32`}>
        <Image
          style={tailwind`w-[32px] h-[32px] rounded-full`}
          source={{ uri: data.profileUrl || DEFAULT_PROFILE_URL }}
          PlaceholderContent={<ActivityIndicator />}
        />
      </View>
      <View style={tailwind`grow pt-2`}>
        <Text
          style={[
            tailwind`capitalize text-[${theme.value.textColor}] font-bold text-[18px]`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          {shortName}
        </Text>
      </View>
    </View>
  );
}

Panel.propTypes = {
  id: PropTypes.number.isRequired,
};

ProfileChatHeader.propTypes = {
  id: PropTypes.number.isRequired,
};

export { ProfileChatHeader as default };
