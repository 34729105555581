import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Dimensions,
  Platform,
  ScrollView,
  Pressable,
  View,
} from "react-native";
import tailwind from "twrnc";
import { Image } from "@rneui/themed";
import { theme } from "../../signals/signals";

const WebScrollContainer = ({ children, style }) => {
  const leftArrowRef = useRef();
  const leftArrowButtonRef = useRef();
  const rightArrowRef = useRef();
  const rightArrowButtonRef = useRef();
  const [isScrollingLeft, setIsScrollingLeft] = useState(false);
  const [isScrollingRight, setIsScrollingRight] = useState(false);
  const scrollLeftDistance = useRef(0);
  const scrollRightDistance = useRef(0);
  const windowWidth = Dimensions.get("window").width;
  const moveWidth = windowWidth * 0.75;
  const scrollViewRef = useRef(null);

  useEffect(() => {
    if (isScrollingLeft) {
      if (scrollLeftDistance.current === 0) {
        const scrollingInterval = setInterval(() => {
          scrollViewRef.current.scrollLeft -= moveWidth / 10;
          scrollLeftDistance.current += moveWidth / 10;
          if (
            scrollLeftDistance.current >= moveWidth ||
            scrollViewRef.current.scrollLeft === 0
          ) {
            setIsScrollingLeft(false);
            clearInterval(scrollingInterval);
            scrollLeftDistance.current = 0;
          }
        }, 30);
      }
    }
  }, [isScrollingLeft]);

  useEffect(() => {
    if (isScrollingRight) {
      if (scrollRightDistance.current === 0) {
        const scrollingInterval = setInterval(() => {
          scrollViewRef.current.scrollLeft += moveWidth / 10;
          scrollRightDistance.current += moveWidth / 10;
          if (
            scrollRightDistance.current >= moveWidth ||
            scrollViewRef.current.scrollLeft >=
            scrollViewRef.current.scrollWidth
          ) {
            setIsScrollingRight(false);
            clearInterval(scrollingInterval);
            scrollRightDistance.current = 0;
          }
        }, 30);
      }
    }
  }, [isScrollingRight]);

  return (
    <>
      {Platform.OS === "web" && Dimensions.get("screen").width > 768 && scrollViewRef ? ( // && scrollViewRef.current ? (
        <View style={tailwind`absolute flex-row right-4 top-0 mt--11`}>
          <Pressable
            ref={leftArrowButtonRef}
            style={tailwind`mr-2`}
            onPress={() => {
              if (scrollViewRef.current.scrollLeft > 0) {
                setIsScrollingLeft(true);
              }
            }}
          >
            <View
              ref={leftArrowRef}
              style={tailwind`bg-[${theme.value.webScrollArrorBackgroundColor}] w-[32px] h-[32px] rounded-full pt-[7px] pl-[7px]`}
            >
              <Image
                resizeMode="contain"
                style={tailwind`h-[30px] w-[30px]`}
                source={require("../../assets/left-arrow.png")}
                tintColor={theme.value.webScrollArrorColor}
              />
            </View>
          </Pressable>
          <Pressable
            ref={rightArrowButtonRef}
            style={tailwind`ml-2`}
            onPress={() => {
              setIsScrollingRight(true);
            }}
          >
            <View
              ref={rightArrowRef}
              style={tailwind`bg-[${theme.value.webScrollArrorBackgroundColor}] w-[32px] h-32px rounded-full pt-[7px] pl-[7px]`}
            >
              <Image
                resizeMode="contain"
                style={tailwind`h-[30px] w-[30px]`}
                source={require("../../assets/right-arrow.png")}
                tintColor={theme.value.webScrollArrorColor}
              />
            </View>
          </Pressable>
        </View>
      ) : null}
      <ScrollView
        ref={scrollViewRef}
        horizontal
        showsHorizontalScrollIndicator={false}
        style={style || tailwind`pt-0`}
      >
        {children}
      </ScrollView>
    </>
  );
};

WebScrollContainer.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export { WebScrollContainer };
