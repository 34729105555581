import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as ScreenOrientation from "expo-screen-orientation";
import YoutubeLayout from "../components/on-demand/YoutubeLayout";
import { Dimensions, Platform, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../signals/signals";

const YoutubeScreen = (props) => {
  const { video, key } = props.route.params;

  const defaultDimensions = {
    height: Dimensions.get("window").height,
    width: Dimensions.get("window").width,
  };
  const defaultOrientation =
    defaultDimensions.width < defaultDimensions.height
      ? "portrait"
      : "landscape";
  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };
  const [orientation, setOrientation] = useState(defaultOrientation);

  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        setOrientation(getOrientation(orientationInfo.orientation));
      }, 300);
    });
  }, [orientation]);

  return (
    <View
      style={tailwind`bg-[${theme.value.backgroundColor}] pl-[${
        Platform.OS === "web" ? "50px" : "10px"
      }] h-full`}
    >
      {Platform.OS === "web" ? <YoutubeLayout key={key} video={video} /> : null}
      {Platform.isTV ? <YoutubeLayout key={key} video={video} /> : null}
      {Platform.OS === "android" || Platform.OS === "ios" ? (
        <YoutubeLayout key={key} video={video} />
      ) : null}
    </View>
  );
};

YoutubeScreen.propTypes = {
  route: PropTypes.object.isRequired,
};

export default YoutubeScreen;
