import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Text, View } from "react-native";
import tailwind from "twrnc";
import { StyledInput } from "../base/StyledInput";
import { Switch } from "@rneui/themed";
import {
  Roboto_400Regular,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";
import { currentUser, theme } from "../../signals/signals";
import { Signal } from "@preact/signals-react";
import { analyzeBaseCost, analyzeClassDuration } from "../helpers/validation";

const PersonalTrainingSettingsNative = ({
  baseCost,
  baseCostStatus,
  classDuration,
  classDurationStatus,
  isProviding,
}) => {
  let fonts = useFonts({ Roboto_700Bold, Roboto_400Regular });
  if (!fonts) return;

  return (
    <PersonalTrainingSettingsPanel
      baseCost={baseCost}
      baseCostStatus={baseCostStatus}
      classDuration={classDuration}
      classDurationStatus={classDurationStatus}
      isProviding={isProviding}
    />
  );
};

const PersonalTrainingSettingsPanel = ({
  baseCost,
  baseCostStatus,
  classDuration,
  classDurationStatus,
  isProviding,
}) => {
  const toggleSwitch = () => {
    isProviding.value = !isProviding.value;
  };
  const { settings } = currentUser.value;
  const fitCentrPTFee = settings.filter(
    (setting) => setting.key === "FITCENTR_PT_FIXED_FEE"
  )[0].value;

  useEffect(() => {
    if (baseCost.value.length && classDuration.value.length) {
      analyzeBaseCost(baseCost, baseCostStatus, isProviding);
      analyzeClassDuration(classDuration, classDurationStatus, isProviding);
    }
  }, [isProviding.value]);

  return (
    <View style={tailwind`mx-2`}>
      <View style={tailwind`pb-4 mb-4`}>
        <View></View>
        <Text
          style={[
            tailwind`text-[20px] text-[${theme.value.textColor}]`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Personal Training Charges
        </Text>
        <View style={tailwind`flex-row self-center mt-4`}>
          <Switch
            style={tailwind`mt-1`}
            trackColor={{
              false: theme.value.pTEnabledSwitchTrackColorTrue,
              true: theme.value.pTEnabledSwitchTrackColorFalse,
            }}
            ios_backgroundColor={"#3e3e3e"}
            onValueChange={toggleSwitch}
            value={isProviding.value}
          />
          <Text
            style={[
              tailwind`ml-2 font-semibold self-center text-[${
                isProviding ? theme.value.redColor : theme.value.textColor
              }]`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            {isProviding
              ? "Connections for PT Enabled "
              : "Connections for PT Disabled"}
          </Text>
        </View>
      </View>
      <View>
        <View style={tailwind`flex-row`}>
          <View style={tailwind`w-1/2`}>
            <StyledInput
              disabled={!isProviding.value}
              label="Cost for single session"
              onChangeText={(text) => {
                baseCost.value = text;
                analyzeBaseCost(baseCost, baseCostStatus, isProviding);
              }}
              placeholder={Number(fitCentrPTFee).toFixed(2)}
              value={baseCost.value}
              width={"100px"}
              autoCorrect={false}
              fieldStatus={baseCostStatus}
              onBlur={() =>
                analyzeBaseCost(baseCost, baseCostStatus, isProviding)
              }
            />
          </View>
          <View style={tailwind`w-1/2`}>
            <StyledInput
              label={"Duration of session (minutes)"}
              onChangeText={(text) => {
                classDuration.value = text;
                analyzeClassDuration(
                  classDuration,
                  classDurationStatus,
                  isProviding
                );
              }}
              value={classDuration.value}
              placeholder={"30"}
              width={"100px"}
              autoCorrect={false}
              fieldStatus={classDurationStatus}
              onBlur={() =>
                analyzeClassDuration(
                  classDuration,
                  classDurationStatus,
                  isProviding
                )
              }
            />
          </View>
        </View>
      </View>
    </View>
  );
};

PersonalTrainingSettingsNative.propTypes = {
  baseCost: PropTypes.instanceOf(Signal<string>).isRequired,
  baseCostStatus: PropTypes.instanceOf(Signal<string>).isRequired,
  classDuration: PropTypes.instanceOf(Signal<string>).isRequired,
  classDurationStatus: PropTypes.instanceOf(Signal<string>).isRequired,
  isProviding: PropTypes.instanceOf(Signal<boolean>).isRequired,
};

PersonalTrainingSettingsPanel.propTypes = {
  baseCost: PropTypes.instanceOf(Signal<string>).isRequired,
  baseCostStatus: PropTypes.instanceOf(Signal<string>).isRequired,
  classDuration: PropTypes.instanceOf(Signal<string>).isRequired,
  classDurationStatus: PropTypes.instanceOf(Signal<string>).isRequired,
  isProviding: PropTypes.instanceOf(Signal<boolean>).isRequired,
};

export { PersonalTrainingSettingsNative as default };
