import React from "react";
import PropTypes from "prop-types";
import { Inter_600SemiBold, useFonts } from "@expo-google-fonts/inter";
import { Roboto_400Regular } from "@expo-google-fonts/roboto";
import { Signal } from "@preact/signals-react";
import EditableOnDemandThumbnail from "./EditableOnDemandThumbnail";
import EditableReplayThumbnail from "./EditableReplayThumbnail";

const EditableVideoThumbnail = ({ video, onPressOverride, videoThumbnailPath, videoTitleShown, videoTitle }) => {
  const userScheduleId = video.userSchedule?.id;

  let fonts = useFonts({ Inter_600SemiBold, Roboto_400Regular });
  if (!fonts) return;

  return (
    userScheduleId
      ? <EditableReplayThumbnail video={video} onPressOverride={onPressOverride} videoThumbnailPath={videoThumbnailPath} videoTitleShown={videoTitleShown} videoTitle={videoTitle} />
      : <EditableOnDemandThumbnail video={video} onPressOverride={onPressOverride} videoThumbnailPath={videoThumbnailPath} videoTitleShown={videoTitleShown} videoTitle={videoTitle} />
  );
};

EditableVideoThumbnail.propTypes = {
  video: PropTypes.object.isRequired,
  onPressOverride: PropTypes.func,
  videoThumbnailPath: PropTypes.instanceOf(Signal).isRequired,
  videoTitleShown: PropTypes.instanceOf(Signal).isRequired,
  videoTitle: PropTypes.instanceOf(Signal),
};

export default EditableVideoThumbnail;
