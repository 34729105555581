import { Signal } from "@preact/signals-react";
import { Socket } from "socket.io-client";
import { PeerConnectionStatus } from "../types/PeerConnectionStatus";
import { peerStatus, pendingStreams } from "../../../signals/signals";
import { sendIceCandidate } from "./webRTCHandler";
import { PeerPayload } from "../types/PeerPayload";
import { CustomStream } from "../types/CustomStream";
import { RTCPeerConnection } from "react-native-webrtc-web-shim";

export const addAnswerEventListeners = (
  peerConnection: RTCPeerConnection,
  socket: Socket,
  remoteStreams: Signal,
  myUserId: number,
  toUserId: number,
  roomId: string
) => {
  peerConnection.addEventListener("connectionstatechange", () => {
    const existingPeerStatus: PeerConnectionStatus = peerStatus.value.find(
      (peer) => peer.userId === toUserId
    );
    if (existingPeerStatus) {
      existingPeerStatus.connectionStatus = peerConnection.connectionState;
      peerStatus.value = [
        ...peerStatus.value.filter(
          (peer) => peer.userId !== existingPeerStatus.userId
        ),
        existingPeerStatus,
      ];
    }

    switch (peerConnection.connectionState) {
      case "connected":
        if (remoteStreams.value.length > 0) {
          if (
            remoteStreams.value.filter(
              (stream: CustomStream) => stream.userId === toUserId
            ).length > 0
          ) {
            remoteStreams.value = [
              ...remoteStreams.value.filter(
                (stream: CustomStream) => stream.userId !== toUserId
              ),
            ];
          }

          remoteStreams.value = [
            ...remoteStreams.value,
            ...pendingStreams.value,
          ];
        } else if (pendingStreams.value.length) {
          remoteStreams.value = [...pendingStreams.value];
        } else {
          console.log("Error: Answer: pendingStreams is empty");
        }
        break;
      case "closed":
        break;
      case "failed":
        break;
    }
  });

  peerConnection.addEventListener("icecandidate", (event: any) => {
    if (!event.candidate) return;
    if (event.currentTarget.userId === myUserId) return;
    sendIceCandidate(myUserId, toUserId, roomId, event.candidate, socket);
  });

  peerConnection.addEventListener("iceconnectionstatechange", () => {
    const existingPeerStatus = peerStatus.value.find(
      (peer) => peer.userId === toUserId
    );

    if (existingPeerStatus) {
      existingPeerStatus.iceConnectionStatus =
        peerConnection.iceConnectionState;
      peerStatus.value = [
        ...peerStatus.value.filter(
          (peer) => peer.userId !== existingPeerStatus.userId
        ),
        existingPeerStatus,
      ];
    }

    switch (peerConnection.iceConnectionState) {
      case "connected":
      case "completed":
        break;
    }
  });

  peerConnection.addEventListener("negotiationneeded", (event: any) => {
    let sessionConstraints: any = {
      mandatory: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
        VoiceActivityDetection: true,
      },
    };

    peerConnection
      .createOffer(sessionConstraints)
      .then((offer: RTCSessionDescription) => {
        return peerConnection.setLocalDescription(offer);
      })
      .then(() => {
        const payload: PeerPayload = {
          fromUserId: myUserId,
          toUserId: event.target.userId,
          roomId: roomId,
          sdp: peerConnection.localDescription,
        };
        socket.emit("offer", payload);
      })
      .catch((err) =>
        console.log("Error handling negotiation needed event: " + err)
      );
  });

  peerConnection.addEventListener("signalingstatechange", () => {
    const existingPeerStatus: PeerConnectionStatus = peerStatus.value.find(
      (peer) => peer.userId === toUserId
    );

    if (existingPeerStatus) {
      existingPeerStatus.signallingState = peerConnection.signalingState;
      peerStatus.value = [
        ...peerStatus.value.filter(
          (peer) => peer.userId !== existingPeerStatus.userId
        ),
        existingPeerStatus,
      ];
    }
  });

  peerConnection.addEventListener("track", (rtcTrackEvent: RTCTrackEvent) => {
    const existingPeerStatus: PeerConnectionStatus = peerStatus.value.find(
      (peer) => peer.userId === toUserId
    );

    if (existingPeerStatus) {
      existingPeerStatus.mediaStreamTracks++;
      peerStatus.value = [
        ...peerStatus.value.filter(
          (peer) => peer.userId !== existingPeerStatus.userId
        ),
        existingPeerStatus,
      ];
    }

    let newStream: CustomStream = {
      userId: toUserId,
      media: { streams: [rtcTrackEvent.streams[0]] },
    };

    pendingStreams.value = [newStream];
  });
};
