import React from "react";
import PropTypes from "prop-types";
import { QueryClientProvider } from "@tanstack/react-query";
import { ActivityIndicator } from "react-native";
import { queryClient } from "../../base/Constants";
import VideoThumbnail from "../VideoThumbnail";
import { currentUser } from "../../../signals/signals";
import YoutubeThumbnail from "../youtube/YoutubeThumbnail";
import ApiError from "../../error/ApiError";
import useHomePageOnDemandSection from "../../custom-hooks/database/useHomePageOnDemandSection";
import NoOnDemandContentThumbnail from "./NoOnDemandContentThumbnail";

const OnDemandThumbnailStrip = ({ section, isDynamic }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <VideoStrip
        sectionId={section.id}
        userId={currentUser.value.id}
        isDynamic={isDynamic}
      />
    </QueryClientProvider>
  );
};

OnDemandThumbnailStrip.propTypes = {
  section: PropTypes.object.isRequired,
  isDynamic: PropTypes.bool,
};

function VideoStrip({ sectionId, userId, isDynamic }) {
  const { isLoading, error, data } = useHomePageOnDemandSection(
    sectionId,
    userId
  );
  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  if (data.length) {
    return (
      <>
        {data.map((video) => {
          if (isDynamic) {
            return video.youtubeId ? (
              <YoutubeThumbnail
                key={video.id}
                video={{
                  id: video.id,
                  video: video,
                }}
              />
            ) : (
              <VideoThumbnail
                key={video.id}
                video={{ id: video.id, video: video }}
              />
            );
          } else {
            if (video.video)
              return video.video.youtubeId ? (
                <YoutubeThumbnail key={video.id} video={video} />
              ) : (
                <VideoThumbnail key={video.id} video={video} />
              );
          }
        })}
      </>
    );
  } else {
    return <NoOnDemandContentThumbnail />;
  }
}

VideoStrip.propTypes = {
  sectionId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  isDynamic: PropTypes.bool,
};

export default OnDemandThumbnailStrip;
