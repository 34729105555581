import React from "react";
import { ActivityIndicator, Text, View } from "react-native";
import tailwind from "twrnc";
import { currentUser, privateMessages, theme } from "../../signals/signals";
import { useFonts, Roboto_400Regular } from "@expo-google-fonts/roboto";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Image } from "@rneui/themed";
import { DEFAULT_PROFILE_URL } from "../base/Constants";

const MessageView = () => {
  dayjs.extend(utc);
  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <View>
      {privateMessages.value.length ? (
        privateMessages.value.map((message) => {
          const userId = message.user._id;
          const isMyMessage = userId === currentUser.value.id;
          const userName = message.user.name;
          const createdAt = dayjs().utc().format("HH:mm");
          const text = message.text;

          return (
            <View
              key={message._id}
              style={tailwind`w-[80%] ${
                isMyMessage ? "self-end" : "self-start"
              } rounded-lg p-1 my-[1px] bg-[${
                isMyMessage
                  ? theme.value.chatBubbleSelfBackgroundColor
                  : theme.value.chatBubbleBackgroundColor
              }] bg-opacity-50`}
            >
              <View style={tailwind`w-full`}>
                <View>
                  <View style={tailwind`flex-row`}>
                    {!isMyMessage ? (
                      <View style={tailwind`w-[35px]`}>
                        <Image
                          style={tailwind`h-[25px] w-[25px] rounded-full`}
                          source={{
                            uri: message.user.avatar || DEFAULT_PROFILE_URL,
                          }}
                          PlaceholderContent={<ActivityIndicator />}
                        />
                      </View>
                    ) : null}
                    <Text
                      style={[
                        tailwind`text-base text-[14px] pb-2 text-[${
                          isMyMessage
                            ? theme.value.chatBubbleSelfTextColor
                            : theme.value.chatBubbleTextColor
                        }]`,
                        { fontFamily: "Roboto_400Regular" },
                      ]}
                    >
                      {text}
                    </Text>
                  </View>
                  <View style={tailwind`flex-row`}>
                    <View style={tailwind`grow`}>
                      <Text
                        style={[
                          tailwind`text-[10px] text-[${
                            isMyMessage
                              ? theme.value.chatBubbleSelfTimeTextColor
                              : theme.value.chatBubbleTimeTextColor
                          }]`,
                          { fontFamily: "Roboto_400Regular" },
                        ]}
                      >
                        {createdAt}
                      </Text>
                    </View>
                    <View style={tailwind`self-end`}>
                      <Text
                        style={[
                          tailwind`self-end text-[10px] text-[${
                            isMyMessage
                              ? theme.value.chatBubbleSelfTimeTextColor
                              : theme.value.chatBubbleTimeTextColor
                          }]`,
                          { fontFamily: "Roboto_400Regular" },
                        ]}
                      >
                        {isMyMessage ? "Me" : userName}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          );
        })
      ) : (
        <View />
      )}
    </View>
  );
};

export { MessageView as default };
