import React from "react";
import PropTypes from "prop-types";
import { useQuery, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { ActivityIndicator, Text } from "react-native";
import { API_URL, queryClient } from "../base/Constants";
import VideoThumbnail from "../thumbnails/VideoThumbnail";
import { currentUser, searchQuery } from "../../signals/signals";
import YoutubeThumbnail from "../thumbnails/youtube/YoutubeThumbnail";
import ApiError from "../error/ApiError";

const SearchOnDemandThumbnailStrip = ({ section, isDynamic }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <VideoStrip
        sectionId={section.id}
        userId={currentUser.value.id}
        isDynamic={isDynamic}
      />
    </QueryClientProvider>
  );
};

SearchOnDemandThumbnailStrip.propTypes = {
  section: PropTypes.object.isRequired,
  isDynamic: PropTypes.bool,
};

function VideoStrip({ sectionId, userId, isDynamic }) {
  const { isLoading, error, data } = useQuery(
    ["ondemand" + sectionId + searchQuery.value],
    () =>
      axios
        .get(
          API_URL +
          "search/section/" +
          sectionId +
          "/" +
          userId +
          "/" +
          searchQuery.value,
          {
            headers: { authorization: currentUser.value.token },
          }
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: 60000,
    }
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  return (
    <>
      {data.map((video) => {
        if (isDynamic) {
          return video.youtubeId ? (
            <YoutubeThumbnail
              key={video.id}
              video={{
                id: video.id,
                video: video,
              }}
            />
          ) : (
            <VideoThumbnail
              key={video.id}
              id={video.id}
              video={{ id: video.id, video: video }}
            />
          );
        } else {
          if (video.video)
            return video.video.youtubeId ? (
              <YoutubeThumbnail key={video.id} video={video} />
            ) : (
              <VideoThumbnail key={video.id} id={video.id} video={video} />
            );
        }
      })}
    </>
  );
}

VideoStrip.propTypes = {
  sectionId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  isDynamic: PropTypes.bool,
};

export default SearchOnDemandThumbnailStrip;
