import React, { useEffect } from "react";
import { Dimensions, Modal, Platform, Text, View } from "react-native";
import tailwind from "twrnc";
import { fileTooLargeErrorModalVisible, theme, windowOrientation } from "../../signals/signals";
import { useFonts, Roboto_700Bold, Roboto_400Regular } from "@expo-google-fonts/roboto";
import Button from "../base/Button";
import * as ScreenOrientation from "expo-screen-orientation";

const FileTooLargeErrorModal = () => {
  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        windowOrientation.value = getOrientation(orientationInfo.orientation);
      }, 300);
    });
  }, []);

  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };

  const isMobileWebFormat = Platform.OS === "web" &&
    (
      (windowOrientation.value === "portrait" && Dimensions.get("screen").width < 768) ||
      (windowOrientation.value === "landscape" && Dimensions.get("screen").height < 768)
    );

  const modalWidth = Platform.OS === "web" && !isMobileWebFormat ? "50%" : "100%";

  let fonts = useFonts({ Roboto_700Bold, Roboto_400Regular });
  if (!fonts) return;

  return (
    <Modal
      visible={fileTooLargeErrorModalVisible.value}
      animationType="slide"
      transparent={true}
      onRequestClose={() => {
        fileTooLargeErrorModalVisible.value = !fileTooLargeErrorModalVisible.value;
      }}
    >
      <View
        style={tailwind`rounded-lg h-auto p-6 self-center bg-[${theme.value.errorModalBackgroundColor}] border-[0.5px] border-[${theme.value.textColor}] flex-col w-[${modalWidth}] max-h-[400px] absolute bottom-40`}
      >
        <View style={tailwind`w-full pr-4`}>
          <Text
            style={[
              tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold self-center py-2 text-[20px]`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            An error occurred during the upload.
          </Text>
          <View style={tailwind`self-center`}>
            <Text
              style={[tailwind`text-[${theme.value.textColor}]`, { fontFamily: "Roboto_400Regular" }]}
            >
              The file you tried to upload was too large.  Maximum size is 2Mb
            </Text>
            <Text
              style={[tailwind`text-[${theme.value.textColor}]`, { fontFamily: "Roboto_400Regular" }]}
            >
              Please choose a smaller file and try again.
            </Text>
          </View>
        </View>
        <View style={tailwind`flex-row self-center mt-4`}>
          <View>
            <Button
              title="Close"
              onPress={() => {
                console.log("close button");
                fileTooLargeErrorModalVisible.value = false;
              }}
            />
          </View>
        </View>
      </View >
    </Modal>
  );
}

export { FileTooLargeErrorModal as default }