import React, { useEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import { ActivityIndicator, Text, Pressable, View } from "react-native";
import { Image } from "@rneui/themed";
import tailwind, { Style } from "twrnc";
import {
  API_URL,
  CONNECTION_STATUS_BLOCKED,
  CONNECTION_STATUS_CONNECTED,
  CONNECTION_STATUS_IGNORED,
  CONNECTION_STATUS_REQUESTED,
  DEFAULT_PROFILE_URL,
  USER_TYPE_AMBASSADOR,
} from "../base/Constants";
import axios from "axios";
import {
  Roboto_400Regular,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";
import { currentUser, focusedScreen, theme } from "../../signals/signals";
import { ConnectionItem } from "./types/ConnectionItem";
import MiniButton from "../base/MiniButton";
import { Signal, useSignal } from "@preact/signals-react";

const ConnectionThumbnail = ({
  connectionItem,
  handleStatusChange,
}: {
  connectionItem: ConnectionItem;
  handleStatusChange: () => {};
}) => {
  let connectionId: number;
  let userId: number;
  let firstName: string;
  let lastName: string;
  let profileUrl: string;
  let userType: number;
  let isProviding: boolean;
  let isSourceUser: boolean;
  let blockerUserId: number | null;

  connectionId = connectionItem.id;
  if (connectionItem.sourceUser.id === currentUser.value.id) {
    userId = connectionItem.destinationUser.id;
    firstName = connectionItem.destinationUser.firstName;
    lastName = connectionItem.destinationUser.lastName;
    profileUrl = connectionItem.destinationUser.profileUrl;
    userType = connectionItem.destinationUser.type.id;
    isProviding = connectionItem.destinationUser.isProvidingPersonalTraining;
    isSourceUser = true;
    blockerUserId = connectionItem.blockerUser?.id || null;
  } else {
    userId = connectionItem.sourceUser.id;
    firstName = connectionItem.sourceUser.firstName;
    lastName = connectionItem.sourceUser.lastName;
    profileUrl = connectionItem.sourceUser.profileUrl;
    userType = connectionItem.sourceUser.type.id;
    isProviding = connectionItem.sourceUser.isProvidingPersonalTraining;
    isSourceUser = false;
    blockerUserId = connectionItem.blockerUser?.id || null;
  }

  const connectionStatus = useSignal(connectionItem.status);
  const shortName = firstName + " " + lastName;
  const navigation = useNavigation();

  const acceptConnection = () => {
    axios
      .post(
        API_URL + "userconnection/accept",
        {
          id: connectionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.value.token,
          },
        }
      )
      .then(() => {
        connectionStatus.value = CONNECTION_STATUS_CONNECTED;
        handleStatusChange();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const rejectConnection = () => {
    axios
      .post(
        API_URL + "userconnection/reject",
        { id: connectionId },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.value.token,
          },
        }
      )
      .then(() => {
        connectionStatus.value = CONNECTION_STATUS_IGNORED;
        handleStatusChange();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const blockConnection = () => {
    axios
      .post(
        API_URL + "userconnection/block",
        { id: connectionId },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.value.token,
          },
        }
      )
      .then(() => {
        connectionStatus.value = CONNECTION_STATUS_BLOCKED;
        handleStatusChange();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const unblockConnection = () => {
    axios
      .post(
        API_URL + "userconnection/accept",
        {
          id: connectionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.value.token,
          },
        }
      )
      .then(() => {
        connectionStatus.value = CONNECTION_STATUS_CONNECTED;
        handleStatusChange();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const messageConnection = (privateRoom) => {
    focusedScreen.value = "Chat";
    navigation.navigate({
      name: "Chat",
      params: {
        roomId: connectionItem.roomId,
        targetUserId: userId,
        privateRoom: privateRoom,
      },
    } as never);
  };

  const showCalendar = () => {
    focusedScreen.value = "ProviderCalendarScreen";
    navigation.navigate({
      name: "ProviderCalendarScreen",
      params: {
        providerId: userId,
        providerShortName: shortName,
      },
    } as never);
  };

  const cardWidth = "170";
  let cardBackgroundStyle: Signal<Style> = useSignal(
    tailwind`rounded-lg bg-[${theme.value.connectionThumbnailBackgroundColor}] pb-2 m-2 border-[1px] border-[#cccccc] w-[${cardWidth}px]`
  );
  let cardHeaderStyle: Signal<Style> = useSignal(
    tailwind`w-full bg-[#cccccc] rounded-t-[7px] h-[50px]`
  );

  useEffect(() => {
    if (
      blockerUserId === currentUser.value.id &&
      (connectionStatus.value === CONNECTION_STATUS_IGNORED ||
        connectionStatus.value === CONNECTION_STATUS_BLOCKED)
    ) {
      cardBackgroundStyle.value = tailwind`rounded-lg bg-[#aaaaaa] pb-2 m-2 border-[1px] border-[#aaaaaa] w-[${cardWidth}px]`;
      cardHeaderStyle.value = tailwind`w-full bg-[#aaaaaa] rounded-t-[7px] h-[50px]`;
    }

    if (connectionStatus.value === CONNECTION_STATUS_REQUESTED) {
      cardBackgroundStyle.value = tailwind`rounded-lg bg-[${theme.value.connectionThumbnailBackgroundColor}] pb-2 m-2 border-[1px] border-[${theme.value.redColor}] w-[${cardWidth}px]`;
      cardHeaderStyle.value = tailwind`w-full bg-[${theme.value.redColor}] rounded-t-[7px] h-[50px]`;
    }

    if (connectionStatus.value === CONNECTION_STATUS_CONNECTED) {
      cardBackgroundStyle.value = tailwind`rounded-lg bg-[${theme.value.connectionThumbnailBackgroundColor}] pb-2 m-2 border-[1px] border-[#cccccc] w-[${cardWidth}px]`;
      cardHeaderStyle.value = tailwind`w-full bg-[#cccccc] rounded-t-[7px] h-[50px]`;
    }
  }, [connectionStatus.value]);

  let fonts = useFonts({ Roboto_400Regular, Roboto_700Bold });
  if (!fonts) return;

  return (
    <View style={cardBackgroundStyle.value}>
      <View style={cardHeaderStyle.value} />
      <View style={tailwind`self-center top--8`}>
        <Pressable
          style={tailwind`rounded-full`}
          onPress={() => {
            focusedScreen.value = "ViewProfileScreen";
            navigation.navigate({
              name: "ViewProfileScreen",
              params: {
                id: userId,
                type: userType,
              },
            } as never);
          }}
        >
          <Image
            style={tailwind`w-[70px] h-[70px] rounded-full`}
            source={{
              uri: profileUrl || DEFAULT_PROFILE_URL,
            }}
            PlaceholderContent={<ActivityIndicator />}
          />
        </Pressable>
      </View>
      <View style={tailwind`self-center top--8`}>
        <Text
          style={[
            tailwind`capitalize text-[${theme.value.textColor}] mx-2 text-[14px]`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          {shortName}
        </Text>
      </View>
      <View style={tailwind`self-center top--8`}>
        {userType === USER_TYPE_AMBASSADOR ? (
          <View style={tailwind`w-full flex-row px-4`}>
            <Image
              resizeMode="contain"
              style={tailwind`h-[16px] w-[13px] mr-2`}
              source={require("../../assets/ambassador-icon.png")}
              tintColor={theme.value.iconColor}
            />
            <Text
              style={[
                tailwind`text-[${theme.value.textColor}] font-semibold`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              Ambassador
            </Text>
          </View>
        ) : null}
      </View>
      <View style={tailwind`grow`} />
      <View style={tailwind`self-center`}>
        {connectionStatus.value === CONNECTION_STATUS_CONNECTED ? (
          <>
            <View style={tailwind`mb-2`}>
              {isProviding ? (
                <MiniButton title="View Calendar" onPress={showCalendar} />
              ) : null}
            </View>
            <View style={tailwind`mb-2`}>
              <MiniButton
                title="Message"
                onPress={() => messageConnection(false)}
              />
            </View>
          </>
        ) : null}
        {!isSourceUser &&
        connectionStatus.value === CONNECTION_STATUS_REQUESTED ? (
          <>
            <View style={tailwind`mb-2`}>
              <MiniButton title="Accept" onPress={acceptConnection} />
            </View>
            <View style={tailwind`mb-2`}>
              <MiniButton title="Reject" onPress={rejectConnection} />
            </View>
          </>
        ) : null}
        {isSourceUser &&
        connectionStatus.value === CONNECTION_STATUS_REQUESTED ? (
          <View style={tailwind`mb-2`}>
            <Text
              style={[
                tailwind`text-[${theme.value.textColor}] font-semibold`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              Requested
            </Text>
          </View>
        ) : null}
        {connectionStatus.value === CONNECTION_STATUS_CONNECTED ? (
          <View style={tailwind`mb-2`}>
            <MiniButton title="Block" onPress={blockConnection} />
          </View>
        ) : null}
        {blockerUserId === currentUser.value.id &&
        (connectionStatus.value === CONNECTION_STATUS_IGNORED ||
          connectionStatus.value === CONNECTION_STATUS_BLOCKED) ? (
          <View style={tailwind`mb-2`}>
            <MiniButton title="Unblock" onPress={unblockConnection} />
          </View>
        ) : null}
      </View>
    </View>
  );
};

export default ConnectionThumbnail;
