import { Text, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../signals/signals";

const ApiError = () => {
  return (
    <View style={tailwind`justify-center`}>
      <View
        style={tailwind`rounded-lg h-auto p-6 self-center bg-[${theme.value.modalBackgroundColor}]`}
      >
        <View style={tailwind`self-center`}>
          <Text
            style={tailwind`text-[${theme.value.textColor}] font-bold text-sm mt-3 self-center`}
          >
            Unable to communicate with the server
          </Text>
          <Text
            style={tailwind`text-[${theme.value.textColor}] font-bold text-sm mt-3 self-center`}
          >
            Please check your internet connection
          </Text>
        </View>
      </View>
    </View>
  );
};

export { ApiError as default };
