import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { View, Platform, Dimensions } from "react-native";
import tailwind from "twrnc";
import { ResizeMode, Video } from "expo-av";
import { useSignal } from "@preact/signals-react";
import { windowOrientation } from "../../../signals/signals";

const ExpoPlayer = ({ video }) => {
  const videoUri = video.video.path;

  const containerStyle = useSignal();
  const videoStyle = useSignal();
  const playerStyle = useSignal();
  const isMobileWebFormat = useSignal();

  const setVideoLayout = () => {
    const defaultDimensions = {
      height: Dimensions.get("screen").height,
      width: Dimensions.get("screen").width,
    };
    const defaultOrientation =
      defaultDimensions.width < defaultDimensions.height
        ? "portrait"
        : "landscape";

    windowOrientation.value = defaultOrientation;

    let
      playerWidth,
      playerHeight,
      videoHeight,
      videoWidth;

    let windowWidth;
    let windowHeight;

    isMobileWebFormat.value = Platform.OS === "web" &&
      (
        (windowOrientation.value === "portrait" && Dimensions.get("screen").width < 768) ||
        (windowOrientation.value === "landscape" && Dimensions.get("screen").height < 768)
      );

    windowWidth = Dimensions.get("window").width;
    windowHeight = Dimensions.get("window").height;

    if (Platform.isPad) {
      playerWidth = windowWidth;
      playerHeight = windowHeight;
    } else if (Platform.isTV) {
      videoStyle.value = {
        height: videoHeight,
        width: videoWidth,
        verticalAlign: "top",
      };

      playerStyle.value = {
        videoBackgroundColor: "black",
        height: playerHeight,
        width: playerWidth,
        verticalAlign: "top",
      };
    }

    if (Platform.OS === "web") {
      if (isMobileWebFormat.value) {
        if (windowOrientation.value === "portrait") {
          playerWidth = windowWidth;
          playerHeight = playerWidth * 0.6;
          videoWidth = playerHeight * 1.3333333;
          videoHeight = playerHeight;
          containerStyle.value = tailwind`h-[${windowHeight / 3}px] w-full`;
        } else {
          playerHeight = windowHeight - 150;
          playerWidth = playerHeight * 1.3333333;

          videoWidth = playerWidth;
          videoHeight = playerHeight;
          containerStyle.value = tailwind`h-[${windowHeight / 3}px] w-full`;
        }

        playerStyle.value = {
          videoBackgroundColor: "black",
          height: playerHeight,
          width: playerWidth,
          verticalAlign: "top",
        };

      } else {
        playerWidth = windowWidth * (2 / 3);
        playerHeight = windowHeight - 350;
        videoWidth = playerHeight * 1.3333333;
        videoHeight = playerHeight;
        containerStyle.value = tailwind`h-[${windowHeight - 250}px] w-full`;

        playerStyle.value = {
          videoBackgroundColor: "black",
          height: playerHeight,
          width: playerWidth,
          verticalAlign: "top",
        };
      }

      videoStyle.value = {
        height: videoHeight,
        width: videoWidth,
        verticalAlign: "top",
      };
    }

    if (windowOrientation.value === "portrait" && Platform.OS === "ios" || Platform.OS === "android" && !Platform.isPad) {
      if (Platform.OS === "ios") {
        containerStyle.value = tailwind`h-[${windowHeight / 3}px] w-full`;
        playerHeight = windowHeight / 3;
        playerWidth = windowWidth;
        videoHeight = playerHeight;
        videoWidth = windowWidth;

        videoStyle.value = {
          height: videoHeight,
          width: videoWidth,
          verticalAlign: "top",
        };

        playerStyle.value = {
          videoBackgroundColor: "black",
          height: playerHeight,
          width: playerWidth,
          verticalAlign: "top",
        };

      } else if (Platform.OS === "android") {
        if (windowHeight > windowWidth) {
          //android tablet doesn't set Platform.isPad
          containerStyle.value = tailwind`h-[${windowHeight / 3}px] w-full`;
          playerHeight = windowHeight / 3;
          playerWidth = windowWidth;
          videoWidth = windowWidth;
        } else {
          playerWidth = windowWidth;
          playerHeight = 220;
        }

        videoStyle.value = {
          height: videoHeight,
          width: videoWidth,
          verticalAlign: "top",
        };

        playerStyle.value = {
          videoBackgroundColor: "black",
          height: playerHeight,
          width: playerWidth,
          verticalAlign: "top",
        };
      }
    }
  };

  // useEffect(() => {
  //   const listenerSubscription = ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
  //     console.log(getOrientation(orientationInfo.orientation));
  //     setTimeout(() => {
  //       setOrientation(getOrientation(orientationInfo.orientation));
  //     }, 300);
  //   });
  //   return () => {
  //     ScreenOrientation.removeOrientationChangeListener(listenerSubscription);
  //   }
  // }, [orientation]);

  useEffect(() => {
    setVideoLayout();
  }, [windowOrientation.value]);

  useEffect(() => {
    setVideoLayout();
  }, []);

  const refVideo = useRef();
  console.log(videoStyle.value);
  return (
    <View style={[containerStyle.value]}>
      <Video
        ref={refVideo}
        volume={5}
        style={playerStyle.value}
        source={{
          uri: videoUri,
        }}
        videoStyle={videoStyle.value}
        useNativeControls
        resizeMode={ResizeMode.CONTAIN}
        onError={(error) => console.log("error", error)}
        // onLoadStart={() => console.log("onLoadStart", videoUri)}
        // onReadyForDisplay={() => console.log("onReadyForDisplay")}
        shouldPlay={Platform.OS === "web" ? false : true}
        onPlaybackStatusUpdate={(playbackStatus) => {
          if (playbackStatus.error) {
            console.log("playback error");
          }
          if (playbackStatus.didJustFinish) {
            // console.log("playback finished");
            // refVideo.current.playAsync();
          }
        }}
      />
    </View>
  );
};

ExpoPlayer.propTypes = {
  video: PropTypes.object.isRequired,
};

export default ExpoPlayer;
