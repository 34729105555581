import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ScrollView, View } from "react-native";
import tailwind from "twrnc";

import MessageEntry from "./MessageEntry";
import MessageView from "./MessageView";
import {
  connectToLiveChatRoom,
  disconnectFromLiveChatRoom,
} from "../private-room/shared/socketHandler";
import {
  chatSocket,
  currentUser,
  privateMessages,
  theme,
} from "../../signals/signals";
import { Socket } from "socket.io-client";
import { newConnectChatSocket } from "../conference/handlers/callSocketHandler";
import { Message } from "../conference/types/Message";

const SignalChatLive = ({ roomId }): JSX.Element => {
  const scrollViewRef: React.RefObject<ScrollView> = useRef(null);

  useEffect((): any => {
    init();
    return async () => {
      disconnectFromLiveChatRoom();
      privateMessages.value = [];
    };
  }, []);

  const handleReceivedMessage = (newMessage: Message) => {
    privateMessages.value = [...privateMessages.value, newMessage];
    setTimeout(() => onMessageSent(), 200);
  };

  const onMessageSent = () => {
    scrollChatToBottom();
  };

  const scrollChatToBottom = () => {
    scrollViewRef.current &&
      scrollViewRef.current.scrollToEnd({ animated: true });
  };

  useEffect(() => {
    scrollChatToBottom();
  }, [privateMessages.value]);

  const init = async () => {
    const socket: Socket = await newConnectChatSocket();
    chatSocket.value = socket;

    connectToLiveChatRoom(
      chatSocket.value,
      roomId,
      currentUser.value.id,
      currentUser.value.token,
      (newMessage: Message) => handleReceivedMessage(newMessage),
      (messageHistory: Message[]) => {
        if (!messageHistory.length) return;
        privateMessages.value = messageHistory;
        setTimeout(() => onMessageSent(), 200);
      },
      () => {
        //Send join room message
      }
    );
  };

  return (
    <View style={tailwind`w-full pr-[2px] bg-[#fff] max-h-[200px]`}>
      <ScrollView ref={scrollViewRef} style={tailwind`max-h-[200px]`}>
        <MessageView />
      </ScrollView>
      <View>
        <MessageEntry roomId={roomId} onMessageSent={onMessageSent} />
      </View>
    </View>
  );
};

SignalChatLive.propTypes = {
  roomId: PropTypes.string.isRequired,
};

export { SignalChatLive as default };
