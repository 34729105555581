import { Dimensions, SafeAreaView, ScrollView, Text, View } from "react-native";
import DateTimeInput from "../../form/events/DateTimeInput";
import tailwind from "twrnc";
import DurationInput from "../../form/events/DurationInput";
import DifficultyInput from "../../form/shared/DifficultyInput";
import PriceInput from "../../form/events/PriceInput";
import TitleInput from "../../form/events/TitleInput";
import DescriptionInput from "../../form/events/DescriptionInput";
import TagSelector from "../../tags/TagSelector";
import {
  API_URL,
  EVENT_TYPE_BROADCAST,
  TREE_TYPE_BODY,
  TREE_TYPE_USER,
  queryClient,
} from "../../base/Constants";
import {
  userTagSelection,
  bodyTagSelection,
  currentUser,
  theme,
} from "../../../signals/signals";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import EventHeaderBar from "../EventHeaderBar";
import { useSignal } from "@preact/signals-react";
import dayjs from "dayjs";
import { BottomSheetContext } from "../../base/ApplicationContext";
import { useContext, useEffect } from "react";
import Button from "../../base/Button";
import axios from "axios";
import ViewEvent from "../../calendar-screen/ViewEvent";
import {
  analyzeDuration,
  analyzeSalePrice,
  analyzeShortDescription,
  analyzeTime,
} from "../../helpers/validation";
import DatePicker from "react-native-neat-date-picker";
import { setTagSelections } from "../../helpers/tagProcessor";

const EditLiveEvent = ({ event }) => {
  const eventId = event.id;
  const mode = useSignal("date");
  const startDate = useSignal(
    dayjs(dayjs.utc(event.startDate).format("YYYY-MM-DDTHH:mm:[00]Z"))
  );
  const time = useSignal(dayjs.utc(event.startDate).format("HH:mm"));
  const timeStatus = useSignal("");
  const duration = useSignal(event.duration.toString());
  const durationStatus = useSignal("");
  const shortDescription = useSignal(event.shortDescription);
  const shortDescriptionStatus = useSignal("");
  const longDescription = useSignal(event.longDescription);
  const longDescriptionStatus = useSignal("");
  const price = useSignal(event.price.toString());
  const priceStatus = useSignal("");
  const userTagStatus = useSignal("");
  const bodyTagStatus = useSignal("");

  const showDatePicker = useSignal(false);
  const difficulty = useSignal(event.difficulty.id);
  const canSave = useSignal(false);
  const isPublic = useSignal(event.isPublic);

  const { setBottomSheetVisible, setBottomSheetContent } =
    useContext(BottomSheetContext);

  const selectedTags = [];
  event.scheduleTags.forEach((scheduleTag) => {
    selectedTags.push({
      id: scheduleTag.tag.id,
      type: scheduleTag.tag.descendantClosure[0].ancestor.id,
    });
  });

  const onCancel = () => {
    showDatePicker.value = false;
  };

  const onConfirm = (date) => {
    showDatePicker.value = false;

    if (typeof date.dateString !== "undefined") {
      if (mode.value === "date") {
        let currentTime = dayjs(startDate.value).format("THH:mm:ssZ");
        startDate.value = dayjs(
          dayjs(date.dateString).format("YYYY-MM-DD") + currentTime
        );
      } else {
        //time
        let newTime = dayjs(date.dateString).format("THH:mm:ssZ");
        let newStartDateTime =
          dayjs(startDate.value).format("YYYY-MM-DD") + newTime;
        startDate.value = dayjs(newStartDateTime);
      }
    } else {
      if (mode.value === "date") {
        let currentTime = dayjs(startDate.value).format("THH:mm:ssZ");
        startDate.value = dayjs(dayjs(date).format("YYYY-MM-DD") + currentTime);
      } else {
        let newStartDateTime =
          dayjs(startDate.value).format("YYYY-MM-DD") +
          dayjs(date).format("THH:mm:ssZ");
        startDate.value = dayjs(newStartDateTime);
      }
    }
  };

  useEffect(() => {
    setTagSelections(selectedTags);
    analyzeShortDescription(shortDescription, shortDescriptionStatus);
    analyzeDuration(duration, durationStatus);
    analyzeTime(time, timeStatus);
    analyzeSalePrice(price, priceStatus);
  }, []);

  useEffect(() => {
    canSave.value =
      shortDescriptionStatus.value === "" &&
      userTagStatus.value === "" &&
      bodyTagStatus.value === "" &&
      durationStatus.value === "" &&
      timeStatus.value === "";
  }, [
    shortDescriptionStatus.value,
    userTagStatus.value,
    bodyTagStatus.value,
    durationStatus.value,
    timeStatus.value,
  ]);

  const windowHeight = Dimensions.get("window").height;

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <SafeAreaView
      style={tailwind`flex w-full bg-[${theme.value.backgroundColor}]`}
    >
      <ScrollView
        scrollEnabled={true}
        style={tailwind`max-h-[${windowHeight}px] w-full`}
      >
        <EventHeaderBar type={EVENT_TYPE_BROADCAST} />
        <View style={tailwind`p-2 max-w-[800px] self-center`}>
          <View style={tailwind`pt-2 pb-6`}>
            <Text
              style={[
                tailwind`text-[${theme.value.textColor}] font-semibold pb-1 self-center`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              Edit Live Broadcast Event
            </Text>
          </View>
          <View style={tailwind`self-center`}>
            <DateTimeInput
              startDate={startDate}
              time={time}
              timeStatus={timeStatus}
              showDatePicker={showDatePicker}
            />
            <View style={tailwind`flex-row pt-2`}>
              <View style={tailwind`w-1/2`}>
                <DurationInput
                  duration={duration}
                  durationStatus={durationStatus}
                />
              </View>
              <View style={tailwind`w-1/2`}>
                <PriceInput price={price} priceStatus={priceStatus} />
              </View>
            </View>
            <View style={tailwind`pt-2`}>
              <DifficultyInput difficulty={difficulty} />
            </View>
            <View style={tailwind`pt-2`}>
              <TitleInput
                shortDescription={shortDescription}
                shortDescriptionStatus={shortDescriptionStatus}
              />
            </View>
            <View style={tailwind`pt-2`}>
              <DescriptionInput
                longDescription={longDescription}
                longDescriptionStatus={longDescriptionStatus}
              />
            </View>
          </View>
          <View style={tailwind`self-center pt-2`}>
            <Text
              style={[
                tailwind`text-[${theme.value.textColor}] font-semibold pb-1`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              Categories
            </Text>
            <TagSelector tagType={TREE_TYPE_USER} fieldStatus={userTagStatus} />
            {userTagStatus.value ? (
              <View style={tailwind`mb-2`}>
                <Text
                  style={[
                    tailwind`text-xs ml-2 text-[${theme.value.redColor}]`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  {userTagStatus.value}
                </Text>
              </View>
            ) : null}
          </View>
          <View style={tailwind`self-center pt-2`}>
            <TagSelector tagType={TREE_TYPE_BODY} fieldStatus={bodyTagStatus} />
            {bodyTagStatus.value ? (
              <View style={tailwind`mb-2`}>
                <Text
                  style={[
                    tailwind`text-xs ml-2 text-[${theme.value.redColor}]`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  {bodyTagStatus.value}
                </Text>
              </View>
            ) : null}
          </View>
        </View>

        <View style={tailwind`self-center flex-row pt-2 pb-4`}>
          <Button
            title="Close"
            onPress={() => {
              setBottomSheetVisible(false);
            }}
          />
          <Button
            disabled={!canSave.value}
            title="Save"
            onPress={() => {
              axios
                .put(
                  API_URL + "schedule/" + eventId,
                  {
                    userId: currentUser.value.id,
                    startDate: dayjs(startDate.value).format(
                      "YYYY-MM-DDTHH:mm:ssZ"
                    ),
                    duration: duration.value,
                    price: price.value,
                    type: EVENT_TYPE_BROADCAST,
                    shortDescription: shortDescription.value,
                    longDescription: longDescription.value,
                    participants: [],
                    tags: [
                      ...userTagSelection.value.filter((tag) => !tag.deleted),
                      ...bodyTagSelection.value.filter((tag) => !tag.deleted),
                    ],
                    difficulty: difficulty.value,
                    rule: null,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      authorization: currentUser.value.token,
                    },
                  }
                )
                .then((res) => {
                  queryClient.invalidateQueries([
                    "schedule" +
                      currentUser.value.id +
                      dayjs().format("YYYY-MM-DD"),
                  ]);

                  queryClient.invalidateQueries([
                    "providerSchedule" +
                      currentUser.value.id +
                      dayjs().format("YYYY-MM-DD"),
                  ]);
                  queryClient.invalidateQueries(["homepagesections"]);

                  const event = res.data.raw;
                  setBottomSheetContent(<ViewEvent event={event} />);
                  return res.data;
                });
            }}
          />
        </View>
      </ScrollView>
      {mode.value === "date" && startDate.value.isValid() ? (
        <DatePicker
          isVisible={showDatePicker.value}
          mode={"single"}
          initialDate={new Date(startDate.value.toISOString())}
          onCancel={onCancel}
          onConfirm={onConfirm}
          colorOptions={{
            headerColor: theme.value.backgroundColor,
            selectedDateBackgroundColor: theme.value.textColor,
            confirmButtonColor: theme.value.textColor,
            weekDaysColor: theme.value.backgroundColor,
            changeYearModalColor: theme.value.textColor,
            dateTextColor: theme.value.textColor,
            headerTextColor: theme.value.textColor,
          }}
        />
      ) : null}
    </SafeAreaView>
  );
};

export { EditLiveEvent as default };
