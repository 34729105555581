import { StyledInput } from "../../base/StyledInput";
import { analyzeVideoDescription } from "../../helpers/validation";

const DescriptionInput = ({ longDescription, longDescriptionStatus }) => {
  return (
    <StyledInput
      label={"Description"}
      multiline={true}
      numberOfLines={3}
      placeholder={"Description"}
      autoCorrect={true}
      onChangeText={(text) => {
        longDescription.value = text;
        analyzeVideoDescription(longDescription, longDescriptionStatus);
      }}
      onBlur={() =>
        analyzeVideoDescription(longDescription, longDescriptionStatus)
      }
      width={"355px"}
      fieldStatus={longDescriptionStatus}
      height={"100px"}
      value={longDescription.value}
    />
  );
};

export { DescriptionInput as default };
