import React from "react";
import { View } from "react-native";
import { theme } from "../../signals/signals";

const HorizontalRule = () => {
  return (
    <View
      style={{
        marginTop: 5,
        marginBottom: 8,
        borderTopColor: theme.value.horizontalRuleColor,
        borderTopWidth: 1,
        resizeMode: "contain",
        alignSelf: "center",
        width: "90%",
      }}
    ></View>
  );
};

export default HorizontalRule;
