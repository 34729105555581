import React, { useContext, useEffect } from "react";
import { View, Text, SectionList, Platform, Dimensions } from "react-native";
import { useFonts, Roboto_700Bold } from "@expo-google-fonts/roboto";
import tailwind from "twrnc";
import {
  EVENT_TYPE_BROADCAST,
  SECTION_LIVE_THIS_WEEK,
  queryClient,
} from "../components/base/Constants";
import SectionContainer from "../components/home-screen/SectionContainer";
import { useQuery, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../components/base/Constants";
import { ActivityIndicator } from "react-native";
import { BottomSheetContext } from "../components/base/ApplicationContext";
import PushNotificationHandler from "../components/base/PushNotificationHandler";
import {
  currentUser,
  emailVerificationCode,
  emailValidationErrorModalVisible,
  focusedScreen,
  isEmailAddressVerified,
  modalVisible,
  redirectParams,
  redirectUrl,
  theme,
} from "../signals/signals";
import EmailVerifiedModal from "../components/modals/EmailVerifiedModal";
import { useNavigation } from "@react-navigation/native";
import ViewEvent from "../components/calendar-screen/ViewEvent";
import ApiError from "../components/error/ApiError";
import useHomePageSections from "../components/custom-hooks/database/useHomePageSections";

const HomeScreen = () => {
  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);
  const navigation = useNavigation();
  const { token } = currentUser.value;

  useEffect(() => {
    const handleEmailVerification = () => {
      axios
        .post(
          API_URL + "users/email/check",
          {
            code: emailVerificationCode.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: token,
            },
          }
        )
        .then(() => {
          isEmailAddressVerified.value = true;
          modalVisible.value = true;
        })
        .catch(() => {
          emailValidationErrorModalVisible.value = true;
        });
    };

    if (redirectUrl.value) {
      switch (redirectUrl.value) {
        case "verify-email":
          handleEmailVerification();
          break;
        case "ViewProfileScreen":
          focusedScreen.value = "ViewProfileScreen";
          navigation.navigate({
            name: "ViewProfileScreen",
            params: redirectParams.value,
          } as never);
          break;
        case "live":
          axios
            .get(API_URL + "schedule/" + redirectParams.value, {
              headers: { authorization: currentUser.value.token },
            })
            .then((response) => {
              if (response.data) {
                setBottomSheetContent(<ViewEvent event={response.data} />);
                setBottomSheetVisible(true);
              }
            })
            .catch((err) => console.log(err));
          break;
      }
      redirectUrl.value = "";
      redirectParams.value = {};
    }
  }, []);

  let [fontsLoaded, error] = useFonts({ Roboto_700Bold });
  if (!fontsLoaded || error) return null;

  return (
    <View
      style={tailwind`flex h-full bg-[${theme.value.backgroundColor}] pl-[${
        Platform.OS === "web" && Dimensions.get("screen").width > 768
          ? "50px"
          : "10px"
      }]`}
    >
      {/* <PushNotificationHandler /> */}
      <QueryClientProvider client={queryClient}>
        <Sections />
      </QueryClientProvider>
      <EmailVerifiedModal />
    </View>
  );
};

function Sections() {
  const { isLoading, error, data } = useHomePageSections();
  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const formattedData = data.map((item) => ({
    id: item.data?.id || item.id,
    title: item.data?.title || item.title || "",
    type: item.data?.type || item.type || "",
    data: [item],
  }));

  return (
    <SectionList
      sections={formattedData}
      keyExtractor={(item) => item.id.toString()}
      renderItem={({ item }) => {
        return <SectionContainer item={item} />;
      }}
      renderSectionHeader={({ section }) => {
        const title = section.title;

        return section.id === SECTION_LIVE_THIS_WEEK ||
          section.id !== SECTION_LIVE_THIS_WEEK ? (
          <View
            style={tailwind`flex-row bg-[${theme.value.backgroundColor}] pt-6 pb-4`}
          >
            <View>
              <Text
                style={[
                  tailwind`text-[${theme.value.textColor}] text-[20px]`,
                  { fontFamily: "Roboto_700Bold" },
                ]}
              >
                {title}
              </Text>
            </View>
            {section.type.id === EVENT_TYPE_BROADCAST ? (
              <View
                style={tailwind`rounded-full w-[10px] h-[10px] bg-[${theme.value.liveDotColor}] mt-2 ml-2`}
              />
            ) : null}
          </View>
        ) : null;
      }}
    />
  );
}

export default HomeScreen;
