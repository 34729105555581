import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Dimensions, Linking, Modal, Platform, Pressable, Text, View } from "react-native";
import tailwind from "twrnc";
import { currentUser, modalVisible, theme, windowOrientation } from "../../signals/signals";
import { useFonts, Roboto_700Bold, Roboto_400Regular } from "@expo-google-fonts/roboto";
import Button from "../base/Button";
import axios from "axios";
import { API_URL, queryClient } from "../base/Constants";
import { BottomSheetContext } from "../base/ApplicationContext";
import * as ScreenOrientation from "expo-screen-orientation";
import dayjs from "dayjs";

const PublishConfirmationModal = ({ event }) => {
  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        windowOrientation.value = getOrientation(orientationInfo.orientation);
      }, 300);
    });
  }, []);

  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };

  const isMobileWebFormat = Platform.OS === "web" &&
    (
      (windowOrientation.value === "portrait" && Dimensions.get("screen").width < 768) ||
      (windowOrientation.value === "landscape" && Dimensions.get("screen").height < 768)
    );

  const modalWidth = Platform.OS === "web" && !isMobileWebFormat ? "50%" : "100%";
  const { setBottomSheetVisible } = useContext(BottomSheetContext);

  let fonts = useFonts({ Roboto_700Bold, Roboto_400Regular });
  if (!fonts) return;

  return (
    <Modal
      visible={modalVisible.value}
      animationType="slide"
      transparent={true}
      onRequestClose={() => {
        modalVisible.value = !modalVisible.value;
      }}
    >
      <View
        style={tailwind`rounded-lg h-auto p-6 self-center bg-[${theme.value.modalBackgroundColor}] border-[0.5px] border-[${theme.value.textColor}] flex-col w-[${modalWidth}] max-h-[400px] absolute bottom-40`}
      >
        <View style={tailwind`w-full pr-4`}>
          <Text
            style={[
              tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold self-center py-2 text-[20px]`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            Publish Livestream
          </Text>
          <View style={tailwind`self-center`}>
            <Text
              style={[tailwind`text-[${theme.value.textColor}]`, { fontFamily: "Roboto_400Regular" }]}
            >
              By publishing this livestream, you agree to the terms and conditions
            </Text>
            <Pressable style={tailwind`self-center mt-2`} onPress={() => { Linking.openURL("https://www.fitcentr.com/terms") }}>
              <Text
                style={[tailwind`text-[${theme.value.textColor}] underline`, { fontFamily: "Roboto_400Regular" }]}
              >
                &nbsp;View terms and conditions
              </Text>
            </Pressable>
            <Text
              style={[tailwind`text-[${theme.value.textColor}]`, { fontFamily: "Roboto_400Regular" }]}
            >
              .
            </Text>
          </View>
          <View style={tailwind`self-center`}>
            <Text
              style={[
                tailwind`text-[${theme.value.textColor}]`,
                { fontFamily: "Roboto_400Regular" }
              ]}
            >
              Once a payment has been received for this event, the event is locked and no further changes are allowed.
            </Text>
          </View>
        </View>
        <View style={tailwind`flex-row self-center mt-4`}>
          <View>
            <Button
              title="Confirm"
              onPress={() => {
                axios
                  .post(
                    API_URL + "schedule/publish",
                    {
                      eventId: event.id,
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        authorization: currentUser.value.token,
                      },
                    }
                  )
                  .then((res) => {
                    queryClient.invalidateQueries(
                      "schedule" +
                      currentUser.value.id +
                      dayjs().format("YYYY-MM-DD"),
                      "homepagesections"
                    );

                    modalVisible.value = false;
                    setBottomSheetVisible(false);
                    return res.data;
                  });
              }}
            />
          </View>
          <View>
            <Button
              title="Cancel"
              onPress={() => {
                modalVisible.value = !modalVisible.value;
              }}
            />
          </View>
        </View>
      </View >
    </Modal>
  );
}

PublishConfirmationModal.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    type: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    isPublic: PropTypes.bool.isRequired,
    duration: PropTypes.number.isRequired,
    shortDescription: PropTypes.string.isRequired,
    longDescription: PropTypes.string,
    difficulty: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    participants: PropTypes.array,
    rule: PropTypes.shape({
      id: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      dayOfMonth: PropTypes.number,
      daysOfWeek: PropTypes.string,
      repeatType: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
    scheduleTags: PropTypes.array,
  }),
};

export { PublishConfirmationModal as default }