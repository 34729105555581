import React from "react";
import PropTypes from "prop-types";
import TrainerThumbnail from "./TrainerThumbnail";
import { QueryClientProvider } from "@tanstack/react-query";
import { ActivityIndicator } from "react-native";
import { queryClient } from "../../base/Constants";
import { currentUser } from "../../../signals/signals";
import ApiError from "../../error/ApiError";
import useHomePageTrainersSection from "../../custom-hooks/database/useHomePageTrainersSection";
import NoTrainerContentThumbnail from "./NoTrainerContentThumbnail";

const TrainerThumbnailStrip = ({ section }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThumbnailStrip sectionId={section.id} userId={currentUser.value.id} />
    </QueryClientProvider>
  );
};

TrainerThumbnailStrip.propTypes = {
  section: PropTypes.object.isRequired,
};

function ThumbnailStrip({ sectionId, userId }) {
  const { isLoading, error, data } = useHomePageTrainersSection(
    sectionId,
    userId
  );
  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  let trainers = data;
  if (data.length && data[0].trainer) {
    trainers = data.map((trainer) => trainer.trainer);
  }

  if (data.length) {
    return (
      <>
        {trainers.map((trainer) => {
          return <TrainerThumbnail key={trainer.id} trainer={trainer} />;
        })}
      </>
    );
  } else {
    return <NoTrainerContentThumbnail />;
  }
}

ThumbnailStrip.propTypes = {
  sectionId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};

export default TrainerThumbnailStrip;
