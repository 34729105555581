import React from "react";
import PropTypes from "prop-types";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "../components/base/Constants";
import EditProfileContainer from "../components/edit-profile-screen/EditProfileContainer";

const EditProfileScreen = (props: any): JSX.Element => {
  const { userId, userType } = props.route.params;
  return (
    <QueryClientProvider client={queryClient}>
      <EditProfileContainer userId={userId} userType={userType} />
    </QueryClientProvider>
  );
};

EditProfileScreen.propTypes = {
  route: PropTypes.object.isRequired,
};

export { EditProfileScreen };
