import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Dimensions, Platform } from "react-native";
import { Signal } from "@preact/signals-react";
import { analyzeCity } from "../../helpers/validation";
import { StyledInput } from "../../base/StyledInput";

const City = ({ city, cityStatus }) => {
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;

  useEffect(() => {
    if (city.value?.length) {
      analyzeCity(city, cityStatus);
    }
  }, []);

  return (
    <>
      <StyledInput
        label={"City"}
        onChangeText={(text) => {
          city.value = text;
          analyzeCity(city, cityStatus);
        }}
        value={city.value}
        width={isMobileWebFormat ? "300px" : "350px"}
        onBlur={() => analyzeCity(city, cityStatus)}
        autoCorrect={false}
        fieldStatus={cityStatus}
      />
    </>
  );
};

City.propTypes = {
  city: PropTypes.instanceOf(Signal).isRequired,
  cityStatus: PropTypes.instanceOf(Signal).isRequired,
};

export { City as default };
