import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Dimensions, Platform, SafeAreaView, ScrollView, Text, View } from "react-native";
import tailwind from "twrnc";
import { currentUser, focusedScreen, isEmailAddressVerified, isProfileComplete, theme } from "../../signals/signals";
import { BottomSheetContext } from "../base/ApplicationContext";
import MiniTrainerProfilePanel from "../profile-screen/MiniTrainerProfilePanel";
import Button from "../base/Button";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSignal } from "@preact/signals-react";
import EventAttendee from "./EventAttendee";
import { useNavigation } from "@react-navigation/native";
import axios from "axios";
import { API_URL } from "../base/Constants";
import {
  Roboto_400Regular,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";
import { Inter_400Regular } from "@expo-google-fonts/inter";
import * as ScreenOrientation from "expo-screen-orientation";
import EditPTEvent from "../event-screen/edit/EditPTEvent";

const ViewPrivatePTEvent = ({ event }) => {
  const typeName = "Private Personal Training";
  const headerColour = theme.value.calendarEventBookableColor;
  const navigation = useNavigation();
  const isHistoric = useSignal(false);
  const canJoinPrivateRoom = useSignal(false);
  const isBooked = useSignal(false);
  const isStalled = useSignal(false);

  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);
  const {
    startDate,
    shortDescription,
    bookings,
    duration,
    participants,
    longDescription,
    price,
  } = event;
  const time = dayjs(event.startDate).format("HH:mm");
  const hostId = event.userId;
  const isHost = hostId === currentUser.value.id;
  const windowOrientation = useSignal("portrait");
  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        windowOrientation.value = getOrientation(orientationInfo.orientation);
      }, 300);
    });
  }, []);

  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };

  const isMobileWebFormat = Platform.OS === "web" &&
    (
      (windowOrientation.value === "portrait" && Dimensions.get("screen").width < 768) ||
      (windowOrientation.value === "landscape" && Dimensions.get("screen").height < 768)
    );

  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  let startTimeButtonLabel;
  if (dayjs.utc().diff(startDate) > 0) {
    startTimeButtonLabel = "Expired";
  } else {
    startTimeButtonLabel = dayjs.utc().to(startDate);
  }

  const handlePTBooking = () => {
    axios
      .post(
        API_URL + "booking",
        {
          eventId: event.id,
          eventDate: event.startDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.value.token,
          },
        }
      )
      .then(async (res) => {
        if (!res.error) {
          setBottomSheetVisible(false);
          navigation.navigate("OrderSummaryScreen", {
            bookingId: res.data.id,
            userScheduleId: event.id,
          });
        } else {
          console.log(res.error);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    isHistoric.value = dayjs(startDate).diff(dayjs(), "minute") < -duration;
    if (bookings && bookings.length) {
      if (bookings[0].user.id === currentUser.value.id) {
        const payments = bookings[0].payments;
        if (payments.length) {
          payments.forEach((payment) => {
            if (payment.responses) {
              isStalled.value = true;
              payment.responses.forEach((response) => {
                if (response.state === "COMPLETED" || response.state === "AUTHORISED") {
                  isStalled.value = false;
                  isBooked.value = true;
                }
              });
            } else {
              isStalled.value = true;
            }
          });
        } else {
          isStalled.value = true;
        }
      } else {
        isBooked.value = true;
      }
    }
  }, []);

  useEffect(() => {
    if (
      dayjs(startDate).diff(dayjs(), "minute") < 30 &&
      dayjs(startDate).diff(dayjs(), "minute") > -duration
    ) {
      canJoinPrivateRoom.value = true;
    }
    isHistoric.value = dayjs(startDate).diff(dayjs(), "minute") < -duration;
  }, []);

  let eventParticipants = [];
  participants.forEach((participant) => {
    const newParticipant = {
      id: participant.user.id,
      profileUrl: participant.user.profileUrl,
      name: participant.user.firstName,
    };
    if (currentUser.value.id !== participant.user.id) {
      eventParticipants.push(newParticipant);
    }
  });

  let fonts = useFonts({ Roboto_400Regular, Roboto_700Bold, Inter_400Regular });
  if (!fonts) return;

  return (
    <SafeAreaView style={tailwind`bg-[${theme.value.backgroundColor}]`}>
      <View style={tailwind`pb-10`}>
        <View
          style={tailwind`${headerColour &&
            "border-t-[" + headerColour + "] border-t-[4px] p-1 min-h-[15px]"
            }`}
        />
        <View style={tailwind`flex-row m-2 w-[600px] self-center`}>
          <View style={!isHost ? tailwind`${isMobileWebFormat && windowOrientation.value === "portrait" ? "w-full" : "w-1/2"} pr-4` : tailwind`w-full pr-4`}>
            <Text
              style={[
                tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold self-center pt-2 text-[20px]`,
                { fontFamily: "Roboto_700Bold" },
              ]}
            >
              {typeName}
            </Text>
            <View style={tailwind`flex-row self-center`}>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {dayjs(startDate).format("ddd MMM DD")}
              </Text>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] mx-2`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                at
              </Text>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {time}
              </Text>
            </View>
            <View style={tailwind`self-center`}>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] text-[12px] font-semibold ml-1 self-start grow`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {duration} MIN
              </Text>
            </View>
            {isHost && (!isBooked.value || isStalled.value) ? (
              <View style={tailwind`self-center`}>
                <Text
                  style={[
                    tailwind`text-[${theme.value.redColor}] text-[12px] font-semibold ml-1 self-start grow`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  &pound;{price.toFixed(2)} OUTSTANDING
                </Text>
              </View>
            ) : null}
            <View style={tailwind`my-4`}>
              <Text
                style={[
                  tailwind`self-center text-[${theme.value.textColor}] font-semibold`,
                  { fontFamily: "Roboto_700Bold" },
                ]}
              >
                {shortDescription}
              </Text>
            </View>
            <View style={tailwind`mb-4`}>
              <Text
                style={[
                  tailwind`self-center text-[${theme.value.textColor}] font-semibold mr-2`,
                  { fontFamily: "Inter_400Regular" },
                ]}
              >
                {longDescription}
              </Text>
            </View>

            <View style={tailwind` p-1 mb-2`}>
              <Text
                style={[
                  tailwind`self-center text-[${theme.value.textColor}] font-semibold`,
                  { fontFamily: "Roboto_700Bold" },
                ]}
              >
                Attending
              </Text>
              <ScrollView style={tailwind`max-h-[200px]`}>
                <View style={tailwind`flex-row flex-wrap`}>
                  {eventParticipants.map((attendee) => {
                    return (
                      <View key={attendee.id} style={tailwind`m-2`}>
                        <EventAttendee attendee={attendee} />
                        <Text
                          style={[
                            tailwind`self-center text-[${theme.value.textColor}]`,
                            { fontFamily: "Inter_400Regular" },
                          ]}
                        >
                          {attendee.name}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </ScrollView>
            </View>
          </View>
          {!isHost && (Platform.OS === "web" && isMobileWebFormat && windowOrientation.value === "landscape") ? (
            <View style={tailwind`mb-2 w-1/2 pl-4`}>
              <MiniTrainerProfilePanel id={hostId} />
            </View>
          ) : null}
        </View>
        <View style={tailwind`self-center flex-row`}>
          {!isHost &&
            !isHistoric.value &&
            (!isBooked.value || isStalled.value) ? (
            <Button title={"Pay £" + price.toFixed(2)} onPress={() => {
              if (isProfileComplete.value && isEmailAddressVerified.value) {
                handlePTBooking();
              } else {
                focusedScreen.value = "EditProfileScreen";
                navigation.navigate("EditProfileScreen", {
                  userId: currentUser.value.id,
                  userType: currentUser.value.type,
                });
                setBottomSheetVisible(false);
              }
            }} />
          ) : null}
          {isHost ? (
            <>
              <Button
                disabled={isBooked.value}
                title="Edit"
                onPress={() => {
                  setBottomSheetContent(
                    <EditPTEvent event={event} />
                  );
                }}
              />
            </>
          ) : null}
          {canJoinPrivateRoom.value &&
            isBooked.value &&
            !isStalled.value &&
            !isHistoric.value ? (
            <Button
              title="Join"
              onPress={() => {
                setBottomSheetVisible(false);
                focusedScreen.value = "Conference";
                navigation.navigate("Conference", { event: event });
              }}
            />
          ) : null}
          {!canJoinPrivateRoom.value &&
            isBooked.value &&
            !isStalled.value &&
            !isHistoric.value ? (
            <Button
              title={startTimeButtonLabel}
              disabled={true}
              onPress={() => { }}
            />
          ) : null}
          <Button
            title="Close"
            onPress={() => {
              setBottomSheetVisible(false);
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

ViewPrivatePTEvent.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    type: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    isPublic: PropTypes.bool.isRequired,
    duration: PropTypes.number.isRequired,
    shortDescription: PropTypes.string.isRequired,
    longDescription: PropTypes.string,
    difficulty: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    participants: PropTypes.array,
    location: PropTypes.string,
    tags: PropTypes.arrayOf({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    rule: PropTypes.shape({
      id: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      dayOfMonth: PropTypes.number,
      daysOfWeek: PropTypes.string,
      repeatType: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
    scheduleTags: PropTypes.array,
    bookings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired,
        eventDate: PropTypes.string.isRequired,
        updatedAt: PropTypes.string.isRequired,
        user: PropTypes.shape({
          id: PropTypes.number.isRequired,
          createdAt: PropTypes.string.isRequired,
        }).isRequired,
        payments: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
          })
        ),
      })
    ),
  }),
};

export { ViewPrivatePTEvent as default };
