import { Socket } from "socket.io-client";
import {
  chatSocket,
  participants,
  peerConnections,
  peerStatus,
  remoteStreams,
} from "../../../signals/signals";
import { PeerConnectionStatus } from "../types/PeerConnectionStatus";
import { RoomMember } from "../types/RoomMember";
import { RTCPeerConnection } from "react-native-webrtc-web-shim";

export type ConferenceLobby = {
  roomId: string;
  participants: number[];
  hostId: number;
  hostName: string;
  hostProfileUrl: string;
};

export const connectToConferenceChat = async (
  socket: Socket,
  roomId: string,
  hostId: number,
  userId: number,
  newMessageCallback: (newMessage: any) => void
): Promise<ConferenceLobby> => {
  try {
    await attachChatSocketListeners(socket, userId, roomId, newMessageCallback);

    const newLobby: ConferenceLobby = {
      roomId: roomId,
      participants: [userId],
      hostId: hostId,
      hostName: "The hostname",
      hostProfileUrl: "The host profile URL",
    };
    return Promise.resolve(newLobby);
  } catch (err) {
    console.log("Error connecting to conference room", err);
    return Promise.reject(err);
  }
};

const attachChatSocketListeners = async (
  socket: Socket,
  myUserId: number,
  roomId: string,
  newMessageCallback: (newMessage: any) => void
): Promise<void> => {
  socket.on("connected", () => {
    try {
      chatSocket.value.emit("join conference room", roomId, myUserId);
    } catch (err) {
      console.log("caught an error", err);
    }
  });

  socket.on("room joined", (room) => {
    console.log("room joined", room);
    setParticipantsInRoom(room);
  });

  socket.on("authentication error", () => {
    console.log("Authentication error from socket server");
  });

  socket.on("user disconnected from you", (userId, room) => {
    setParticipantsInRoom(room);
    clearDownLeavingMember(userId);
  });

  socket.on("user left", (userId: number, room) => {
    setParticipantsInRoom(room);
    clearDownLeavingMember(userId);
  });

  socket.on("user left call", (userId: number, room: string) => {
    setParticipantsInRoom(room);
    clearDownLeavingMember(userId);
  });

  socket.on("new-message", (newMessage: any) => {
    console.log("chatSocketHandlers.ts: newMessage", newMessage);
    if (newMessage.user._id !== myUserId) {
      newMessageCallback(newMessage);
    }
  });

  socket.on(
    "user joined",
    async (
      joiningUserId: number,
      room: any,
      socketId: string,
      messageHistory: any
    ) => {
      console.log("user joined (chatSocketHandler)", joiningUserId, room, socketId, messageHistory);
      if (joiningUserId !== myUserId) {
        setParticipantsInRoom(room);
      }
    }
  );
  return Promise.resolve();
};

export const clearDownLeavingMember = (userId: number) => {
  const streamOfLeavingMember = remoteStreams.value.find(
    (stream) => stream.userId === userId
  );

  if (streamOfLeavingMember) {
    remoteStreams.value = remoteStreams.value.filter(
      (stream) => stream.userId !== userId
    );
  }

  resetPeerConnectionStatus(userId);
  updatePeerStatusForLeavingMember(userId);
};

const updatePeerStatusForLeavingMember = (targetUserId: number) => {
  peerStatus.value = peerStatus.value.filter(
    (peerStatus: PeerConnectionStatus) => {
      if (peerStatus.userId === targetUserId) {
        peerStatus.connectionStatus = "Disconnected";
        peerStatus.iceConnectionStatus = "closed";
        peerStatus.signallingState = "closed";
        peerStatus.mediaStreamTracks = 0;
      }
      return peerStatus;
    }
  );
};

const resetPeerConnectionStatus = (userId: number) => {
  const leavingPeerConnection: RTCPeerConnection = peerConnections.value.find(
    (pc) => pc.userId === userId
  );
  if (leavingPeerConnection) {
    leavingPeerConnection.close();
    peerConnections.value = peerConnections.value.filter(
      (pc) => pc.userId !== userId
    );
  }
};

const setParticipantsInRoom = (room: any) => {
  let member: any;

  let clearedParticipants = participants.value;
  clearedParticipants.map((participant) => (participant.inRoom = false));

  room.members?.forEach((roomMember: RoomMember) => {
    member = clearedParticipants.find(
      (participant) => participant.id === roomMember.userId
    );

    if (member) {
      member.inRoom = true;

      clearedParticipants = clearedParticipants.filter(
        (participant) => participant.id !== roomMember.userId
      );

      clearedParticipants.push(member);
      participants.value = clearedParticipants;
    } else {
      console.log("Error: got an unexpected room member", roomMember);
    }
  });
};
