import React, { Text, View } from "react-native";
import tailwind from "twrnc";
import dayjs from "dayjs";
import MiniTag from "../base/MiniTag";
import { theme } from "../../signals/signals";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import { FitCentrEvent } from "../broadcast-screen/types/FitCentrEvent";

const LiveStreamDetails = ({ event }: { event: FitCentrEvent }) => {
  const {
    shortDescription,
    longDescription,
    scheduleTags,
    duration,
    startDate,
    difficulty,
  } = event;

  const formattedDate = dayjs(startDate).format("ddd, DD MMM YYYY");
  const formattedTime = dayjs(startDate).format("HH:mm");

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <>
      <View style={tailwind`bg-[#ffffff] rounded-lg p-2`}>
        <View style={tailwind`py-2`}>
          <Text
            style={[
              tailwind`text-[${theme.value.textColor}] font-semibold self-center`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            {formattedDate} {formattedTime}
          </Text>
          <Text
            style={[
              tailwind`text-[${theme.value.textColor}] font-semibold mt-2 text-[16px] self-center`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            {shortDescription}
          </Text>
        </View>
        <View style={tailwind`flex-row`}>
          <View style={tailwind`w-1/2 pr-2`}>
            <Text
              style={[
                tailwind`text-[${theme.value.textColor}] font-semibold mt-2 text-[16px] self-center text-xs`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              Difficulty {difficulty.name.toUpperCase()}
            </Text>
          </View>
          <View style={tailwind`w-1/2 pr-2`}>
            <Text
              style={[
                tailwind`text-[${theme.value.textColor}] font-semibold mt-2 self-end`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              {duration} minutes
            </Text>
          </View>
        </View>
        <View style={tailwind`w-full px-2`}>
          <Text
            style={[
              tailwind`text-[${theme.value.textColor}] mt-2`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            {longDescription}
          </Text>
        </View>
        <View style={tailwind`flex flex-row flex-wrap px-2 mt-2`}>
          {scheduleTags.map((scheduleTag) => (
            <MiniTag
              key={scheduleTag.tag.id}
              label={scheduleTag.tag.tagName}
              colorKey={scheduleTag.tag.tagColorKey}
            />
          ))}
        </View>
      </View>
    </>
  );
};

export default LiveStreamDetails;
