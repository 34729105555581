import { Text, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../../signals/signals";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";

const InLibraryLabel = () => {
  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <View
      style={tailwind`rounded-lg`}
    >
      <Text
        style={[
          tailwind`text-[${theme.value.coinsIconColor}] self-center text-[10px]`,
          { fontFamily: "Roboto_400Regular" },
        ]}
      >
        IN LIBRARY
      </Text>
    </View>
  );
};

export { InLibraryLabel as default };
