export const satisfyMinimumCount = (
  value: number,
  minimumCount: number
): boolean => {
  return value >= minimumCount ? true : false;
};

export const satisfyTextRequired = (value: string): boolean => {
  return value?.length > 0 ? true : false;
};

export const satisfyOnlyLetters = (value: string): boolean => {
  return /^[a-zA-Z!\s]+$/.test(value) ? true : false;
};

export const satisfyDescriptionText = (value: string): boolean => {
  return /^[a-zA-Z0-9\'\`\&\(\)\-\!;:\s]+$/.test(value) ? true : false;
};

export const satisfyOnlyLettersAndNumbers = (value: string): boolean => {
  return /^[a-zA-Z0-9]+$/.test(value) ? true : false;
};

export const satisfyMinimumNumericalValue = (
  value: number,
  minimumValue: number
): boolean => {
  return value >= minimumValue ? true : false;
};

export const satisfyMaximumNumericalValue = (
  value: number,
  maximumValue: number
): boolean => {
  return value <= maximumValue ? true : false;
};

export const satisfyOnlyNumbersWithDecimal = (value: string): boolean => {
  return /^[0-9.]+$/.test(value) ? true : false;
};

export const satisfyOnlyWholeNumbers = (value: string): boolean => {
  return /^[0-9]+$/.test(value) ? true : false;
};

export const satisfyPreciseCharacterCount = (
  value: string,
  requiredCount: number
): boolean => {
  return value.length != requiredCount ? false : true;
};

export const satisfyMaxCharacterCount = (
  value: string,
  maximumCount: number
): boolean => {
  return value?.length <= maximumCount ? true : false;
};

export const satisfyMinCharacterCount = (
  value: string,
  minimumCount: number
): boolean => {
  return value?.length >= minimumCount ? true : false;
};

export const satisfyPostCodeCharacters = (value: string): boolean => {
  return /^[a-zA-Z0-9\s\-]+$/.test(value) ? true : false;
};

export const satisfyPasswordComplexity = (value: string): boolean => {
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  return strongRegex.test(value) ? true : false;
};
