import { StyledInput } from "../../base/StyledInput";
import { analyzeSalePrice } from "../../helpers/validation";

const PriceInput = ({ price, priceStatus }) => {
  return (
    <StyledInput
      label="Price (&pound;)"
      onChangeText={(itemValue) => {
        price.value = itemValue;
        analyzeSalePrice(price, priceStatus);
      }}
      onBlur={() => analyzeSalePrice(price, priceStatus)}
      fieldStatus={priceStatus}
      autoCorrect={false}
      value={price.value}
      width={"100px"}
    />
  );
};

export { PriceInput as default };
