import { Text } from "react-native";
import { View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../../signals/signals";
import Button from "../../base/Button";
import { StyledInput } from "../../base/StyledInput";
import { analyzeTime } from "../../helpers/validation";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import { Signal } from "@preact/signals-react";
import { useEffect } from "react";
import { localizeDateTime } from "../../helpers/timeZones";

const DateTimeInput = ({ startDate, time, timeStatus, showDatePicker }) => {
  let currentTime = localizeDateTime(startDate.value).format("HH:mm");

  const openDatePicker = () => {
    showDatePicker.value = true;
  };

  const setTime = (newTime: string) => {
    dayjs.extend(tz);
    if (analyzeTime(new Signal(newTime), new Signal(""))) {
      let newStartDateTime =
        startDate.value.format("YYYY-MM-DD") + "T" + newTime + ":00Z";
      let newDate = dayjs.tz(newStartDateTime);
      startDate.value = newDate;
    }
  };

  useEffect(() => {}, [time.value]);

  return (
    <View style={tailwind`flex-row`}>
      <View style={tailwind`w-1/2`}>
        <Text
          style={[
            tailwind`text-[${theme.value.textColor}] font-semibold mb-1`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          Date
        </Text>
        <Button
          title={dayjs(startDate.value).format("ddd MMM DD")}
          onPress={openDatePicker}
        />
      </View>
      <View style={tailwind`w-1/2`}>
        <StyledInput
          label={"Time"}
          placeholder={currentTime}
          onChangeText={(text) => {
            setTime(text);
            time.value = text;
            analyzeTime(time, timeStatus);
          }}
          fieldStatus={timeStatus}
          onBlur={() => {
            analyzeTime(time, timeStatus);
          }}
          autoCorrect={false}
          width={"100px"}
        />
      </View>
    </View>
  );
};

export { DateTimeInput as default };
