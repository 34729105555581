import React from "react";
import PropTypes from "prop-types";
import { useQuery, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { ActivityIndicator, Text } from "react-native";
import { API_URL, queryClient } from "../base/Constants";
import LiveThumbnail from "../thumbnails/live/LiveThumbnail";
import { currentUser, searchQuery } from "../../signals/signals";
import ApiError from "../error/ApiError";

const SearchLiveThumbnailStrip = ({ section }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <LiveStrip sectionId={section.id} />
    </QueryClientProvider>
  );
};

SearchLiveThumbnailStrip.propTypes = {
  section: PropTypes.object.isRequired,
};

function LiveStrip({ sectionId }) {
  const { isLoading, error, data } = useQuery(
    ["section" + sectionId + searchQuery.value],
    () =>
      axios
        .get(
          API_URL +
          "search/section/" +
          sectionId +
          "/" +
          currentUser.value.id +
          "/" +
          searchQuery.value,
          {
            headers: { authorization: currentUser.value.token },
          }
        )
        .then((res) => res.data)
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  return (
    <>
      {data.map((liveEvent) => (
        <LiveThumbnail
          key={liveEvent.id + liveEvent.startDate}
          event={liveEvent}
        />
      ))}
    </>
  );
}

LiveStrip.propTypes = {
  sectionId: PropTypes.number.isRequired,
};

export default SearchLiveThumbnailStrip;
