import React, { MutableRefObject, useEffect, useRef } from "react";
import { View } from "react-native";
import tailwind from "twrnc";
import { timeLive } from "../signals/signals";
import { Signal, signal, useSignal } from "@preact/signals-react";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import AccessingCamera from "../components/devices/AccessingCamera";
import NoCameraOrAudioPermission from "../components/devices/NoCameraOrAudioPermission";
import {
  loadImage,
  startStreaming,
  stopStreaming,
} from "../components/broadcast-screen/broadcastFunctions";
import {
  enableCamera,
  handleCameraReady,
  requestCameraPermission,
} from "../components/broadcast-screen/cameraFunctions";
import { FitCentrEvent } from "../components/broadcast-screen/types/FitCentrEvent";
import BroadcastPanel from "../components/broadcast-screen/BroadcastPanel";
import StreamEndingModal from "../components/modals/StreamEndingModal";
import StreamCompletedModal from "../components/modals/StreamCompletedModal";
import { handleResize2 } from "../components/helpers/viewFunctions";
import {
  BROADCAST_VIDEO_HEIGHT,
  BROADCAST_VIDEO_WIDTH,
} from "../components/base/Constants";

const Broadcast3Screen = (props: React.PropsWithoutRef<any>) => {
  const { event }: { event: FitCentrEvent } = props.route.params;
  const cameraAvailable = useSignal(true);
  const cameraPermissionGranted = useSignal(false);
  const audioPermissionGranted = useSignal(false);
  const scrollViewHeight = useSignal(0);

  const cameraEnabled = useSignal(false);
  const streamStoppingCounter = useSignal(0);
  const streamStopped: Signal<boolean> = useSignal(false);

  const startTime = useSignal(null);
  const endTime = useSignal(null);
  const inputStreamRef: MutableRefObject<any> = useRef();
  const videoRef: MutableRefObject<any> = useRef(null);
  const recordingCanvasRef: MutableRefObject<any> = useRef(null);
  const previewCanvasRef: MutableRefObject<any> = useRef(null);
  const wsRef: MutableRefObject<any> = useRef(null);
  const mediaRecorderRef: MutableRefObject<any> = useRef(null);
  const requestAnimationRef: MutableRefObject<any> = useRef(null);
  const outputStreamRef: MutableRefObject<any> = useRef(null);
  const overlayPlan = useSignal([]);
  const overlayPlanRef: MutableRefObject<any> = useRef(null);
  const planRunning = useSignal(false);
  const planShown: Signal<boolean> = useSignal(false);
  const planStep: MutableRefObject<any> = useRef(null);
  const planStepTime: MutableRefObject<any> = useRef(null);
  const planStepInterval: MutableRefObject<any> = useRef(null);
  const currentPlanStep: MutableRefObject<any> = useRef(null);

  // Chat
  const messages = signal([]);
  const roomId = event.eventKey;
  let stoppingCountdownInterval = null;
  const logoImageRef: MutableRefObject<any> = useRef(null);
  const imageLoaded: Signal<boolean> = useSignal(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (previewCanvasRef.current) {
        const { containerWidth, containerHeight, scrollContainerSize } =
          handleResize2({
            sourceWidth: BROADCAST_VIDEO_WIDTH,
            sourceHeight: BROADCAST_VIDEO_HEIGHT,
          });
        scrollViewHeight.value = scrollContainerSize;
        previewCanvasRef.current.width = containerWidth;
        previewCanvasRef.current.height = containerHeight;
      }
    });
    if (previewCanvasRef.current) {
      const { containerWidth, containerHeight, scrollContainerSize } =
        handleResize2({
          sourceWidth: BROADCAST_VIDEO_WIDTH,
          sourceHeight: BROADCAST_VIDEO_HEIGHT,
        });
      scrollViewHeight.value = scrollContainerSize;
      previewCanvasRef.current.width = containerWidth;
      previewCanvasRef.current.height = containerHeight;
    }

    loadImage(imageLoaded, logoImageRef);

    if (videoRef.current) {
      videoRef.current.onCameraReady = handleCameraReady(cameraAvailable);
    }

    requestCameraPermission(
      cameraPermissionGranted,
      audioPermissionGranted,
      cameraAvailable
    );

    return () => {
      window.removeEventListener("resize", () => {});
      clearInterval(stoppingCountdownInterval);
      cancelAnimationFrame(requestAnimationRef.current);
      if (inputStreamRef.current) {
        const tracks = inputStreamRef.current.getTracks();
        tracks.forEach((track) => track.stop());
      }

      if (wsRef.current) wsRef.current.close();
      messages.value = [];
    };
  }, []);

  useEffect(() => {
    enableCamera(
      inputStreamRef,
      recordingCanvasRef,
      previewCanvasRef,
      videoRef,
      requestAnimationRef,
      cameraEnabled,
      streamStopped,
      startTime,
      endTime,
      timeLive,
      logoImageRef,
      planShown,
      currentPlanStep,
      overlayPlanRef,
      planStep,
      planStepTime
    );

    if (previewCanvasRef.current) {
      const { containerWidth, containerHeight, scrollContainerSize } =
        handleResize2({
          sourceWidth: BROADCAST_VIDEO_WIDTH,
          sourceHeight: BROADCAST_VIDEO_HEIGHT,
        });
      scrollViewHeight.value = scrollContainerSize;
      previewCanvasRef.current.width = containerWidth;
      previewCanvasRef.current.height = containerHeight;
    }
  }, [
    cameraPermissionGranted.value,
    audioPermissionGranted.value,
    cameraAvailable.value,
  ]);

  useEffect(() => {
    if (planRunning.value && !planStepInterval.current) {
      //first run
      planShown.value = true;
      overlayPlanRef.current = overlayPlan.value;
      planStepTime.current = 0;
      planStep.current = 0;

      planStepInterval.current = setInterval(() => {
        if (!planRunning.value) {
          clearInterval(planStepInterval.current);
          planStepInterval.current = null;
        }
        planStepTime.current++;

        currentPlanStep.current = overlayPlanRef.current.filter(
          (planStepItem) => {
            return planStepItem.key === planStep.current;
          }
        )[0];
        if (
          parseInt(currentPlanStep.current.duration) -
            parseInt(planStepTime.current) <=
          0
        ) {
          planStep.current++;
          // console.log("increment plan step", planStep.current);
          currentPlanStep.current = overlayPlanRef.current.filter(
            (planStepItem) => {
              return planStepItem.key === planStep.current;
            }
          )[0];
          planStepTime.current = 0;
          if (!currentPlanStep.current) {
            // console.log("end of plan");
            clearInterval(planStepInterval.current);
            planStepInterval.current = null;
            planShown.value = false;
            overlayPlanRef.current = null;
            planRunning.value = false;
          }
        }
      }, 1000);
    } else {
      clearInterval(planStepInterval.current);
      planStepInterval.current = null;
      planShown.value = false;
      overlayPlanRef.current = null;
    }
  }, [planRunning.value]);

  const audioStream = new MediaStream();

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <View style={tailwind`w-full`}>
      <StreamEndingModal />
      <StreamCompletedModal />
      <View
        style={tailwind`w-[${BROADCAST_VIDEO_WIDTH}px] absolute top-[-${BROADCAST_VIDEO_WIDTH}px] left-[-${BROADCAST_VIDEO_HEIGHT}px]`}
      >
        {cameraAvailable.value &&
          cameraPermissionGranted.value &&
          audioPermissionGranted.value && (
            <video
              ref={videoRef}
              controls
              width={BROADCAST_VIDEO_WIDTH}
              height={BROADCAST_VIDEO_HEIGHT}
              muted
            />
          )}
      </View>
      <View
        style={tailwind`absolute top-[-${BROADCAST_VIDEO_HEIGHT}px] left-[-${BROADCAST_VIDEO_WIDTH}px]`}
      >
        <canvas
          style={tailwind`w-[${BROADCAST_VIDEO_WIDTH}px] h-[${BROADCAST_VIDEO_HEIGHT}px]`}
          ref={recordingCanvasRef}
        ></canvas>
      </View>
      <View>
        <View style={tailwind`w-full self-center`}>
          <View
            style={tailwind`max-w-[${BROADCAST_VIDEO_WIDTH}px] self-center`}
          >
            {(cameraAvailable.value && !cameraPermissionGranted.value) ||
            !audioPermissionGranted.value ? (
              <NoCameraOrAudioPermission />
            ) : null}
            {!cameraAvailable.value && <AccessingCamera />}
            {cameraAvailable.value &&
              cameraPermissionGranted.value &&
              audioPermissionGranted.value && (
                <canvas ref={previewCanvasRef}></canvas>
              )}
          </View>
          <BroadcastPanel
            event={event}
            roomId={roomId}
            cameraEnabled={cameraEnabled}
            startStreaming={() =>
              startStreaming(
                wsRef,
                event,
                outputStreamRef,
                streamStoppingCounter,
                streamStopped,
                stoppingCountdownInterval,
                mediaRecorderRef,
                startTime,
                endTime,
                recordingCanvasRef,
                inputStreamRef,
                audioStream
              )
            }
            stopStreaming={() =>
              stopStreaming(
                outputStreamRef,
                streamStoppingCounter,
                streamStopped,
                stoppingCountdownInterval,
                mediaRecorderRef,
                endTime,
                wsRef,
                event
              )
            }
            streamStoppingCounter={streamStoppingCounter}
            scrollViewHeight={scrollViewHeight}
          />
        </View>
      </View>
    </View>
  );
};

export default Broadcast3Screen;
