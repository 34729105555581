import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

export const localizeDateTime = (
  utcDateTime: dayjs.Dayjs | string
): dayjs.Dayjs => {
  dayjs.extend(tz);
  return dayjs(utcDateTime).tz(dayjs.tz.guess());
};

export const localTimeToUTC = (
  localTime: dayjs.Dayjs | string
): dayjs.Dayjs => {
  dayjs.extend(utc);
  return dayjs(localTime).utc();
};
