import { ActivityIndicator, Text, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../signals/signals";

const AccessingCamera = () => {
  return (
    <View style={tailwind`w-full`}>
      <View style={tailwind`rounded-lg self-center bg-white p-4 m-4`}>
        <ActivityIndicator />
        <Text
          style={[
            tailwind`self-center ml-2 pt-1 font-semibold text-[${theme.value.textColor}]`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          Connecting to your camera
        </Text>
      </View>
    </View>
  );
};

export { AccessingCamera as default };
