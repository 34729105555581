import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import tailwind from "twrnc";

import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import {
  bodyTagSelection,
  theme,
  userTagSelection,
} from "../../signals/signals";
import { Signal, useSignal } from "@preact/signals-react";
import { CheckBox, Image } from "@rneui/themed";
import { TREE_TYPE_BODY, TREE_TYPE_USER } from "../base/Constants";
import { TagReducer } from "./TagReducer";
import { analyzeTagSelections } from "../helpers/validation";

const TagItem = ({ item, type, fieldStatus }) => {
  const itemChecked = useSignal(false);

  useEffect(() => {
    let isChecked = false;
    switch (type) {
      case TREE_TYPE_BODY:
        if (bodyTagSelection.value.length) {
          isChecked =
            bodyTagSelection.value.filter(
              (tag) => tag.id === item.descendant.id
            ).length > 0;
        }
        break;
      case TREE_TYPE_USER:
      default:
        if (userTagSelection.value.length) {
          isChecked =
            userTagSelection.value.filter(
              (tag) => tag.id === item.descendant.id
            ).length > 0;
        }
        break;
    }
    itemChecked.value = isChecked;
  }, []);

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <View>
      <CheckBox
        checkedIcon={
          <Image
            resizeMode="contain"
            style={tailwind`h-[18px] w-[18px]`}
            source={require("../../assets/tag-selected-icon.png")}
            tintColor={theme.value.tagSelectorCheckedTextColor}
          />
        }
        uncheckedIcon={
          <Image
            resizeMode="contain"
            style={tailwind`h-[18px] w-[18px]`}
            source={require("../../assets/tag-unselected-icon.png")}
          />
        }
        // center
        title={item.descendant.tagName.toUpperCase()}
        checked={itemChecked.value}
        containerStyle={tailwind`rounded-lg p-1`}
        textStyle={[
          tailwind`text-[${theme.value.tagSelectorTextColor}] text-[12px]`,
          { fontFamily: "Roboto_400Regular" },
        ]}
        checkedColor={theme.value.tagSelectorCheckedTextColor}
        onPress={() => {
          switch (type) {
            case TREE_TYPE_BODY:
              bodyTagSelection.value = TagReducer(bodyTagSelection.value, {
                id: item.descendant.id,
                title: item.descendant.tagName,
                deleted: itemChecked.value,
              });
              break;
            case TREE_TYPE_USER:
            default:
              userTagSelection.value = TagReducer(userTagSelection.value, {
                id: item.descendant.id,
                title: item.descendant.tagName,
                deleted: itemChecked.value,
              });
              break;
          }
          userTagSelection.value = userTagSelection.value.filter(
            (tag) => !tag.deleted
          );
          bodyTagSelection.value = bodyTagSelection.value.filter(
            (tag) => !tag.deleted
          );
          itemChecked.value = !itemChecked.value;
          analyzeTagSelections(type, fieldStatus);
        }}
      />
    </View>
  );
};

TagItem.propTypes = {
  item: PropTypes.object.isRequired,
  type: PropTypes.number.isRequired,
  fieldStatus: PropTypes.instanceOf(Signal).isRequired,
};

export default TagItem;
