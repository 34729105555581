import { Text } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../../signals/signals";

const NoInvites = () => {
  return (
    <>
      <Text
        style={[
          tailwind`self-center text-[${theme.value.textColor}] font-semibold mb-2`,
          { fontFamily: "Roboto_700Bold" },
        ]}
      >
        No-one - Invite between 1 and 7 connections
      </Text>
    </>
  );
};

export { NoInvites as default };
