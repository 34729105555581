import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import tailwind from "twrnc";
import { currentUser, outstandingMessages, theme } from "../signals/signals";
import SignalChatPrivate from "../components/chat/SignalChatPrivate";
import ProfileChatHeader from "../components/profile-screen/ProfileChatHeader";
import { updateReadMessages } from "../components/base/ApplicationContext";

const Chat = (props) => {
  const { route } = props;
  const { roomId, targetUserId, privateRoom } = route.params;
  const { token } = currentUser.value;

  useEffect(() => {
    if (privateRoom) updateReadMessages(targetUserId, privateRoom.id, token);
  }, [outstandingMessages.value]);

  return (
    <View style={tailwind`flex h-full p-2 bg-[${theme.value.backgroundColor}]`}>
      <ProfileChatHeader id={targetUserId} />
      <SignalChatPrivate roomId={roomId} />
    </View>
  )
}

Chat.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      roomId: PropTypes.string.isRequired,
      targetUserId: PropTypes.number.isRequired,
      privateRoom: PropTypes.shape({
        id: PropTypes.number.isRequired
      }).isRequired
    })
  })
}

export { Chat as default }