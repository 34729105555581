import React from "react";
import PropTypes from "prop-types";
import { Text, View } from "react-native";
import tailwind from "twrnc";
import AddressLine from "../form/user/AddressLine";
import City from "../form/user/City";
import Country from "../form/user/Country";
import State from "../form/user/State";
import PostalCode from "../form/user/PostalCode";
import { Roboto_700Bold, useFonts } from "@expo-google-fonts/roboto";
import { theme } from "../../signals/signals";
import { Signal } from "@preact/signals-react";

const BillingSettings = ({
  addressLine1,
  addressLine1Status,
  addressLine2,
  addressLine2Status,
  city,
  cityStatus,
  country,
  unitedState,
  postalCode,
  postalCodeStatus,
}) => {
  let fonts = useFonts({ Roboto_700Bold });
  if (!fonts) return;

  return (
    <BillingSettingsPanel
      addressLine1={addressLine1}
      addressLine1Status={addressLine1Status}
      addressLine2={addressLine2}
      addressLine2Status={addressLine2Status}
      city={city}
      cityStatus={cityStatus}
      country={country}
      unitedState={unitedState}
      postalCode={postalCode}
      postalCodeStatus={postalCodeStatus}
    />
  );
};

const BillingSettingsPanel = ({
  addressLine1,
  addressLine1Status,
  addressLine2,
  addressLine2Status,
  city,
  cityStatus,
  country,
  unitedState,
  postalCode,
  postalCodeStatus,
}) => {
  const COUNTRY_UNITED_STATES = 2;
  return (
    <View style={tailwind`w-full pb-4 mb-4`}>
      <View>
        <View style={tailwind`py-3`}>
          <Text
            style={[
              tailwind`text-[20px] text-[${theme.value.textColor}] ml-2 grow`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            Billing Settings
          </Text>
        </View>
        <View style={tailwind`ml-2 mb-4`}>
          <AddressLine
            label={"Address"}
            addressLine={addressLine1}
            addressLineStatus={addressLine1Status}
            mandatory={true}
          />
        </View>
        <View style={tailwind`ml-2 mb-4`}>
          <AddressLine
            label={""}
            addressLine={addressLine2}
            addressLineStatus={addressLine2Status}
          />
        </View>
        <View style={tailwind`ml-2 mb-4`}>
          <City city={city} cityStatus={cityStatus} />
        </View>
        <View style={tailwind`ml-2 mb-4`}>
          <Country country={country} />
        </View>
        {country === COUNTRY_UNITED_STATES ? (
          <View style={tailwind`ml-2 mb-4`}>
            <State state={unitedState} />
          </View>
        ) : null}
        <View style={tailwind`ml-2 mb-4`}>
          <PostalCode
            country={parseInt(country.value)}
            postalCode={postalCode}
            postalCodeStatus={postalCodeStatus}
          />
        </View>
      </View>
    </View>
  );
};

BillingSettings.propTypes = {
  addressLine1: PropTypes.instanceOf(Signal).isRequired,
  addressLine1Status: PropTypes.instanceOf(Signal).isRequired,
  addressLine2: PropTypes.instanceOf(Signal).isRequired,
  addressLine2Status: PropTypes.instanceOf(Signal).isRequired,
  city: PropTypes.instanceOf(Signal).isRequired,
  cityStatus: PropTypes.instanceOf(Signal).isRequired,
  country: PropTypes.instanceOf(Signal).isRequired,
  unitedState: PropTypes.instanceOf(Signal).isRequired,
  postalCode: PropTypes.instanceOf(Signal).isRequired,
  postalCodeStatus: PropTypes.instanceOf(Signal).isRequired,
};

BillingSettingsPanel.propTypes = {
  addressLine1: PropTypes.instanceOf(Signal).isRequired,
  addressLine1Status: PropTypes.instanceOf(Signal).isRequired,
  addressLine2: PropTypes.instanceOf(Signal).isRequired,
  addressLine2Status: PropTypes.instanceOf(Signal).isRequired,
  city: PropTypes.instanceOf(Signal).isRequired,
  cityStatus: PropTypes.instanceOf(Signal).isRequired,
  country: PropTypes.instanceOf(Signal).isRequired,
  unitedState: PropTypes.instanceOf(Signal).isRequired,
  postalCode: PropTypes.instanceOf(Signal).isRequired,
  postalCodeStatus: PropTypes.instanceOf(Signal).isRequired,
};

export { BillingSettings };
