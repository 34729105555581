import { ScrollView, Text, View } from "react-native";
import tailwind from "twrnc";
import { currentUser, theme } from "../../../signals/signals";
import NoInvites from "./NoInvites";
import InviteItem from "./InviteItem";

const InviteList = ({ attendees }) => {
  return (
    <View style={tailwind`w-[300px]`}>
      <Text
        style={[
          tailwind`self-center text-[${theme.value.textColor}] font-semibold mb-2`,
          { fontFamily: "Roboto_700Bold" },
        ]}
      >
        Invited
      </Text>

      <ScrollView
        style={tailwind`p-2 rounded-lg bg-[${theme.value.inviteeListBackgroundColor}] h-[200px] w-full`}
      >
        {attendees.value.length ? (
          attendees.value.map((attendee) => {
            return (
              <View key={attendee.id}>
                {attendee.id !== currentUser.value.id ? (
                  <InviteItem attendee={attendee} />
                ) : null}
              </View>
            );
          })
        ) : (
          <NoInvites />
        )}
      </ScrollView>
    </View>
  );
};

export { InviteList as default };
