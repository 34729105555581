import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Platform, Pressable, View, ActivityIndicator } from "react-native";
import { Camera, CameraType, CameraView } from "expo-camera";
import tailwind from "twrnc";
import { Image } from "@rneui/themed";
import { theme } from "../../signals/signals";
import { Signal, useSignal } from "@preact/signals-react";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import AccessingCamera from "../devices/AccessingCamera";
import NoCameraPermission from "../devices/NoCameraPermission";

const TakeSelfie = ({ selfieImage, takingSelfie }) => {
  const cameraAvailable = useSignal(true);
  const cameraType = useSignal<CameraType>("front");
  const cameraPermissionGranted = useSignal(false);
  const cameraRef = useRef(null);

  const toggleCameraType = () => {
    cameraType.value = cameraType.value === "back" ? "front" : "back";
  };

  const handleCapture = async () => {
    if (cameraRef.current) {
      const photo = await cameraRef.current.takePictureAsync();
      selfieImage.value = photo;
      takingSelfie.value = false;
    }
  };

  const cancelSelfie = () => {
    takingSelfie.value = false;
  };

  const iconTintColor =
    Platform.OS === "web"
      ? "#FFFFFF"
      : theme.value.mobileChatVideoControlIconColor;

  const handleCameraReady = () => {
    cameraAvailable.value = true;
  };

  const requestCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      cameraPermissionGranted.value = true;
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      console.error("Error requesting camera permission:", error);
      cameraPermissionGranted.value = false;
      cameraAvailable.value = false;
    }
  };

  useEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.onCameraReady = handleCameraReady;
    }
    requestCameraPermission();
  }, []);

  const cameraStyleWeb = tailwind`max-w-[350px] max-h-[480px] bg-white self-center`;
  const cameraStyleNative = tailwind`h-[400px] w-[90%] bg-white self-center mt-14`;

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <View
      style={tailwind`${
        Platform.OS !== "web"
          ? "w-full mb-4"
          : "h-[600px] w-[600px] self-center rounded-lg mb-2"
      } p-2`}
    >
      <View style={tailwind`absolute top-6 left-4`}>
        {Platform.OS !== "web" && !Platform.isTV ? (
          <Pressable style={tailwind`m-1`} onPress={toggleCameraType}>
            {cameraType.value === "front" ? (
              <Image
                resizeMode="contain"
                style={tailwind`h-[32px] w-[32px]`}
                source={require("../../assets/camera-front-icon.png")}
                tintColor={iconTintColor}
              />
            ) : (
              <Image
                resizeMode="contain"
                style={tailwind`h-[32px] w-[32px]`}
                source={require("../../assets/camera-rear-icon.png")}
                tintColor={iconTintColor}
              />
            )}
          </Pressable>
        ) : null}
      </View>
      <>
        {cameraAvailable.value && cameraPermissionGranted.value && (
          <CameraView
            ref={cameraRef}
            style={Platform.OS === "web" ? cameraStyleWeb : cameraStyleNative}
            facing={cameraType.value}
            onCameraReady={() => handleCameraReady()}
          >
            <View
              style={tailwind`rounded-lg h-full w-[350px] self-center opacity-0`}
            />
          </CameraView>
        )}
        {!cameraAvailable.value && cameraPermissionGranted.value && (
          <AccessingCamera />
        )}
        {!cameraPermissionGranted.value && !cameraAvailable.value && (
          <NoCameraPermission />
        )}
      </>
      <Pressable
        style={tailwind`absolute ${
          Platform.OS === "web" ? "bottom-40" : "bottom-20"
        } self-center border-[1px] rounded-full border-[${
          theme.value.selfieTakePhotoIconColor
        }]`}
        onPress={handleCapture}
      >
        <Image
          style={tailwind`w-[32px] h-[32px]`}
          source={require("../../assets/camera-icon.png")}
          PlaceholderContent={<ActivityIndicator />}
          tintColor={theme.value.selfieTakePhotoIconColor}
        />
      </Pressable>
      <Pressable
        style={tailwind`absolute bg-white rounded-full ${
          Platform.OS === "web" ? "top-0 right-30" : "top-6 right-6"
        }`}
        onPress={cancelSelfie}
      >
        <Image
          style={tailwind`w-[32px] h-[32px]`}
          source={require("../../assets/close-icon.png")}
          PlaceholderContent={<ActivityIndicator />}
          tintColor={theme.value.selfieCloseIconColor}
        />
      </Pressable>
    </View>
  );
};

TakeSelfie.propTypes = {
  selfieImage: PropTypes.instanceOf(Signal).isRequired,
  takingSelfie: PropTypes.instanceOf(Signal).isRequired,
};

export { TakeSelfie as default };
