import React, { useState } from "react";
import PropTypes from "prop-types";
import { ActivityIndicator, ScrollView, Text, View } from "react-native";
import tailwind from "twrnc";
import { QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL, queryClient } from "../components/base/Constants";
import {
  useFonts,
  Roboto_400Regular,
  Roboto_700Bold,
} from "@expo-google-fonts/roboto";
import { Switch } from "@rneui/themed";
import dayjs from "dayjs";
import { currentUser, theme } from "../signals/signals";
import ApiError from "../components/error/ApiError";

const DeviceSettingsScreen = () => {
  let fonts = useFonts({ Roboto_400Regular, Roboto_700Bold });
  if (!fonts) return;

  return (
    <View style={tailwind`flex-col w-full bg-[${theme.value.backgroundColor}]`}>
      <QueryClientProvider client={queryClient}>
        <Devices />
      </QueryClientProvider>
    </View>
  );
};

const Devices = () => {
  const userId = currentUser.value.id;
  const { isLoading, error, data, isFetching } = useQuery(
    ["devices" + userId],
    () =>
      axios
        .get(API_URL + "users/devices/" + userId, {
          headers: { authorization: currentUser.value.token },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err))
  );

  if (isLoading || isFetching) return <ActivityIndicator />;
  if (error) return <ApiError />;

  return (
    <>
      <View style={tailwind`flex-row mx-4 p-2`}>
        <Text style={[tailwind`w-1/5`, { fontFamily: "Roboto_700Bold" }]}>
          Name
        </Text>
        <Text style={[tailwind`w-1/5`, { fontFamily: "Roboto_700Bold" }]}>
          Platform
        </Text>
        <Text style={[tailwind`w-1/5`, { fontFamily: "Roboto_700Bold" }]}>
          Push Notifications Enabled
        </Text>
        <Text style={[tailwind`w-1/5`, { fontFamily: "Roboto_700Bold" }]}>
          Logged In
        </Text>
        <Text style={[tailwind`w-1/5`, { fontFamily: "Roboto_700Bold" }]}>
          Date
        </Text>
      </View>
      <ScrollView
        style={tailwind`mx-4 p-2 rounded-lg bg-[${theme.value.deviceListBackgroundColor}]`}
      >
        {data.map((device) => {
          const deviceId = device.id;
          const name = device.deviceName;
          const platform = device.platform;
          const isPushNotificationEnabled = device.isPushNotificationEnabled;
          const isLoggedIn = device.isConnected;
          const updatedAt = dayjs(device.updatedAt).format("DD MMM YYYY HH:mm");
          return (
            <View key={device.id}>
              <Device
                deviceId={deviceId}
                name={name}
                platform={platform}
                isPushNotificationEnabled={isPushNotificationEnabled}
                isLoggedIn={isLoggedIn}
                updatedAt={updatedAt}
                userId={userId}
              />
            </View>
          );
        })}
      </ScrollView>
    </>
  );
};

const Device = ({
  deviceId,
  name,
  platform,
  isPushNotificationEnabled,
  isLoggedIn,
  updatedAt,
  userId,
}) => {
  const [pushNotificationEnabled, setPushNotificationEnabled] = useState(
    isPushNotificationEnabled
  );

  const toggleSwitch = () => {
    setPushNotificationEnabled(!pushNotificationEnabled);
    //axios call to update device
    axios
      .post(
        API_URL + "user-device/push-enabled",
        {
          deviceId: deviceId,
          isPushNotificationEnabled: !pushNotificationEnabled,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.value.token,
          },
        }
      )
      .then(() => {
        queryClient.invalidateQueries(["devices" + userId]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <View style={tailwind`flex-row min-h-[40px]`}>
      <Text style={[tailwind`w-1/5`, { fontFamily: "Roboto_400Regular" }]}>
        {name}
      </Text>
      <Text style={[tailwind`w-1/5`, { fontFamily: "Roboto_400Regular" }]}>
        {platform}
      </Text>
      <Text style={[tailwind`w-1/5`, { fontFamily: "Roboto_400Regular" }]}>
        {platform === "web" ? (
          "N/A"
        ) : (
          <Switch
            style={tailwind`mt-1`}
            trackColor={{
              false: theme.value.pTEnabledSwitchTrackColorFalse,
              true: theme.value.pTEnabledSwitchTrackColorTrue,
            }}
            ios_backgroundColor={"#3e3e3e"}
            onValueChange={toggleSwitch}
            value={pushNotificationEnabled}
          />
        )}
      </Text>
      <Text style={[tailwind`w-1/5`, { fontFamily: "Roboto_400Regular" }]}>
        {isLoggedIn ? "Yes" : "No"}
      </Text>
      <Text style={[tailwind`w-1/5`, { fontFamily: "Roboto_400Regular" }]}>
        {updatedAt}
      </Text>
    </View>
  );
};

Device.propTypes = {
  deviceId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  isPushNotificationEnabled: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  updatedAt: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
};

export { DeviceSettingsScreen as default };
