import { StyledInput } from "../../base/StyledInput";
import { analyzeShortDescription } from "../../helpers/validation";

const TitleInput = ({ shortDescription, shortDescriptionStatus }) => {
  return (
    <StyledInput
      width={"355px"}
      label={"Title"}
      placeholder={"Title"}
      value={shortDescription.value}
      autoCorrect={true}
      onChangeText={(text) => {
        shortDescription.value = text;
        analyzeShortDescription(shortDescription, shortDescriptionStatus);
      }}
      onBlur={() =>
        analyzeShortDescription(shortDescription, shortDescriptionStatus)
      }
      fieldStatus={shortDescriptionStatus}
    />
  );
};

export { TitleInput as default };
