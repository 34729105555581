import React from "react";
import PropTypes from "prop-types";
import {
  View,
  SafeAreaView,
  ScrollView,
  Platform,
  Dimensions,
} from "react-native";
import tailwind from "twrnc";
import { theme } from "../../signals/signals";
import { VIDEO_TYPE_REPLAY } from "../base/Constants";
import YouTubeAddPanel from "./YouTubeAddPanel";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import OnDemandAddPanel from "./OnDemandAddPanel";

const AddVideo = ({ type }): JSX.Element => {
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;

  const screenHeight = Dimensions.get("screen").height;

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <SafeAreaView
      style={tailwind`flex w-full bg-[${theme.value.backgroundColor}]`}
    >
      <ScrollView
        scrollEnabled={true}
        style={isMobileWebFormat ? tailwind`h-[${screenHeight}px]` : null}
      >
        <View style={tailwind`p-2`}>
          {type == VIDEO_TYPE_REPLAY ? (
            <OnDemandAddPanel />
          ) : (
            <YouTubeAddPanel />
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

AddVideo.propTypes = {
  type: PropTypes.number.isRequired,
};

export { AddVideo as default };
