import { Signal } from "@preact/signals-react";
import { updateCanvas } from "./broadcastFunctions";
import { MutableRefObject } from "react";
import { handleResize2 } from "../helpers/viewFunctions";
import {
  BROADCAST_VIDEO_HEIGHT,
  BROADCAST_VIDEO_WIDTH,
} from "../base/Constants";

export const handleCameraReady = (cameraAvailable: Signal<boolean>) => {
  cameraAvailable.value = true;
};

export const requestCameraPermission = async (
  cameraPermissionGranted: Signal<boolean>,
  audioPermissionGranted: Signal<boolean>,
  cameraAvailable: Signal<boolean>
) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    cameraPermissionGranted.value = true;
    audioPermissionGranted.value = true;
    stream.getTracks().forEach((track) => track.stop());
  } catch (error) {
    console.log("Error requesting camera & audio permission:", error);
    cameraPermissionGranted.value = false;
    audioPermissionGranted.value = false;
    cameraAvailable.value = false;
  }
};

export const enableCamera = async (
  inputStreamRef: MutableRefObject<MediaStream>,
  recordingCanvasRef: MutableRefObject<HTMLCanvasElement>,
  previewCanvasRef: MutableRefObject<HTMLCanvasElement>,
  videoRef: MutableRefObject<HTMLVideoElement>,
  requestAnimationRef: MutableRefObject<any>,
  cameraEnabled: Signal<boolean>,
  streamStopped: Signal<boolean>,
  startTime: Signal<string>,
  endTime: Signal<string>,
  timeLive: Signal<number>,
  logoImageRef: MutableRefObject<HTMLImageElement>,
  planShown: Signal<boolean>,
  currentPlanStep: MutableRefObject<any>,
  overlayPlanRef: MutableRefObject<any>,
  planStep: MutableRefObject<any>,
  planStepTime: MutableRefObject<any>
) => {
  inputStreamRef.current = await navigator.mediaDevices.getUserMedia({
    video: true,
    audio: true,
  });

  if (!inputStreamRef.current) {
    return;
  }

  if (videoRef.current) {
    videoRef.current.srcObject = inputStreamRef.current;
    await videoRef.current.play();

    // We need to set the canvas height/width to match the video element.
    recordingCanvasRef.current.height = videoRef.current.clientHeight;
    recordingCanvasRef.current.width = videoRef.current.clientWidth;
    if (previewCanvasRef.current) {
      const { containerWidth, containerHeight } = handleResize2({
        sourceWidth: BROADCAST_VIDEO_WIDTH,
        sourceHeight: BROADCAST_VIDEO_HEIGHT,
      });
      previewCanvasRef.current.width = containerWidth;
      previewCanvasRef.current.height = containerHeight;
    }

    requestAnimationRef.current = requestAnimationFrame(() => {
      updateCanvas(
        videoRef,
        recordingCanvasRef,
        streamStopped,
        startTime,
        endTime,
        timeLive,
        logoImageRef,
        planShown,
        requestAnimationRef,
        currentPlanStep,
        overlayPlanRef,
        planStep,
        planStepTime
      );
      updateCanvas(
        videoRef,
        previewCanvasRef,
        streamStopped,
        startTime,
        endTime,
        timeLive,
        logoImageRef,
        planShown,
        requestAnimationRef,
        currentPlanStep,
        overlayPlanRef,
        planStep,
        planStepTime
      );
    });

    cameraEnabled.value = true;
  }
};
