import axios from "axios";
import { API_URL } from "../../base/Constants";
import { useQuery } from "@tanstack/react-query";
import { currentUser } from "../../../signals/signals";

const useHomePageSections = () => {
  const { isLoading, error, data } = useQuery(
    ["homepagesections"],
    async () => {
      const response = await axios
        .get(API_URL + "homepage/sections", {
          headers: { authorization: currentUser.value.token },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err));
      return response;
    },
    {
      cacheTime: 1000 * 60 * 60,
    }
  );

  return { isLoading, error, data };
};

export { useHomePageSections as default };
