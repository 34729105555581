import { Signal } from "@preact/signals-react";
import { currentUser } from "../../signals/signals";
import { FitCentrEvent } from "../broadcast-screen/types/FitCentrEvent";

export const determineBookingStatus = ({
  bookings,
  event,
  isHost,
  numberOfBookings,
  revolutPublicId,
  revolutOrderid,
  paymentId,
  isBooked,
  hasBookings,
  isStalled,
}: {
  bookings: any;
  event: FitCentrEvent;
  isHost: boolean;
  numberOfBookings: Signal<number>;
  revolutPublicId: Signal<string>;
  revolutOrderid: Signal<string>;
  paymentId: Signal<string>;
  isBooked: Signal<boolean>;
  hasBookings: Signal<boolean>;
  isStalled: Signal<boolean>;
}) => {
  numberOfBookings.value = 0;
  bookings.forEach((booking) => {
    if (
      (booking.user.id === currentUser.value.id || isHost) &&
      booking.eventDate === event.startDate
    ) {
      booking.payments.forEach((payment) => {
        paymentId.value = payment.id;
        payment.responses.forEach((response) => {
          switch (response.state) {
            case "COMPLETED":
              isBooked.value = true;
              hasBookings.value = true;
              if (booking.eventDate === event.startDate) {
                numberOfBookings.value++;
              }
              isStalled.value = false;
              break;
            default:
              isStalled.value = true;
              break;
          }
          revolutPublicId.value = response.revolutPublicId;
          revolutOrderid.value = response.revolutOrderid;
        });
      });
    }
  });
};
