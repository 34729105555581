import PropTypes from "prop-types";
import { Text, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../../signals/signals";

const VideoTitle = ({ title }) => {
  return (
    <View
      style={tailwind`bg-[${theme.value.replayTitleBackgoundColor}] rounded-md py-[2px] px-[6px] mx-4 opacity-80`}
    >
      <Text
        numberOfLines={1}
        style={[
          tailwind`text-[${theme.value.replayTitleTextColor}] text-[12px] uppercase self-center`,
          { fontFamily: "Inter_600SemiBold" },
        ]}
      >
        {title}
      </Text>
    </View>
  );
};

VideoTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export { VideoTitle as default };
