import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { mediaDevices } from "react-native-webrtc-web-shim";
import {
  chatSocket,
  currentUser,
  localStream,
  participants,
  peerConnections,
  peerStatus,
  privateMessages,
  remoteStreams,
} from "../signals/signals";
import { DEFAULT_PROFILE_URL } from "../components/base/Constants";
import { Socket } from "socket.io-client";
import PeerStatus from "../components/conference/components/PeerStatus";
import NewProviderStreamLayout from "../components/conference/components/NewProviderStreamLayout";
import NewClientStreamLayout from "../components/conference/components/NewClientStreamLayout";
import {
  ConferenceLobby,
  connectToConferenceChat,
} from "../components/conference/handlers/chatSocketHandler";
import { CustomStream } from "../components/conference/types/CustomStream";
import {
  attachRTCListeners,
  newConnectChatSocket,
} from "../components/conference/handlers/callSocketHandler";
import { CallParticipant } from "../components/conference/types/CallParticipant";
import { EventParticipant } from "../components/conference/types/EventParticipant";

const Conference = (props: React.PropsWithoutRef<any>) => {
  const { event } = props.route.params;
  const roomId: string = event.eventKey;
  const hostId: number = parseInt(event.userId);
  const isHost: boolean = hostId === currentUser.value.id;

  const mapParticipants = () => {
    let eventParticipants: CallParticipant[] = [];
    if (event?.participants?.length) {
      const participantsForEvent: EventParticipant[] = event.participants;
      eventParticipants = [];
      participantsForEvent.forEach((participant: EventParticipant) => {
        const newParticipant: CallParticipant = {
          id: participant.user.id,
          profileUrl: participant.user.profileUrl || DEFAULT_PROFILE_URL,
          name: participant.user.firstName + " " + participant.user.lastName,
          inRoom: false,
        };
        if (currentUser.value.id !== participant.id) {
          eventParticipants.push(newParticipant);
        }
      });
      participants.value = eventParticipants;
    }
  };

  const clearDown = async () => {
    peerConnections.value.forEach((peerConnection) => {
      peerConnection.close();
      peerConnection = null;
    });
    peerConnections.value = [];
    participants.value = [];
    privateMessages.value = [];
    peerStatus.value = [];
    remoteStreams.value.forEach((remoteStream: CustomStream) => {
      remoteStream.media?.streams.forEach((stream) => {
        stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
      });
    });
    remoteStreams.value = [];
    localStream.value
      .getTracks()
      .forEach((track: MediaStreamTrack) => track.stop());
    localStream.value = null;
  };

  const cleanupFunction: VoidFunction = () => async () => {
    await clearDown();
  };

  useEffect(() => {
    mapParticipants();
    init();
    return cleanupFunction();
  }, []);

  const init = async () => {
    await getLocalStream(async (stream: MediaStream) => {
      localStream.value = stream;
      const socket: Socket = await newConnectChatSocket();
      chatSocket.value = socket;
      const conferenceLobby = await doConnect(stream, socket);
    });
  };

  const doConnect = async (
    stream: MediaStream,
    socket: Socket
  ): Promise<ConferenceLobby> => {
    try {
      const conferenceLobby: ConferenceLobby = await connectToConferenceChat(
        socket,
        roomId,
        hostId,
        currentUser.value.id,
        (newMessage) => {
          privateMessages.value = [...privateMessages.value, newMessage];
          privateMessages.value = privateMessages.value.slice(-100);
        }
      );

      await attachRTCListeners(
        socket,
        currentUser.value.id,
        roomId,
        peerConnections,
        localStream.value,
        remoteStreams
      );
      return Promise.resolve(conferenceLobby);
    } catch (err) {
      console.log("Error connecting to conference room", err);
      return Promise.reject(err);
    }
  };

  async function getLocalStream(callback: (stream: MediaStream) => void) {
    try {
      const stream: MediaStream = await mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      callback(stream);
    } catch (err) {
      console.log("error setting local stream", err);
    }
  }

  return isHost ? (
    <>
      <NewProviderStreamLayout event={event} participants={participants} />
      <PeerStatus roomId={roomId} />
    </>
  ) : (
    <>
      <NewClientStreamLayout
        hostId={hostId}
        event={event}
        participants={participants}
      />
      <PeerStatus roomId={roomId} />
    </>
  );
};

Conference.propTypes = {
  route: PropTypes.object.isRequired,
};

export { Conference as default };
