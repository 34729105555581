import { Signal, signal } from "@preact/signals-react";
import { RTCPeerConnection } from "react-native-webrtc-web-shim";
import { PeerConnectionStatus } from "../components/conference/types/PeerConnectionStatus";
import { CallParticipant } from "../components/conference/types/CallParticipant";
import { CustomStream } from "../components/conference/types/CustomStream";

const defaultTheme = {
  //Content
  backgroundColor: "#f2f2f2",
  textColor: "#231710",
  primaryButtonColor: "#116D6E",
  primaryButtonTextColor: "#ffffff",
  primaryButtonSelectedColor: "#116D6E",
  redColor: "#e3b04b",
  greenColor: "#44DF44",
  liveDotColor: "#e3b04b",

  //Registration Screen
  registrationUserTypeSliderTextColor: "#231710",
  registrationUserTypeSliderActiveColor: "#FFFFFF",
  registrationUserTypeButtonColor: "#e3b04b",
  registrationUserTypeBackgroundColor: "#FFFFFF",

  //Tag Selector
  tagSelectorBackgroundColor: "#EEECEA",
  tagSelectorTextColor: "#000000",
  tagSelectorCheckedTextColor: "#e3b04b",

  //Navigation Drawer
  navigationDrawerBackgroundColor: "#321D1C",

  //Navigation Footer
  footerNavigationIconColor: "#998E8E",

  //Email Verified Modal
  viewEventHeadingTextColor: "#000000",
  modalBackgroundColor: "#f8f8f8",

  //Populated from theme
  pTEnabledSwitchTrackColorFalse: null,
  pTEnabledSwitchTrackColorTrue: null,
  warningMessageBackgroundColor: null,
  mobileChatVideoControlIconColor: null,
  selfieTakePhotoIconColor: null,
  selfieCloseIconColor: null,
  calendarEventBookableColor: null,
  calendarEventPrivateColor: null,
  calendarEventBroadcastColor: null,
  calendarEventOtherColor: null,
  inviteeListBackgroundColor: null,
  attendeeListBackgroundColor: null,
  attendeeListSelectedBackgroundColor: null,
  attendeeListSelectedTextColor: null,
  searchModalTopColor: null,
  searchModalTextColor: null,
  iconColor: null,
  timePillBackgoundColor: null,
  timePillTextColor: null,
  replayTitleBackgoundColor: null,
  replayTitleTextColor: null,
  inLibraryTextBackgroundColor: null,
  coinsIconColor: null,
  onDemandProfileBorderColor: null,
  eventBookingsYearTitleBottomBorderColor: null,
  eventBookingsYearTitleBackgroundColor: null,
  connectionThumbnailBackgroundColor: null,
  connectionThumbnailIconBorderColor: null,
  connectionThumbnailIconBackgroundColor: null,
  connectionThumbnailIconColor: null,
  notificationBadgeBackgroundColor: null,
  notificationBadgeTextColor: null,
  chatBubbleBackgroundColor: null,
  chatBubbleSelfBackgroundColor: null,
  chatBubbleSelfTextColor: null,
  chatBubbleTextColor: null,
  chatBubbleSelfTimeTextColor: null,
  chatBubbleTimeTextColor: null,
  primaryColor: null,
  trainerThumbnailBackgroundColor: null,
  timerButtonBackgroundColor: null,
  difficultyTextColour: null,
  difficultyAdvancedColor: null,
  difficultyIntermediateColor: null,
  difficultyBeginnerColor: null,
};

export const currentUser: Signal<{
  id: number | null;
  token: string | null;
  emailAddress: string | null;
  firstName: string | null;
  lastName: string | null;
  profileUrl: string | null;
  authenticated: boolean | null;
  type: number;
  currencyId: number | null;
  currencyName: string | null;
  currencySymbol: string | null;
  credits: number | null;
  settings: [
    {
      id: number;
      key: string;
      name: string;
      value: string;
    }
  ];
  theme: { id: number } | null;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  country?: string;
  postalCode?: string;
  isProfileCompleted?: boolean;
}> = signal({
  id: null,
  token: null,
  emailAddress: null,
  firstName: null,
  lastName: null,
  profileUrl: null,
  authenticated: null,
  type: 1,
  currencyId: null,
  currencyName: null,
  currencySymbol: null,
  credits: null,
  settings: null,
  theme: null,
  isProfileCompleted: false,
});
export const outstandingNotifications = signal([]);
export const outstandingNotificationCount = signal(0);
export const outstandingMessages = signal([]);
export const outstandingMessageCount = signal(0);
export const outstandingConnections = signal([]);
export const outstandingConnectionCount = signal(0);
export const focusedScreen = signal("HomeScreen");
export const theme = signal(defaultTheme);
export const loggedIn = signal(false);
export const searchQuery = signal("");
export const searchType = signal("0");
export const expoPushToken = signal(null);
export const modalVisible = signal(false);
export const emailValidationErrorModalVisible = signal(false);
export const notificationServerDisconnectedModalVisible = signal(false);
export const emailValidationErrorMessage = signal("");
export const deleteConfirmModalVisible = signal(false);
export const fileTooLargeErrorModalVisible = signal(false);
export const isProfileComplete = signal(false);
export const isEmailAddressVerified = signal(false);
export const profileMessages = signal([]);
export const userTagSelection = signal([]);
export const bodyTagSelection = signal([]);

export const currentPrivateChatRoom = signal(null);
export const privateMessages = signal([]);
export const chatSocket = signal(null);
export const notificationSocket = signal(null);
export const isStreaming = signal(false);
export const timeLive = signal(0);

export const redirectUrl = signal("");
export const emailVerificationCode = signal("");
export const redirectParams = signal({});

export const peerConnections: Signal<RTCPeerConnection[]> = signal([]);
export const peerStatus: Signal<PeerConnectionStatus[]> = signal([]);
export const participants: Signal<CallParticipant[]> = signal([]);
export const providerStream: Signal<CustomStream> = signal(null);
export const pendingStreams = signal([]);
export const remoteStreams = signal([]);
export const localStream: Signal<MediaStream> = signal(null);

export const windowOrientation: Signal<string> = signal("portrait");

export const themes = signal([]);

export const isNotificationServerConnected = signal(true);

export const streamClosing = signal(false);
export const streamCompleted = signal(false);
