import { Modal, Platform, Text, View } from "react-native";
import tailwind from "twrnc";
import { streamClosing, theme } from "../../signals/signals";

const StreamEndingModal = () => {
  const modalWidth = Platform.OS === "web" ? "65%" : "100%";

  return (
    <View style={tailwind`justify-center self-center`}>
      <Modal
        visible={streamClosing.value}
        animationType="slide"
        transparent={true}
      >
        <View
          style={tailwind`rounded-lg h-auto p-6 self-center bg-[${theme.value.modalBackgroundColor}] border-[0.5px] border-[${theme.value.textColor}] flex-col w-[${modalWidth}] max-h-[400px] absolute top-40`}
        >
          <View style={tailwind`w-full`}>
            <Text
              style={tailwind`text-[${theme.value.textColor}] text-[16px] font-bold text-sm mt-3 self-center`}
            >
              Creating your replay video
            </Text>
            <Text
              style={tailwind`text-[${theme.value.textColor}] font-bold text-sm mt-3 self-center`}
            >
              Do not close this window
            </Text>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export { StreamEndingModal as default };
