import React, { useContext } from "react";
import PropTypes from "prop-types";
import { SafeAreaView, Text, View } from "react-native";
import tailwind from "twrnc";
import { currentUser, theme } from "../../signals/signals";
import dayjs from "dayjs";
import Button from "../base/Button";
import {
  BottomSheetContext,

} from "../base/ApplicationContext";
import EditGeneralEvent from "../event-screen/edit/EditGeneralEvent";

const ViewGeneralEvent = ({ event }) => {
  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);
  const startDate = event.startDate;
  const time = dayjs(event.startDate).format("HH:mm");
  const duration = event.duration;
  const shortDescription = event.shortDescription;
  const longDescription = event.longDescription;
  const hostId = event.userId;
  const headerColour = theme.value.calendarEventOtherColor;
  const typeName = "General";

  return (
    <SafeAreaView style={tailwind`bg-[${theme.value.backgroundColor}]`}>
      <View style={tailwind`pb-10`}>
        <View
          style={tailwind`${headerColour &&
            "border-t-[" + headerColour + "] border-t-[4px] p-1 min-h-[15px]"
            }`}
        />
        <View style={tailwind`flex-row m-2 w-[600px] self-center`}>
          <View style={tailwind`w-full pr-4`}>
            <Text
              style={[
                tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold self-center pt-2 text-[20px]`,
                { fontFamily: "Roboto_700Bold" },
              ]}
            >
              {typeName}
            </Text>
            <View style={tailwind`flex-row self-center`}>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {dayjs(startDate).format("ddd MMM DD")}
              </Text>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] mx-2`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                at
              </Text>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] font-semibold`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {time}
              </Text>
            </View>
            <View style={tailwind`self-center`}>
              <Text
                style={[
                  tailwind`text-[${theme.value.viewEventHeadingTextColor}] text-[12px] font-semibold ml-1`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {duration} MIN
              </Text>
            </View>
            <View style={tailwind`my-4`}>
              <Text
                style={[
                  tailwind`self-center text-[${theme.value.textColor}] font-semibold`,
                  { fontFamily: "Roboto_700Bold" },
                ]}
              >
                {shortDescription}
              </Text>
            </View>
            <View style={tailwind`mb-4`}>
              <Text
                style={[
                  tailwind`self-center text-[${theme.value.textColor}] font-semibold mr-2`,
                  { fontFamily: "Inter_400Regular" },
                ]}
              >
                {longDescription}
              </Text>
            </View>
          </View>
        </View>
        <View style={tailwind`self-center flex-row`}>
          {hostId === currentUser.value.id ? (
            <>
              <Button
                title="Edit"
                onPress={() => {
                  setBottomSheetContent(
                    <EditGeneralEvent event={event} />
                  );
                }}
              />
            </>
          ) : null}
          <Button
            title="Close"
            onPress={() => {
              setBottomSheetVisible(false);
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

ViewGeneralEvent.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    type: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    duration: PropTypes.number.isRequired,
    shortDescription: PropTypes.string.isRequired,
    longDescription: PropTypes.string,
    rule: PropTypes.shape({
      id: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      dayOfMonth: PropTypes.number,
      daysOfWeek: PropTypes.string,
      repeatType: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  }),
};

export { ViewGeneralEvent as default };
