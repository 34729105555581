import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import tailwind from "twrnc";
import { StyledInput } from "../base/StyledInput";
import {
  analyzeConfirmPassword,
  analyzeEmailAddress,
  analyzeFirstName,
  analyzeLastName,
  analyzeNewPassword,
} from "../helpers/validation";
import { Signal } from "@preact/signals-react";

const PersonalDetailsForm = ({
  emailAddress,
  emailAddressStatus,
  password,
  passwordStatus,
  confirmPassword,
  confirmPasswordStatus,
  firstName,
  firstNameStatus,
  lastName,
  lastNameStatus,
}) => {
  useEffect(() => {
    analyzeFirstName(firstName, firstNameStatus);
    analyzeLastName(lastName, lastNameStatus);
    analyzeEmailAddress(emailAddress, emailAddressStatus);
    analyzeNewPassword(password, passwordStatus);
    analyzeConfirmPassword(confirmPassword, password, confirmPasswordStatus);
  }, []);

  return (
    <>
      <View style={tailwind`mb-6 px-2 self-center w-full`}>
        <View style={tailwind`flex-row flex-wrap`}>
          <View style={tailwind`mb-2`}>
            <StyledInput
              label={"First Name"}
              placeholder={"Enter your first name"}
              autoFocus={true}
              onChangeText={(text) => {
                firstName.value = text;
                analyzeFirstName(firstName, firstNameStatus);
              }}
              onBlur={() => analyzeFirstName(firstName, firstNameStatus)}
              fieldStatus={firstNameStatus}
              autoCorrect={false}
            />
          </View>
          <View style={tailwind`mb-2`}>
            <StyledInput
              label={"Last Name"}
              placeholder={"Enter your last name"}
              onChangeText={(text) => {
                lastName.value = text;
                analyzeLastName(lastName, lastNameStatus);
              }}
              onBlur={() => analyzeLastName(lastName, lastNameStatus)}
              fieldStatus={lastNameStatus}
              autoCorrect={false}
            />
          </View>
        </View>
        <View style={tailwind`mb-2`}>
          <StyledInput
            label="Email Address"
            placeholder={"someone@somewhere.net"}
            autoComplete="email"
            keyboardType="email"
            onChangeText={(text) => {
              emailAddress.value = text;
              analyzeEmailAddress(emailAddress, emailAddressStatus);
            }}
            onBlur={() => analyzeEmailAddress(emailAddress, emailAddressStatus)}
            fieldStatus={emailAddressStatus}
            width={"250px"}
            autoCorrect={false}
          />
        </View>
        <View style={tailwind`flex-row flex-wrap`}>
          <View style={tailwind`mb-2`}>
            <StyledInput
              label={"Password"}
              placeholder="password"
              keyboardType="text"
              autoComplete="password"
              secure={true}
              onChangeText={(text) => {
                password.value = text;
                analyzeNewPassword(password, passwordStatus);
                analyzeConfirmPassword(
                  confirmPassword,
                  password,
                  confirmPasswordStatus
                );
              }}
              onBlur={() => {
                analyzeNewPassword(password, passwordStatus);
                analyzeConfirmPassword(
                  confirmPassword,
                  password,
                  confirmPasswordStatus
                );
              }}
              fieldStatus={passwordStatus}
              autoCorrect={false}
            />
          </View>
          <View style={tailwind`mb-2`}>
            <StyledInput
              label={"Confirm Password"}
              placeholder="confirm password"
              keyboardType="text"
              autoComplete="password"
              secure={true}
              onChangeText={(text) => {
                confirmPassword.value = text;
                analyzeNewPassword(password, passwordStatus);
                analyzeConfirmPassword(
                  confirmPassword,
                  password,
                  confirmPasswordStatus
                );
              }}
              onBlur={() => {
                analyzeNewPassword(password, passwordStatus);
                analyzeConfirmPassword(
                  confirmPassword,
                  password,
                  confirmPasswordStatus
                );
              }}
              fieldStatus={confirmPasswordStatus}
              autoCorrect={false}
            />
          </View>
        </View>
      </View>
    </>
  );
};

PersonalDetailsForm.propTypes = {
  emailAddress: PropTypes.instanceOf(Signal).isRequired,
  emailAddressStatus: PropTypes.instanceOf(Signal).isRequired,
  password: PropTypes.instanceOf(Signal).isRequired,
  confirmPassword: PropTypes.instanceOf(Signal).isRequired,
  passwordStatus: PropTypes.instanceOf(Signal).isRequired,
  confirmPasswordStatus: PropTypes.instanceOf(Signal).isRequired,
  firstName: PropTypes.instanceOf(Signal).isRequired,
  firstNameStatus: PropTypes.instanceOf(Signal).isRequired,
  lastName: PropTypes.instanceOf(Signal).isRequired,
  lastNameStatus: PropTypes.instanceOf(Signal).isRequired,
};

export default PersonalDetailsForm;
