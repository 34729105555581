import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import { focusedScreen, theme } from "../../signals/signals";
import { Signal, useSignal } from "@preact/signals-react";
import { ActivityIndicator, Pressable, Text, View } from "react-native";
import tailwind from "twrnc";
import MiniTag from "../base/MiniTag";
import { Image } from "@rneui/themed";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import { Inter_600SemiBold } from "@expo-google-fonts/inter";
import VideoTitle from "../thumbnails/shared/VideoTitle";
import ReplayPrice from "../thumbnails/replay/ReplayPrice";
import DurationPill from "../thumbnails/replay/DurationPill";

const EditableReplayThumbnail = ({
  video,
  onPressOverride,
  videoThumbnailPath,
  videoTitleShown,
  videoTitle,
}) => {
  const navigation = useNavigation();
  const { id, userVideos, onDemandVideoTags, price, thumbnailPath } = video;
  let title = videoTitle?.value;
  if (!title) {
    if (video.title) {
      title = video.title;
    } else {
      title = "Untitled";
    }
  }

  const startDate = video.userSchedule?.startDate;
  const duration = video.userSchedule?.duration;

  const displayDate = dayjs(startDate).format("ddd DD MMM").toUpperCase();

  const thumbnailDimensions = useSignal({
    width: 212,
    height: 178,
  });
  const thumbnailImageDimensions = useSignal({
    width: 212,
    height: 118,
  });

  useEffect(() => {
    if (!videoThumbnailPath.value) {
      if (thumbnailPath) {
        videoThumbnailPath.value = thumbnailPath;
      } else {
        if (userVideos && userVideos.length > 0) {
          videoThumbnailPath.value = userVideos[0].userId.profileUrl;
        }
      }
    }
  }, []);

  let fonts = useFonts({ Inter_600SemiBold, Roboto_400Regular });
  if (!fonts) return;

  return (
    <Pressable
      style={tailwind`rounded-lg bg-[${theme.value.backgroundColor}] mr-[40px] mb-2`}
      onPress={
        onPressOverride
          ? onPressOverride
          : () => {
              focusedScreen.value = "OnDemandScreen";
              navigation.navigate({
                name: "OnDemandScreen",
                params: { video: video },
              } as never);
            }
      }
    >
      <View
        style={tailwind`rounded-lg w-[${thumbnailDimensions.value.width}px] bg-white`}
      >
        <View
          style={tailwind`w-[${thumbnailDimensions.value.width}px] h-[${thumbnailDimensions.value.height}px]`}
        >
          <View style={tailwind`absolute`}>
            <Image
              style={tailwind`w-[${thumbnailImageDimensions.value.width}px] h-[${thumbnailImageDimensions.value.height}px] rounded-tr-lg rounded-tl-lg`}
              source={videoThumbnailPath.value}
              PlaceholderContent={<ActivityIndicator />}
            >
              <View style={null} />
            </Image>
          </View>
          <View style={tailwind`p-1 grow`}>
            <View style={tailwind`py-10`}>
              {videoTitleShown.value ? (
                <VideoTitle title={title} />
              ) : (
                <View style={tailwind`py-[2px] px-[6px] mx-4 h-[18px]`}></View>
              )}
            </View>
            <View style={tailwind`w-full flex-row pt-6 px-1`}>
              <View style={tailwind`w-1/2 flex-row`}>
                <View>
                  <DurationPill duration={duration} />
                  <View style={tailwind`grow`} />
                </View>
                <View style={tailwind`grow`} />
              </View>
              <View style={tailwind`w-1/2 self-end`}>
                <ReplayPrice
                  price={price}
                  hasPaid={false}
                  didAttend={false}
                  isOwner={true}
                />
              </View>
            </View>
            <View style={tailwind`flex-row flex-wrap px-1`}>
              {onDemandVideoTags.length ? (
                <MiniTag
                  key={onDemandVideoTags[0].id}
                  label={
                    (
                      onDemandVideoTags[0].tagId &&
                      onDemandVideoTags[0].tagId.tagName
                    ).toUpperCase() || ""
                  }
                  colorKey={onDemandVideoTags[0].tagId.tagColorKey}
                />
              ) : null}
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

EditableReplayThumbnail.propTypes = {
  video: PropTypes.object.isRequired,
  onPressOverride: PropTypes.func,
  videoThumbnailPath: PropTypes.instanceOf(Signal).isRequired,
  videoTitleShown: PropTypes.instanceOf(Signal).isRequired,
  videoTitle: PropTypes.instanceOf(Signal),
};

export { EditableReplayThumbnail as default };
