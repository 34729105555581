import React, { useContext, useEffect } from "react";
import {
  Dimensions,
  Platform,
  SafeAreaView,
  ScrollView,
  Text,
  View,
} from "react-native";
import tailwind from "twrnc";
import Button from "../base/Button";
import {
  API_URL,
  DIFFICULTY_ALL,
  TREE_TYPE_BODY,
  TREE_TYPE_USER,
} from "../base/Constants";
import TagSelector from "../tags/TagSelector";
import { useNavigation } from "@react-navigation/native";
import { BottomSheetContext } from "../base/ApplicationContext";
import {
  bodyTagSelection,
  currentUser,
  focusedScreen,
  searchQuery,
  searchType,
  theme,
  userTagSelection,
} from "../../signals/signals";
import { useSignal } from "@preact/signals-react";
import DifficultyInput from "../form/shared/DifficultyInput";
import SearchTypeSelector from "./SearchTypeSelector";
import TextSearchInput from "../form/search/TextSearchInput";
import TextSearchResults from "./TextSearchResults";
import axios from "axios";

const SearchModal = () => {
  const navigation = useNavigation();
  const { setBottomSheetVisible, setBottomSheetContent } =
    useContext(BottomSheetContext);
  const windowHeight = Dimensions.get("window").height;
  const difficulty = useSignal(DIFFICULTY_ALL);
  const userTagStatus = useSignal("");
  const bodyTagStatus = useSignal("");
  const searchText = useSignal("");
  const searchTextStatus = useSignal("");
  const isLoading = useSignal(false);
  const isTyping = useSignal(false);
  const foundTrainers = useSignal([]);

  const fetchTrainersByNameSearch = async () => {
    if (searchText.value) {
      isLoading.value = true;
      axios
        .get(`${API_URL}users/search/${searchText.value}`, {
          headers: { authorization: currentUser.value.token },
        })
        .then((response) => {
          foundTrainers.value = response.data;
          if (response.data.message) {
            foundTrainers.value = [];
          }
          isLoading.value = false;
        })
        .catch((error) => {
          console.log(error);
          isLoading.value = false;
          foundTrainers.value = [];
        });
    }
  };

  useEffect(() => {
    userTagSelection.value = [];
    bodyTagSelection.value = [];
    if (searchQuery.value) {
      const decodedSearchQuery = JSON.parse(searchQuery.value);

      if (decodedSearchQuery.difficulty !== DIFFICULTY_ALL)
        difficulty.value = decodedSearchQuery.difficulty;

      decodedSearchQuery.userTags.forEach((tag) => {
        userTagSelection.value = [
          ...userTagSelection.value,
          {
            id: tag.id,
            title: tag.tagName,
            deleted: 0,
          },
        ];
      });

      decodedSearchQuery.bodyTags.forEach((tag) => {
        bodyTagSelection.value = [
          ...bodyTagSelection.value,
          {
            id: tag.id,
            title: tag.tagName,
            deleted: 0,
          },
        ];
      });
    }
  }, []);

  useEffect(() => {
    if (
      !isTyping.value &&
      searchText.value.length &&
      searchTextStatus.value === ""
    ) {
      fetchTrainersByNameSearch();
    }
  }, [searchText.value, isTyping.value]);

  useEffect(() => {
    if (isTyping.value) {
      setTimeout(() => {
        isTyping.value = false;
      }, 1000);
    }
  }, [isTyping.value]);

  return (
    <SafeAreaView
      style={tailwind`bg-[${theme.value.backgroundColor}] h-[${windowHeight}px]`}
    >
      <View
        style={tailwind`h-[50px] py-4 mb-2 bg-[${theme.value.searchModalTopColor}]`}
      >
        <Text
          style={tailwind`self-center text-[${theme.value.searchModalTextColor}] uppercase font-semibold`}
        >
          Search
        </Text>
      </View>
      <View style={tailwind`self-center`}>
        <SearchTypeSelector searchType={searchType} />
      </View>
      {searchType.value === "0" ? (
        <View style={tailwind`self-center grow w-full max-w-[800px] pr-4`}>
          <TextSearchInput
            searchText={searchText}
            searchTextStatus={searchTextStatus}
            isTyping={isTyping}
          />
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={tailwind`w-full h-[${
              Dimensions.get("window").height - 300
            }px] pl-4`}
          >
            <TextSearchResults
              foundTrainers={foundTrainers}
              isLoading={isLoading}
            />
          </ScrollView>
          <View style={tailwind`grow`} />
          <View style={tailwind`self-center py-6`}>
            <Button
              title={"Close"}
              onPress={() => {
                setBottomSheetVisible(false);
              }}
            />
          </View>
        </View>
      ) : (
        <View style={tailwind`w-full grow`}>
          <View style={tailwind`self-center mx-2 mb-2`}>
            <TagSelector tagType={TREE_TYPE_USER} fieldStatus={userTagStatus} />
          </View>
          <View style={tailwind`self-center mx-2 mb-2`}>
            <TagSelector tagType={TREE_TYPE_BODY} fieldStatus={bodyTagStatus} />
          </View>
          <View style={tailwind`w-full self-center mt-8 mb-2 px-3`}>
            {Platform.OS !== "web" ? (
              <Text
                style={[
                  tailwind`text-[20px] font-semibold text-[${theme.value.textColor}] ml-2`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                Difficulty
              </Text>
            ) : null}
            <View style={tailwind`self-center`}>
              <DifficultyInput difficulty={difficulty} />
            </View>
          </View>
          <View style={tailwind`grow`} />
          <View style={tailwind`self-center flex-row py-6`}>
            <Button
              title={"Search"}
              disabled={
                userTagSelection.value.filter((tag) => !tag.deleted).length ===
                  0 &&
                bodyTagSelection.value.filter((tag) => !tag.deleted).length ===
                  0 &&
                difficulty.value === DIFFICULTY_ALL
              }
              onPress={() => {
                const query = {
                  userTags: userTagSelection.value.filter(
                    (tag) => !tag.deleted
                  ),
                  bodyTags: bodyTagSelection.value.filter(
                    (tag) => !tag.deleted
                  ),
                  difficulty: difficulty,
                };
                searchQuery.value = JSON.stringify(query);
                setBottomSheetVisible(false);
                setBottomSheetContent(<></>);
                focusedScreen.value = "SearchResultsScreen";
                navigation.navigate("SearchResultsScreen" as never);
              }}
            />
            <Button
              title={"Close"}
              onPress={() => {
                setBottomSheetVisible(false);
              }}
            />
          </View>
        </View>
      )}
    </SafeAreaView>
  );
};

export { SearchModal as default };
