import { currentUser, notificationSocket } from "../../signals/signals";
import { Signal } from "@preact/signals-react";

export const attachSocketFileHandlers = (
  hasErrored: Signal,
  videoPath: Signal,
  isUploading: Signal,
  publicFilePath: Signal
) => {
  const socket = notificationSocket.value;
  socket.on("addVideoCompleteOk", (publicPath: string) => {
    videoPath.value = publicPath;
    publicFilePath.value = publicPath;
    clearUploadingFlag(isUploading);
  });
  socket.on("addVideoChunkOffsetError", () => {
    hasErrored.value = true;
    clearUploadingFlag(isUploading);
  });
  socket.on("addVideoPermissionError", () => {
    hasErrored.value = true;
    clearUploadingFlag(isUploading);
  });
};

const clearUploadingFlag = (isUploading: Signal) => {
  setTimeout(() => {
    isUploading.value = false;
  }, 500);
};

export const prepareVideoUpload = (
  fileName: string,
  hasErrored: Signal,
  callback: () => void
): void => {
  const token = currentUser.value.token;
  notificationSocket.value.on("prepareAddVideoError", () => {
    hasErrored.value = true;
  });
  notificationSocket.value.on("prepareAddVideoOk", () => {
    callback();
  });
  notificationSocket.value.emit(
    "prepareAddVideo",
    token,
    fileName,
    currentUser.value.id
  );
};

export const signalVideoUploadAbandoned = (
  fileName: string,
  hasErrored: Signal
) => {
  const token = currentUser.value.token;
  notificationSocket.value.emit(
    "addVideoAbandoned",
    token,
    fileName,
    currentUser.value.id
  );
};

export const signalVideoUploadComplete = (fileName: string) => {
  const { id, token } = currentUser.value;
  notificationSocket.value.emit("addVideoComplete", token, fileName, id);
};

export const sendVideoChunk = (
  chunk: string,
  chunkStart: number,
  chunkEnd: number
) => {
  const token = currentUser.value.token;
  const chunkSize = chunk.length;

  const chunkData = {
    token: token,
    chunk: chunk,
    chunkSize: chunkSize,
    chunkStart: chunkStart,
    chunkEnd: chunkEnd,
  };

  notificationSocket.value.emit("addVideo", chunkData);
};
