import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useFonts, Inter_600SemiBold } from "@expo-google-fonts/inter";
import { Roboto_700Bold, Roboto_400Regular } from "@expo-google-fonts/roboto";
import { View, Pressable, Text, ActivityIndicator } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { DEFAULT_PROFILE_URL } from "../../base/Constants";
import dayjs from "dayjs";
import ViewEvent from "../../calendar-screen/ViewEvent";
import { BottomSheetContext } from "../../base/ApplicationContext";
import MiniTag from "../../base/MiniTag";
import { currentUser, theme } from "../../../signals/signals";
import { useSignal } from "@preact/signals-react";
import DurationPill from "../replay/DurationPill";
import LivePrice from "./LivePrice";
import { determineBookingStatus } from "../../helpers/bookingFunctions";

const LiveThumbnail = ({ event }) => {
  let userSchedule;
  if (event.userSchedule) {
    userSchedule = event.userSchedule;
  } else {
    userSchedule = event;
    event.userSchedule = userSchedule;
  }

  const startDate = userSchedule.startDate;
  const shortDescription = userSchedule.shortDescription;
  const longDescription = userSchedule.longDescription;
  const duration = userSchedule.duration;
  const profileUrl =
    (userSchedule.user && userSchedule.user.profileUrl) || DEFAULT_PROFILE_URL;
  const shortName =
    (userSchedule.user &&
      userSchedule.user.firstName + " " + userSchedule.user.lastName) ||
    "";
  const tags = userSchedule.scheduleTags;
  const bookings = userSchedule.bookings;

  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);

  const isBooked = useSignal(false);
  const isStalled = useSignal(false);
  const revolutPublicId = useSignal(null);
  const revolutOrderid = useSignal(null);
  const paymentId = useSignal(null);

  const hasBookings = useSignal(false);
  const numberOfBookings = useSignal(0);
  const isHost = userSchedule.userId === currentUser.value.id;
  const price = userSchedule.price;

  const thumbnailDimensions = useSignal({
    width: 365,
    height: 300,
  });
  const thumbnailImageDimensions = useSignal({
    width: 365,
    height: 240,
  });

  let typeId;
  useEffect(() => {
    typeId = event.type.id;

    if (typeId) {
      determineBookingStatus({
        bookings,
        event,
        isHost,
        numberOfBookings,
        revolutPublicId,
        revolutOrderid,
        paymentId,
        isBooked,
        hasBookings,
        isStalled,
      });
    }
  }, [event.type.id]);

  let displayDate;
  let eventTime = dayjs(startDate).format("HH:mm");
  if (dayjs(startDate).format("YYYYMMDD") === dayjs().format("YYYYMMDD"))
    displayDate = "TODAY " + eventTime;
  else if (
    dayjs(startDate).format("YYYYMMDD") ===
    dayjs().add(1, "day").format("YYYYMMDD")
  )
    displayDate = "TOMORROW " + eventTime;
  else
    displayDate = (
      dayjs(startDate).format("dddd") +
      ", " +
      eventTime
    ).toUpperCase();

  let [fontsLoaded] = useFonts({
    Inter_600SemiBold,
    Roboto_700Bold,
    Roboto_400Regular,
  });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <Pressable
      style={tailwind`rounded-lg bg-[${theme.value.backgroundColor}] mr-[40px] mb-2`}
      onPress={() => {
        setBottomSheetContent(<ViewEvent event={event} />);
        setBottomSheetVisible(true);
      }}
    >
      <View
        style={tailwind`rounded-lg w-[${thumbnailDimensions.value.width}px] bg-white`}
      >
        <View
          style={tailwind`w-[${thumbnailDimensions.value.width}px] h-[${thumbnailDimensions.value.height}px]`}
        >
          <View style={tailwind`absolute`}>
            <Image
              style={tailwind`w-[${thumbnailImageDimensions.value.width}px] h-[${thumbnailImageDimensions.value.height}px] rounded-tr-lg rounded-tl-lg`}
              source={{ uri: profileUrl }}
              PlaceholderContent={<ActivityIndicator />}
            >
              <View />
            </Image>
          </View>
          <View style={tailwind`p-1 grow`}>
            <View style={tailwind`grow`} />
            <View style={tailwind`w-full flex-row pt-6 px-1`}>
              <View style={tailwind`w-1/2 flex-row`}>
                <View style={tailwind`pb-1`}>
                  <DurationPill duration={duration} />
                  <View style={tailwind`grow`} />
                  <View style={tailwind`flex-row flex-wrap`}>
                    {tags?.length ? (
                      <MiniTag
                        key={tags[0].tag.id}
                        label={tags[0].tag.tagName.toUpperCase() || ""}
                        colorKey={tags[0].tag.tagColorKey}
                      />
                    ) : null}
                  </View>
                </View>
                <View style={tailwind`grow`} />
              </View>
              <View style={tailwind`w-1/2 self-end mb-1`}>
                <View style={tailwind`mb-1`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.value.timePillTextColor}] self-end text-[10px]`,
                      { fontFamily: "Inter_600SemiBold" },
                    ]}
                  >
                    {displayDate}
                  </Text>
                </View>
                <LivePrice
                  price={price}
                  hasBookings={hasBookings}
                  isBooked={isBooked}
                  isHost={isHost}
                  isStalled={isStalled}
                  numberOfBookings={numberOfBookings}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={tailwind`mt-2 max-w-[365px]`}>
        <Text
          numberOfLines={1}
          style={[
            tailwind`self-start text-[${theme.value.textColor}] text-[20px]`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          {shortDescription}
        </Text>
        <View style={tailwind`mb-4 mt-2 max-w-[365px] min-h-[10px]`}>
          <Text
            numberOfLines={1}
            style={[
              tailwind`text-[10px] self-start text-[${theme.value.textColor}] text-[12px]`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            {longDescription}
          </Text>
        </View>
        <View style={tailwind`flex-row`}>
          <View
            style={tailwind`rounded-full bg-[${theme.value.backgroundColor}]`}
          >
            <Image
              style={tailwind`w-[40px] h-[40px] rounded-full`}
              source={{ uri: profileUrl }}
            />
          </View>
          <View style={tailwind`ml-3`}>
            <Text
              style={[
                tailwind`text-[16px] text-[${theme.value.textColor}]`,
                { fontFamily: "Roboto_700Bold" },
              ]}
            >
              {shortName}
            </Text>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

LiveThumbnail.propTypes = {
  event: PropTypes.object.isRequired,
};

export default LiveThumbnail;
