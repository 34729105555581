import { Socket } from "socket.io-client";
import {
  currentUser,
  isNotificationServerConnected,
  outstandingConnectionCount,
  outstandingConnections,
  outstandingMessageCount,
  outstandingMessages,
  outstandingNotificationCount,
  outstandingNotifications,
} from "../../signals/signals";
import {
  NOTIFICATION_TYPE_CONNECTION,
  NOTIFICATION_TYPE_EVENT,
  queryClient,
} from "./Constants";
import { Message } from "../conference/types/Message";

export const attachNotificationSocketHandlers = (socket: Socket) => {
  outstandingConnectionCount.value = 0;
  outstandingConnectionCount.value = 0;
  outstandingMessageCount.value = 0;
  outstandingNotificationCount.value = 0;

  socket.on("messages", (messages: Message[]) => {
    outstandingMessages.value = messages;
    outstandingMessageCount.value = messages.length;
    queryClient.invalidateQueries([
      "connection-messages" + currentUser.value.id,
    ]);
  });

  socket.on("clear-all-notifications", () => {
    outstandingNotifications.value = [];
    outstandingNotificationCount.value = 0;
  });

  socket.on("connections", (connections) => {
    outstandingConnections.value = connections;
    outstandingConnectionCount.value = connections.length;
  });

  socket.on("disconnect", () => {
    console.log("Connection to notification server lost");
    isNotificationServerConnected.value = false;
  });

  socket.on("connect", () => {
    console.log("Connection to notification server re-established");
    isNotificationServerConnected.value = true;
  });

  socket.on("notifications", (notifications: any[]) => {
    const eventNotifications = notifications.filter((notification) => {
      return notification.type.id === NOTIFICATION_TYPE_EVENT;
    });

    const connectionNotifications = notifications.filter((notification) => {
      return notification.type.id === NOTIFICATION_TYPE_CONNECTION;
    });

    outstandingNotifications.value = [...eventNotifications];

    outstandingConnections.value = [
      ...outstandingConnections.value,
      ...connectionNotifications.map((notification) => {
        return notification.sourceUser.id;
      }),
    ];

    outstandingConnectionCount.value = outstandingConnections.value.length;
    outstandingNotificationCount.value = outstandingNotifications.value.length;
  });
};
