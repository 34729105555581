import React, { Pressable, ScrollView, Text, View } from "react-native";
import {
  currentUser,
  participants,
  peerStatus,
  theme,
} from "../../../signals/signals";
import tailwind from "twrnc";
import { PeerConnectionStatus } from "../types/PeerConnectionStatus";
import EventAttendee from "../../calendar-screen/EventAttendee";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import { Image } from "@rneui/themed";
import { useSignal } from "@preact/signals-react";
import CallDisabled from "./CallDisabled";
import CallEnabled from "./CallEnabled";
import CallPending from "./CallPending";
import CallConnected from "./CallConnected";
import CallDisconnected from "./CallDisconnected";
import CallEndEnabled from "./CallEndEnabled";
import CallEndDisbled from "./CallEndDisbled";

const PeerStatus = ({ roomId }) => {
  const panelShown = useSignal(true);

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <View
      style={tailwind`${
        panelShown.value ? "h-[180px]" : "h-[32px]"
      } max-w-[600px] bg-[${theme.value.errorModalBackgroundColor}]`}
    >
      <View style={tailwind`self-center`}>
        <Pressable
          style={tailwind`m-1`}
          onPress={() => (panelShown.value = !panelShown.value)}
        >
          <Image
            resizeMode="contain"
            style={tailwind`h-[25px] w-[25px]`}
            source={
              panelShown.value
                ? require("../../../assets/collapse-vertical.png")
                : require("../../../assets/expand-vertical.png")
            }
            tintColor={theme.value.iconColor}
          />
        </Pressable>
      </View>

      <ScrollView>
        {participants.value.map((participant) => {
          if (participant.id === currentUser.value.id) {
            return null;
          }
          const peerConnectionStatus: PeerConnectionStatus =
            peerStatus.value.find(
              (peerConnectionStatus) =>
                peerConnectionStatus.userId === participant.id
            );

          return (
            <PeerStatusItem
              key={participant.id}
              roomId={roomId}
              userId={participant.id}
              name={participant.name}
              profileUrl={participant.profileUrl}
              connectionStatus={peerConnectionStatus?.connectionStatus}
              inRoom={participant.inRoom}
            />
          );
        })}
      </ScrollView>
    </View>
  );
};

const PeerStatusItem = ({
  roomId,
  userId,
  name,
  profileUrl,
  connectionStatus,
  inRoom,
}) => {
  return (
    <View>
      <View style={[tailwind`flex-row`]}>
        <View style={tailwind`w-1/8 self-center`}>
          <EventAttendee attendee={{ profileUrl }} small />
        </View>
        <View style={tailwind`w-3/8 self-center px-1`}>
          <Text
            style={[
              tailwind`text-[${theme.value.textColor}] font-bold`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            {name}
          </Text>
        </View>
        <View style={tailwind`self-center px-1 w-1/8`}>
          {inRoom ? (
            <Image
              resizeMode="contain"
              style={tailwind`h-[25px] w-[25px]`}
              source={require("../../../assets/cloud-check.png")}
              tintColor={theme.value.greenColor}
            />
          ) : (
            <Image
              resizeMode="contain"
              style={tailwind`h-[25px] w-[25px]`}
              source={require("../../../assets/cloud-question.png")}
              tintColor={theme.value.disabledColor}
            />
          )}
        </View>
        <View style={tailwind`self-center px-1 w-1/8`}>
          {connectionStatus === "connecting" && <CallPending />}
          {connectionStatus === "Calling" && <CallPending />}
          {connectionStatus === "Answering" && <CallPending />}
          {connectionStatus === "connected" && <CallConnected />}
          {connectionStatus === "disconnected" && <CallDisconnected />}
          {connectionStatus === "Disconnected" && <CallDisconnected />}
          {connectionStatus === undefined && <CallDisconnected />}
        </View>
        <View style={tailwind`w-1/8 px-1`}>
          {inRoom &&
          connectionStatus !== "connected" &&
          connectionStatus !== "Answering" ? (
            <CallEnabled userId={userId} roomId={roomId} />
          ) : (
            <CallDisabled />
          )}
        </View>
        <View style={tailwind`w-1/8 px-1`}>
          {inRoom && connectionStatus === "connected" ? (
            <CallEndEnabled userId={userId} roomId={roomId} />
          ) : (
            <CallEndDisbled />
          )}
        </View>
      </View>
    </View>
  );
};

export { PeerStatus as default };
