import { Dimensions, Platform } from "react-native";

//Calculates proportional container resize on window resize
//as well as remaining height of page
export const handleResize2 = ({
  sourceWidth,
  sourceHeight,
}: {
  sourceWidth: number;
  sourceHeight: number;
}): {
  containerWidth: number;
  containerHeight: number;
  scrollContainerSize: number;
} => {
  let windowWidth = 0;
  let windowHeight = 0;
  let containerWidth = 0;
  let containerHeight = 0;
  let scrollContainerSize = 0;

  const ratio = sourceWidth / sourceHeight;
  windowWidth = Dimensions.get("window").width;
  windowHeight = Dimensions.get("window").height;

  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;

  if (isMobileWebFormat) {
    if (windowWidth * 1 < sourceWidth) {
      containerWidth = windowWidth;
      containerHeight = windowWidth / ratio;
    } else {
      containerWidth = sourceWidth;
      containerHeight = sourceHeight;
    }
    scrollContainerSize = windowHeight - containerHeight - 92;
  } else {
    if (windowWidth < sourceWidth + 112) {
      containerWidth = windowWidth - 112;
      containerHeight = (windowWidth - 112) / ratio;
    } else {
      containerWidth = sourceWidth;
      containerHeight = sourceHeight;
    }
    scrollContainerSize = windowHeight - containerHeight - 20;
  }

  return {
    containerWidth: containerWidth,
    containerHeight: containerHeight,
    scrollContainerSize: scrollContainerSize,
  };
};
