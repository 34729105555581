import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Dimensions, Platform, ScrollView, Text, View } from "react-native";
import ClientStreamsContainer from "./ClientStreamsContainer";
import {
  currentUser,
  localStream,
  providerStream,
  remoteStreams,
} from "../../../signals/signals";
import { Signal, useSignal } from "@preact/signals-react";
import tailwind, { Style } from "twrnc";
import * as ScreenOrientation from "expo-screen-orientation";
import SignalChatPT from "../../chat/SignalChatPT";
import { CustomStream } from "../types/CustomStream";
import ProviderStream from "./ProviderStream";
import LocalStreamWrapper from "./LocalStreamWrapper";

const NewClientStreamLayout = ({
  hostId,
  event,
  participants,
}): JSX.Element => {
  const roomId: string = event.eventKey;
  const videoRef = useRef(null);

  useEffect(() => {
    let providerStreamFound: boolean = false;
    if (remoteStreams.value.length > 0) {
      remoteStreams.value.forEach((remoteStream: CustomStream) => {
        if (remoteStream.userId === hostId) {
          providerStream.value = remoteStream;
          providerStreamFound = true;
        }
      });
    }
    if (!providerStreamFound) providerStream.value = null;
  }, [remoteStreams.value, hostId]);

  const windowOrientation: Signal = useSignal("portrait");
  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        windowOrientation.value = getOrientation(orientationInfo.orientation);
      }, 300);
    });
  }, []);

  const getOrientation = (orientation: number): string => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };

  const isMobileWebFormat: boolean =
    Platform.OS === "web" &&
    ((windowOrientation.value === "portrait" &&
      Dimensions.get("screen").width < 768) ||
      (windowOrientation.value === "landscape" &&
        Dimensions.get("screen").height < 768));

  let outerViewStyle: Style;
  if (isMobileWebFormat && windowOrientation.value === "landscape") {
    outerViewStyle = tailwind`flex-row`;
  } else if (isMobileWebFormat && windowOrientation.value === "portrait") {
    outerViewStyle = tailwind`grow`;
  } else {
    outerViewStyle = tailwind`flex-row`;
  }

  let innerViewStyle: Style;
  if (isMobileWebFormat && windowOrientation.value === "portrait") {
    innerViewStyle = tailwind`w-[${Dimensions.get("screen").width}px]`;
  } else if (isMobileWebFormat && windowOrientation.value === "landscape") {
    innerViewStyle = tailwind`w-[${Dimensions.get("screen").width / 2}px]`;
  } else {
    innerViewStyle = tailwind`w-[640}px h-[${
      isMobileWebFormat ? Dimensions.get("screen").height / 2 : "480"
    }px]`;
  }

  return (
    <ScrollView style={tailwind`flex-row pb-10`}>
      <View style={outerViewStyle}>
        <View style={tailwind`grow`}>
          {providerStream.value ? (
            <ProviderStream />
          ) : (
            <View style={innerViewStyle}>
              <View
                style={tailwind`grow ${
                  isMobileWebFormat && windowOrientation.value === "landscape"
                    ? "min-w-[" + Dimensions.get("screen").width / 2 + "px]"
                    : "min-w-[" + Dimensions.get("screen").width / 2 + "px]"
                }`}
              >
                <Text style={tailwind`self-center`}>
                  Waiting for host to join
                </Text>
              </View>
            </View>
          )}
          <View style={tailwind`grow`} />
          <View style={tailwind`flex-row`}>
            <View>
              {localStream.value && (
                <LocalStreamWrapper userId={currentUser.value.id} />
              )}
            </View>
            <View>
              <ClientStreamsContainer
                hostId={hostId}
                participants={participants}
              />
            </View>
          </View>
        </View>
        <View
          style={tailwind`w-[300px] bg-[#00000f] mr-2 ${
            isMobileWebFormat && windowOrientation.value === "landscape"
              ? "max-h-[" + (Dimensions.get("screen").height - 250) + "px]"
              : "max-h-[" + (Dimensions.get("screen").height - 250) + "px]"
          }`}
        >
          <SignalChatPT roomId={roomId} />
        </View>
      </View>
    </ScrollView>
  );
};

NewClientStreamLayout.propTypes = {
  hostId: PropTypes.number.isRequired,
  event: PropTypes.object.isRequired,
  participants: PropTypes.instanceOf(Signal).isRequired,
};

export { NewClientStreamLayout as default };
