import * as React from "react";
import PropTypes from "prop-types";
import ReactHlsPlayer from "react-hls-player/dist";
import { View } from "react-native";
import { LIVE_STREAM_VIEW_URL } from "../../base/Constants";
import tailwind from "twrnc";

const WebPlayer = ({ event }) => {
  const playerRef = React.useRef();
  const eventKey = event.eventKey;
  return (
    <View>
      <ReactHlsPlayer
        playerRef={playerRef}
        hlsConfig={{
          startPosition: 0,
        }}
        src={LIVE_STREAM_VIEW_URL + eventKey + ".m3u8"}
        autoPlay={true}
        controls={true}
        width={"100%"}
        height={"100%"}
        style={tailwind`grow`}
      />
    </View>
  );
};

WebPlayer.propTypes = {
  event: PropTypes.object.isRequired,
};

export default WebPlayer;
