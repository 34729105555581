import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { theme } from "../../../signals/signals";

const CallPending = () => {
  return (
    <Image
      resizeMode="contain"
      style={tailwind`h-[25px] w-[25px]`}
      source={require("../../../assets/lan-pending.png")}
      tintColor={theme.value.greenColor}
    />
  );
};

export { CallPending as default };
