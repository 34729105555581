import React from "react";
import {
  ActivityIndicator,
  Platform,
  Pressable,
  ScrollView,
  Text,
  View,
} from "react-native";
import tailwind from "twrnc";
import {
  currentUser,
  focusedScreen,
  outstandingMessages,
  theme,
} from "../signals/signals";
import { QueryClientProvider, useQuery } from "@tanstack/react-query";
import {
  API_URL,
  CONNECTION_STATUS_CONNECTED,
  DEFAULT_PROFILE_URL,
  queryClient,
} from "../components/base/Constants";
import axios from "axios";
import { Image } from "@rneui/themed";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  useFonts,
  Roboto_400Regular,
  Roboto_700Bold,
} from "@expo-google-fonts/roboto";
import { useNavigation } from "@react-navigation/native";
import ApiError from "../components/error/ApiError";

const Chats = () => {
  dayjs.extend(utc);
  dayjs.extend(relativeTime);

  let fonts = useFonts({ Roboto_400Regular, Roboto_700Bold });
  if (!fonts) return;

  return (
    <View style={tailwind`flex h-full p-2 bg-[${theme.value.backgroundColor}]`}>
      <QueryClientProvider client={queryClient}>
        <MessagingScreenInner />
      </QueryClientProvider>
    </View>
  );
};

const MessagingScreenInner = () => {
  const navigation = useNavigation();
  const userId = currentUser.value.id;
  const {
    isLoading,
    error,
    data,
  }: { isLoading: boolean; error: any; data: any } = useQuery(
    ["connection-messages" + userId],
    () =>
      axios
        .get(API_URL + "userconnection/messages/" + userId, {
          headers: { authorization: currentUser.value.token },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err))
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;
  let formattedData = data.filter((item) => {
    return item.status === CONNECTION_STATUS_CONNECTED;
  });

  //Attach outstanding message count to each connection object
  formattedData.map((connection) => {
    connection.outstandingMessages = outstandingMessages.value.filter(
      (message) => {
        if (
          message.user.id === connection.sourceUser.id ||
          message.user.id === connection.destinationUser.id
        )
          return message;
      }
    );
  });

  if (!formattedData.length) {
    return (
      <View style={tailwind`justify-center`}>
        <Text
          style={[
            tailwind`text-[${theme.value.textColor}]`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          No connections
        </Text>
      </View>
    );
  }
  return (
    <View style={tailwind``}>
      <ScrollView>
        {formattedData.map((connection) => {
          let recentMessage = null;
          const { roomId, privateRoom } = connection;
          const targetUser =
            connection.sourceUser.id === userId
              ? connection.destinationUser
              : connection.sourceUser;

          let messagesForRoom = [];
          if (outstandingMessages.value.length) {
            messagesForRoom = outstandingMessages.value.filter(
              (message) => message.user.id === targetUser.id
            );
          }

          const onPress = () => {
            focusedScreen.value = "Chat";
            navigation.navigate({
              name: "Chat",
              params: {
                roomId: roomId,
                targetUserId: targetUser.id,
                privateRoom: privateRoom[0],
              },
            } as never);
          };

          if (privateRoom.length) {
            if (
              privateRoom[0].privateRoomMessages &&
              privateRoom[0].privateRoomMessages.length
            ) {
              recentMessage = privateRoom[0].privateRoomMessages[0];
            }
          }

          const connectionUser =
            connection.sourceUser.id === userId
              ? connection.destinationUser
              : connection.sourceUser;
          const profileUrl = connectionUser.profileUrl || DEFAULT_PROFILE_URL;

          return (
            <Pressable key={connection.id} onPress={onPress}>
              <View
                style={tailwind`flex-row self-center border-b-[1px] border-[${theme.value.redColor}] p-2 w-full min-w-[250px] bg-[#fff]`}
              >
                <View style={tailwind`w-[150px]`}>
                  <View style={tailwind`self-center`}>
                    <Image
                      source={{ uri: profileUrl }}
                      style={tailwind`w-12 h-12 rounded-full`}
                      PlaceholderContent={<ActivityIndicator />}
                    />
                  </View>
                  {messagesForRoom.length ? (
                    <View
                      style={tailwind`absolute top-0 ${
                        Platform.OS === "ios" ? "right-2" : "right-10"
                      } rounded-full w-[15px] h-[15px] bg-[${
                        theme.value.notificationBadgeTextColor
                      }]`}
                    >
                      <Text
                        style={tailwind`text-[8px] self-center pt-[3px] text-[${theme.value.notificationBadgeBackgroundColor}]`}
                      >
                        {messagesForRoom.length}
                      </Text>
                    </View>
                  ) : null}
                </View>
                {recentMessage ? (
                  <>
                    <View style={tailwind`grow`}>
                      <View>
                        <Text style={[{ fontFamily: "Roboto_700Bold" }]}>
                          {connectionUser.firstName +
                            " " +
                            connectionUser.lastName}
                        </Text>
                      </View>
                      <View style={tailwind`flex-row`}>
                        <View style={tailwind`ml-2 w-[80px]`}>
                          {recentMessage.user.id !== userId ? (
                            <Text
                              style={[
                                tailwind`my-2 font-semibold opacity-70`,
                                { fontFamily: "Roboto_400Regular" },
                              ]}
                            >
                              {recentMessage.user.firstName}
                            </Text>
                          ) : (
                            <Text
                              style={[
                                tailwind`my-2 font-semibold opacity-70`,
                                { fontFamily: "Roboto_400Regular" },
                              ]}
                            >
                              You
                            </Text>
                          )}
                        </View>
                        <View style={tailwind`ml-2`}>
                          <Text
                            style={[
                              tailwind`my-2 opacity-50 text-[${
                                messagesForRoom.length
                                  ? theme.value.redColor
                                  : theme.value.textColor
                              }] ${
                                messagesForRoom.length
                                  ? "font-semibold"
                                  : "font-normal"
                              }`,
                              { fontFamily: "Roboto_400Regular" },
                            ]}
                          >
                            {recentMessage.message}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View>
                      <Text
                        style={[
                          tailwind`text-[10px] self-end`,
                          { fontFamily: "Roboto_400Regular" },
                        ]}
                      >
                        {dayjs.utc(recentMessage.createdAt).from(dayjs.utc())}
                      </Text>
                    </View>
                  </>
                ) : (
                  <View>
                    <View>
                      <Text style={[{ fontFamily: "Roboto_700Bold" }]}>
                        {connectionUser.firstName +
                          " " +
                          connectionUser.lastName}
                      </Text>
                    </View>
                    <View style={tailwind`flex-row`}>
                      <View style={tailwind`ml-2 w-[80px]`} />
                      <Text
                        style={[
                          tailwind`ml-2 my-2 opacity-50`,
                          { fontFamily: "Roboto_400Regular" },
                        ]}
                      >
                        No messages
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            </Pressable>
          );
        })}
      </ScrollView>
    </View>
  );
};

export { Chats as default };
