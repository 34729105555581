import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Dimensions, ScrollView, View } from "react-native";
import tailwind from "twrnc";

import MessageEntry from "./MessageEntry";
import MessageView from "./MessageView";
import { privateMessages, theme } from "../../signals/signals";
import { disconnectFromPTChatRoom } from "../private-room/shared/socketHandler";
import { Message } from "../conference/types/Message";

const SignalChatPT = ({ roomId }): JSX.Element => {
  const scrollViewRef: React.RefObject<ScrollView> = useRef(null);

  useEffect((): any => {
    return async () => {
      disconnectFromPTChatRoom();
      privateMessages.value = [];
    };
  }, []);

  const onMessageSent = () => {
    scrollChatToBottom();
  };

  const scrollChatToBottom = () => {
    scrollViewRef.current &&
      scrollViewRef.current.scrollToEnd({ animated: true });
  };

  useEffect(() => {
    scrollChatToBottom();
  }, [privateMessages.value]);

  return (
    <View
      style={tailwind`w-full h-full pr-[2px] bg-[${theme.value.backgroundColor}]`}
    >
      <View
        style={tailwind`grow h-[${Dimensions.get("screen").height - 600}px]`}
      >
        <ScrollView ref={scrollViewRef}>
          <MessageView />
        </ScrollView>
      </View>
      <View>
        <MessageEntry roomId={roomId} onMessageSent={onMessageSent} />
      </View>
    </View>
  );
};

SignalChatPT.propTypes = {
  roomId: PropTypes.string.isRequired,
};

export { SignalChatPT as default };
