import React, { useEffect } from "react";
import { ScrollView, View } from "react-native";
import WebPlayer from "../video-players/hls/WebPlayer";
import tailwind from "twrnc";
import LiveStreamDetails from "./LiveStreamDetails";
import { currentUser, focusedScreen, theme } from "../../signals/signals";
import axios from "axios";
import {
  API_URL,
  BROADCAST_VIDEO_HEIGHT,
  BROADCAST_VIDEO_WIDTH,
} from "../base/Constants";
import { Signal, signal, useSignal } from "@preact/signals-react";
import dayjs from "dayjs";
import { useNavigation } from "@react-navigation/native";
import { Image } from "@rneui/themed";
import SignalChatLive from "../chat/SignalChatLive";
import { FitCentrEvent } from "../broadcast-screen/types/FitCentrEvent";
import ViewerSummary from "../broadcast-screen/ViewerSummary";
import { handleResize2 } from "../helpers/viewFunctions";

const WebLayout = ({ event }: { event: FitCentrEvent }) => {
  const roomId = event.eventKey;
  const interval: Signal<any> = signal(null);
  const isStarted = useSignal(false);
  const isEnded = useSignal(false);
  const navigation = useNavigation();
  const streamNotStarted = require("../../assets/stream_not_started.png");
  const streamEnded = require("../../assets/stream_ended.png");

  const getEventStatus = async (
    callback: (started: boolean, ended: boolean) => void
  ) => {
    await axios
      .get(API_URL + "schedule/status/" + event.id, {
        headers: { authorization: currentUser.value.token },
      })
      .then((response) => {
        if (response.data) {
          if (response.data.broadcastEndDate) {
            callback(true, true);
          }

          if (response.data.broadcastStartDate) {
            if (response.data.broadcastEndDate) {
              focusedScreen.value = "OnDemandScreen";
              navigation.navigate({
                name: "OnDemandScreen",
                params: {
                  video: { video: response.data.onDemandVideos[0] },
                },
              } as never);
            } else {
              if (
                dayjs()
                  .utc()
                  .diff(dayjs(response.data.broadcastStartDate), "second") > 10
              )
                callback(true, false);
            }
          } else {
            callback(false, false);
          }
        }
      })
      .catch(() => {
        callback(false, false);
      });
  };

  useEffect(() => {
    if (!interval.value) {
      getEventStatus((started, ended) => {
        if (started) {
          clearInterval(interval.value);
          isStarted.value = true;
        } else {
          isStarted.value = false;
        }
        if (ended) {
          isEnded.value = true;
        } else {
          isEnded.value = false;
        }
      });
      interval.value = setInterval(() => {
        getEventStatus((started, ended) => {
          if (started) {
            clearInterval(interval.value);
            isStarted.value = true;
          } else {
            isStarted.value = false;
          }
          if (ended) {
            isEnded.value = true;
          } else {
            isEnded.value = false;
          }
        });
      }, 2000);
    }
    return () => {
      clearInterval(interval.value);
    };
  }, []);

  const videoContainerWidth = useSignal(0);
  const videoContainerHeight = useSignal(0);
  const scrollViewHeight = useSignal(0);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const { containerWidth, containerHeight, scrollContainerSize } =
        handleResize2({
          sourceWidth: BROADCAST_VIDEO_WIDTH,
          sourceHeight: BROADCAST_VIDEO_HEIGHT,
        });
      videoContainerWidth.value = containerWidth;
      videoContainerHeight.value = containerHeight;
      scrollViewHeight.value = scrollContainerSize;
    });
    const { containerWidth, containerHeight, scrollContainerSize } =
      handleResize2({
        sourceWidth: BROADCAST_VIDEO_WIDTH,
        sourceHeight: BROADCAST_VIDEO_HEIGHT,
      });
    videoContainerWidth.value = containerWidth;
    videoContainerHeight.value = containerHeight;
    scrollViewHeight.value = scrollContainerSize;

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  console.log("isStarted, isEnded", isStarted.value, isEnded.value);
  return (
    <View style={tailwind`flex-col bg-[${theme.value.backgroundColor}]`}>
      <View style={tailwind`w-full`}>
        <View
          style={tailwind`w-[${videoContainerWidth.value}px] h-[${videoContainerHeight.value}px] self-center`}
        >
          {isStarted.value && !isEnded.value ? (
            <WebPlayer event={event} />
          ) : null}
          {((!isStarted.value && !isEnded.value) || isEnded.value) && (
            <View>
              {!isStarted.value && !isEnded.value ? (
                <Image
                  resizeMode="contain"
                  style={tailwind`w-[${videoContainerWidth.value}px] h-[${videoContainerHeight.value}px]`}
                  source={streamNotStarted}
                />
              ) : null}
              {isEnded.value && (
                <Image
                  resizeMode="contain"
                  style={tailwind`w-[${videoContainerWidth.value}px] h-[${videoContainerHeight.value}px]`}
                  source={streamEnded}
                />
              )}
            </View>
          )}
        </View>
        <ScrollView style={tailwind`h-[${scrollViewHeight.value}px] w-full`}>
          <View style={tailwind`flex-row flex-wrap self-center mt-2`}>
            <View style={tailwind`w-9/30 min-w-[300px] max-w-[600px] mb-2 px-1`}>
              <SignalChatLive roomId={roomId} />
            </View>
            <View style={tailwind`grow`} />
            <View style={tailwind`w-9/30 min-w-[300px] max-w-[600px] mb-2 px-1`}>
              <LiveStreamDetails event={event} />
            </View>
            <View style={tailwind`grow`} />
            <View style={tailwind`w-9/30 min-w-[300px] max-w-[600px] mb-2 px-1`}>
              <View style={tailwind`rounded-lg bg-white p-2`}>
                <ViewerSummary event={event} />
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export { WebLayout as default };
