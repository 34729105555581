import PropTypes from "prop-types";
import React, { Text, View } from "react-native";
import tailwind from "twrnc";
import Tag from "../base/Tag";
import { theme } from "../../signals/signals";

const YoutubeDetails = ({ video, title }) => {
  const tags = video.video.onDemandVideoTags;

  return (
    <View style={tailwind`mx-2`}>
      <View>
        <Text style={tailwind`text-[${theme.value.textColor}] font-bold`}>
          {title}
        </Text>
      </View>
      <Text
        style={tailwind`text-[${theme.value.textColor}] font-semibold mt-2`}
      ></Text>
      <View style={tailwind`px-4 my-2`}>
        <View style={tailwind`flex flex-row mt-2 flex-wrap`}>
          {tags.map((userTag) => (
            <Tag
              key={userTag.id}
              label={userTag.tagId.tagName}
              colorKey={userTag.tagId.tagColorKey}
            />
          ))}
        </View>
      </View>
    </View>
  );
};

YoutubeDetails.propTypes = {
  video: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default YoutubeDetails;
