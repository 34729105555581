import PropTypes from "prop-types";
import axios from "axios";
import { ActivityIndicator, ScrollView, Text, View } from "react-native";
import {
  API_URL,
  CONNECTION_STATUS_CONNECTED,
  DEFAULT_PROFILE_URL,
} from "../../base/Constants";
import { currentUser, theme } from "../../../signals/signals";
import { useQuery } from "@tanstack/react-query";
import tailwind from "twrnc";
import AttendeeItem from "./AttendeeItem";
import ApiError from "../../error/ApiError";

const AttendeeList = ({ userId, selectedAttendees, onPress }) => {
  const { isLoading, error, data, isFetching } = useQuery(
    ["connected" + userId],
    () =>
      axios
        .get(API_URL + "userconnection/connections/" + userId, {
          headers: { authorization: currentUser.value.token },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err))
  );

  if (isLoading || isFetching) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const connections = data.filter((connection) => {
    return connection.status === CONNECTION_STATUS_CONNECTED;
  });
  const attendees = connections.map((connection) => {
    return connection.sourceUser.id !== currentUser.value.id
      ? connection.sourceUser
      : connection.destinationUser;
  });
  return (
    <View style={tailwind`w-[300px]`}>
      <Text
        style={[
          tailwind`self-center text-[${theme.value.textColor}] font-semibold mb-2`,
          { fontFamily: "Roboto_700Bold" },
        ]}
      >
        Connections
      </Text>

      <ScrollView
        style={tailwind`p-2 rounded-lg bg-[${theme.value.attendeeListBackgroundColor}] h-[200px] w-full`}
      >
        {attendees.map((attendee) => (
          <View key={attendee.id}>
            {attendee.id !== currentUser.value.id ? (
              <AttendeeItem
                onPress={() => onPress(attendee)}
                name={attendee.firstName + " " + attendee.lastName}
                attendeeProfileUrl={attendee.profileUrl || DEFAULT_PROFILE_URL}
                selected={
                  selectedAttendees.filter(
                    (currentAttendee) => currentAttendee.id === attendee.id
                  ).length > 0
                }
              />
            ) : null}
          </View>
        ))}
      </ScrollView>
    </View>
  );
};

AttendeeList.propTypes = {
  userId: PropTypes.number.isRequired,
  selectedAttendees: PropTypes.array.isRequired,
  onPress: PropTypes.func.isRequired,
};

export { AttendeeList as default };
