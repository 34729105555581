import React, { View } from "react-native";
import tailwind from "twrnc";
import NotificationsPanel from "../components/notifications/NotificationsPanel";
import { theme } from "../signals/signals";

const NotificationScreen = () => {
  return (
    <>
      <View
        style={tailwind`flex w-full h-full bg-[${theme.value.backgroundColor}]`}
      >
        <NotificationsPanel />
      </View>
    </>
  );
};

export { NotificationScreen };
