import React from "react";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";
import { View, Pressable, ActivityIndicator, Text } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";

import { focusedScreen, theme } from "../../../signals/signals";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import { Inter_600SemiBold } from "@expo-google-fonts/inter";
import { useSignal } from "@preact/signals-react";
import { DEFAULT_PROFILE_URL } from "../../base/Constants";
import ThumbnailProfile from "../on-demand/ThumbnailProfile";
import MiniTag from "../../base/MiniTag";

const YoutubeThumbnail = ({ video, onPressOverride }) => {
  const navigation = useNavigation();

  const id = video.video.id;
  const videoId = video.video.youtubeId;
  const tags = video.video.onDemandVideoTags;
  const userVideos = video.video.userVideos;

  let profileUrl = "";
  if (userVideos && userVideos.length > 0) {
    profileUrl = userVideos[0].userId.profileUrl;
  }

  const thumbnailDimensions = useSignal({
    width: 212,
    height: 107,
  });
  const thumbnailImageDimensions = useSignal({
    width: 212,
    height: 118,
  });

  let fonts = useFonts({ Inter_600SemiBold, Roboto_400Regular });
  if (!fonts) return;

  return (
    <Pressable
      key={id}
      style={tailwind`rounded-lg bg-[${theme.value.backgroundColor}] mr-[40px] mb-2`}
      onPress={
        onPressOverride
          ? onPressOverride
          : () => {
              focusedScreen.value = "YoutubeScreen";
              navigation.navigate({
                name: "YoutubeScreen",
                params: { key: videoId, video: video },
              } as never);
            }
      }
    >
      <View
        style={tailwind`rounded-lg w-[${thumbnailDimensions.value.width}px] bg-white`}
      >
        <View
          style={tailwind`w-[${thumbnailDimensions.value.width}px] h-[${thumbnailDimensions.value.height}px]`}
        >
          <View style={tailwind`absolute`}>
            <Image
              style={tailwind`w-[${thumbnailImageDimensions.value.width}px] h-[${thumbnailImageDimensions.value.height}px] rounded-tr-lg rounded-tl-lg`}
              source={{
                uri: "https://img.youtube.com/vi/" + videoId + "/0.jpg",
              }}
              PlaceholderContent={<ActivityIndicator />}
            />
          </View>
        </View>
        <View style={tailwind`p-1 grow`}>
          <View style={tailwind`w-full flex-row pt-3 pb-1 px-1`}>
            <View style={tailwind`w-1/2 flex-row`}>
              <Image
                resizeMode="contain"
                style={tailwind`w-12`}
                source={require("../../../assets/yt_logo_rgb_light.png")}
              />
            </View>
            <View style={tailwind`w-1/2 self-end`}>
              <ThumbnailProfile
                profileUrl={profileUrl || DEFAULT_PROFILE_URL}
              />
            </View>
          </View>
          <View style={tailwind`flex-row flex-wrap px-1 pb-[5px]`}>
            {tags.length ? (
              <MiniTag
                key={tags[0].id}
                label={
                  (tags[0].tagId && tags[0].tagId.tagName).toUpperCase() || ""
                }
                colorKey={tags[0].tagId.tagColorKey}
              />
            ) : null}
          </View>
        </View>
      </View>
    </Pressable>
  );
};

YoutubeThumbnail.propTypes = {
  video: PropTypes.object.isRequired,
  onPressOverride: PropTypes.func,
};

export default YoutubeThumbnail;
