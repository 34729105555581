import axios from "axios";
import React, { useContext, useEffect } from "react";
import { Dimensions, Platform, Text, View } from "react-native";
import {
  API_URL,
  TREE_TYPE_BODY,
  TREE_TYPE_USER,
  queryClient,
} from "../base/Constants";
import { Signal, useSignal } from "@preact/signals-react";
import {
  bodyTagSelection,
  currentUser,
  theme,
  userTagSelection,
} from "../../signals/signals";
import { BottomSheetContext } from "../base/ApplicationContext";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import tailwind from "twrnc";
import { Switch } from "@rneui/themed";
import Button from "../base/Button";
import TagSelector from "../tags/TagSelector";
import { StyledInput } from "../base/StyledInput";
import YTPlayer from "react-native-youtube-iframe";
import {
  analyzeTagSelections,
  analyzeVideoYoutubeId,
} from "../helpers/validation";
import YoutubeThumbnail from "../thumbnails/youtube/YoutubeThumbnail";
import { setTagSelections } from "../helpers/tagProcessor";

const YouTubeAddPanel = (): JSX.Element => {
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;

  const isPublic: Signal<boolean> = useSignal(false);
  const videoYoutubeId = useSignal("");
  const videoYoutubeIdStatus = useSignal("");
  const validatedYoutubeId = useSignal("");
  const { setBottomSheetVisible } = useContext(BottomSheetContext);

  const userTagStatus = useSignal("");
  const bodyTagStatus = useSignal("");

  const toggleSwitch = () => {
    isPublic.value = !isPublic.value;
  };

  const saveVideoFile = async () => {
    axios
      .post(
        API_URL + "videos",
        {
          duration: 0,
          isEnabled: isPublic.value,
          onDemandVideoTags: [
            ...userTagSelection.value.filter((tag) => !tag.deleted),
            ...bodyTagSelection.value.filter((tag) => !tag.deleted),
          ],
          youtubeId: validatedYoutubeId.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.value.token,
          },
        }
      )
      .then((res) => {
        setBottomSheetVisible(false);
        queryClient.invalidateQueries([
          "videos/provider/youtube/" + currentUser.value.id,
        ]);
        return res.data;
      });
  };

  const selectedTags = [];

  useEffect(() => {
    setTagSelections(selectedTags);
    analyzeVideoYoutubeId(videoYoutubeId, videoYoutubeIdStatus);
    analyzeTagSelections(TREE_TYPE_USER, userTagStatus);
    analyzeTagSelections(TREE_TYPE_BODY, bodyTagStatus);
  }, []);

  useEffect(() => {
    if (videoYoutubeId.value.length === 11) {
      validatedYoutubeId.value = videoYoutubeId.value;
    } else {
      validatedYoutubeId.value = "";
    }
  }, [videoYoutubeId.value]);

  let playerHeight = 320;
  let playerWidth = 210;

  let containerStyle = tailwind`h-[300px] w-full`;

  const video = {
    youtubeId: videoYoutubeId.value,
    video: {},
  };

  const canSave = useSignal(false);
  useEffect(() => {
    canSave.value =
      videoYoutubeIdStatus.value === "" &&
      userTagStatus.value === "" &&
      bodyTagStatus.value === "";
  }, [videoYoutubeIdStatus.value, userTagStatus.value, bodyTagStatus.value]);

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <>
      <View style={tailwind`p-2`}>
        <View
          style={tailwind`w-full mb-4 ${
            !isPublic.value ? "bg-[" + theme.value.redColor + "]" : ""
          }`}
        >
          <View style={tailwind`flex-row self-center py-2`}>
            <Switch
              style={tailwind`mt-1`}
              trackColor={{
                false: theme.value.greenColor,
                true: theme.value.redColor,
              }}
              ios_backgroundColor="#3e3e3e"
              onValueChange={() => toggleSwitch()}
              value={isPublic.value}
            />
            <Text
              style={tailwind`ml-2 pt-1 font-semibold text-[${
                isPublic.value ? theme.value.textColor : theme.value.textColor
              }]`}
            >
              {isPublic.value
                ? "Enabled, this video will be shown publicly"
                : "Disabled, this video will not be shown publibly"}
            </Text>
          </View>
        </View>
        <View
          style={isMobileWebFormat ? tailwind`flex-col` : tailwind`flex-row`}
        >
          <View style={isMobileWebFormat ? tailwind`w-full` : tailwind`w-1/3`}>
            <View style={tailwind`self-center py-2`}>
              <View style={tailwind`py-2`}>
                <Text
                  style={[
                    tailwind`text-[${theme.value.textColor}] font-semibold pb-1`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  Video
                </Text>
                <View style={tailwind`w-[212px] h-[118px] rounded-lg py-2`}>
                  <View style={tailwind`self-center pt-2 pl-2`}>
                    <StyledInput
                      width={"255px"}
                      label={"Youtube Id"}
                      placeholder={"Youtube Id"}
                      value={videoYoutubeId.value}
                      onChangeText={(text) => {
                        videoYoutubeId.value = text;
                        analyzeVideoYoutubeId(
                          videoYoutubeId,
                          videoYoutubeIdStatus
                        );
                      }}
                      onBlur={() => {
                        analyzeVideoYoutubeId(
                          videoYoutubeId,
                          videoYoutubeIdStatus
                        );
                      }}
                      fieldStatus={videoYoutubeIdStatus}
                      autoCorrect={true}
                    />
                  </View>
                </View>

                <View style={tailwind`w-[212px] h-[118px] rounded-lg`}>
                  <View style={containerStyle}>
                    {validatedYoutubeId.value ? (
                      <YTPlayer
                        height={playerHeight}
                        width={playerWidth}
                        play={false}
                        videoId={validatedYoutubeId.value}
                        onChangeState={() => {}}
                      />
                    ) : null}
                  </View>
                </View>
              </View>
              <View style={tailwind`py-2`}>
                <Text
                  style={[
                    tailwind`text-[${theme.value.textColor}] font-semibold pb-1`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  Thumbnail
                </Text>
                {videoYoutubeId.value && videoYoutubeIdStatus.value === "" ? (
                  <YoutubeThumbnail
                    key={0}
                    video={{
                      video: {
                        id: 0,
                        youtubeId: videoYoutubeId.value,
                        video: video,
                        userVideos: [],
                      },
                    }}
                    onPressOverride={() => {}}
                  />
                ) : (
                  <View
                    style={tailwind`rounded-lg bg-[${theme.value.primaryButtonTextColor}] mr-[40px] mb-2`}
                  >
                    <View style={tailwind`rounded-lg w-[212px] h-[118px]`} />
                  </View>
                )}
              </View>
            </View>
          </View>
          <View style={isMobileWebFormat ? tailwind`w-full` : tailwind`w-2/3`}>
            <View style={tailwind`mt-4 mr-2`}>
              <Text
                style={[
                  tailwind`text-[${theme.value.textColor}] font-semibold pb-1`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                Categories
              </Text>
              <View style={tailwind`mb-2`}>
                <TagSelector
                  tagType={TREE_TYPE_USER}
                  fieldStatus={userTagStatus}
                />
                {userTagStatus.value ? (
                  <View style={tailwind`mb-2`}>
                    <Text
                      style={[
                        tailwind`text-xs ml-2 text-[${theme.value.redColor}]`,
                        { fontFamily: "Roboto_400Regular" },
                      ]}
                    >
                      {userTagStatus.value}
                    </Text>
                  </View>
                ) : null}
              </View>
              <View style={tailwind`mb-2`}>
                <TagSelector
                  tagType={TREE_TYPE_BODY}
                  fieldStatus={bodyTagStatus}
                />
                {bodyTagStatus.value ? (
                  <View style={tailwind`mb-2`}>
                    <Text
                      style={[
                        tailwind`text-xs ml-2 text-[${theme.value.redColor}]`,
                        { fontFamily: "Roboto_400Regular" },
                      ]}
                    >
                      {bodyTagStatus.value}
                    </Text>
                  </View>
                ) : null}
              </View>
            </View>
          </View>
        </View>
        <View style={tailwind`self-center w-full pb-6 mt-2`}></View>
      </View>
      <View style={tailwind`self-center flex-row`}>
        <Button
          title="Close"
          onPress={() => {
            setBottomSheetVisible(false);
          }}
        />
        <Button
          disabled={!canSave.value}
          title="Save"
          onPress={() => saveVideoFile()}
        />
      </View>
    </>
  );
};

export { YouTubeAddPanel as default };
