import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Dimensions, Platform, View } from "react-native";
import * as ScreenOrientation from "expo-screen-orientation";
import tailwind from "twrnc";
import { Signal, computed, useSignal } from "@preact/signals-react";
import { currentUser, remoteStreams } from "../../../signals/signals";
import { CallParticipant } from "../types/CallParticipant";
import ClientStream from "./ClientStream";

const ClientStreamsContainer = ({ hostId, participants }): JSX.Element => {
  const screenWidth: number = Dimensions.get("screen").width;
  const isClient: boolean = hostId === currentUser.value.id;
  const numberOfStreams: Signal = computed(() => {
    if (remoteStreams.value) {
      return remoteStreams.value.length;
    } else {
      return 0;
    }
  });

  const windowOrientation: Signal = useSignal("portrait");
  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        windowOrientation.value = getOrientation(orientationInfo.orientation);
      }, 300);
    });
  }, []);

  const getOrientation = (orientation: number): string => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };

  const isMobileWebFormat: boolean =
    Platform.OS === "web" &&
    ((windowOrientation.value === "portrait" &&
      Dimensions.get("screen").width < 768) ||
      (windowOrientation.value === "landscape" &&
        Dimensions.get("screen").height < 768));
  const viewWidthPercentage = computed(() =>
    numberOfStreams.value > 0 ? 100 / numberOfStreams.value : 0
  );

  const extractUserInfo = (participants: CallParticipant[], userId: number) => {
    const participant = participants.find(
      (participant) => participant.id === userId
    );
    return { name: participant.name, profileUrl: participant.profileUrl };
  };

  return (
    <View
      style={tailwind`flex-row flex-wrap max-w-[${
        isMobileWebFormat && windowOrientation.value === "landscape"
          ? screenWidth / 2
          : screenWidth
      }px]`}
    >
      {remoteStreams.value.map((remoteStream, i) => {
        if (
          remoteStream.userId === hostId ||
          remoteStream.userId === currentUser.value.id
        ) {
          return null;
        }

        const { name, profileUrl } = extractUserInfo(
          participants.value,
          remoteStream.userId
        );

        return (
          <View
            key={"key" + i}
            style={tailwind`max-w-[${viewWidthPercentage.value}%] max-w-[${
              isMobileWebFormat && windowOrientation.value === "landscape"
                ? screenWidth / 2
                : screenWidth
            }px]`}
          >
            <ClientStream
              remoteStream={remoteStream}
              name={name}
              profileUrl={profileUrl}
              isClient={isClient}
            />
          </View>
        );
      })}
    </View>
  );
};

ClientStreamsContainer.propTypes = {
  hostId: PropTypes.number.isRequired,
  participants: PropTypes.instanceOf(Signal).isRequired,
};

export { ClientStreamsContainer as default };
