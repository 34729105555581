import React from "react";
import PropTypes from "prop-types";
import { SectionList, Text, View } from "react-native";
import TagItem from "./TagItem";
import { QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL, queryClient } from "../base/Constants";
import { ActivityIndicator } from "react-native";
import tailwind from "twrnc";
import { currentUser } from "../../signals/signals";
import { Signal } from "@preact/signals-react";
import ApiError from "../error/ApiError";

const TagSection = ({ item, fieldStatus }) => {
  return (
    <View style={tailwind`pb-4`}>
      <QueryClientProvider client={queryClient}>
        <TagItems item={item} fieldStatus={fieldStatus} />
      </QueryClientProvider>
    </View>
  );
};

const TagItems = ({ item, fieldStatus }) => {
  const parentTagId = item.id;
  const { isLoading, error, data }: any = useQuery(
    ["tagitems" + parentTagId],
    () =>
      axios
        .get(API_URL + "tags/descendants/" + parentTagId, {
          headers: { authorization: currentUser.value.token },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err))
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const formattedData = data.map((item) => ({
    data: [item],
  }));

  return (
    <SectionList
      scrollEnabled={true}
      nestedScrollEnabled={true}
      sections={formattedData}
      keyExtractor={(item) => item.id.toString()}
      renderItem={({ item }) => {
        return (
          <TagItem item={item} type={parentTagId} fieldStatus={fieldStatus} />
        );
      }}
      style={tailwind`flex-row flex-wrap`}
    />
  );
};

TagItems.propTypes = {
  item: PropTypes.object.isRequired,
  fieldStatus: PropTypes.instanceOf(Signal).isRequired,
};

TagSection.propTypes = {
  item: PropTypes.object.isRequired,
  fieldStatus: PropTypes.instanceOf(Signal).isRequired,
};

export default TagSection;
