import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  View,
  Text,
  ScrollView,
  SafeAreaView,
  Platform,
  Pressable,
  Dimensions,
} from "react-native";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { QueryClientProvider } from "@tanstack/react-query";
import HorizontalRule from "../components/base/HorizontalRule";
import tailwind from "twrnc";
import CalendarDayHeading from "../components/calendar-screen/CalendarDayHeading";
import {
  USER_TYPE_AMBASSADOR,
  USER_TYPE_PROVIDER,
  queryClient,
} from "../components/base/Constants";
import { WebScrollContainer } from "../components/home-screen/WebScrollContainer";
import CalendarDay from "../components/calendar-screen/CalendarDay";
import { Image } from "@rneui/themed";
import { BottomSheetContext } from "../components/base/ApplicationContext";
import { currentUser, isEmailAddressVerified, theme } from "../signals/signals";
import { useSignal } from "@preact/signals-react";
import EventTypeChoice from "../components/event-screen/EventTypeChoice";

const CalendarScreen = (props) => {
  dayjs.extend(utc);
  const viewStartDate = dayjs.utc().format("YYYY-MM-DD");
  const selectedDate = useSignal(
    (props &&
      props.route &&
      props.route.params &&
      dayjs(props.route.params.initialDate).format("YYYY-MM-DD")) ||
    dayjs().format("YYYY-MM-DD")
  );

  const quickDaySelect = (event, date) => {
    selectedDate.value = dayjs(date).format("YYYY-MM-DD");
    headings.value = setupHeadings(
      dayjs(date).format("YYYY-MM-DD"),
      viewStartDate
    );
  };

  const setupHeadings = (selectedDate, initialDate) => {
    const headings = [];
    for (let index = 0; index < 31; index++) {
      let upcomingDate = dayjs(initialDate).add(index, "day");
      headings.push({
        label: dayjs(upcomingDate).format("ddd"),
        onPress: (event) => quickDaySelect(event, upcomingDate),
        dayNumber: dayjs(upcomingDate).format("DD"),
        monthNumber: dayjs(upcomingDate).format("MM"),
        selected:
          dayjs(upcomingDate).toString() === dayjs(selectedDate).toString(),
      });
    }
    return headings;
  };

  const headings = useSignal(setupHeadings(selectedDate.value, viewStartDate));

  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);

  return (
    <SafeAreaView
      style={
        Platform.OS === "web"
          ? tailwind`h-full bg-[${theme.value.backgroundColor}] pt-14`
          : tailwind`h-full bg-[${theme.value.backgroundColor}]`
      }
    >
      <QueryClientProvider client={queryClient}>
        <View style={tailwind`flex w-full`}>
          <WebScrollContainer
            horizontal
            showsHorizontalScrollIndicator={
              Platform.OS === "web" ? true : false
            }
            style={tailwind`ml-2 mr-2`}
          >
            {headings.value &&
              headings.value.map((heading) => {
                return (
                  <CalendarDayHeading
                    key={heading.dayNumber + heading.monthNumber}
                    label={heading.label}
                    onPress={heading.onPress}
                    dayNumber={heading.dayNumber}
                    selected={heading.selected}
                  />
                );
              })}
          </WebScrollContainer>
        </View>
        <View style={tailwind`self-center`}>
          <Text style={tailwind`text-[${theme.value.textColor}]`}>
            {dayjs(selectedDate.value).format("ddd, DD MMM YYYY")}
          </Text>
        </View>
        <HorizontalRule />
        <ScrollView style={tailwind`mb-4`}>
          <CalendarDay day={selectedDate.value} />
        </ScrollView>
        {currentUser.value.type === USER_TYPE_PROVIDER ||
          currentUser.value.type === USER_TYPE_AMBASSADOR ? (
          <View
            style={
              Platform.OS === "web"
                ? tailwind`absolute bottom-20 self-center`
                : tailwind`absolute bottom-5 self-center`
            }
          >
            {isEmailAddressVerified.value ? (
              <Pressable
                onPress={() => {
                  if (Platform.OS === "web" && Dimensions.get("screen").width < 768) {
                    setBottomSheetContent(<EventTypeChoice />);
                    setBottomSheetVisible(true);
                  } else {
                    setBottomSheetContent(<EventTypeChoice />);
                    setBottomSheetVisible(true);
                  }
                }}
              >
                <View
                  style={tailwind`border-[1px] border-[${theme.value.calendarAddIconBorderColor}] bg-[${theme.value.backgroundColor}] rounded-full p-3`}
                >
                  <Image
                    resizeMode="contain"
                    style={tailwind`h-[18px] w-[18px]`}
                    source={require("../assets/calendar-plus-icon.png")}
                  />
                </View>
              </Pressable>
            ) : null}
          </View>
        ) : null}
      </QueryClientProvider>
    </SafeAreaView>
  );
};

CalendarScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      initialDate: PropTypes.string,
    }),
  }),
};

export default CalendarScreen;
