import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import { useContext } from "react";
import {
  currentUser,
  focusedScreen,
  isEmailAddressVerified,
  isProfileComplete,
  theme,
} from "../../../signals/signals";
import { BottomSheetContext } from "../../base/ApplicationContext";
import { signal, useSignal } from "@preact/signals-react";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import { Inter_600SemiBold } from "@expo-google-fonts/inter";
import { ActivityIndicator, Pressable, Text, View } from "react-native";
import tailwind from "twrnc";
import { Image } from "@rneui/themed";
import MiniTag from "../../base/MiniTag";
import DurationPill from "./DurationPill";
import ReplayPrice from "./ReplayPrice";
import VideoTitle from "../shared/VideoTitle";

const ReplayThumbnail = ({ video, onPressOverride }) => {
  const navigation = useNavigation();
  const {
    id,
    userVideos,
    title,
    onDemandVideoTags,
    price,
    thumbnailPath,
    isTitleShown,
    isEnabled,
  } = video.video;
  const duration = video.video.userSchedule?.duration;
  const isHost = currentUser.value.id === video.video.userVideos[0].userId.id;

  let profileUrl = "";
  if (thumbnailPath) {
    profileUrl = thumbnailPath;
  } else {
    if (userVideos && userVideos.length > 0) {
      profileUrl = userVideos[0].userId.profileUrl;
    }
  }

  const { setBottomSheetVisible } = useContext(BottomSheetContext);
  const isOwner = video.video.userVideos[0].userId.id === currentUser.value.id;
  const hasPaid = signal(false);
  const didAttend = signal(false);
  const thumbnailDimensions = useSignal({
    width: 212,
    height: 178,
  });
  const thumbnailImageDimensions = useSignal({
    width: 212,
    height: 118,
  });

  hasPaid.value = false;
  const payments = video.video.payments;
  if (payments && payments.length) {
    payments.forEach((payment) => {
      if (payment.user.id === currentUser.value.id)
        payment.responses &&
          payment.responses.forEach((response) => {
            if (
              response.state === "COMPLETED" ||
              response.state === "AUTHORISED"
            ) {
              hasPaid.value = true;
            }
          });
    });
  }

  didAttend.value = false;
  if (video.video.userSchedule && video.video.userSchedule.bookings) {
    const bookings = video.video.userSchedule.bookings;
    if (bookings && bookings.length) {
      bookings.forEach((booking) => {
        if (booking.user.id === currentUser.value.id) {
          booking.payments &&
            booking.payments.forEach((payment) => {
              if (payment.responses) {
                payment.responses.forEach((response) => {
                  if (
                    response.state === "COMPLETED" ||
                    response.state === "AUTHORISED"
                  ) {
                    didAttend.value = true;
                  }
                });
              }
            });
        }
      });
    }
  }

  const handleReplayBooking = () => {
    setBottomSheetVisible(false);
    if (isProfileComplete.value && isEmailAddressVerified.value) {
      focusedScreen.value = "ReplayOrderSummaryScreen";
      navigation.navigate({
        name: "ReplayOrderSummaryScreen",
        params: {
          videoId: id,
        },
      } as never);
    } else {
      focusedScreen.value = "EditProfileScreen";
      navigation.navigate({
        name: "EditProfileScreen",
        params: {
          userId: currentUser.value.id,
          userType: currentUser.value.type,
        },
      } as never);
    }
  };

  let fonts = useFonts({ Inter_600SemiBold, Roboto_400Regular });
  if (!fonts) return;

  return (
    <Pressable
      style={tailwind`rounded-lg bg-[${theme.value.backgroundColor}] mr-[40px] mb-2`}
      onPress={
        onPressOverride
          ? onPressOverride
          : () => {
              if (price > 0 && !hasPaid.value && !didAttend.value && !isOwner) {
                handleReplayBooking();
              } else {
                focusedScreen.value = "OnDemandScreen";
                navigation.navigate({
                  name: "OnDemandScreen",
                  params: { video: video },
                } as never);
              }
            }
      }
    >
      <View
        style={tailwind`rounded-lg w-[${thumbnailDimensions.value.width}px] bg-white`}
      >
        <View
          style={tailwind`w-[${thumbnailDimensions.value.width}px] h-[${thumbnailDimensions.value.height}px]`}
        >
          <View style={tailwind`absolute`}>
            <Image
              style={tailwind`w-[${thumbnailImageDimensions.value.width}px] h-[${thumbnailImageDimensions.value.height}px] rounded-tr-lg rounded-tl-lg`}
              source={{ uri: profileUrl }}
              PlaceholderContent={<ActivityIndicator />}
            >
              <View
                style={
                  isEnabled || !isHost
                    ? null
                    : tailwind`absolute w-full h-full opacity-50 bg-black rounded-tr-lg rounded-tl-lg`
                }
              />
            </Image>
          </View>
          <View style={tailwind`p-1 grow`}>
            <View style={tailwind`py-10`}>
              {isTitleShown ? (
                <VideoTitle title={title} />
              ) : (
                <View style={tailwind`py-[2px] px-[6px] mx-4 h-[18px]`}></View>
              )}
            </View>
            <View style={tailwind`w-full flex-row pt-6 px-1`}>
              <View style={tailwind`w-1/2 flex-row`}>
                <View>
                  <DurationPill duration={duration} />
                  <View style={tailwind`grow`} />
                </View>
                <View style={tailwind`grow`} />
              </View>
              <View style={tailwind`w-1/2 self-end`}>
                <ReplayPrice
                  price={price}
                  hasPaid={hasPaid}
                  didAttend={didAttend}
                  isOwner={isOwner}
                />
              </View>
            </View>
            <View style={tailwind`flex-row flex-wrap px-1`}>
              {onDemandVideoTags.length ? (
                <MiniTag
                  key={onDemandVideoTags[0].id}
                  label={
                    (
                      onDemandVideoTags[0].tagId &&
                      onDemandVideoTags[0].tagId.tagName
                    ).toUpperCase() || ""
                  }
                  colorKey={onDemandVideoTags[0].tagId.tagColorKey}
                />
              ) : null}
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

ReplayThumbnail.propTypes = {
  video: PropTypes.object.isRequired,
  onPressOverride: PropTypes.func,
};

export { ReplayThumbnail as default };
