import { theme, themes } from "../../signals/signals";
import { Theme } from "../types/Theme";

export const configureTheme = (selectedTheme: number, serverThemes: any[]) => {
  const themeToApply: Theme = serverThemes.find(
    (theme) => theme.id === selectedTheme
  );

  if (themeToApply) {
    themeToApply.themeSettings.forEach((themeObject) => {
      theme.value[themeObject.settingName] = themeObject.settingValue;
    });
  }
  themes.value = serverThemes;
};
