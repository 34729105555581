import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  useFonts,
  Roboto_700Bold,
  Roboto_400Regular,
} from "@expo-google-fonts/roboto";
import { Inter_400Regular } from "@expo-google-fonts/inter";
import { ActivityIndicator, Text, View, Pressable } from "react-native";
import { useQuery, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import MiniTag from "../base/MiniTag";
import ConnectModal from "../modals/ConnectModal";
import {
  API_URL,
  DEFAULT_PROFILE_URL,
  USER_TYPE_AMBASSADOR,
  queryClient,
} from "../base/Constants";
import { BottomSheetContext } from "../base/ApplicationContext";
import { useNavigation } from "@react-navigation/native";
import { focusedScreen, theme, modalVisible, currentUser } from "../../signals/signals";
import ApiError from "../error/ApiError";

const MiniTrainerProfilePanel = ({ id, maxWidth }) => {
  let [fontsLoaded] = useFonts({
    Roboto_700Bold,
    Roboto_400Regular,
    Inter_400Regular,
  });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Panel id={id} maxWidth={maxWidth} />
    </QueryClientProvider>
  );
};

MiniTrainerProfilePanel.propTypes = {
  id: PropTypes.number.isRequired,
  maxWidth: PropTypes.string,
};

const Panel = ({ id, maxWidth }) => {
  const { setBottomSheetVisible } = useContext(BottomSheetContext);
  const navigation = useNavigation();
  const [isFollowing, setIsFollowing] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isProviding, setIsProviding] = useState(false);
  const [followers, setFollowers] = useState(0);
  const connections = useRef(0);
  const videoCount = useRef(0);
  const { isLoading, error, data } = useQuery(["trainer" + id], () =>
    axios
      .get(API_URL + "trainers/" + id, {
        staleTime: 0,
        headers: { authorization: currentUser.value.token },
      })
      .then((res) => {
        const connectedUsers = [
          ...res.data.incomingConnections,
          ...res.data.outgoingConnections,
        ].map(
          (connection) =>
            (connection.sourceUser && connection.sourceUser.id) ||
            (connection.destinationUser && connection.destinationUser.id)
        );
        connections.current = connectedUsers.length;
        videoCount.current = res.data.videos.length;
        const isConnected = connectedUsers.find(
          (userId) => userId === currentUser.value.id
        );
        setIsConnected(isConnected);
        setIsFollowing(res.data.isFollowing);
        setIsProviding(res.data.isProvidingPersonalTraining);
        setFollowers(res.data.followers.length);
        return res.data;
      })
      .catch((err) => console.log(err))
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const userId = data.id;
  const userType = data.type.id;
  const firstName = data.firstName;
  const shortName = firstName + " " + data.lastName;
  const userTags = data.tags;
  const isProfileOwner = data.id === currentUser.value.id;

  return (
    <View
      style={tailwind`flex-col ${maxWidth ? "w-[" + maxWidth + "]" : "w-full"}`}
    >
      <View>
        <View style={tailwind`flex-row w-full px-4`}>
          <View style={tailwind`w-2/5`}>
            <Pressable
              onPress={() => {
                setBottomSheetVisible(false);
                focusedScreen.value = "ViewProfileScreen";
                navigation.navigate("ViewProfileScreen", {
                  id: userId,
                  type: userType,
                });
              }}
            >
              <Image
                style={tailwind`w-[30px] h-[41px] rounded-lg`}
                source={{ uri: data.profileUrl || DEFAULT_PROFILE_URL }}
                PlaceholderContent={<ActivityIndicator />}
              />
            </Pressable>
          </View>
          <View style={tailwind`w-1/5`}>
            <View style={tailwind`flex-row`}>
              <Image
                resizeMode="contain"
                style={tailwind`h-[18px] w-[18px]`}
                source={require("../../assets/connections-icon.png")}
              />
              <Text
                style={[
                  tailwind`self-center font-bold text-[${theme.value.textColor}] ml-2`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {Math.abs(connections.current)}
              </Text>
            </View>
          </View>
          <View style={tailwind`w-1/5`}>
            <View style={tailwind`flex-row`}>
              <Image
                resizeMode="contain"
                style={tailwind`h-[18px] w-[18px]`}
                source={require("../../assets/followers-icon.png")}
              />
              <Text
                style={[
                  tailwind`self-center font-bold text-[${theme.value.textColor}] ml-2`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {Math.abs(followers)}
              </Text>
            </View>
          </View>
          <View style={tailwind`w-1/5`}>
            <View style={tailwind`flex-row`}>
              <Image
                resizeMode="contain"
                style={tailwind`h-[18px] w-[18px]`}
                source={require("../../assets/videos-icon.png")}
              />
              <Text
                style={[
                  tailwind`self-center font-bold text-[${theme.value.textColor}] ml-2`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {videoCount.current}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={tailwind`flex-row px-4`}>
        <View style={tailwind`self-start grow`}>
          <Text
            style={[
              tailwind`capitalize text-[${theme.value.textColor}] font-bold text-[18px] mt-3`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            {shortName}
          </Text>
        </View>
        <View style={tailwind`self-end flex-row`}>
          <Pressable
            onPress={() => {
              modalVisible.value = !modalVisible.value;
            }}
            style={tailwind`flex-row mr-6`}
          >
            {isConnected || isProfileOwner || !isProviding ? null : (
              <>
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[18px] w-[18px]`}
                  source={require("../../assets/connect-icon.png")}
                />
                <Text
                  style={[
                    tailwind`text-[${theme.value.textColor}] font-semibold m-auto ml-2`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  Connect
                </Text>
              </>
            )}
          </Pressable>
          {!isProfileOwner ? (
            <Pressable
              onPress={() => {
                setIsFollowing(!isFollowing);
                if (!isFollowing) {
                  setFollowers(followers + 1);
                  axios
                    .post(
                      API_URL + "userfollowing/follow",
                      {
                        sourceUserId: currentUser.value.id,
                        destinationUserId: data.id,
                      },
                      {
                        headers: {
                          "Content-Type": "application/json",
                          authorization: currentUser.value.token,
                        },
                      }
                    )
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  setFollowers(followers - 1);
                  axios
                    .post(
                      API_URL + "userfollowing/unfollow",
                      {
                        sourceUserId: currentUser.value.id,
                        destinationUserId: data.id,
                      },
                      {
                        headers: {
                          "Content-Type": "application/json",
                          authorization: currentUser.value.token,
                        },
                      }
                    )
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }}
              style={tailwind`flex-row ml-2`}
            >
              {isFollowing ? (
                <>
                  <Image
                    resizeMode="contain"
                    style={tailwind`h-[18px] w-[18px]`}
                    source={require("../../assets/following-icon.png")}
                  />
                  <Text
                    style={[
                      tailwind`text-[${theme.value.textColor}] font-semibold m-auto ml-2`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    Following
                  </Text>
                </>
              ) : (
                <>
                  <Image
                    resizeMode="contain"
                    style={tailwind`h-[18px] w-[18px]`}
                    source={require("../../assets/follow-icon.png")}
                  />
                  <Text
                    style={[
                      tailwind`text-[${theme.value.textColor}] font-semibold m-auto ml-2`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    Follow
                  </Text>
                </>
              )}
            </Pressable>
          ) : null}
        </View>
      </View>
      {userType === USER_TYPE_AMBASSADOR ? (
        <View style={tailwind`w-full flex-row px-4`}>
          <Image
            resizeMode="contain"
            style={tailwind`h-[16px] w-[13px] mt-1 mr-2`}
            source={require("../../assets/ambassador-icon.png")}
            tintColor={theme.value.iconColor}
          />
          <Text
            style={[
              tailwind`text-[${theme.value.textColor}] self-center font-semibold`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            Ambassador
          </Text>
        </View>
      ) : null}
      <View style={tailwind`px-4`}>
        <Text
          numberOfLines={5}
          style={[
            tailwind`text-[${theme.value.textColor}] font-semibold py-2`,
            { fontFamily: "Inter_400Regular" },
          ]}
        >
          {data.profileText}
        </Text>
      </View>
      <View style={tailwind`px-4 my-2`}>
        <View style={tailwind`flex flex-row mt-2 flex-wrap`}>
          {userTags.map((userTag) => (
            <MiniTag
              key={userTag.id}
              label={userTag.tagId.tagName.toUpperCase()}
              colorKey={userTag.tagId.tagColorKey}
            />
          ))}
        </View>
      </View>
      <ConnectModal
        id={id}
        firstName={firstName}
        profileUrl={data.profileUrl || DEFAULT_PROFILE_URL}
      />
    </View>
  );
};

Panel.propTypes = {
  id: PropTypes.number.isRequired,
  maxWidth: PropTypes.string,
};

export { MiniTrainerProfilePanel as default };
