import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dimensions, Platform, View } from "react-native";
import PortraitLayout from "../components/on-demand/PortraitLayout";
import * as ScreenOrientation from "expo-screen-orientation";
import LandscapeLayout from "../components/on-demand/LandscapeLayout";
import WebLayout from "../components/on-demand/WebLayout";
import tailwind from "twrnc";
import { theme } from "../signals/signals";

const OnDemandScreen = (props) => {
  const { video } = props.route.params;

  const defaultDimensions = {
    height: Dimensions.get("window").height,
    width: Dimensions.get("window").width,
  };
  const defaultOrientation =
    defaultDimensions.width < defaultDimensions.height
      ? "portrait"
      : "landscape";
  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };
  const [orientation, setOrientation] = useState(defaultOrientation);

  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        setOrientation(getOrientation(orientationInfo.orientation));
      }, 300);
    });
  }, [orientation]);

  return (
    <View
      style={tailwind`bg-[${theme.value.backgroundColor}] pl-[${
        Platform.OS === "web" ? "50px" : "10px"
      }] h-full`}
    >
      {orientation === "landscape" &&
        !Platform.isTV &&
        Platform.OS !== "web" && <LandscapeLayout video={video} />}
      {orientation === "portrait" &&
        !Platform.isTV &&
        Platform.OS !== "web" && <PortraitLayout video={video} />}
      {Platform.isTV && <LandscapeLayout video={video} />}
      {Platform.OS === "web" && <WebLayout video={video} />}
    </View>
  );
};

OnDemandScreen.propTypes = {
  route: PropTypes.object.isRequired,
};

export default OnDemandScreen;
