import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { theme } from "../../../signals/signals";

const CallDisconnected = () => {
  return (
    <Image
      resizeMode="contain"
      style={tailwind`h-[25px] w-[25px]`}
      source={require("../../../assets/lan-disconnect.png")}
      tintColor={theme.value.disabledColor}
    />
  );
};

export { CallDisconnected as default };
