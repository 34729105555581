import React from "react";
import PropTypes from "prop-types";
import { Text, Pressable, View } from "react-native";
import tailwind from "twrnc";
import { theme } from "../../signals/signals";

const MiniButton = ({ title, onPress, selected, disabled }) => {
  let buttonStyle = {};

  buttonStyle = selected
    ? tailwind`h-[25px] bg-[${theme.value.primaryButtonSelectedColor}] rounded-lg px-1 pt-1 w-[100px]`
    : disabled
    ? tailwind`h-[25px] bg-[#cccccc] rounded-lg px-2 pt-1 w-[100px]`
    : tailwind`h-[25px] bg-[${theme.value.primaryButtonColor}] rounded-lg px-1 pt-1 w-[100px]`;

  return (
    <Pressable onPress={!disabled ? onPress : null} style={buttonStyle}>
      <View style={tailwind``}>
        <Text
          style={tailwind`text-[${theme.value.primaryButtonTextColor}] font-semibold text-xs self-center`}
        >
          {title}
        </Text>
      </View>
    </Pressable>
  );
};

MiniButton.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

export { MiniButton as default };
