import { ScrollView, Text, View } from "react-native";
import { FitCentrEvent } from "./types/FitCentrEvent";
import ViewerSummaryItem from "./ViewerSummaryItem";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "../base/Constants";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import tailwind from "twrnc";

const ViewerSummary = ({ event }: { event: FitCentrEvent }) => {
  let [fontsLoaded] = useFonts({ Roboto_400Regular });
  if (!fontsLoaded) return null;

  if (!event.bookings.length) {
    return (
      <View style={tailwind`min-w-[200px]`}>
        <Text
          style={[
            tailwind`p-2 self-center font-semibold`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          No bookings
        </Text>
      </View>
    );
  } else {
    return (
      <QueryClientProvider client={queryClient}>
        <View style={tailwind`min-w-[200px] p-2`}>
          <Text
            style={[
              tailwind`font-semibold mb-2 self-center`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            Users
          </Text>
          <ScrollView style={tailwind`max-h-[190px]`}>
            {event.bookings.map((booking) => {
              const userId = booking.user.id;
              return <ViewerSummaryItem key={userId} userId={userId} />;
            })}
          </ScrollView>
        </View>
      </QueryClientProvider>
    );
  }
};

export { ViewerSummary as default };
