import React, { useEffect } from "react";
import {
  useFonts,
  Roboto_400Regular,
  Roboto_500Medium,
} from "@expo-google-fonts/roboto";
import {
  EVENT_TYPE_BOOKABLE,
  EVENT_TYPE_BROADCAST,
  EVENT_TYPE_PRIVATE,
} from "../base/Constants";
import tailwind from "twrnc";
import { Text, Pressable } from "react-native";
import dayjs from "dayjs";
import { View } from "react-native";
import EventAttendee from "./EventAttendee";
import { currentUser, theme } from "../../signals/signals";
import { useSignal } from "@preact/signals-react";

const CalendarEvent = (calendarEvent, PressableProps) => {
  const eventStyle = useSignal();
  const eventTitle = useSignal();

  let eventParticipants = [];
  const { event } = calendarEvent;
  const eventTypeId = event.type.id;
  const isPublic = event.isPublic;

  useEffect(() => {
    const isHistoric = dayjs(event.startDate).diff(dayjs(), "minute") < -5;
    switch (eventTypeId) {
      case EVENT_TYPE_BROADCAST:
        eventStyle.value = tailwind`bg-[${isHistoric
          ? theme.value.calendarEventHistoricBackgroundColor
          : theme.value.calendarEventBackgroundColor
          }] font-semibold border-[1px] border-t-[${theme.value.calendarEventBorderColor
          }] border-b-[${theme.value.calendarEventBorderColor}] border-r-[${theme.value.calendarEventBorderColor
          }] border-l-[10px] border-l-[${theme.value.calendarEventBroadcastColor
          }] rounded-sm self-center`;
        eventTitle.value = calendarEvent.title;
        break;
      case EVENT_TYPE_BOOKABLE:
        eventStyle.value = tailwind`bg-[${isHistoric
          ? theme.value.calendarEventHistoricBackgroundColor
          : theme.value.calendarEventBackgroundColor
          }] font-semibold border-[1px] border-t-[${theme.value.calendarEventBorderColor
          }] border-b-[${theme.value.calendarEventBorderColor}] border-r-[${theme.value.calendarEventBorderColor
          }] border-l-[10px] border-l-[${theme.value.calendarEventBookableColor
          }] rounded-sm self-center`;
        eventTitle.value = "Open Personal Training (click to book)";
        break;
      case EVENT_TYPE_PRIVATE:
        eventStyle.value = tailwind`bg-[${isHistoric
          ? theme.value.calendarEventHistoricBackgroundColor
          : theme.value.calendarEventBackgroundColor
          }] font-semibold border-[1px] border-t-[${theme.value.calendarEventBorderColor
          }] border-b-[${theme.value.calendarEventBorderColor}] border-r-[${theme.value.calendarEventBorderColor
          }] border-l-[10px] border-l-[${theme.value.calendarEventPrivateColor
          }] rounded-sm self-center`;
        eventTitle.value = calendarEvent.title;
        if (event && event.participants && event.participants.length) {
          const participants = event.participants;

          eventParticipants = [];
          participants.forEach((participant) => {
            const newParticipant = {
              id: participant.user.id,
              profileUrl: participant.user.profileUrl,
              name:
                participant.user.firstName + " " + participant.user.lastName,
            };
            if (currentUser.value.id !== participant.user.id) {
              eventParticipants.push(newParticipant);
            }
          });
        }
        break;
      default:
        eventStyle.value = tailwind`bg-[${theme.value.calendarEventBackgroundColor}] font-semibold border-[1px] border-t-[${theme.value.calendarEventBorderColor}] border-b-[${theme.value.calendarEventBorderColor}] border-r-[${theme.value.calendarEventBorderColor}] border-l-[10px] border-l-[${theme.value.calendarEventOtherColor}] rounded-sm self-center`;
        eventTitle.value = calendarEvent.title;
        break;
    }
  }, [event]);

  let [fontsLoaded] = useFonts({ Roboto_400Regular, Roboto_500Medium });
  if (!fontsLoaded) return null;

  return (
    <Pressable
      {...PressableProps}
      style={[...PressableProps.style, eventStyle.value]}
    >
      {dayjs(calendarEvent.end).diff(calendarEvent.start, "minute") < 32 ? (
        <View style={tailwind`flex-row`}>
          <View style={tailwind`flex-row`}>
            <Text
              style={[
                tailwind`text-[${theme.value.calendarEventTextColor}] text-[10px]`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              {eventTitle.value},
            </Text>
            <Text
              style={[
                tailwind`ml-2 text-[${theme.value.calendarEventTextColor}] font-bold text-[10px]`,
                { fontFamily: "Roboto_500Medium" },
              ]}
            >
              {dayjs(calendarEvent.start).format("HH:mm")}
            </Text>
          </View>
          {eventTypeId === EVENT_TYPE_BROADCAST && !isPublic ? (
            <View style={tailwind`grow`}>
              <View style={tailwind`self-end`}>
                <Text
                  style={[
                    tailwind`text-[${theme.value.redColor}] font-bold text-[10px]`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  NOT PUBLISHED
                </Text>
              </View>
            </View>
          ) : null}
        </View>
      ) : (
        <View style={tailwind`flex-row`}>
          <View>
            <Text
              style={[
                tailwind`text-[${theme.value.calendarEventTextColor}]`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              {eventTitle.value}
            </Text>
            <Text
              style={[
                tailwind`text-[${theme.value.calendarEventTextColor}] font-bold`,
                { fontFamily: "Roboto_500Medium" },
              ]}
            >
              {formatStartEnd(calendarEvent.start, calendarEvent.end, "HH:mm")}
            </Text>
          </View>
          {eventTypeId === EVENT_TYPE_PRIVATE ? (
            <View style={tailwind`flex-row grow`}>
              {eventParticipants.map((attendee) => {
                return (
                  <EventAttendee
                    key={attendee.id}
                    attendee={attendee}
                    small={true}
                  />
                );
              })}
            </View>
          ) : null}
          {eventTypeId === EVENT_TYPE_BROADCAST && !isPublic ? (
            <View style={tailwind`grow`}>
              <View style={tailwind`self-end`}>
                <Text
                  style={[
                    tailwind`text-[${theme.value.redColor}] font-bold text-[10px]`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  NOT PUBLISHED
                </Text>
              </View>
            </View>
          ) : null}
          {calendarEvent.children && calendarEvent.children}
        </View>
      )}
    </Pressable>
  );
};

function formatStartEnd(start, end, format) {
  return `${dayjs(start).format(format)} - ${dayjs(end).format(format)}`;
}

export { CalendarEvent as default, formatStartEnd };
