import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { StyledInput } from "../../base/StyledInput";
import { Dimensions, Platform } from "react-native";
import { analyzePostalCode } from "../../helpers/validation";
import { Signal } from "@preact/signals-react";

const PostalCode = ({ country, postalCode, postalCodeStatus }) => {
  const isMobileWebFormat =
    Platform.OS === "web" && Dimensions.get("screen").width < 768;

  useEffect(() => {
    if (postalCode.value?.length) {
      analyzePostalCode(postalCode, postalCodeStatus);
    }
  }, []);

  return (
    <>
      <StyledInput
        label={country === 2 ? "Zip Code" : "Postal Code"}
        onChangeText={(text) => {
          postalCode.value = text;
          analyzePostalCode(postalCode, postalCodeStatus);
        }}
        value={postalCode.value}
        width={isMobileWebFormat ? "300px" : "350px"}
        onBlur={() => analyzePostalCode(postalCode, postalCodeStatus)}
        autoCorrect={false}
        fieldStatus={postalCodeStatus}
      />
    </>
  );
};

PostalCode.propTypes = {
  country: PropTypes.number,
  postalCode: PropTypes.instanceOf(Signal).isRequired,
  postalCodeStatus: PropTypes.instanceOf(Signal).isRequired,
};

export { PostalCode as default };
