import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ActivityIndicator, Platform, Text, View } from "react-native";
import { currentUser, theme } from "../../signals/signals";
import { API_URL, DEFAULT_PROFILE_URL } from "../base/Constants";
import ApiError from "../error/ApiError";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";

const ViewerSummaryItem = ({ userId }: { userId: number }) => {
  const { isLoading, error, data } = useQuery(["users" + userId], () =>
    axios
      .get(API_URL + "users/" + userId, {
        headers: { authorization: currentUser.value.token },
      })
      .then((res) => {
        return res.data;
      })
  );

  let [fontsLoaded] = useFonts({ Roboto_400Regular });
  if (!fontsLoaded) return null;
  if (isLoading) return <ActivityIndicator />;
  if (error) return <ApiError />;

  const { firstName, lastName, profileUrl } = data;
  return (
    <>
      <ViewerSummaryProfileCard
        firstName={firstName}
        lastName={lastName}
        profileUrl={profileUrl}
      />
    </>
  );
};

const ViewerSummaryProfileCard = ({
  firstName,
  lastName,
  profileUrl,
}: {
  firstName: string;
  lastName: string;
  profileUrl: string;
}) => {
  return (
    <View>
      <View style={tailwind`flex-row`}>
        <Image
          style={tailwind`w-[${Platform.OS === "web" ? "40px" : "35px"}] h-[${
            Platform.OS === "web" ? "40px" : "35px"
          }] rounded-full self-center`}
          source={{ uri: profileUrl || DEFAULT_PROFILE_URL }}
          PlaceholderContent={<ActivityIndicator />}
        />
        <View style={tailwind`flex-row flex-wrap py-2 ml-2`}>
          <Text
            style={[
              tailwind`flex-wrap text-[${theme.value.textColor}] text-[14px] self-center`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            {firstName}
          </Text>
          <Text
            style={[
              tailwind`flex-wrap text-[${theme.value.textColor}] text-[14px] self-center pl-1 grow`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            {lastName}
          </Text>
        </View>
      </View>
    </View>
  );
};

export { ViewerSummaryItem as default };
