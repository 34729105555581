import React from "react";
import PropTypes from "prop-types";
import { Pressable, View } from "react-native";
import tailwind from "twrnc"
import { theme } from "../../signals/signals";
import { Image } from "@rneui/themed";

const AddVideoThumbnail = ({ onPress }) => {
  return (
    <Pressable
      onPress={onPress}
      style={tailwind`bg-[${theme.value.addVideoThumbnailBackgroundColor}] w-[212px] h-[118px] rounded-lg mr-[40px] mb-2 border-[1px] border-[${theme.value.addVideoThumbnailBorderColor}] bg-opacity-60`}
    >
      <View style={tailwind`flex items-center justify-center h-full opacity-100`}>
        <Image
          source={require("../../assets//add-icon.png")}
          style={tailwind`w-12 h-12`}
          tintColor={theme.value.addVideoThumbnailIconColor}
        />
      </View>
    </Pressable>
  )
}

AddVideoThumbnail.propTypes = {
  onPress: PropTypes.func.isRequired,
}

export { AddVideoThumbnail as default }